// src/components/OrderPageComponents/AdjustableShelvesSection.js

import React from 'react';
import {getTooltipContent} from './utils/renderUtils';

/**
 * Component for configuring adjustable shelves in the order form.
 *
 * @param {Object} props - The properties for the component.
 * @param {Object} props.itemProperties - The properties of the item being configured.
 * @param {Function} props.handleInputChange - Function to handle input field changes.
 * @param {Function} props.handleToolTipClick - Function to handle tooltip interactions.
 */
function AdjustableShelvesSection({itemProperties, handleInputChange, handleToolTipClick}) {
    return (
        <div className="order-form__section">
            {/* Section title for adjustable shelves */}
            <h3 className="order-form__section-title">Adjustable Shelves</h3>

            {/* Input group for the number of adjustable shelves */}
            <div className="order-form__group">
                <label htmlFor="numOfShelves" className="order-form__label">
                    Number of Adjustable Shelves
                    {/* Tooltip trigger for the field */}
                    <span
                        className="order-form__tooltip-trigger"
                        onClick={() => handleToolTipClick('numOfShelves')}
                    >
                        <span className="order-form__tooltip-content">
                            {getTooltipContent('numOfShelves')}
                        </span>
                    </span>
                </label>

                {/* Dropdown for selecting the number of shelves */}
                <select
                    id="numOfShelves"
                    className="order-form__select"
                    value={itemProperties.numOfShelves || 'Parametric Shelves'}
                    onChange={(e) => handleInputChange(e, 'numOfShelves')}
                >
                    {/* Default option for parametric shelves */}
                    <option value="Parametric Shelves">Parametric Shelves</option>

                    {/* Dynamically generate options for 0 to 12 shelves */}
                    {[...Array(13).keys()].map((i) => (
                        <option key={i} value={i}>
                            {i}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
}

export default AdjustableShelvesSection;
