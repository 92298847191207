// components/OrderMatHardware/BlindFrontsTable.js

import React from 'react';

/**
 * BlindFrontsTable Component
 *
 * This component renders a table displaying dimensions and details of blind fronts.
 *
 * @param {Array} blindFronts - An array of objects representing blind front data.
 * @param {Object} blindFronts[] - Individual blind front information.
 * @param {string} blindFronts[].pos - The position name or item name of the blind front.
 * @param {string} blindFronts[].name1 - The part name of the blind front (e.g., Left, Right, etc.).
 * @param {string} blindFronts[].matId - The material ID used for the blind front.
 * @param {number} blindFronts[].width - The width of the blind front (in inches).
 * @param {number} blindFronts[].height - The height of the blind front (in inches).
 *
 * @returns {JSX.Element | null} - The rendered table displaying blind front details or null if no data is available.
 */
const BlindFrontsTable = ({blindFronts}) => {
    // If blindFronts is null or empty, return null to avoid rendering an empty table
    if (!blindFronts || blindFronts.length === 0) return null;

    return (
        <div className="col-md-6 mb-4">
            <h4 className="mat-hware-titles">Blind Front Dimensions</h4> {/* Section title */}
            <table className="table table-striped mat-hware-tables">
                <thead>
                <tr>
                    <th>Item Name</th>
                    {/* Column header for the position or item name */}
                    <th>Part</th>
                    {/* Column header for the part name */}
                    <th>Material</th>
                    {/* Column header for the material ID */}
                    <th>Width</th>
                    {/* Column header for the width of the blind front */}
                    <th>Height</th>
                    {/* Column header for the height of the blind front */}
                </tr>
                </thead>
                <tbody>
                {/* Iterate over blindFronts to render rows for each blind front */}
                {blindFronts.map((blindFront, index) => (
                    <tr key={index}>
                        <td>{blindFront.pos}</td>
                        {/* Render the position or item name */}
                        <td>{blindFront.name1}</td>
                        {/* Render the part name (e.g., Left, Right) */}
                        <td>{blindFront.matId}</td>
                        {/* Render the material ID */}
                        <td>{blindFront.width}"</td>
                        {/* Render the width in inches */}
                        <td>{blindFront.height}"</td>
                        {/* Render the height in inches */}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default BlindFrontsTable;
