import React from 'react';
import {Button, Card, Col, Container, Row} from 'react-bootstrap';
import {FaTools} from 'react-icons/fa';
import './InternalToolsStyles.css';

const InternalTools = () => {
    return (
        <div className="it-internal-tools">
            {/* Render Navbar */}
            {/*<NavbarLoggedInComponent/>*/}

            <Container fluid className="it-tools-container">
                <Row>
                    <Col className="text-center mt-4">
                        <h1 className="it-title">Internal Tools</h1>
                        <p className="it-description">
                            Quickly access administrative tools to manage your platform effectively.
                        </p>
                    </Col>
                </Row>

                <Row className="justify-content-center mt-5 g-4">
                    {/* Order Management Tool */}
                    <Col xs={12} sm={6} md={4} lg={3} className="d-flex justify-content-center">
                        <Card className="it-tool-card">
                            <Card.Body className="text-center">
                                <div className="it-icon-container">
                                    <FaTools size={50} className="it-tool-icon"/>
                                </div>
                                <h5 className="it-tool-title mt-3">Order Management Tool</h5>
                                <p className="it-tool-description">
                                    Manage and track customer orders in real-time.
                                </p>
                                <Button
                                    href="/internal/order-management"
                                    className="it-tool-button"
                                    variant="dark"
                                >
                                    Go to Tool
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default InternalTools;
