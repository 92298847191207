import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import './ChatBotStyles.css';
import {MessageSquare, Send, Trash2} from 'lucide-react';
import {sendMessage} from '../../features/chatBot/chatBotActions';
import {clearMessages, selectIsLoading, selectMessages} from '../../features/chatBot/chatBotSlice';
import {selectDimensionRanges, selectDimensions} from '../../features/suggestedArticles/suggestedArticlesSlice';

const ChatBot = () => {
    const [input, setInput] = useState('');
    const dispatch = useDispatch();
    const messages = useSelector(selectMessages);
    const isLoading = useSelector(selectIsLoading);
    const dimensions = useSelector(selectDimensions);
    const dimensionRanges = useSelector(selectDimensionRanges);

    const handleSendMessage = async () => {
        if (!input.trim()) return;
        await dispatch(sendMessage({message: input}));
        setInput('');
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
        
    };

    const handleClearHistory = () => {
        dispatch(clearMessages());
    };

    const handleSerialClick = (item) => {
        const botMessage = messages.find(
            (msg) => msg.items?.some((i) => i.serial_number === item.serial_number)
        );
        const currentDimensions = botMessage?.dimensions || dimensions;
        const currentDimensionRanges = botMessage?.dimensionRanges || dimensionRanges;

        const queryParams = new URLSearchParams({
            description: item.description || '',
            width: currentDimensions.width || currentDimensionRanges.width_range.join('-'),
            height: currentDimensions.height || currentDimensionRanges.height_range.join('-'),
            depth: currentDimensions.depth || currentDimensionRanges.depth_range.join('-'),
        }).toString();

        window.open(`/catalog/${item.serial_number}?${queryParams}`, '_blank');
    };

    const renderMessageContent = (msg) => {
        // Materials/Edge Banding List
        if (msg.custom?.list) {
            return (
                <div className="cb-message-content">
                    <p className="cb-message-text">{msg.text}</p>
                    <div className="card mt-2">
                        <div className="list-group list-group-flush">
                            {msg.custom.list.map((item, index) => (
                                <div key={index} className="list-group-item">
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
        }

        // Catalog Items
        if (msg.items && Array.isArray(msg.items)) {
            return (
                <div className="cb-message-content">
                    <p className="cb-message-text">{msg.text}</p>
                    <ul className="cb-serial-list">
                        {msg.items.map((item, idx) => (
                            <li
                                key={idx}
                                className="cb-serial-item"
                                onClick={() => handleSerialClick(item)}
                            >
                                <span className="cb-serial-number">{item.serial_number}</span>
                                <span className="cb-serial-description">{item.description}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }

        // Default Text Message
        return (
            <div className="cb-message-content">
                <p className="cb-message-text">{msg.text}</p>
            </div>
        );
    };

    return (
        <div className="container min-vh-100">
            <div className="cb-wrapper">
                <div className="cb-header">
                    <MessageSquare size={20} className="cb-header-icon"/>
                    <h4 className="cb-title">Chat Assistant</h4>
                    <button
                        className="btn btn-link cb-clear-btn"
                        onClick={handleClearHistory}
                        title="Clear chat history"
                    >
                        <Trash2 size={18}/>
                    </button>
                </div>

                <div className="cb-messages">
                    {messages.map((msg, index) => (
                        <div
                            key={index}
                            className={`cb-message ${msg.sender === 'user' ? 'cb-message-user' : 'cb-message-bot'}`}
                        >
                            {renderMessageContent(msg)}
                        </div>
                    ))}
                </div>

                <div className="cb-input-container">
                    <input
                        type="text"
                        className="form-control cb-input"
                        placeholder="Type your message..."
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        onKeyDown={handleKeyDown}
                        disabled={isLoading}
                    />
                    <button
                        className="btn btn-dark cb-send-btn"
                        onClick={handleSendMessage}
                        disabled={isLoading}
                    >
                        <Send size={18}/>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChatBot;


