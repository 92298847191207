// components/OrderMatHardware/MaterialTable.js

import React from 'react';

/**
 * MaterialTable Component
 *
 * This component renders a table displaying materials used in an order,
 * along with their total square footage (SQFT) and estimated sheet count.
 *
 * @param {Object} materialSqft - Object containing material IDs and their respective total SQFT.
 * @param {number} materialSqft[matId] - Total square footage for a given material ID.
 *
 * @returns {JSX.Element} - The rendered table showing material data.
 */
// const MaterialTable = ({materialSqft}) => {
//     return (
//         <div className="col-md-6 mb-4">
//             <h4 className="mat-hware-titles">Materials</h4>
//             <table className="table table-striped mat-hware-tables">
//                 <thead>
//                 <tr>
//                     <th>Material</th>
//                     {/* Column for material IDs */}
//                     <th>Total SQFT</th>
//                     {/* Column for total square footage */}
//                     <th>Estimated Sheets</th>
//                     {/* Column for estimated sheet count */}
//                 </tr>
//                 </thead>
//                 <tbody>
//                 {/* Iterate over the materialSqft object to render each material row */}
//                 {Object.entries(materialSqft).map(([matId, sqft]) => (
//                     <tr key={matId}>
//                         <td>{matId}</td>
//                         {/* Render material ID */}
//                         <td>{(sqft).toFixed(2)}</td>
//                         {/* Render total SQFT, rounded to 2 decimal places */}
//                         <td>
//                             {Math.round((sqft / 32) * 1.2) || 1}
//                             {/* Estimate sheet count:
//                                   - Divide SQFT by 32 (assumed sheet size)
//                                   - Multiply by 1.2 for a safety factor
//                                   - Use Math.round to get the closest whole number
//                                   - Ensure at least 1 sheet is displayed */}
//                         </td>
//                     </tr>
//                 ))}
//                 </tbody>
//             </table>
//         </div>
//     );
// };


const MaterialTable = ({materialSqft = {}}) => {
    return (
        <div className="col-md-6 mb-4">
            <h4 className="mat-hware-titles">Materials</h4>
            <table className="table table-striped mat-hware-tables">
                <thead>
                <tr>
                    <th>Material</th>
                    <th>Total SQFT</th>
                    <th>Estimated Sheets</th>
                </tr>
                </thead>
                <tbody>
                {Object.entries(materialSqft).map(([matId, sqft]) => (
                    <tr key={matId}>
                        <td>{matId}</td>
                        <td>{sqft.toFixed(2)}</td>
                        <td>
                            {Math.round((sqft / 32) * 1.2) || 1}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default MaterialTable;
