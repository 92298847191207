import React from 'react';
import {Card, Form} from 'react-bootstrap';

const OrderStatusManagement = ({order, handleStatusChange}) => {
    return (
        <Card className="mb-4 om-status-card">
            <Card.Header className="om-card-header">
                <Card.Title className="om-card-title">Order Status Management</Card.Title>
            </Card.Header>
            <Card.Body>
                <div className="d-flex flex-column gap-3">
                    <div className="d-flex align-items-center">
                        <Form.Label className="mb-0 me-3" style={{width: '150px'}}>
                            CNC Status
                        </Form.Label>
                        <Form.Select
                            className="om-cnc-status"
                            value={order.cncStatus || ''}
                            onChange={(e) => handleStatusChange('cncStatus', e.target.value)}
                            style={{width: '200px'}}
                        >
                            <option value="Ordered">Ordered</option>
                            <option value="Complete">Complete</option>
                            <option value="Incomplete">Incomplete</option>
                            <option value="Pending">Pending</option>
                            <option value="Action Needed">Action Needed</option>
                        </Form.Select>
                    </div>
                    <div className="d-flex align-items-center">
                        <Form.Label className="mb-0 me-3" style={{width: '150px'}}>
                            Drawer boxes Status
                        </Form.Label>
                        <Form.Select
                            className="om-drawer-box-status"
                            value={order.dboxStatus || ''}
                            onChange={(e) => handleStatusChange('dboxStatus', e.target.value)}
                            style={{width: '200px'}}
                            disabled={order.dboxStatus === 'N/A'}
                        >
                            <option value="Ordered">Ordered</option>
                            <option value="Shipped">Shipped</option>
                            <option value="Pending">Pending</option>
                            <option value="Action Needed">Action Needed</option>
                            <option value="N/A">N/A</option>
                        </Form.Select>
                    </div>
                    <div className="d-flex align-items-center">
                        <Form.Label className="mb-0 me-3" style={{width: '150px'}}>
                            Hardware Status
                        </Form.Label>
                        <Form.Select
                            className="om-hardware-status"
                            value={order.hwareStatus || ''}
                            onChange={(e) => handleStatusChange('hwareStatus', e.target.value)}
                            style={{width: '200px'}}
                            disabled={order.hwareStatus === 'N/A'}
                        >
                            <option value="Ordered">Ordered</option>
                            <option value="Shipped">Shipped</option>
                            <option value="Pending">Pending</option>
                            <option value="Action Needed">Action Needed</option>
                            <option value="N/A">N/A</option>
                        </Form.Select>
                    </div>
                    <div className="d-flex align-items-center">
                        <Form.Label className="mb-0 me-3" style={{width: '150px'}}>
                            Assembly Status
                        </Form.Label>
                        <Form.Select
                            className="om-assembly-status"
                            value={order.assemblyStatus || ''}
                            onChange={(e) => handleStatusChange('assemblyStatus', e.target.value)}
                            style={{width: '200px'}}
                            disabled={order.assemblyStatus === 'N/A'}
                        >
                            <option value="In Progress">In Progress</option>
                            <option value="Completed">Complete</option>
                            <option value="Incompleted">Incomplete</option>
                            <option value="Pending">Pending</option>
                            <option value="Action Needed">Action Needed</option>
                            <option value="N/A">N/A</option>
                        </Form.Select>
                    </div>
                    <div className="d-flex align-items-center">
                        <Form.Label className="mb-0 me-3" style={{width: '150px'}}>
                            Finishing Status
                        </Form.Label>
                        <Form.Select
                            className="om-finishing-status"
                            value={order.finishingStatus || ''}
                            onChange={(e) => handleStatusChange('finishingStatus', e.target.value)}
                            style={{width: '200px'}}
                            disabled={order.finishingStatus === 'N/A'}
                        >
                            <option value="In Progress">In Progress</option>
                            <option value="Completed">Completed</option>
                            <option value="Incompleted">Incomplete</option>
                            <option value="Pending">Pending</option>
                            <option value="Action Needed">Action Needed</option>
                            <option value="N/A">N/A</option>
                        </Form.Select>
                    </div>
                    <div className="d-flex align-items-center">
                        <Form.Label className="mb-0 me-3" style={{width: '150px'}}>
                            Shipping Status
                        </Form.Label>
                        <Form.Select
                            className="om-shipping-status"
                            value={order.shippingStatus || ''}
                            onChange={(e) => handleStatusChange('shippingStatus', e.target.value)}
                            style={{width: '200px'}}
                        >
                            <option value="Delivered">Delivered</option>
                            <option value="Ready for Pickup">Ready for Pickup</option>
                            <option value="Shipped">Shipped</option>
                            <option value="Pending">Pending</option>
                            <option value="Action Needed">Action Needed</option>
                        </Form.Select>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default OrderStatusManagement;
