// import React, {useEffect, useState} from 'react';
// import axios from 'axios';
// import NavbarLoggedInComponent from '../NavBarComponents/NavbarLoggedInComponent';
// import NavbarComponent from '../NavBarComponents/NavbarComponent';
// import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
// import {useParams} from 'react-router-dom';
// import {useSelector} from 'react-redux';
// import './OrderMatHardwareStyles.css';
// import sealabIcon from '../../images/sealab_icon.png'
//
// const OrderMatHardwareComponent = () => {
//     const isUserLoggedIn = UserLoggedIn();
//     const {orderId} = useParams();
//     const [materialSqft, setMaterialSqft] = useState(null);
//     const [hardwareQuantity, setHardwareQuantity] = useState(null);
//     const [sppData, setSppData] = useState(null);
//     const [drawerData, setDrawerData] = useState(null);
//     const [drawerFronts, setDrawerFronts] = useState([]);
//     const [doorDimensions, setDoorDimensions] = useState([]);
//     const [blindFronts, setBlindFronts] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const token = useSelector(state => state.user.accessToken);
//     const baseURL = process.env.REACT_APP_BASEURL;
//
//     useEffect(() => {
//         const fetchOrderSummary = async () => {
//             try {
//                 const config = {
//                     headers: {
//                         'Authorization': `Bearer ${token}`
//                     }
//                 };
//
//                 const response = await axios.post(`${baseURL}/user/customer-portal/${orderId}/getOrderSummary`, null, config);
//                 const roundedMaterialSqft = Object.entries(response.data.materialSqft).reduce((acc, [matId, sqft]) => {
//                     acc[matId] = parseFloat(sqft.toFixed(2));  // Ensure SQFT is rounded to 2 decimal places
//                     return acc;
//                 }, {});
//                 setMaterialSqft(roundedMaterialSqft);
//                 setHardwareQuantity(response.data.hardwareQuantity);
//                 setSppData(response.data.spp);
//                 setDrawerData(response.data.drawers);
//                 setDrawerFronts(response.data.drawerFronts);
//                 setDoorDimensions(response.data.doorDimensions);
//                 setBlindFronts(response.data.blindFronts);
//             } catch (error) {
//                 console.error('Error fetching order summary:', error);
//             } finally {
//                 setLoading(false);
//             }
//         };
//
//         if (token) {
//             fetchOrderSummary();
//         }
//     }, [orderId, token, baseURL]);
//
//     const handleDownloadPDF = async () => {
//         const jsPDFModule = await import('jspdf');
//         const autoTableModule = await import('jspdf-autotable');
//
//         const jsPDF = jsPDFModule.default
//         const autoTable = autoTableModule.default;
//
//         const doc = new jsPDF();
//
//         // Add company logo
//         doc.addImage(sealabIcon, 'PNG', 14, 10, 30, 30);
//
//         // Add company info
//         const pageWidth = doc.internal.pageSize.getWidth();
//         const companyInfo = [
//             'The Sealab',
//             '63 Flushing Ave',
//             'Building 3, Suite 1108',
//             'Brooklyn, NY 11205',
//             'info@thesealab.com'
//         ];
//
//         doc.setFontSize(10);
//         let startY = 15;
//         companyInfo.forEach((line) => {
//             doc.text(line, pageWidth - 14, startY, {align: 'right'});
//             startY += 5;
//         });
//
//         // Add title
//         doc.setFontSize(18);
//         doc.text('Material & Hardware Summary', doc.internal.pageSize.getWidth() / 2, 45, {align: 'center'});
//
//         startY = 65; // Reset starting Y position after header
//
//         // Material Table
//         doc.text('Materials', doc.internal.pageSize.getWidth() / 2, startY - 2, {align: 'center'})
//         if (materialSqft && Object.keys(materialSqft).length > 0) {
//             const materialData = Object.entries(materialSqft).map(([matId, sqft]) => {
//                 // const totalSheets = roundToNextDecimal(sqft / 32);
//                 // const totalSheets = Math.round((sqft / 32) * 1.2)
//                 const totalSheets = Math.round((sqft / 32) * 1.2) === 0 ? 1 : Math.round((sqft / 32) * 1.2)
//                 console.log('sqft: ', sqft)
//
//                 return [matId, sqft.toFixed(2), totalSheets];
//             });
//             doc.autoTable({
//                 head: [['Material', 'Total SQFT', 'Estimated Sheets']],
//                 body: materialData,
//                 startY,
//                 styles: {
//                     fontSize: 12,
//                     cellPadding: 2,
//                 },
//                 headStyles: {
//                     fillColor: [0, 0, 0], // Black background for header
//                     textColor: [255, 255, 255], // White text
//                 },
//             });
//             startY = doc.lastAutoTable.finalY + 15;
//         }
//
//         // Hardware Table
//         doc.text('Hardware', doc.internal.pageSize.getWidth() / 2, startY - 2, {align: "center"})
//         if (hardwareQuantity && Object.keys(hardwareQuantity).length > 0) {
//             const hardwareData = Object.entries(hardwareQuantity).map(([hardware, quantity]) => [hardware, quantity]);
//             doc.autoTable({
//                 head: [['Hardware', 'QTY']],
//                 body: hardwareData,
//                 startY,
//                 styles: {
//                     fontSize: 12,
//                     cellPadding: 2,
//                 },
//                 headStyles: {
//                     fillColor: [0, 0, 0], // Black background for header
//                     textColor: [255, 255, 255], // White text
//                 },
//             });
//             startY = doc.lastAutoTable.finalY + 15;
//         }
//
//         // Drawer Data Table
//         if (drawerData && drawerData.length > 0) {
//             const drawerDataFormatted = drawerData.map(drawer => [
//                 drawer.pos,
//                 drawer.cpId,
//                 `${drawer.width}"`,
//                 `${drawer.height}"`,
//                 `${drawer.depth}"`,
//             ]);
//             doc.text('Drawer Boxes', doc.internal.pageSize.getWidth() / 2, startY - 2, {align: "center"})
//             doc.autoTable({
//                 head: [['Item Name', 'Drawer Type', 'Width', 'Height', 'Depth']],
//                 body: drawerDataFormatted,
//                 startY,
//                 styles: {
//                     fontSize: 12,
//                     cellPadding: 2,
//                 },
//                 headStyles: {
//                     fillColor: [0, 0, 0], // Black background for header
//                     textColor: [255, 255, 255], // White text
//                 },
//             });
//             startY = doc.lastAutoTable.finalY + 15;
//         }
//
//         // Drawer Fronts Table
//         if (drawerFronts && drawerFronts.length > 0) {
//             const drawerFrontsFormatted = drawerFronts.map(drawerFront => [
//                 drawerFront.pos,
//                 drawerFront.matId,
//                 `${drawerFront.width}"`,
//                 `${drawerFront.height}"`
//             ])
//             doc.text('Drawer Fronts', doc.internal.pageSize.getWidth() / 2, startY - 2, {align: 'center'})
//             doc.autoTable({
//                 title: 'Drawer Fronts',
//                 head: [['Item Name', 'Material', 'Width', 'Height']],
//                 body: drawerFrontsFormatted,
//                 startY,
//                 styles: {
//                     fontSize: 12,
//                     cellPadding: 2,
//                 },
//                 headStyles: {
//                     fillColor: [0, 0, 0],
//                     textColor: [255, 255, 255]
//                 },
//             });
//             startY = doc.lastAutoTable.finalY + 15;
//         }
//
//         // Door Dimensions Table
//         if (doorDimensions && doorDimensions.length > 0) {
//             const doorDimensionsFormatted = doorDimensions.map(doorDimension => [
//                 doorDimension.pos,
//                 doorDimension.name1,
//                 doorDimension.matId,
//                 `${doorDimension.width}"`,
//                 `${doorDimension.height}"`
//             ])
//             doc.text('Door Dimensions', doc.internal.pageSize.getWidth() / 2, startY - 2, {align: 'center'})
//             doc.autoTable({
//                 title: 'Door Dimensions',
//                 head: [['Item Name', 'Door Side', 'Material', 'Width', 'Height']],
//                 body: doorDimensionsFormatted,
//                 startY,
//                 styles: {
//                     fontSize: 12,
//                     cellPadding: 2,
//                 },
//                 headStyles: {
//                     fillColor: [0, 0, 0],
//                     textColor: [255, 255, 255]
//                 },
//             });
//             startY = doc.lastAutoTable.finalY + 15
//         }
//
//         // Blind Fronts Table
//         if (blindFronts && blindFronts.length > 0) {
//             const blindFrontsFormatted = blindFronts.map(blindFront => [
//                 blindFront.pos,
//                 blindFront.name1,
//                 blindFront.matId,
//                 `${blindFront.width}"`,
//                 `${blindFront.height}"`
//             ])
//             doc.text('Blind Front Dimensions', doc.internal.pageSize.getWidth() / 2, startY - 2, {align: 'center'})
//             doc.autoTable({
//                 title: 'Blind Front Dimensions',
//                 head: [['Item Name', 'Part', 'Material', 'Width', 'Height']],
//                 body: blindFrontsFormatted,
//                 startY,
//                 styles: {
//                     fontSize: 12,
//                     cellPadding: 2,
//                 },
//                 headStyles: {
//                     fillColor: [0, 0, 0],
//                     textColor: [255, 255, 255]
//                 },
//             })
//             startY = doc.lastAutoTable.finalY + 15;
//         }
//
//         // Stretchable Purchased Parts (SPP) Table
//         if (sppData && sppData.length > 0) {
//             const sppDataFormatted = sppData.map(spp => [
//                 spp.sppName,
//                 spp.sppLength,
//             ]);
//             const totalSppLength = sppData.reduce((total, spp) => total + parseFloat(spp.sppLength), 0);
//             sppDataFormatted.push(['Total Length (ft)', totalSppLength.toFixed(2)]);
//             doc.text('Stretchable Purchase Parts', doc.internal.pageSize.getWidth() / 2, startY - 2, {align: 'center'})
//             doc.autoTable({
//                 head: [['Name', 'Length (ft)']],
//                 body: sppDataFormatted,
//                 startY,
//                 styles: {
//                     fontSize: 12,
//                     cellPadding: 2,
//                 },
//                 headStyles: {
//                     fillColor: [0, 0, 0], // Black background for header
//                     textColor: [255, 255, 255], // White text
//                 },
//             });
//         }
//
//         doc.save('material_hardware_summary.pdf');
//     };
//
//     const roundToNextDecimal = (value) => {
//         if (value === 0) return 0;
//         const precision = Math.pow(10, Math.floor(Math.log10(1 / value)) + 1);
//         return Math.ceil(value * precision) / precision;
//     };
//
//     if (loading) {
//         return (
//             <>
//                 {isUserLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}
//                 <div className="loading-screen">
//                     <div className="spinner-border" role="status">
//                         <span className="sr-only">Loading...</span>
//                     </div>
//                 </div>
//             </>
//         );
//     }
//
//     function customRound(value) {
//         // Multiply to shift decimal, round, then divide back
//         return Math.round(value * 10) / 10 >= Math.ceil(value) - 0.5 ? Math.ceil(value) : Math.floor(value);
//     }
//
//     return (
//         <>
//             {isUserLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}
//             <div className="mat-hware-container min-vh-100">
//                 <h2 id="mat-hware-summary-title">Order Component Lists</h2>
//                 <div className="download-btn">
//                     <button className="btn btn-dark" onClick={handleDownloadPDF}>Download PDF</button>
//                 </div>
//                 <div className="row">
//                     <div className="col-md-6 mb-4">
//                         <h4 className="mat-hware-titles">Materials</h4>
//                         <table className="table table-striped mat-hware-tables">
//                             <thead>
//                             <tr>
//                                 <th>Material</th>
//                                 <th>Total SQFT</th>
//                                 <th>Estimated Sheets</th>
//                             </tr>
//                             </thead>
//                             <tbody>
//                             {Object.entries(materialSqft).map(([matId, sqft]) => (
//                                 <tr key={matId}>
//                                     <td>{matId}</td>
//                                     <td>{(sqft).toFixed(2)}</td>
//                                     {/*<td>{roundToNextDecimal(sqft / 32) < 1 ? 1 : roundToNextDecimal(sqft / 32)}</td>*/}
//                                     {/*<td>{Math.ceil((sqft) / 32) * 1.2}</td>*/}
//                                     {/*<td>{Math.round((sqft / 32) * 1.2)}</td>*/}
//                                     <td>{Math.round((sqft / 32) * 1.2) === 0 ? 1 : Math.round((sqft / 32) * 1.2)}</td>
//
//
//                                 </tr>
//                             ))}
//                             </tbody>
//                         </table>
//                     </div>
//
//                     {hardwareQuantity && Object.keys(hardwareQuantity).length !== 0 && (
//                         <div className="col-md-6 mb-4">
//                             <h4 className="mat-hware-titles">Hardware</h4>
//                             <table className="table table-striped mat-hware-tables">
//                                 <thead>
//                                 <tr>
//                                     <th>Hardware</th>
//                                     <th>QTY</th>
//                                 </tr>
//                                 </thead>
//                                 <tbody>
//                                 {Object.entries(hardwareQuantity)
//                                     // .filter(hardwareQuantity?.hardware !== 'Marker')
//                                     .map(([hardware, quantity]) => (
//                                         <tr key={hardware}>
//                                             <td>{hardware}</td>
//                                             <td>{quantity}</td>
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </table>
//                         </div>
//                     )}
//
//                     {drawerData && drawerData.length > 0 && (
//                         <div className="col-md-6 mb-4">
//                             <h4 className="mat-hware-titles">Drawer Boxes</h4>
//                             <table className="table table-striped mat-hware-tables">
//                                 <thead>
//                                 <tr>
//                                     <th>Item Name</th>
//                                     <th>Drawer Type</th>
//                                     <th>Width</th>
//                                     <th>Height</th>
//                                     <th>Depth</th>
//                                 </tr>
//                                 </thead>
//                                 <tbody>
//                                 {drawerData.map((drawer, index) => (
//                                     <tr key={index}>
//                                         <td>{drawer.pos}</td>
//                                         <td>{drawer.cpId}</td>
//                                         <td>{drawer.width}"</td>
//                                         <td>{drawer.height}"</td>
//                                         <td>{drawer.depth}"</td>
//                                     </tr>
//                                 ))}
//                                 </tbody>
//                             </table>
//                         </div>
//                     )}
//
//                     {drawerFronts && drawerFronts.length > 0 && (
//                         <div className="col-md-6 mb-4">
//                             <h4 className="mat-hware-titles">Drawer Fronts</h4>
//                             <table className="table table-striped mat-hware-tables">
//                                 <thead>
//                                 <tr>
//                                     <th>Item Name</th>
//                                     <th>Material</th>
//                                     <th>Width</th>
//                                     <th>Height</th>
//                                 </tr>
//                                 </thead>
//                                 <tbody>
//                                 {drawerFronts.map((drawerFront, index) => (
//                                     <tr key={index}>
//                                         <td>{drawerFront.pos}</td>
//                                         <td>{drawerFront.matId}"</td>
//                                         <td>{drawerFront.width}"</td>
//                                         <td>{drawerFront.height}"</td>
//                                     </tr>
//                                 ))}
//                                 </tbody>
//                             </table>
//                         </div>
//                     )}
//                     {doorDimensions && doorDimensions.length > 0 && (
//                         <div className="col-md-6 mb-4">
//                             <h4 className="mat-hware-titles">Door Dimensions</h4>
//                             <table className="table table-striped mat-hware-tables">
//                                 <thead>
//                                 <tr>
//                                     <th>Item Name</th>
//                                     <th>Door Side</th>
//                                     <th>Material</th>
//                                     <th>Width</th>
//                                     <th>Height</th>
//                                 </tr>
//                                 </thead>
//                                 <tbody>
//                                 {doorDimensions.map((doorDimension, index) => (
//                                     <tr key={index}>
//                                         <td>{doorDimension.pos}</td>
//                                         <td>{doorDimension.name1}</td>
//                                         <td>{doorDimension.matId}</td>
//                                         <td>{doorDimension.width}"</td>
//                                         <td>{doorDimension.height}"</td>
//                                     </tr>
//                                 ))}
//                                 </tbody>
//                             </table>
//                         </div>
//                     )}
//                     {blindFronts && blindFronts.length > 0 && (
//                         <div className="col-md-6 mb-4">
//                             <h4 className="mat-hware-titles">Blind Front Dimensions</h4>
//                             <table className="table table-striped mat-hware-tables">
//                                 <thead>
//                                 <tr>
//                                     <th>Item Name</th>
//                                     <th>Part</th>
//                                     <th>Material</th>
//                                     <th>Width</th>
//                                     <th>Height</th>
//                                 </tr>
//                                 </thead>
//                                 <tbody>
//                                 {blindFronts.map((blindFront, index) => (
//                                     <tr key={index}>
//                                         <td>{blindFront.pos}</td>
//                                         <td>{blindFront.name1}</td>
//                                         <td>{blindFront.matId}</td>
//                                         <td>{blindFront.width}"</td>
//                                         <td>{blindFront.height}"</td>
//                                     </tr>
//                                 ))}
//                                 </tbody>
//
//                             </table>
//                         </div>
//                     )}
//                     {sppData && sppData.length > 0 && (
//                         <div className="col-md-6 mb-4">
//                             <h4 className="mat-hware-titles">Stretchable Purchased Parts</h4>
//                             <table className="table table-striped mat-hware-tables">
//                                 <thead>
//                                 <tr>
//                                     <th>Name</th>
//                                     <th>Length (ft)</th>
//                                 </tr>
//                                 </thead>
//                                 <tbody>
//                                 {sppData.map((spp, index) => (
//                                     <tr key={index}>
//                                         <td>{spp.sppName}</td>
//                                         <td>{spp.sppLength}</td>
//                                     </tr>
//                                 ))}
//                                 <tr>
//                                     <td><strong>Total Length (ft)</strong></td>
//                                     <td>
//                                         <strong>{sppData.reduce((total, spp) => total + parseFloat(spp?.sppLength), 0).toFixed(2)}</strong>
//                                     </td>
//                                 </tr>
//                                 </tbody>
//                             </table>
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </>
//     );
// };
//
// export default OrderMatHardwareComponent;
//


/* Modularized */

// components/OrderMatHardware/OrderMatHardwareComponent.js


import React, {useEffect, useState} from 'react';
import axios from 'axios';

import {UserLoggedIn} from '../../hooks/isUserLoggedIn'; // Hook to check if the user is logged in
import {useParams} from 'react-router-dom'; // To get route parameters
import {useSelector} from 'react-redux'; // Access Redux store
import MaterialTable from './MaterialTable'; // Material table component
import HardwareTable from './HardwareTable'; // Hardware table component
import DrawerTable from './DrawerTable'; // Drawer table component
import DrawerFrontsTable from './DrawerFrontsTable'; // Drawer fronts table component
import DoorDimensionsTable from './DoorDimensionsTable'; // Door dimensions table component
import BlindFrontsTable from './BlindFrontsTable'; // Blind fronts table component
import SppTable from './SppTable'; // SPP table component
import './OrderMatHardwareStyles.css'; // Component-specific styles
import generatePDF from "./PDFGenerator";
import ProfileTable from "./ProfileTable"; // Function to generate a PDF

/**
 * OrderMatHardwareComponent - Displays order material and hardware details.
 * Allows users to download a PDF of the order summary.
 *
 * @returns {JSX.Element} - The Order Material and Hardware Summary page.
 */
const OrderMatHardwareComponent = () => {
    const isUserLoggedIn = UserLoggedIn(); // Check if the user is logged in
    const {orderId} = useParams(); // Get orderId from the URL
    const [materialSqft, setMaterialSqft] = useState(null); // State to store material square footage
    const [hardwareQuantity, setHardwareQuantity] = useState(null); // State to store hardware quantity
    const [sppData, setSppData] = useState(null); // State to store SPP data
    const [drawerData, setDrawerData] = useState(null); // State to store drawer data
    const [drawerFronts, setDrawerFronts] = useState([]); // State to store drawer fronts data
    const [doorDimensions, setDoorDimensions] = useState([]); // State to store door dimensions
    const [blindFronts, setBlindFronts] = useState([]); // State to store blind fronts
    const [prfData, setPrfData] = useState([]);
    const [loading, setLoading] = useState(true); // State to manage loading screen
    const token = useSelector(state => state.user.accessToken); // Get user's token from Redux store
    const baseURL = process.env.REACT_APP_BASEURL; // Base URL for API calls

    // Fetch order summary on component mount or when orderId/token changes
    useEffect(() => {
        const fetchOrderSummary = async () => {
            try {
                const config = {
                    headers: {
                        'Authorization': `Bearer ${token}` // Set authorization header
                    }
                };

                // API call to fetch order summary
                const response = await axios.post(`${baseURL}/user/customer-portal/${orderId}/getOrderSummary`, null, config);

                // Round material square footage values to two decimal places
                const roundedMaterialSqft = Object.entries(response.data.materialSqft).reduce((acc, [matId, sqft]) => {
                    acc[matId] = parseFloat(sqft.toFixed(2));
                    return acc;
                }, {});

                // Update states with the fetched data
                setMaterialSqft(roundedMaterialSqft);
                setHardwareQuantity(response.data.hardwareQuantity);
                setSppData(response.data.spp);
                setDrawerData(response.data.drawers);
                setDrawerFronts(response.data.drawerFronts);
                setDoorDimensions(response.data.doorDimensions);
                setBlindFronts(response.data.blindFronts);
                setPrfData(response.data.prfFt)
            } catch (error) {
                console.error('Error fetching order summary:', error); // Log any errors
            } finally {
                setLoading(false); // Stop loading screen
            }
        };

        // Fetch data if the user has a valid token
        if (token) {
            fetchOrderSummary();
        }
    }, [orderId, token, baseURL]);

    /**
     * Handle PDF download - Generates a PDF containing order summary details.
     */
    const handleDownloadPDF = () => {
        generatePDF({
            materialSqft,
            hardwareQuantity,
            drawerData,
            drawerFronts,
            doorDimensions,
            blindFronts,
            sppData,
        });
    };


    // Render the order material and hardware details once data is loaded
    return (
        <>
            {/* Render the appropriate navbar based on user login status */}

            {/* Main container */}
            <div className="mat-hware-container min-vh-100">
                <h2 id="mat-hware-summary-title">Order Component Lists</h2>
                {/* Download PDF button */}
                <div className="download-btn">
                    <button className="btn btn-dark" onClick={handleDownloadPDF}>Download PDF</button>
                </div>
                {/* Tables displaying order components */}
                <div className="row">
                    {/*<MaterialTable materialSqft={materialSqft}/> /!* Material details *!/*/}
                    {materialSqft ? (
                        <MaterialTable materialSqft={materialSqft}/>
                    ) : (
                        <p>Loading material data...</p>
                    )}
                    <HardwareTable hardwareQuantity={hardwareQuantity}/> {/* Hardware details */}
                    <ProfileTable prfData={prfData}/> {/* Profile details */}
                    <DrawerTable drawerData={drawerData}/> {/* Drawer details */}
                    <DrawerFrontsTable drawerFronts={drawerFronts}/> {/* Drawer fronts details */}
                    <DoorDimensionsTable doorDimensions={doorDimensions}/> {/* Door dimensions */}
                    <BlindFrontsTable blindFronts={blindFronts}/> {/* Blind fronts */}
                    <SppTable sppData={sppData}/> {/* SPP details */}
                </div>
            </div>
        </>
    );
};

export default OrderMatHardwareComponent;


