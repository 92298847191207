// import React, {useEffect, useState} from 'react';
// import {Alert, Button, Card, Col, Container, Form, Modal, Nav, Row, Spinner, Table} from 'react-bootstrap';
// import {useSelector} from 'react-redux';
// import {useNavigate, useParams} from 'react-router-dom';
// import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
// import axios from 'axios';
//
// import DataService from '../../api/DataService';
// import './OrderManagementStyles.css';
//
// const OrderDetailPage = () => {
//     // Check if user is logged in
//     const userLoggedIn = UserLoggedIn();
//     const token = useSelector(state => state.user.accessToken);
//
//     // Router
//     const {orderId} = useParams();
//     const navigate = useNavigate();
//
//     // Single order states
//     const [order, setOrder] = useState(null);
//     const [articles, setArticles] = useState([]);
//     const [searchError, setSearchError] = useState('');
//
//     // Image existence
//     const [imageExistsMap, setImageExistsMap] = useState({});
//
//     // Article selection
//     const [selectedArticles, setSelectedArticles] = useState(new Set());
//
//     // Filter articles by position name
//     const [searchFilter, setSearchFilter] = useState('');
//
//     // Materials & Edgebanding
//     const [materials, setMaterials] = useState([]);
//     const [edgebandingOptions, setEdgebandingOptions] = useState([]);
//
//     // Drawers
//     const [drawers, setDrawers] = useState([]);
//
//     // Tabs
//     const [activeTab, setActiveTab] = useState('materials');
//
//     // Saving changes
//     const [saving, setSaving] = useState(false);
//
//     // Modals
//     const [showNewMaterialModal, setShowNewMaterialModal] = useState(false);
//     const [showNewEdgebandingModal, setShowNewEdgebandingModal] = useState(false);
//
//     // Validation
//     const [materialValidation, setMaterialValidation] = useState({});
//     const [edgebandingValidation, setEdgebandingValidation] = useState({});
//
//     // Alerts
//     const [showSuccessAlert, setShowSuccessAlert] = useState(false);
//     const [showErrorAlert, setShowErrorAlert] = useState(false);
//     const [alertMessage, setAlertMessage] = useState('');
//
//     // New Material & Edgebanding
//     const [newMaterial, setNewMaterial] = useState({
//         name: '',
//         description: '',
//         thickness: '',
//         rawCost: '',
//         visible: '1'
//     });
//     const [newEdgebanding, setNewEdgebanding] = useState({
//         imosName: '',
//         englishDescription: '',
//         visible: '1'
//     });
//
//     // Environment
//     const baseURL = process.env.REACT_APP_BASEURL;
//     const thumbNail = process.env.REACT_APP_THMBNAIL;
//
//     // Fetch order details on mount
//     useEffect(() => {
//         if (!orderId) return;
//         fetchOrderSummary(orderId);
//         fetchMaterials();
//         fetchEdgebandingOptions();
//         fetchDrawers();
//         // eslint-disable-next-line
//     }, [orderId]);
//
//     // -----------------------------
//     //  Fetch Single Order Summary
//     // -----------------------------
//     const fetchOrderSummary = async (id) => {
//         try {
//             const response = await axios.get(`${baseURL}/api/orders/${id}/summary`, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`
//                 }
//             });
//             console.log('test: ', response.data.order)
//             if (response.data.order) {
//                 setOrder(response.data.order);
//                 setArticles(response.data.articles);
//                 setSearchError('');
//                 setSelectedArticles(new Set());
//                 // Optional: checkImagesExistence(response.data.articles);
//             } else {
//                 setSearchError('Order not found');
//                 setOrder(null);
//                 setArticles([]);
//             }
//         } catch (error) {
//             console.error('Error fetching order summary:', error);
//             setSearchError(
//                 error.response?.status === 404
//                     ? 'Order not found'
//                     : 'An error occurred while fetching the order. Please try again.'
//             );
//             setOrder(null);
//             setArticles([]);
//         }
//     };
//
//     // -----------------------------
//     //  Fetch Materials
//     // -----------------------------
//     const fetchMaterials = async () => {
//         try {
//             const response = await DataService.getAllMaterials();
//             const sortedMaterials = response.sort((a, b) => {
//                 if (a.description.startsWith('3/4') && !b.description.startsWith('3/4')) {
//                     return -1;
//                 } else if (!a.description.startsWith('3/4') && b.description.startsWith('3/4')) {
//                     return 1;
//                 }
//                 return a.description.localeCompare(b.description);
//             });
//             setMaterials(sortedMaterials);
//         } catch (error) {
//             console.error('Error fetching materials:', error);
//         }
//     };
//
//     // -----------------------------
//     //  Fetch Edgebanding
//     // -----------------------------
//     const fetchEdgebandingOptions = async () => {
//         try {
//             const response = await DataService.getAllEdgebanding();
//             setEdgebandingOptions(response);
//         } catch (error) {
//             console.error('Error fetching edgebanding:', error);
//         }
//     };
//
//     // -----------------------------
//     //  Fetch Drawers
//     // -----------------------------
//     const fetchDrawers = async () => {
//         try {
//             const response = await axios.get(`${baseURL}/api/internal/getDrawers/${orderId}`, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`
//                 }
//             });
//             console.log(response.data)
//             setDrawers(response.data.drawers);
//         } catch (error) {
//             console.error('Error fetching drawers:', error);
//         }
//     };
//
//     // -----------------------------
//     //  Save Changes
//     // -----------------------------
//     const handleSaveChanges = async () => {
//         setSaving(true);
//         try {
//             if (!order) return;
//             const updatedOrder = {
//                 ...order,
//                 articles: articles
//             };
//             setOrder(updatedOrder);
//
//             const response = await axios.post(`${baseURL}/api/internal/custom-materials`, updatedOrder, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`
//                 }
//             });
//
//             // Check if the response is successful
//             if (response.status === 200 || response.status === 201) {
//                 setShowSuccessAlert(true);
//                 setAlertMessage('Changes have been saved successfully!');
//                 window.scrollTo({top: 0, behavior: "instant"})
//             } else {
//                 setShowErrorAlert(true);
//                 setAlertMessage('Unexpected response from the server.');
//                 window.scrollTo({top: 0, behavior: "instant"})
//             }
//         } catch (error) {
//             console.error('Error saving changes:', error);
//             setSearchError('Failed to save changes. Please try again.');
//             setShowErrorAlert(true);
//             setAlertMessage('Failed to save changes. Please try again.');
//         } finally {
//             setSaving(false);
//             console.log('finally')
//         }
//     };
//
//     // -----------------------------
//     //  Status Changes
//     // -----------------------------
//     const handleStatusChange = (field, value) => {
//         setOrder(prev => ({
//             ...prev,
//             [field]: value
//         }));
//     };
//
//     // -----------------------------
//     //  Material / Edgebanding Changes
//     // -----------------------------
//     const handleMaterialChange = (type, value, positionName) => {
//         const updated = articles.map(article => {
//             if (article.positionName === positionName) {
//                 return {...article, [type]: value};
//             }
//             return article;
//         });
//         setArticles(updated);
//     };
//
//     const handleBulkMaterialChange = (type, value) => {
//         const updated = articles.map(article => {
//             if (selectedArticles.has(article.positionName)) {
//                 return {...article, [type]: value};
//             }
//             return article;
//         });
//         setArticles(updated);
//     };
//
//     const handleEdgebandingChange = (type, value, positionName) => {
//         const updated = articles.map(article => {
//             if (article.positionName === positionName) {
//                 return {...article, [type]: value};
//             }
//             return article;
//         });
//         setArticles(updated);
//     };
//
//     const handleBulkEdgebandingChange = (type, value) => {
//         const updated = articles.map(article => {
//             if (selectedArticles.has(article.positionName)) {
//                 return {...article, [type]: value};
//             }
//             return article;
//         });
//         setArticles(updated);
//     };
//
//     // -----------------------------
//     //  Handle Drawer Field Change
//     // -----------------------------
//     const handleDrawerFieldChange = (index, field, value) => {
//         const updatedDrawers = drawers.map((drawer, idx) => {
//             if (idx === index) {
//                 return {...drawer, [field]: value};
//             }
//             return drawer;
//         });
//         setDrawers(updatedDrawers);
//     };
//
//     // -----------------------------
//     //  Select / Deselect Articles
//     // -----------------------------
//     const handleSelectAll = (e) => {
//         if (e.target.checked) {
//             const filtered = articles.filter(a =>
//                 a.positionName.toLowerCase().includes(searchFilter.toLowerCase())
//             ).map(a => a.positionName);
//             setSelectedArticles(new Set(filtered));
//         } else {
//             setSelectedArticles(new Set());
//         }
//     };
//
//     const handleSelectArticle = (positionName) => {
//         const newSet = new Set(selectedArticles);
//         if (newSet.has(positionName)) {
//             newSet.delete(positionName);
//         } else {
//             newSet.add(positionName);
//         }
//         setSelectedArticles(newSet);
//     };
//
//     const filteredArticles = articles.filter(a =>
//         a.positionName.toLowerCase().includes(searchFilter.toLowerCase())
//     );
//
//     const isAllSelected = filteredArticles.length > 0 &&
//         filteredArticles.every(a => selectedArticles.has(a.positionName));
//
//     // -----------------------------
//     //  Create Material / Edgebanding
//     // -----------------------------
//     const validateMaterialForm = () => {
//         const errors = {};
//         if (!newMaterial.name.trim()) errors.name = 'Name is required';
//         if (!newMaterial.description.trim()) errors.description = 'Description is required';
//         if (!newMaterial.thickness) errors.thickness = 'Thickness is required';
//         if (!newMaterial.rawCost) errors.rawCost = 'Raw cost is required';
//         setMaterialValidation(errors);
//         return Object.keys(errors).length === 0;
//     };
//
//     const handleNewMaterialSubmit = async () => {
//         if (!validateMaterialForm()) return;
//         try {
//             const response = await DataService.createNewMaterial(newMaterial, token);
//             if (response.status === 200) {
//                 setShowSuccessAlert(true);
//                 setAlertMessage('Material created successfully!');
//                 setShowNewMaterialModal(false);
//                 await fetchMaterials();
//                 setNewMaterial({
//                     name: '',
//                     description: '',
//                     thickness: '',
//                     rawCost: '',
//                     visible: '1'
//                 });
//             }
//         } catch (error) {
//             setShowErrorAlert(true);
//             setAlertMessage('Failed to create material. Please try again.');
//             console.error('Error creating new material:', error);
//         }
//     };
//
//     const validateEdgebandingForm = () => {
//         const errors = {};
//         if (!newEdgebanding.imosName.trim()) errors.imosName = 'Imos name is required';
//         if (!newEdgebanding.englishDescription.trim()) errors.englishDescription = 'Description is required';
//         setEdgebandingValidation(errors);
//         return Object.keys(errors).length === 0;
//     };
//
//     const handleNewEdgebandingSubmit = async () => {
//         if (!validateEdgebandingForm()) return;
//         try {
//             const response = await DataService.createNewEdgebanding(newEdgebanding, token);
//             if (response.status === 200) {
//                 setShowSuccessAlert(true);
//                 setAlertMessage('Edgebanding created successfully!');
//                 setShowNewEdgebandingModal(false);
//                 await fetchEdgebandingOptions();
//                 setNewEdgebanding({
//                     imosName: '',
//                     englishDescription: '',
//                     visible: '1'
//                 });
//             }
//         } catch (error) {
//             setShowErrorAlert(true);
//             setAlertMessage('Failed to create edgebanding. Please try again.');
//             console.error('Error creating new edgebanding:', error);
//         }
//     };
//
//     // -----------------------------
//     //  Render Status Management
//     // -----------------------------
//     const renderOrderStatuses = () => {
//         if (!order) return null;
//         return (
//             <Card className="mb-4 om-status-card">
//                 <Card.Header className="om-card-header">
//                     <Card.Title className="om-card-title">Order Status Management</Card.Title>
//                 </Card.Header>
//                 <Card.Body>
//                     <div className="d-flex flex-column gap-3">
//                         <div className="d-flex align-items-center">
//                             <Form.Label className="mb-0 me-3" style={{width: '150px'}}>CNC Status</Form.Label>
//                             <Form.Select
//                                 className="om-cnc-status"
//                                 value={order.cncStatus || ''}
//                                 onChange={(e) => handleStatusChange('cncStatus', e.target.value)}
//                                 style={{width: '200px'}}
//                             >
//                                 <option value="Ordered">Ordered</option>
//                                 <option value="Complete">Complete</option>
//                                 <option value="Incomplete">Incomplete</option>
//                                 <option value="Pending">Pending</option>
//                                 <option value="Action Needed">Action Needed</option>
//                             </Form.Select>
//                         </div>
//
//                         <div className="d-flex align-items-center">
//                             <Form.Label className="mb-0 me-3" style={{width: '150px'}}>Drawer boxes Status</Form.Label>
//                             <Form.Select
//                                 className="om-drawer-box-status"
//                                 value={order.dboxStatus || ''}
//                                 onChange={(e) => handleStatusChange('dboxStatus', e.target.value)}
//                                 style={{width: '200px'}}
//                                 disabled={order.dboxStatus === 'N/A'}
//                             >
//                                 <option value="Ordered">Ordered</option>
//                                 <option value="Shipped">Shipped</option>
//                                 <option value="Pending">Pending</option>
//                                 <option value="Action Needed">Action Needed</option>
//                                 <option value="N/A">N/A</option>
//                             </Form.Select>
//                         </div>
//
//                         <div className="d-flex align-items-center">
//                             <Form.Label className="mb-0 me-3" style={{width: '150px'}}>Hardware Status</Form.Label>
//                             <Form.Select
//                                 className="om-hardware-status"
//                                 value={order.hwareStatus || ''}
//                                 onChange={(e) => handleStatusChange('hwareStatus', e.target.value)}
//                                 style={{width: '200px'}}
//                                 disabled={order.hwareStatus === 'N/A'}
//                             >
//                                 <option value="Ordered">Ordered</option>
//                                 <option value="Shipped">Shipped</option>
//                                 <option value="Pending">Pending</option>
//                                 <option value="Action Needed">Action Needed</option>
//                                 <option value="N/A">N/A</option>
//                             </Form.Select>
//                         </div>
//
//                         <div className="d-flex align-items-center">
//                             <Form.Label className="mb-0 me-3" style={{width: '150px'}}>Assembly Status</Form.Label>
//                             <Form.Select
//                                 className="om-assembly-status"
//                                 value={order.assemblyStatus || ''}
//                                 onChange={(e) => handleStatusChange('assemblyStatus', e.target.value)}
//                                 style={{width: '200px'}}
//                                 disabled={order.assemblyStatus === 'N/A'}
//                             >
//                                 <option value="In Progress">In Progress</option>
//                                 <option value="Completed">Complete</option>
//                                 <option value="Incompleted">Incomplete</option>
//                                 <option value="Pending">Pending</option>
//                                 <option value="Action Needed">Action Needed</option>
//                                 <option value="N/A">N/A</option>
//                             </Form.Select>
//                         </div>
//
//                         <div className="d-flex align-items-center">
//                             <Form.Label className="mb-0 me-3" style={{width: '150px'}}>Finishing Status</Form.Label>
//                             <Form.Select
//                                 className="om-finishing-status"
//                                 value={order.finishingStatus || ''}
//                                 onChange={(e) => handleStatusChange('finishingStatus', e.target.value)}
//                                 style={{width: '200px'}}
//                                 disabled={order.finishingStatus === 'N/A'}
//                             >
//                                 <option value="In Progress">In Progress</option>
//                                 <option value="Completed">Completed</option>
//                                 <option value="Incompleted">Incomplete</option>
//                                 <option value="Pending">Pending</option>
//                                 <option value="Action Needed">Action Needed</option>
//                                 <option value="N/A">N/A</option>
//                             </Form.Select>
//                         </div>
//
//                         <div className="d-flex align-items-center">
//                             <Form.Label className="mb-0 me-3" style={{width: '150px'}}>Shipping Status</Form.Label>
//                             <Form.Select
//                                 className="om-shipping-status"
//                                 value={order.shippingStatus || ''}
//                                 onChange={(e) => handleStatusChange('shippingStatus', e.target.value)}
//                                 style={{width: '200px'}}
//                             >
//                                 <option value="Delivered">Delivered</option>
//                                 <option value="Ready for Pickup">Ready for Pickup</option>
//                                 <option value="Shipped">Shipped</option>
//                                 <option value="Pending">Pending</option>
//                                 <option value="Action Needed">Action Needed</option>
//                             </Form.Select>
//                         </div>
//                     </div>
//                 </Card.Body>
//             </Card>
//         );
//     };
//
//     // -----------------------------
//     //  Render Materials Table
//     // -----------------------------
//     const renderMaterialsTable = () => (
//         <>
//             <div className="mb-3">
//                 <Button
//                     className="om-create-material-btn"
//                     variant="dark"
//                     onClick={() => setShowNewMaterialModal(true)}
//                 >
//                     Create New Material
//                 </Button>
//             </div>
//             <Table responsive className="om-table">
//                 <thead>
//                 <tr>
//                     <th>
//                         <Form.Check
//                             type="checkbox"
//                             className="om-checkbox"
//                             checked={isAllSelected}
//                             onChange={handleSelectAll}
//                         />
//                     </th>
//                     <th>Preview</th>
//                     <th>Position Name</th>
//                     <th>Case Material</th>
//                     <th>Front Material</th>
//                     <th>Back Panel Material</th>
//                 </tr>
//                 </thead>
//                 <tbody>
//                 {filteredArticles.map((article, index) => (
//                     <tr key={index} className="om-article-row">
//                         <td>
//                             <Form.Check
//                                 type="checkbox"
//                                 className="om-checkbox"
//                                 checked={selectedArticles.has(article.positionName)}
//                                 onChange={() => handleSelectArticle(article.positionName)}
//                             />
//                         </td>
//                         <td className="om-preview-cell">
//                             {imageExistsMap[article.positionName] ? (
//                                 <img
//                                     src={`${thumbNail}${orderId}/${article.positionName}.png`}
//                                     alt={article.positionName}
//                                     className="om-preview-image"
//                                 />
//                             ) : (
//                                 <div className="om-no-image">No Preview</div>
//                             )}
//                         </td>
//                         <td>{article.positionName}</td>
//                         <td>
//                             <Form.Group className="om-material-select">
//                                 <Form.Select
//                                     className="om-case-material-select"
//                                     value={article.caseMaterial}
//                                     onChange={(e) => {
//                                         if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
//                                             handleBulkMaterialChange('caseMaterial', e.target.value);
//                                         } else {
//                                             handleMaterialChange('caseMaterial', e.target.value, article.positionName);
//                                         }
//                                     }}
//                                 >
//                                     {materials.map((mat, idx) => (
//                                         <option key={idx} value={mat.description}>
//                                             {mat.description}
//                                         </option>
//                                     ))}
//                                 </Form.Select>
//                             </Form.Group>
//                         </td>
//                         <td>
//                             <Form.Group className="om-material-select">
//                                 <Form.Select
//                                     className="om-front-material-select"
//                                     value={article.frontMaterial}
//                                     onChange={(e) => {
//                                         if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
//                                             handleBulkMaterialChange('frontMaterial', e.target.value);
//                                         } else {
//                                             handleMaterialChange('frontMaterial', e.target.value, article.positionName);
//                                         }
//                                     }}
//                                 >
//                                     {materials.map((mat, idx) => (
//                                         <option key={idx} value={mat.description}>
//                                             {mat.description}
//                                         </option>
//                                     ))}
//                                 </Form.Select>
//                             </Form.Group>
//                         </td>
//                         <td>
//                             <Form.Group className="om-material-select">
//                                 <Form.Select
//                                     className="om-backpanel-material-select"
//                                     value={article.backPanelMaterial}
//                                     onChange={(e) => {
//                                         if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
//                                             handleBulkMaterialChange('backPanelMaterial', e.target.value);
//                                         } else {
//                                             handleMaterialChange('backPanelMaterial', e.target.value, article.positionName);
//                                         }
//                                     }}
//                                 >
//                                     {materials.map((mat, idx) => (
//                                         <option key={idx} value={mat.description}>
//                                             {mat.description}
//                                         </option>
//                                     ))}
//                                 </Form.Select>
//                             </Form.Group>
//                         </td>
//                     </tr>
//                 ))}
//                 </tbody>
//             </Table>
//         </>
//     );
//
//     // -----------------------------
//     //  Render Edgebanding Table
//     // -----------------------------
//     const renderEdgebandingTable = () => (
//         <>
//             <div className="mb-3">
//                 <Button
//                     className="om-create-edgebanding-btn"
//                     variant="dark"
//                     onClick={() => setShowNewEdgebandingModal(true)}
//                 >
//                     Create New Edgebanding
//                 </Button>
//             </div>
//             <Table responsive className="om-table">
//                 <thead>
//                 <tr>
//                     <th>
//                         <Form.Check
//                             type="checkbox"
//                             className="om-checkbox"
//                             checked={isAllSelected}
//                             onChange={handleSelectAll}
//                         />
//                     </th>
//                     <th>Preview</th>
//                     <th>Position Name</th>
//                     <th>Case Edge</th>
//                     <th>Front Edge</th>
//                 </tr>
//                 </thead>
//                 <tbody>
//                 {filteredArticles.map((article, index) => (
//                     <tr key={index} className="om-article-row">
//                         <td>
//                             <Form.Check
//                                 type="checkbox"
//                                 className="om-checkbox"
//                                 checked={selectedArticles.has(article.positionName)}
//                                 onChange={() => handleSelectArticle(article.positionName)}
//                             />
//                         </td>
//                         <td className="om-preview-cell">
//                             {imageExistsMap[article.positionName] ? (
//                                 <img
//                                     src={`${thumbNail}${orderId}/${article.positionName}.png`}
//                                     alt={article.positionName}
//                                     className="om-preview-image"
//                                 />
//                             ) : (
//                                 <div className="om-no-image">No Preview</div>
//                             )}
//                         </td>
//                         <td>{article.positionName}</td>
//                         <td>
//                             <Form.Group className="om-material-select">
//                                 <Form.Select
//                                     className="om-case-edge-select"
//                                     value={article.caseEdge || ''}
//                                     onChange={(e) => {
//                                         if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
//                                             handleBulkEdgebandingChange('caseEdge', e.target.value);
//                                         } else {
//                                             handleEdgebandingChange('caseEdge', e.target.value, article.positionName);
//                                         }
//                                     }}
//                                 >
//                                     {edgebandingOptions.map((edge, idx) => (
//                                         <option key={idx} value={edge.englishDescription}>
//                                             {edge.englishDescription}
//                                         </option>
//                                     ))}
//                                 </Form.Select>
//                             </Form.Group>
//                         </td>
//                         <td>
//                             <Form.Group className="om-material-select">
//                                 <Form.Select
//                                     className="om-front-edge-select"
//                                     value={article.frontEdge || ''}
//                                     onChange={(e) => {
//                                         if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
//                                             handleBulkEdgebandingChange('frontEdge', e.target.value);
//                                         } else {
//                                             handleEdgebandingChange('frontEdge', e.target.value, article.positionName);
//                                         }
//                                     }}
//                                 >
//                                     {edgebandingOptions.map((edge, idx) => (
//                                         <option key={idx} value={edge.englishDescription}>
//                                             {edge.englishDescription}
//                                         </option>
//                                     ))}
//                                 </Form.Select>
//                             </Form.Group>
//                         </td>
//                     </tr>
//                 ))}
//                 </tbody>
//             </Table>
//         </>
//     );
//
//     // -----------------------------
//     //  Render Drawers Table
//     // -----------------------------
//     const renderDrawersTable = () => {
//         // Define Drawer Options dropdown options array
//         const drawerOptionsList = [
//             "A - Cutlery Insert",
//             "B - Utensil Insert",
//             "C - Cutlery/Utensil",
//             "D - Knife Insert",
//             "E - Spice Inser",
//             "F - Custom Dividers",
//             "G - Double Drawer",
//             "H1 - File Drawer - Front to Back",
//             "H2 - File Drawer - Side to Side",
//             "J - Shaped Sides",
//             "L - U-Shaped Drawer",
//             "M - Corner Drawer",
//             "N - Trash Pull-Out, Drawer",
//             "O - Trash Pull-Out Drawer",
//             "P - Bread Drawer"
//         ];
//         return (
//             <>
//                 <Table responsive className="om-table">
//                     <thead>
//                     <tr>
//                         <th>Qty</th>
//                         <th>Width</th>
//                         <th>Height</th>
//                         <th>Depth</th>
//                         <th>Bottom</th>
//                         <th>Notch &amp; Bore</th>
//                         <th>Finish</th>
//                         <th>Drawer Options</th>
//                         <th>Finish Front</th>
//                         <th>Scoop</th>
//                         <th>Engrave Logo</th>
//                         <th>Mount Type</th>
//                         <th>Include Glides</th>
//                         <th>Notes</th>
//                     </tr>
//                     </thead>
//                     <tbody>
//                     {drawers.map((drawer, index) => (
//                         <tr key={index}>
//                             <td>{drawer.quantity}</td>
//                             <td>{drawer.width}</td>
//                             <td>{drawer.height}</td>
//                             <td>{drawer.depth}</td>
//                             <td>{drawer.bottom || '1/4"'}</td>
//                             <td>{drawer.notchAndBore || 'Yes'}</td>
//                             <td>{drawer.finish || 'Yes'}</td>
//                             <td>
//                                 <Form.Group>
//                                     <Form.Select
//                                         value={drawer.drawerOptions || ''}
//                                         onChange={(e) => {
//                                             // Extract the letter before the hyphen from the selected option
//                                             const selectedText = e.target.value;
//                                             // In this implementation, the value stored is already the letter (if selected)
//                                             handleDrawerFieldChange(index, 'drawerOptions', selectedText);
//                                         }}
//                                     >
//                                         <option value="">Select</option>
//                                         {drawerOptionsList.map((option, idx) => {
//                                             // Extract the letter(s) before the hyphen
//                                             const value = option.split('-')[0].trim();
//                                             return (
//                                                 <option key={idx} value={value}>
//                                                     {option}
//                                                 </option>
//                                             );
//                                         })}
//                                     </Form.Select>
//                                 </Form.Group>
//                             </td>
//                             <td>{drawer.finishFront || 'No'}</td>
//                             <td>{drawer.scoop || 'No'}</td>
//                             <td>{drawer.engraveLogo || 'No'}</td>
//                             <td>{drawer.mountType || 'Under'}</td>
//                             <td>{(order && order.includeHardware) ? 'Yes' : 'No'}</td>
//                             <td>{drawer.notes || ''}</td>
//                         </tr>
//                     ))}
//                     </tbody>
//                 </Table>
//             </>
//         );
//     };
//
//     // if (!userLoggedIn) {
//     //     return <NavbarComponent/>;
//     // }
//
//     return (
//         <>
//             {/*<NavbarLoggedInComponent/>*/}
//             <Container fluid className="om-container">
//                 {/* Alerts */}
//                 {showSuccessAlert && (
//                     <Alert
//                         variant="success"
//                         onClose={() => setShowSuccessAlert(false)}
//                         dismissible
//                         className="mt-3 om-alert-success"
//                     >
//                         {alertMessage}
//                     </Alert>
//                 )}
//                 {showErrorAlert && (
//                     <Alert
//                         variant="danger"
//                         onClose={() => setShowErrorAlert(false)}
//                         dismissible
//                         className="mt-3 om-alert-error"
//                     >
//                         {alertMessage}
//                     </Alert>
//                 )}
//
//                 {/* Search Error if any */}
//                 {searchError && (
//                     <Row>
//                         <Col>
//                             <Alert variant="danger" className="om-alert">
//                                 {searchError}
//                             </Alert>
//                         </Col>
//                     </Row>
//                 )}
//
//                 {/* If the order is found, show the detail */}
//                 {order && (
//                     <>
//                         {/* Order Basic Info */}
//                         <Row className="mb-4 mt-2">
//                             <Col md={6}>
//                                 <Card className="om-order-details">
//                                     <Card.Header className="om-card-header">
//                                         <Card.Title className="om-card-title">Order Details</Card.Title>
//                                     </Card.Header>
//                                     <Card.Body className="om-card-body">
//                                         <Table className="om-details-table" borderless>
//                                             <tbody>
//                                             <tr>
//                                                 <td className="om-details-label">Order ID:</td>
//                                                 <td className="om-details-value">{order.orderId}</td>
//                                             </tr>
//                                             <tr>
//                                                 <td className="om-details-label">Project Name:</td>
//                                                 <td className="om-details-value">{order.projectName}</td>
//                                             </tr>
//                                             <tr>
//                                                 <td className="om-details-label">Purchase Order:</td>
//                                                 <td className="om-details-value">{order.purchaseOrder}</td>
//                                             </tr>
//                                             <tr>
//                                                 <td className="om-details-label">Status:</td>
//                                                 <td className="om-details-value">{order.status}</td>
//                                             </tr>
//                                             <tr>
//                                                 <td className="om-details-label">Date:</td>
//                                                 <td className="om-details-value">
//                                                     {new Date(order.date).toLocaleDateString()}
//                                                 </td>
//                                             </tr>
//                                             <tr>
//                                                 <td className="om-details-label">
//                                                     <a href={`/user/customer-portal/${order.orderId}/summary`}>
//                                                         View Item Details
//                                                     </a>
//                                                 </td>
//                                             </tr>
//                                             <tr>
//                                                 <td className="om-details-label">
//                                                     <a href={`/user/customer-portal/${order.orderId}/materials&hardware`}>
//                                                         View Component List
//                                                     </a>
//                                                 </td>
//                                             </tr>
//                                             </tbody>
//                                         </Table>
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                             <Col md={6}>
//                                 <Card className="om-special-instructions">
//                                     <Card.Header className="om-card-header">
//                                         <Card.Title className="om-card-title">Special Instructions</Card.Title>
//                                     </Card.Header>
//                                     <Card.Body className="om-card-body">
//                                         <p className="om-instructions-text">
//                                             {order.specialInstructions || 'No special instructions provided'}
//                                         </p>
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                         </Row>
//
//                         {/* Order Status Management */}
//                         {renderOrderStatuses()}
//
//                         {/* Filter + Save */}
//                         <Row className="mb-3">
//                             <Col md={6}>
//                                 <Form.Group className="om-filter-group">
//                                     <Form.Label className="om-filter-label">Filter by Position Name</Form.Label>
//                                     <Form.Control
//                                         className="om-filter-input"
//                                         type="text"
//                                         placeholder="Enter Position Name"
//                                         value={searchFilter}
//                                         onChange={(e) => setSearchFilter(e.target.value)}
//                                     />
//                                 </Form.Group>
//                             </Col>
//                             <Col md={6} className="text-end">
//                                 <Button
//                                     onClick={handleSaveChanges}
//                                     disabled={saving}
//                                     className="om-save-btn"
//                                     variant="dark"
//                                 >
//                                     {saving ? (
//                                         <>
//                                             <Spinner animation="border" size="sm" className="me-2"/>
//                                             Saving Changes...
//                                         </>
//                                     ) : (
//                                         'Save Changes'
//                                     )}
//                                 </Button>
//                             </Col>
//                         </Row>
//
//                         {/* Materials, Edgebanding & Drawers Tabs */}
//                         <Row className="om-articles-section">
//                             <Col>
//                                 <Card className="om-table-card">
//                                     <Card.Header className="om-card-header">
//                                         <Nav variant="tabs" className="om-nav-tabs">
//                                             <Nav.Item>
//                                                 <Nav.Link
//                                                     className={`om-nav-link ${activeTab === 'materials' ? 'active' : ''}`}
//                                                     onClick={() => setActiveTab('materials')}
//                                                 >
//                                                     Materials
//                                                 </Nav.Link>
//                                             </Nav.Item>
//                                             <Nav.Item>
//                                                 <Nav.Link
//                                                     className={`om-nav-link ${activeTab === 'edgebanding' ? 'active' : ''}`}
//                                                     onClick={() => setActiveTab('edgebanding')}
//                                                 >
//                                                     Edgebanding
//                                                 </Nav.Link>
//                                             </Nav.Item>
//                                             <Nav.Item>
//                                                 <Nav.Link
//                                                     className={`om-nav-link ${activeTab === 'drawers' ? 'active' : ''}`}
//                                                     onClick={() => setActiveTab('drawers')}
//                                                 >
//                                                     Drawers
//                                                 </Nav.Link>
//                                             </Nav.Item>
//                                         </Nav>
//                                     </Card.Header>
//                                     <Card.Body className="om-table-wrapper">
//                                         {activeTab === 'materials'
//                                             ? renderMaterialsTable()
//                                             : activeTab === 'edgebanding'
//                                                 ? renderEdgebandingTable()
//                                                 : renderDrawersTable()
//                                         }
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                         </Row>
//                     </>
//                 )}
//
//                 {/* Create New Material Modal */}
//                 <Modal
//                     className="om-modal-new-material"
//                     show={showNewMaterialModal}
//                     onHide={() => setShowNewMaterialModal(false)}
//                 >
//                     <Modal.Header closeButton>
//                         <Modal.Title>Create New Material</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
//                         <Form>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Imos Name</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     value={newMaterial.name}
//                                     onChange={(e) => setNewMaterial({...newMaterial, name: e.target.value})}
//                                     isInvalid={!!materialValidation.name}
//                                 />
//                                 <Form.Control.Feedback type="invalid">
//                                     {materialValidation.name}
//                                 </Form.Control.Feedback>
//                             </Form.Group>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Description</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     value={newMaterial.description}
//                                     onChange={(e) => setNewMaterial({...newMaterial, description: e.target.value})}
//                                     isInvalid={!!materialValidation.description}
//                                 />
//                                 <Form.Control.Feedback type="invalid">
//                                     {materialValidation.description}
//                                 </Form.Control.Feedback>
//                             </Form.Group>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Thickness (mm)</Form.Label>
//                                 <Form.Control
//                                     type="number"
//                                     step="0.01"
//                                     value={newMaterial.thickness}
//                                     onChange={(e) => {
//                                         const value = e.target.value;
//                                         if (value === '' || /^\d*\.?\d*$/.test(value)) {
//                                             setNewMaterial({...newMaterial, thickness: value});
//                                         }
//                                     }}
//                                     isInvalid={!!materialValidation.thickness}
//                                 />
//                                 <Form.Control.Feedback type="invalid">
//                                     {materialValidation.thickness}
//                                 </Form.Control.Feedback>
//                             </Form.Group>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Raw Cost</Form.Label>
//                                 <Form.Control
//                                     type="number"
//                                     step="0.01"
//                                     value={newMaterial.rawCost}
//                                     onChange={(e) => {
//                                         const value = e.target.value;
//                                         if (value === '' || /^\d*\.?\d*$/.test(value)) {
//                                             setNewMaterial({...newMaterial, rawCost: value});
//                                         }
//                                     }}
//                                     isInvalid={!!materialValidation.rawCost}
//                                 />
//                                 <Form.Control.Feedback type="invalid">
//                                     {materialValidation.rawCost}
//                                 </Form.Control.Feedback>
//                             </Form.Group>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Visible</Form.Label>
//                                 <Form.Select
//                                     value={newMaterial.visible}
//                                     onChange={(e) => setNewMaterial({...newMaterial, visible: e.target.value})}
//                                 >
//                                     <option value="1">1</option>
//                                     <option value="0">0</option>
//                                 </Form.Select>
//                             </Form.Group>
//                         </Form>
//                     </Modal.Body>
//                     <Modal.Footer>
//                         <Button variant="secondary" onClick={() => setShowNewMaterialModal(false)}>
//                             Cancel
//                         </Button>
//                         <Button variant="dark" onClick={handleNewMaterialSubmit}>
//                             Create Material
//                         </Button>
//                     </Modal.Footer>
//                 </Modal>
//
//                 {/* Create New Edgebanding Modal */}
//                 <Modal
//                     className="om-modal-new-edgebanding"
//                     show={showNewEdgebandingModal}
//                     onHide={() => setShowNewEdgebandingModal(false)}
//                 >
//                     <Modal.Header closeButton>
//                         <Modal.Title>Create New Edgebanding</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>
//                         <Form>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Imos Name</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     value={newEdgebanding.imosName}
//                                     onChange={(e) => setNewEdgebanding({...newEdgebanding, imosName: e.target.value})}
//                                     isInvalid={!!edgebandingValidation.imosName}
//                                 />
//                                 <Form.Control.Feedback type="invalid">
//                                     {edgebandingValidation.imosName}
//                                 </Form.Control.Feedback>
//                             </Form.Group>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Description</Form.Label>
//                                 <Form.Control
//                                     type="text"
//                                     value={newEdgebanding.englishDescription}
//                                     onChange={(e) => setNewEdgebanding({
//                                         ...newEdgebanding,
//                                         englishDescription: e.target.value
//                                     })}
//                                     isInvalid={!!edgebandingValidation.englishDescription}
//                                 />
//                                 <Form.Control.Feedback type="invalid">
//                                     {edgebandingValidation.englishDescription}
//                                 </Form.Control.Feedback>
//                             </Form.Group>
//                             <Form.Group className="mb-3">
//                                 <Form.Label>Visible</Form.Label>
//                                 <Form.Select
//                                     value={newEdgebanding.visible}
//                                     onChange={(e) => setNewEdgebanding({...newEdgebanding, visible: e.target.value})}
//                                 >
//                                     <option value="1">1</option>
//                                     <option value="0">0</option>
//                                 </Form.Select>
//                             </Form.Group>
//                         </Form>
//                     </Modal.Body>
//                     <Modal.Footer>
//                         <Button variant="secondary" onClick={() => setShowNewEdgebandingModal(false)}>
//                             Cancel
//                         </Button>
//                         <Button variant="dark" onClick={handleNewEdgebandingSubmit}>
//                             Create Edgebanding
//                         </Button>
//                     </Modal.Footer>
//                 </Modal>
//             </Container>
//         </>
//     );
// };
//
// export default OrderDetailPage;


import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, Col, Container, Form, Nav, Row, Spinner, Table} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import axios from 'axios';

import DataService from '../../api/DataService';
import OrderStatusManagement from './OrderStatusManagement';
import MaterialsTable from './MaterialsTable';
import EdgebandingTable from './EdgebandingTable';
import DrawersTable from './DrawersTable';
import NewMaterialModal from './NewMaterialModal';
import NewEdgebandingModal from './NewEdgebandingModal';
import './OrderManagementStyles.css';

const OrderDetailPage = () => {
    // Check if user is logged in (if needed)
    // const userLoggedIn = UserLoggedIn();
    const token = useSelector((state) => state.user.accessToken);
    const {orderId} = useParams();

    // Single order states
    const [order, setOrder] = useState(null);
    const [articles, setArticles] = useState([]);
    const [searchError, setSearchError] = useState('');

    // Image existence
    const [imageExistsMap, setImageExistsMap] = useState({});

    // Article selection
    const [selectedArticles, setSelectedArticles] = useState(new Set());

    // Filter articles by position name
    const [searchFilter, setSearchFilter] = useState('');

    // Materials & Edgebanding
    const [materials, setMaterials] = useState([]);
    const [edgebandingOptions, setEdgebandingOptions] = useState([]);

    // Drawers
    const [drawers, setDrawers] = useState([]);

    // Tabs
    const [activeTab, setActiveTab] = useState('materials');

    // Saving changes
    const [saving, setSaving] = useState(false);

    // Modals
    const [showNewMaterialModal, setShowNewMaterialModal] = useState(false);
    const [showNewEdgebandingModal, setShowNewEdgebandingModal] = useState(false);

    // Validation
    const [materialValidation, setMaterialValidation] = useState({});
    const [edgebandingValidation, setEdgebandingValidation] = useState({});

    // Alerts
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    // New Material & Edgebanding
    const [newMaterial, setNewMaterial] = useState({
        name: '',
        description: '',
        thickness: '',
        rawCost: '',
        visible: '1'
    });
    const [newEdgebanding, setNewEdgebanding] = useState({
        imosName: '',
        englishDescription: '',
        visible: '1'
    });

    // Environment
    const baseURL = process.env.REACT_APP_BASEURL;
    const thumbNail = process.env.REACT_APP_THMBNAIL;

    // Fetch order details on mount
    useEffect(() => {
        if (!orderId) return;
        fetchOrderSummary(orderId);
        fetchMaterials();
        fetchEdgebandingOptions();
        fetchDrawers();
        // eslint-disable-next-line
    }, [orderId]);

    // -----------------------------
    //  Fetch Single Order Summary
    // -----------------------------
    const fetchOrderSummary = async (id) => {
        try {
            const response = await axios.get(`${baseURL}/api/orders/${id}/summary`, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            console.log('test: ', response.data.order);
            if (response.data.order) {
                setOrder(response.data.order);
                setArticles(response.data.articles);
                setSearchError('');
                setSelectedArticles(new Set());
            } else {
                setSearchError('Order not found');
                setOrder(null);
                setArticles([]);
            }
        } catch (error) {
            console.error('Error fetching order summary:', error);
            setSearchError(
                error.response?.status === 404
                    ? 'Order not found'
                    : 'An error occurred while fetching the order. Please try again.'
            );
            setOrder(null);
            setArticles([]);
        }
    };

    // -----------------------------
    //  Fetch Materials
    // -----------------------------
    const fetchMaterials = async () => {
        try {
            const response = await DataService.getAllMaterials();
            const sortedMaterials = response.sort((a, b) => {
                if (a.description.startsWith('3/4') && !b.description.startsWith('3/4')) {
                    return -1;
                } else if (!a.description.startsWith('3/4') && b.description.startsWith('3/4')) {
                    return 1;
                }
                return a.description.localeCompare(b.description);
            });
            setMaterials(sortedMaterials);
        } catch (error) {
            console.error('Error fetching materials:', error);
        }
    };

    // -----------------------------
    //  Fetch Edgebanding Options
    // -----------------------------
    const fetchEdgebandingOptions = async () => {
        try {
            const response = await DataService.getAllEdgebanding();
            setEdgebandingOptions(response);
        } catch (error) {
            console.error('Error fetching edgebanding:', error);
        }
    };

    // -----------------------------
    //  Fetch Drawers
    // -----------------------------
    const fetchDrawers = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/internal/getDrawers/${orderId}`, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            console.log(response.data);
            setDrawers(response.data.drawers);
        } catch (error) {
            console.error('Error fetching drawers:', error);
        }
    };

    // -----------------------------
    //  Save Changes
    // -----------------------------
    const handleSaveChanges = async () => {
        setSaving(true);
        try {
            if (!order) return;
            const updatedOrder = {...order, articles: articles};
            setOrder(updatedOrder);
            const response = await axios.post(`${baseURL}/api/internal/custom-materials`, updatedOrder, {
                headers: {'Authorization': `Bearer ${token}`}
            });
            if (response.status === 200 || response.status === 201) {
                setShowSuccessAlert(true);
                setAlertMessage('Changes have been saved successfully!');
                window.scrollTo({top: 0, behavior: 'instant'});
            } else {
                setShowErrorAlert(true);
                setAlertMessage('Unexpected response from the server.');
                window.scrollTo({top: 0, behavior: 'instant'});
            }
        } catch (error) {
            console.error('Error saving changes:', error);
            setSearchError('Failed to save changes. Please try again.');
            setShowErrorAlert(true);
            setAlertMessage('Failed to save changes. Please try again.');
        } finally {
            setSaving(false);
        }
    };

    // -----------------------------
    //  Status Changes
    // -----------------------------
    const handleStatusChange = (field, value) => {
        setOrder((prev) => ({...prev, [field]: value}));
    };

    // -----------------------------
    //  Material / Edgebanding Changes
    // -----------------------------
    const handleMaterialChange = (type, value, positionName) => {
        const updated = articles.map((article) =>
            article.positionName === positionName ? {...article, [type]: value} : article
        );
        setArticles(updated);
    };

    const handleBulkMaterialChange = (type, value) => {
        const updated = articles.map((article) =>
            selectedArticles.has(article.positionName) ? {...article, [type]: value} : article
        );
        setArticles(updated);
    };

    const handleEdgebandingChange = (type, value, positionName) => {
        const updated = articles.map((article) =>
            article.positionName === positionName ? {...article, [type]: value} : article
        );
        setArticles(updated);
    };

    const handleBulkEdgebandingChange = (type, value) => {
        const updated = articles.map((article) =>
            selectedArticles.has(article.positionName) ? {...article, [type]: value} : article
        );
        setArticles(updated);
    };

    // -----------------------------
    //  Handle Drawer Field Change
    // -----------------------------
    const handleDrawerFieldChange = (index, field, value) => {
        const updatedDrawers = drawers.map((drawer, idx) =>
            idx === index ? {...drawer, [field]: value} : drawer
        );
        setDrawers(updatedDrawers);
    };

    // -----------------------------
    //  Select / Deselect Articles
    // -----------------------------
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const filtered = articles
                .filter((a) => a.positionName.toLowerCase().includes(searchFilter.toLowerCase()))
                .map((a) => a.positionName);
            setSelectedArticles(new Set(filtered));
        } else {
            setSelectedArticles(new Set());
        }
    };

    const handleSelectArticle = (positionName) => {
        const newSet = new Set(selectedArticles);
        if (newSet.has(positionName)) newSet.delete(positionName);
        else newSet.add(positionName);
        setSelectedArticles(newSet);
    };

    const filteredArticles = articles.filter((a) =>
        a.positionName.toLowerCase().includes(searchFilter.toLowerCase())
    );
    const isAllSelected =
        filteredArticles.length > 0 && filteredArticles.every((a) => selectedArticles.has(a.positionName));

    // -----------------------------
    //  Create Material / Edgebanding Handlers
    // -----------------------------
    const validateMaterialForm = () => {
        const errors = {};
        if (!newMaterial.name.trim()) errors.name = 'Name is required';
        if (!newMaterial.description.trim()) errors.description = 'Description is required';
        if (!newMaterial.thickness) errors.thickness = 'Thickness is required';
        if (!newMaterial.rawCost) errors.rawCost = 'Raw cost is required';
        setMaterialValidation(errors);
        return Object.keys(errors).length === 0;
    };

    const handleNewMaterialSubmit = async () => {
        if (!validateMaterialForm()) return;
        try {
            const response = await DataService.createNewMaterial(newMaterial, token);
            if (response.status === 200) {
                setShowSuccessAlert(true);
                setAlertMessage('Material created successfully!');
                setShowNewMaterialModal(false);
                await fetchMaterials();
                setNewMaterial({
                    name: '',
                    description: '',
                    thickness: '',
                    rawCost: '',
                    visible: '1'
                });
            }
        } catch (error) {
            setShowErrorAlert(true);
            setAlertMessage('Failed to create material. Please try again.');
            console.error('Error creating new material:', error);
        }
    };

    const validateEdgebandingForm = () => {
        const errors = {};
        if (!newEdgebanding.imosName.trim()) errors.imosName = 'Imos name is required';
        if (!newEdgebanding.englishDescription.trim()) errors.englishDescription = 'Description is required';
        setEdgebandingValidation(errors);
        return Object.keys(errors).length === 0;
    };

    const handleNewEdgebandingSubmit = async () => {
        if (!validateEdgebandingForm()) return;
        try {
            const response = await DataService.createNewEdgebanding(newEdgebanding, token);
            if (response.status === 200) {
                setShowSuccessAlert(true);
                setAlertMessage('Edgebanding created successfully!');
                setShowNewEdgebandingModal(false);
                await fetchEdgebandingOptions();
                setNewEdgebanding({
                    imosName: '',
                    englishDescription: '',
                    visible: '1'
                });
            }
        } catch (error) {
            setShowErrorAlert(true);
            setAlertMessage('Failed to create edgebanding. Please try again.');
            console.error('Error creating new edgebanding:', error);
        }
    };

    return (
        <>
            <Container fluid className="om-container">
                {showSuccessAlert && (
                    <Alert
                        variant="success"
                        onClose={() => setShowSuccessAlert(false)}
                        dismissible
                        className="mt-3 om-alert-success"
                    >
                        {alertMessage}
                    </Alert>
                )}
                {showErrorAlert && (
                    <Alert
                        variant="danger"
                        onClose={() => setShowErrorAlert(false)}
                        dismissible
                        className="mt-3 om-alert-error"
                    >
                        {alertMessage}
                    </Alert>
                )}
                {searchError && (
                    <Row>
                        <Col>
                            <Alert variant="danger" className="om-alert">
                                {searchError}
                            </Alert>
                        </Col>
                    </Row>
                )}
                {order && (
                    <>
                        <Row className="mb-4 mt-2">
                            <Col md={6}>
                                <Card className="om-order-details">
                                    <Card.Header className="om-card-header">
                                        <Card.Title className="om-card-title">Order Details</Card.Title>
                                    </Card.Header>
                                    <Card.Body className="om-card-body">
                                        <Table className="om-details-table" borderless>
                                            <tbody>
                                            <tr>
                                                <td className="om-details-label">Order ID:</td>
                                                <td className="om-details-value">{order.orderId}</td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Project Name:</td>
                                                <td className="om-details-value">{order.projectName}</td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Purchase Order:</td>
                                                <td className="om-details-value">{order.purchaseOrder}</td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Status:</td>
                                                <td className="om-details-value">{order.status}</td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">Date:</td>
                                                <td className="om-details-value">{new Date(order.date).toLocaleDateString()}</td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">
                                                    <a href={`/user/customer-portal/${order.orderId}/summary`}>View Item
                                                        Details</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="om-details-label">
                                                    <a href={`/user/customer-portal/${order.orderId}/materials&hardware`}>View
                                                        Component List</a>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card className="om-special-instructions">
                                    <Card.Header className="om-card-header">
                                        <Card.Title className="om-card-title">Special Instructions</Card.Title>
                                    </Card.Header>
                                    <Card.Body className="om-card-body">
                                        <p className="om-instructions-text">
                                            {order.specialInstructions || 'No special instructions provided'}
                                        </p>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <OrderStatusManagement order={order} handleStatusChange={handleStatusChange}/>
                        <Row className="mb-3">
                            <Col md={6}>
                                <Form.Group className="om-filter-group">
                                    <Form.Label className="om-filter-label">Filter by Position Name</Form.Label>
                                    <Form.Control
                                        className="om-filter-input"
                                        type="text"
                                        placeholder="Enter Position Name"
                                        value={searchFilter}
                                        onChange={(e) => setSearchFilter(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6} className="text-end">
                                <Button onClick={handleSaveChanges} disabled={saving} className="om-save-btn"
                                        variant="dark">
                                    {saving ? (
                                        <>
                                            <Spinner animation="border" size="sm" className="me-2"/>
                                            Saving Changes...
                                        </>
                                    ) : (
                                        'Save Changes'
                                    )}
                                </Button>
                            </Col>
                        </Row>
                        <Row className="om-articles-section">
                            <Col>
                                <Card className="om-table-card">
                                    <Card.Header className="om-card-header">
                                        <Nav variant="tabs" className="om-nav-tabs">
                                            <Nav.Item>
                                                <Nav.Link
                                                    className={`om-nav-link ${activeTab === 'materials' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('materials')}
                                                >
                                                    Materials
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link
                                                    className={`om-nav-link ${activeTab === 'edgebanding' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('edgebanding')}
                                                >
                                                    Edgebanding
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link
                                                    className={`om-nav-link ${activeTab === 'drawers' ? 'active' : ''}`}
                                                    onClick={() => setActiveTab('drawers')}
                                                >
                                                    Drawers
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Card.Header>
                                    <Card.Body className="om-table-wrapper">
                                        {activeTab === 'materials' && (
                                            <>
                                                <div className="mb-3">
                                                    <Button
                                                        className="om-create-material-btn"
                                                        variant="dark"
                                                        onClick={() => setShowNewMaterialModal(true)}
                                                    >
                                                        Create New Material
                                                    </Button>
                                                </div>
                                                <MaterialsTable
                                                    articles={filteredArticles}
                                                    materials={materials}
                                                    selectedArticles={selectedArticles}
                                                    handleSelectAll={handleSelectAll}
                                                    handleSelectArticle={handleSelectArticle}
                                                    isAllSelected={isAllSelected}
                                                    handleMaterialChange={handleMaterialChange}
                                                    handleBulkMaterialChange={handleBulkMaterialChange}
                                                    imageExistsMap={imageExistsMap}
                                                    orderId={orderId}
                                                    thumbNail={thumbNail}
                                                />
                                            </>
                                        )}
                                        {activeTab === 'edgebanding' && (
                                            <>
                                                <div className="mb-3">
                                                    <Button
                                                        className="om-create-edgebanding-btn"
                                                        variant="dark"
                                                        onClick={() => setShowNewEdgebandingModal(true)}
                                                    >
                                                        Create New Edgebanding
                                                    </Button>
                                                </div>
                                                <EdgebandingTable
                                                    articles={filteredArticles}
                                                    edgebandingOptions={edgebandingOptions}
                                                    selectedArticles={selectedArticles}
                                                    handleSelectAll={handleSelectAll}
                                                    handleSelectArticle={handleSelectArticle}
                                                    isAllSelected={isAllSelected}
                                                    handleEdgebandingChange={handleEdgebandingChange}
                                                    handleBulkEdgebandingChange={handleBulkEdgebandingChange}
                                                    imageExistsMap={imageExistsMap}
                                                    orderId={orderId}
                                                    thumbNail={thumbNail}
                                                />
                                            </>
                                        )}
                                        {activeTab === 'drawers' && (
                                            <DrawersTable
                                                drawers={drawers}
                                                order={order}
                                                handleDrawerFieldChange={handleDrawerFieldChange}
                                            />
                                        )}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </>
                )}
                <NewMaterialModal
                    show={showNewMaterialModal}
                    onHide={() => setShowNewMaterialModal(false)}
                    newMaterial={newMaterial}
                    setNewMaterial={setNewMaterial}
                    handleNewMaterialSubmit={handleNewMaterialSubmit}
                    materialValidation={materialValidation}
                />
                <NewEdgebandingModal
                    show={showNewEdgebandingModal}
                    onHide={() => setShowNewEdgebandingModal(false)}
                    newEdgebanding={newEdgebanding}
                    setNewEdgebanding={setNewEdgebanding}
                    handleNewEdgebandingSubmit={handleNewEdgebandingSubmit}
                    edgebandingValidation={edgebandingValidation}
                />
            </Container>
        </>
    );
};

export default OrderDetailPage;
