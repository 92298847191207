import React from 'react';
import {Button, Form, Modal} from 'react-bootstrap';

const NewEdgebandingModal = ({
                                 show,
                                 onHide,
                                 newEdgebanding,
                                 setNewEdgebanding,
                                 handleNewEdgebandingSubmit,
                                 edgebandingValidation
                             }) => {
    return (
        <Modal className="om-modal-new-edgebanding" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Create New Edgebanding</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Imos Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={newEdgebanding.imosName}
                            onChange={(e) => setNewEdgebanding({...newEdgebanding, imosName: e.target.value})}
                            isInvalid={!!edgebandingValidation.imosName}
                        />
                        <Form.Control.Feedback type="invalid">{edgebandingValidation.imosName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            value={newEdgebanding.englishDescription}
                            onChange={(e) =>
                                setNewEdgebanding({...newEdgebanding, englishDescription: e.target.value})
                            }
                            isInvalid={!!edgebandingValidation.englishDescription}
                        />
                        <Form.Control.Feedback type="invalid">
                            {edgebandingValidation.englishDescription}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Visible</Form.Label>
                        <Form.Select
                            value={newEdgebanding.visible}
                            onChange={(e) => setNewEdgebanding({...newEdgebanding, visible: e.target.value})}
                        >
                            <option value="1">1</option>
                            <option value="0">0</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="dark" onClick={handleNewEdgebandingSubmit}>
                    Create Edgebanding
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NewEdgebandingModal;
