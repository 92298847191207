import React from 'react';
import {getTooltipContent} from './utils/renderUtils';

/**
 * NameCabinetSection Component
 *
 * Renders the section to allow the user to provide a name for their cabinet.
 * Includes input validation and tooltip support.
 *
 * @param {Object} props - Props passed to the component
 * @param {Array} selectedItems - List of selected items for configuration
 * @param {Object} itemProperties - Current properties of the item being configured
 * @param {Object} positionNameRef - Reference for the position name input field
 * @param {Function} handleInputChange - Function to handle input changes
 * @param {Function} handleToolTipClick - Function to handle tooltip interactions
 * @param {string} positionNameError - Error message for position name validation
 */
function NameCabinetSection({
                                selectedItems,
                                itemProperties,
                                positionNameRef,
                                handleInputChange,
                                handleToolTipClick,
                                positionNameError
                            }) {
    return (
        <>
            {/* Render this section only if there's a single selected item */}
            {selectedItems.length <= 1 && (
                <div className="order-form__section">
                    {/* Section Title */}
                    <h3 className="order-form__section-title">Name Your Cabinet</h3>

                    {/* Caution Text */}
                    <p className="item-name-caution-text">
                        **Please use unique Item Names. Note that 'Item Names' for
                        cabinets with a quantity greater than one will be auto-incremented.**
                    </p>

                    {/* Input Group */}
                    <div className="order-form__group">
                        <label htmlFor="positionName" className="order-form__label">
                            Item Name
                            {/* Tooltip Trigger */}
                            <span
                                className="order-form__tooltip-trigger"
                                onClick={() => handleToolTipClick('positionName')}
                            >
                                <span className="order-form__tooltip-content">
                                    {getTooltipContent('positionName')}
                                </span>
                            </span>
                        </label>

                        {/* Position Name Input */}
                        <input
                            type="text"
                            className={`order-form__input ${positionNameError ? 'order-form__input--invalid' : ''}`}
                            placeholder="Please enter a max of six characters"
                            id="positionName"
                            ref={positionNameRef}
                            value={itemProperties.positionName || ''}
                            onChange={(e) => handleInputChange(e, 'positionName')}
                            onKeyDown={(e) => {
                                // Prevent form submission on Enter key press
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                        />

                        {/* Error Message */}
                        {positionNameError && (
                            <div className="order-form__error-message">{positionNameError}</div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

export default NameCabinetSection;
