import React from 'react';

/**
 * ProfileTable Component
 *
 * This component renders a table displaying profile details for an order,
 * including profile ID and the respective footage.
 *
 * @param {Object} prfData - Object containing profile IDs and their respective footage.
 * @param {number} prfData[prfId] - Footage for a given profile ID.
 *
 * @returns {JSX.Element} - The rendered table showing profile data.
 */
const ProfileTable = ({prfData}) => {
    return (
        <div className="col-md-6 mb-4">
            <h4 className="mat-hware-titles">Edgebanding</h4>
            <table className="table table-striped mat-hware-tables">
                <thead>
                <tr>
                    <th>Material</th>
                    {/* Column for profile IDs */}
                    <th>Total Footage (ft)</th>
                    {/* Column for total footage */}
                </tr>
                </thead>
                <tbody>
                {/* Iterate over the prfData object to render each profile row */}
                {Object.entries(prfData).map(([prfId, footage]) => (
                    <tr key={prfId}>
                        <td>{prfId}</td>
                        {/* Render profile ID */}
                        <td>{footage.toFixed(2)}</td>
                        {/* Render footage, rounded to 2 decimal places */}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProfileTable;
