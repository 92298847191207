// src/components/OrderPageComponents/QuantityControl.js
import React from 'react';
import {FaMinusCircle, FaPlusCircle} from "react-icons/fa";

function QuantityControl({itemProperties, handleDecrement, handleIncrement}) {
    return (
        <div className="quantity-control">
            <span className="quantity-text">
                <strong>Quantity:</strong> {itemProperties.quantity}
            </span>
            <FaMinusCircle className="quantity-icon"
                           onClick={() => handleDecrement(itemProperties.itemId)}/>
            <FaPlusCircle className="quantity-icon"
                          onClick={() => handleIncrement(itemProperties.itemId)}/>
        </div>
    );
}

export default QuantityControl;
