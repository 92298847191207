// components/ShowRoom/FilterPanel/FilterPanel.js

import React from 'react';
import Category from './Category';
import memoizedCategories from './memoizedCategories';

/**
 * The FilterPanel component renders a filter panel for filtering cabinets
 * in the showroom based on categories and subcategories.
 *
 * @param {Object} props - Component properties.
 * @param {Function} props.hasCabinetsWithFilterTag - Function to check if any cabinet matches a specific filter tag.
 * @param {Function} props.handleCategoryClick - Function to handle toggling the expanded/collapsed state of a category.
 * @param {Function} props.isCategoryExpanded - Function to check if a category is currently expanded.
 * @param {Function} props.renderFilterItem - Function to render a filter item within a subcategory.
 * @param {Function} props.renderSubcategory - Function to render subcategories under a category.
 *
 * @returns {JSX.Element} - A rendered filter panel.
 */
const FilterPanel = ({
                         hasCabinetsWithFilterTag,
                         handleCategoryClick,
                         isCategoryExpanded,
                         renderFilterItem,
                         renderSubcategory
                     }) => {
    return (
        <div className="filter-panel">
            {/* Iterate through all memoized categories and render a Category component for each */}
            {memoizedCategories.map(({category, subcategories}) => (
                <Category
                    key={category} // Unique key for each category for React rendering optimization
                    category={category} // The name of the main category
                    subcategories={subcategories} // The subcategories within the main category
                    hasCabinetsWithFilterTag={hasCabinetsWithFilterTag} // Check if any cabinet matches a filter tag
                    handleCategoryClick={handleCategoryClick} // Handle click events for expanding/collapsing categories
                    isCategoryExpanded={isCategoryExpanded} // Check if the category is currently expanded
                    renderFilterItem={renderFilterItem} // Function to render individual filter items
                    renderSubcategory={renderSubcategory} // Function to render subcategories
                />
            ))}
        </div>
    );
};

export default FilterPanel;
