// import React, {useCallback, useEffect, useState} from 'react';
// import {useSelector} from 'react-redux';
// import {Link, useNavigate} from 'react-router-dom';
// import NavbarComponent from '../../components/NavBarComponents/NavbarComponent';
// import NavbarLoggedInComponent from '../../components/NavBarComponents/NavbarLoggedInComponent';
// import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
// import {getAllPastOrdersByEmail} from '../../api/OrderService';
// import './CustomerPortalStyles.css';
// import {Helmet} from "react-helmet-async";
//
// const CustomerPortal = () => {
//     const userLoggedIn = UserLoggedIn();
//     const user = useSelector(state => state.user.user || {});
//     const token = useSelector(state => state.user.accessToken);
//     const [orders, setOrders] = useState(null);
//     const [filteredOrders, setFilteredOrders] = useState(null);
//     const [searchQuery, setSearchQuery] = useState('');
//     const navigate = useNavigate();
//
//     useEffect(() => {
//         window.scrollTo({top: 0, behavior: "instant"})
//         if (userLoggedIn && user.email && token) {
//             const fetchOrders = async () => {
//                 try {
//                     const response = await getAllPastOrdersByEmail(user.email, token);
//                     setOrders(response.data);
//                     setFilteredOrders(response.data); // Set both filtered and original orders
//                 } catch (error) {
//                     console.error('Error fetching orders:', error);
//                 }
//             };
//
//             fetchOrders();
//         }
//     }, [userLoggedIn, user.email, token]);
//
//     const handleEditOrder = useCallback((order) => {
//         navigate(`/user/customer-portal/${order.orderId}/edit-order`, {state: {order}});
//     }, [navigate]);
//
//     const handleSearchChange = (e) => {
//         const query = e.target?.value?.toString()?.toLowerCase();
//         setSearchQuery(query);
//         console.log('eventValue: ', e.target.value)
//         console.log('order: ', orders)
//
//         const filtered = orders.filter(order =>
//             order?.orderId?.toString().includes(query) ||
//             order?.status?.toLowerCase().includes(query) ||
//             order?.projectName?.toLowerCase().includes(query) ||
//             order?.purchaseOrder?.toLowerCase().includes(query)
//         );
//         setFilteredOrders(filtered);
//     };
//
//     const renderOrderCard = useCallback((order) => (
//         <div key={`order-${order?.orderId}`} className="col-md-4 mb-4">
//             <div className="card">
//                 <div className="card-body d-flex flex-column">
//                     <div>
//                         <h5 className="card-title">Order #{order?.orderId}</h5>
//                         <h6 className="cp-text">Order Status: {order?.status}</h6>
//                         <h6 className="cp-text">Project Name: {order?.projectName}</h6>
//                         <h6 className="cp-text">Purchase Order: {order?.purchaseOrder}</h6>
//                         <p className="card-date">Date: {order?.date}</p>
//                     </div>
//                     <div className="mt-auto text-end">
//                         <Link to={`/user/customer-portal/${order.orderId}`} className="btn btn-dark me-2">
//                             View Details
//                         </Link>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     ), [handleEditOrder]);
//
//     return (
//         <div className="min-vh-100">
//             <Helmet>
//                 <title>Customer Portal | The Sealab - Manage Your Kitchen & Closet Cabinet Orders</title>
//                 <meta name="description"
//                       content="Access your customer portal at The Sealab. Manage your orders, view order history, and update your account information for high-quality kitchen and closet cabinets."/>
//                 <meta name="keywords"
//                       content="customer portal, manage orders, order history, update account, The Sealab, kitchen cabinets, closet cabinets, cabinet orders"/>
//                 <meta property="og:title"
//                       content="Customer Portal | The Sealab - Manage Your Kitchen & Closet Cabinet Orders"/>
//                 <meta property="og:description"
//                       content="Access your customer portal at The Sealab. Manage your orders, view order history, and update your account information for high-quality kitchen and closet cabinets."/>
//                 <meta property="og:url" content="https://www.thesealab.com/user/customer-portal"/>
//                 <meta property="og:type" content="website"/>
//             </Helmet>
//             {userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}
//             <div className="container my-5">
//                 <h1 className="text-center mb-4">Customer Portal</h1>
//
//                 {/* Search Bar */}
//                 {orders?.length > 1 && (
//                     <div className="cp-search-bar mb-4">
//                         <input
//                             type="text"
//                             placeholder="Search orders by Order ID, Status, Project Name, or Purchase Order"
//                             value={searchQuery}
//                             onChange={handleSearchChange}
//                             className="form-control cp-search-input"
//                         />
//                     </div>
//                 )}
//                 {filteredOrders !== null && filteredOrders.length > 0
//                     ? (
//                         <div className="row">
//                             {filteredOrders.map(renderOrderCard)}
//                         </div>
//                     ) :
//                     filteredOrders !== null && orders.length > 1 && (
//                         <h2 className="text-center">
//                             No orders have been found matching your criteria...
//                         </h2>
//                     )
//                 }
//                 {filteredOrders !== null && orders.length <= 0 && (
//                     <h2 className="text-center">
//                         Your Customer Portal is Empty
//                         <br/>
//                         <h5>Please check back after you complete your first order.</h5>
//                     </h2>
//                 )}
//
//             </div>
//         </div>
//     );
// };
//
// export default CustomerPortal;
//


// CustomerPortal.jsx
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import {getAllPastOrdersByEmail} from '../../api/OrderService';
import './CustomerPortalStyles.css';
import {Helmet} from "react-helmet-async";

const CustomerPortal = () => {
    const userLoggedIn = UserLoggedIn();
    const user = useSelector(state => state.user.user || {});
    const token = useSelector(state => state.user.accessToken);
    const [orders, setOrders] = useState(null);
    const [filteredOrders, setFilteredOrders] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "smooth"});

        const fetchOrders = async () => {
            if (!userLoggedIn || !user.email || !token) return;

            try {
                setIsLoading(true);
                const response = await getAllPastOrdersByEmail(user.email, token);
                setOrders(response.data);
                setFilteredOrders(response.data);
            } catch (error) {
                console.error('Error fetching orders:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchOrders();
    }, [userLoggedIn, user.email, token]);


    const handleEditOrder = useCallback((order) => {
        navigate(`/user/customer-portal/${order.orderId}/edit-order`, {
            state: {order}
        });

    }, [navigate]);

    const handleSearchChange = (e) => {
        const query = e.target.value.trim().toLowerCase();
        setSearchQuery(query);

        if (!orders) return;

        const filtered = orders.filter(order =>
            order?.orderId?.toString().includes(query) ||
            order?.status?.toLowerCase().includes(query) ||
            order?.projectName?.toLowerCase().includes(query) ||
            order?.purchaseOrder?.toLowerCase().includes(query)
        );
        setFilteredOrders(filtered);
    };

    const getStatusClass = (status) => {
        const statusMap = {
            'pending': 'status-pending',
            'in production': 'status-in-production',
            'ready for pickup': 'status-ready',
            'shipped': 'status-shipped',
            'delivered': 'status-delivered',
            'complete': 'status-complete'
        };
        return statusMap[status?.toLowerCase()] || 'status-default';
    };

    const renderOrderCard = useCallback((order) => (
        <div key={`order-${order?.orderId}`} className="col-lg-4 col-md-6 mb-4">
            <div className="order-card">
                <div className="order-card-body">
                    <div className="order-card-header">
                        <h5 className="order-number">Order #{order?.orderId}</h5>
                        <span className={`order-status ${getStatusClass(order?.status)}`}>
                            {order?.status}
                        </span>
                    </div>
                    <div className="order-details">
                        <div className="detail-row">
                            <span className="detail-label">Project Name:</span>
                            <span className="detail-value">{order?.projectName}</span>
                        </div>
                        <div className="detail-row">
                            <span className="detail-label">Purchase Order:</span>
                            <span className="detail-value">{order?.purchaseOrder}</span>
                        </div>
                        <div className="detail-row">
                            <span className="detail-label">Date:</span>
                            <span className="detail-value">{order?.date}</span>
                        </div>
                    </div>
                    <div className="order-card-actions">
                        <Link
                            to={`/user/customer-portal/${order.orderId}`}
                            className="btn view-details-btn"
                        >
                            View Details
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    ), []);

    return (
        <div className="portal-wrapper">
            <Helmet>
                <title>Customer Portal | The Sealab - Manage Your Kitchen & Closet Cabinet Orders</title>
                <meta
                    name="description"
                    content="Access your customer portal at The Sealab. Manage your orders, view order history, and update your account information for high-quality kitchen and closet cabinets."
                />
                <meta
                    name="keywords"
                    content="customer portal, manage orders, order history, update account, The Sealab, kitchen cabinets, closet cabinets, cabinet orders"
                />
                <meta
                    property="og:title"
                    content="Customer Portal | The Sealab - Manage Your Kitchen & Closet Cabinet Orders"
                />
                <meta
                    property="og:description"
                    content="Access your customer portal at The Sealab. Manage your orders, view order history, and update your account information for high-quality kitchen and closet cabinets."
                />
                <meta property="og:url" content="https://www.thesealab.com/user/customer-portal"/>
                <meta property="og:type" content="website"/>
            </Helmet>

            {/*{userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}*/}

            <div className="portal-container">
                <h1 className="portal-title">Customer Portal</h1>

                {orders?.length > 1 && (
                    <div className="search-container">
                        <input
                            type="text"
                            placeholder="Search by Order ID, Status, Project Name, or Purchase Order"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="search-input"
                            aria-label="Search orders"
                        />
                    </div>
                )}

                {isLoading ? (
                    <div className="loading-state">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <>
                        {filteredOrders?.length > 0 ? (
                            <div className="row g-4">
                                {filteredOrders.map(renderOrderCard)}
                            </div>
                        ) : filteredOrders !== null && orders?.length > 1 ? (
                            <div className="no-results">
                                <h2>No orders match your search criteria</h2>
                                <p>Try adjusting your search terms</p>
                            </div>
                        ) : filteredOrders !== null && orders?.length <= 0 && (
                            <div className="empty-state">
                                <h2>Your Customer Portal is Empty</h2>
                                <p>Please check back after you complete your first order.</p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default CustomerPortal;