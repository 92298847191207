// src/components/OrderPageComponents/GapsSection.js

import React from 'react';
import {renderGapInputFields} from './utils/renderUtils';

/**
 * Component for managing front panel gap settings in the Order Form.
 *
 * @param {Object} itemProperties - Properties of the item being configured.
 * @param {Object} errors - Error states for the input fields.
 * @param {function} handleInputChange - Function to handle changes to the input fields.
 * @param {function} handleToolTipClick - Function to display tooltips for input fields.
 */
function GapsSection({itemProperties, errors, handleInputChange, handleToolTipClick}) {
    return (
        <div className="order-form__section">
            {/* Section title for gap settings */}
            <h3 className="order-form__section-title">Set Front Panel Gaps</h3>

            {/* Render the gap input fields dynamically */}
            {renderGapInputFields(itemProperties, errors, handleInputChange, handleToolTipClick)}
        </div>
    );
}

export default GapsSection;
