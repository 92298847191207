import React from 'react';

const DimensioningGuide = () => {
    return (
        <section id="dimensioning" className="mt-5">
            <h2 className="sealab-tutorial__section-title">Understanding Dimensioning</h2>

            <div className="guide-content">
                <p>
                    Accurate dimensioning is crucial for your cabinet order. Remember that
                    cabinet depth measurements refer to the box itself, excluding the front
                    thickness and bumper gaps. When measuring, account for these additional
                    elements in your overall space planning.
                </p>

                <p>
                    Take time to verify all critical dimensions, including width, height,
                    and depth for each cabinet. For special items like side panels, note
                    that some dimensions may not apply. The system's precision is quite
                    high, typically accurate to within a few thousandths of an inch,
                    ensuring your cabinets will fit together precisely as planned.
                </p>

                <p>
                    When working with dimensions, always document your measurements clearly
                    and double-check them against your available space before proceeding
                    to configuration.
                </p>
            </div>
        </section>
    );
};

export default DimensioningGuide;