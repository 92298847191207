// components/OrderMatHardware/DrawerFrontsTable.js

import React from 'react';

/**
 * DrawerFrontsTable Component
 *
 * This component renders a table displaying details of drawer fronts.
 *
 * @param {Array} drawerFronts - An array of objects representing drawer front data.
 * @param {Object} drawerFronts[] - Individual drawer front information.
 * @param {string} drawerFronts[].pos - The position name or item name of the drawer front.
 * @param {string} drawerFronts[].matId - The material ID used for the drawer front.
 * @param {number} drawerFronts[].width - The width of the drawer front (in inches).
 * @param {number} drawerFronts[].height - The height of the drawer front (in inches).
 *
 * @returns {JSX.Element | null} - The rendered table displaying drawer fronts or null if no data is available.
 */
const DrawerFrontsTable = ({drawerFronts}) => {
    // If drawerFronts is null or empty, return null to avoid rendering an empty table
    if (!drawerFronts || drawerFronts.length === 0) return null;

    return (
        <div className="col-md-6 mb-4">
            <h4 className="mat-hware-titles">Drawer Fronts</h4> {/* Section title */}
            <table className="table table-striped mat-hware-tables">
                <thead>
                <tr>
                    <th>Item Name</th>
                    {/* Column header for the position or item name */}
                    <th>Material</th>
                    {/* Column header for the material ID */}
                    <th>Width</th>
                    {/* Column header for the width of the drawer front */}
                    <th>Height</th>
                    {/* Column header for the height of the drawer front */}
                </tr>
                </thead>
                <tbody>
                {/* Iterate over drawerFronts to render rows for each drawer front */}
                {drawerFronts.map((drawerFront, index) => (
                    <tr key={index}>
                        <td>{drawerFront.pos}</td>
                        {/* Render the position or item name */}
                        <td>{drawerFront.matId}</td>
                        {/* Render the material ID */}
                        <td>{drawerFront.width}"</td>
                        {/* Render the width in inches */}
                        <td>{drawerFront.height}"</td>
                        {/* Render the height in inches */}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default DrawerFrontsTable;
