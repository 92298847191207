import React from 'react';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import './AboutUsStyles.css';
import {Col, Container, Row} from 'react-bootstrap';
import {Helmet} from "react-helmet-async";

const AboutUsComponent = () => {
    const userLoggedIn = UserLoggedIn();
    return (
        <div>
            <Helmet>
                <title>About Us | The Sealab - Premium Kitchen & Closet Cabinets</title>
                <meta name="description"
                      content="Learn more about The Sealab, your trusted source for high-quality kitchen and closet cabinets. Discover our story, values, and commitment to excellence."/>
                <meta name="keywords"
                      content="about us, The Sealab, kitchen cabinets, closet cabinets, company values, our story, premium cabinets, high-quality cabinets"/>
                <meta property="og:title" content="About Us | The Sealab - Premium Kitchen & Closet Cabinets"/>
                <meta property="og:description"
                      content="Learn more about The Sealab, your trusted source for high-quality kitchen and closet cabinets. Discover our story, values, and commitment to excellence."/>
                <meta property="og:url" content="https://www.thesealab.com/about"/>
                <meta property="og:type" content="website"/>
            </Helmet>

            {/*{userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}*/}
            <Container fluid className="aboutus-container">
                <Row>
                    <Col md={3} className="sidebar aboutus-sb">
                        <ul className="sidebar-list">
                            <li><a href="#about" className="sidebar-link">1. About Us</a></li>
                            <li><a href="#process" className="sidebar-link">2. Our Process</a></li>
                            <li><a href="#professionals" className="sidebar-link">3. For Professionals</a></li>
                            <li><a href="#faq" className="sidebar-link">4. FAQ</a></li>
                            <li><a href="#case-studies" className="sidebar-link">5. Case Studies</a></li>
                            {/*<li><a href="#resources" className="sidebar-link">6. Resources & Documentation</a></li>*/}
                        </ul>
                    </Col>
                    <Col md={9} className="content">
                        <section id="about">
                            <h2>About Sealab</h2>
                            <p>Sealab is an innovative platform bridging the gap between custom design and efficient
                                manufacturing in the cabinetry and furniture industry. We believe in enhancing the
                                capabilities of skilled professionals, not replacing them. We empower professionals to
                                streamline their production processes without compromising on craftsmanship or design
                                flexibility.</p>
                        </section>
                        <section id="process">
                            <h2>Our Process</h2>
                            <h3>Our Approach</h3>
                            <p>Unlike traditional cabinet companies, Sealab doesn't provide design tools or finished
                                products. Instead, we offer a powerful web-based configuration tool that complements
                                your existing design workflow:</p>
                            <ol>
                                <li>Create your design using your preferred software</li>
                                <li>Identify compatible cabinet objects within your design</li>
                                <li>Use our web app to customize these objects to your specifications</li>
                                <li>Generate precise manufacturing data for each customized object</li>
                            </ol>
                            <h3>Our Platform</h3>
                            <p>Sealab's system produces:</p>
                            <ol>
                                <li>2D drawings (PDF, DWG, and DXF formats) for each unique component</li>
                                <li>3D Models to visualize cabinets</li>
                                <li>Comprehensive part and hardware lists</li>
                                <li>Cut lists for material processing</li>
                            </ol>
                            <p>These outputs can be seamlessly integrated back into your design software for room
                                layouts or architectural plans.</p>
                            <h3>Manufacturing Process</h3>
                            <p>We partner with vetted factory vendors to offer a range of services. You have the
                                flexibility to select which aspects of the manufacturing process you want to include in
                                your project:</p>
                            <ul>
                                <li>Optimization of manufacturing processes</li>
                                <li>Parts production</li>
                                <li>Hardware sourcing</li>
                                <li>Custom dovetail drawer manufacturing</li>
                                <li>Assembly</li>
                                <li>Finishing</li>
                                <li>Shipping</li>
                            </ul>
                            <p>You can opt to coordinate and pay for any combination of these services through Sealab,
                                streamlining your procurement process. This à la carte approach allows you to leverage
                                our network where it best complements your existing capabilities and project
                                requirements.</p>
                        </section>
                        <section id="professionals">
                            <h2>For Professionals</h2>
                            <p>Sealab's platform revolutionizes the custom cabinetry and furniture industry by bridging
                                the gap between design and manufacturing. Our system empowers professionals to focus on
                                creativity and client relationships while dramatically increasing efficiency and
                                output.</p>
                            <h3>Who We Serve</h3>
                            <p>Our platform caters primarily to industry professionals:</p>
                            <ul>
                                <li>Custom cabinetmakers</li>
                                <li>Architectural millwork specialists</li>
                                <li>Home renovation contractors</li>
                                <li>Interior designers and architects</li>
                            </ul>
                            <p>We also welcome advanced DIY enthusiasts comfortable with professional-grade design
                                processes.</p>
                            <h3>Advantages</h3>
                            <ul>
                                <li>Efficiency: Automate time-consuming documentation and data preparation</li>
                                <li>Flexibility: Customize objects to precise specifications</li>
                                <li>Integration: Outputs designed to work with your existing software and workflows</li>
                                <li>Quality: Leverage professional-grade manufacturing without the overhead</li>
                            </ul>
                            <p>Sealab bridges the gap between traditional craftsmanship and modern manufacturing
                                technologies. Our platform streamlines the transition from design to production,
                                allowing professionals to allocate more resources to innovation and client engagement.
                                By leveraging our tools, businesses in the custom cabinetry and furniture industry can
                                enhance their efficiency without compromising on quality or creativity. We invite
                                industry professionals to explore how Sealab can integrate with and improve their
                                existing workflows.</p>
                        </section>
                        <section id="faq">
                            <h2>FAQ</h2>
                            <ol>
                                <li>What exactly does Sealab offer?</li>
                                <p>Sealab offers a web-based configuration tool for custom cabinetry and furniture,
                                    allowing professionals to generate precise manufacturing data from their
                                    designs.</p>
                                <li>How does Sealab differ from traditional cabinet manufacturers?</li>
                                <p>Sealab doesn't provide design tools or finished products. Instead, it offers a
                                    platform to translate your designs into manufacturing data, working with partner
                                    factories for production.</p>
                                <li>Do I need special software to use Sealab's platform?</li>
                                <p>No, Sealab's platform is web-based. You can use your preferred design software, then
                                    use our configurator to specify details.</p>
                                <li>Can I use Sealab for custom-sized cabinets?</li>
                                <p>Yes, Sealab's parametric configurator allows for infinite customization of dimensions
                                    and features.</p>
                                <li>What types of materials are available?</li>
                                <p>Sealab offers a range of common materials for case, back, and front components.
                                    Custom materials can be requested.</p>
                                <li>Can I specify my own materials?</li>
                                <p>Yes, while we offer common options, custom materials can be used upon request.</p>
                                <li>How accurate are the parts I'll receive?</li>
                                <p>Parts are machined to high precision, typically within 1/10th of a millimeter.</p>
                                <li>What kind of hardware is compatible with Sealab cabinets?</li>
                                <p>Sealab's cabinets are compatible with standard hardware. Machining for hinges and
                                    drawer slides follows Blum or Salice standards, and leg leveler drillings are based
                                    on Hafele Axilo.</p>
                                <li>Can I change my order after submitting it?</li>
                                <p>Yes, you can make up to two revisions without additional charge after initial
                                    submission.</p>
                                <li>How long does it take to get a quote?</li>
                                <p>Typically, you'll receive a quote within one business day.</p>
                                <li>What's included in the $25 processing fee?</li>
                                <p>The fee covers the generation of all manufacturing data, including 2D drawings, 3D
                                    models, cut lists, and parts inventories.</p>
                                <li>How many revisions can I make to my order?</li>
                                <p>You can make up to two revisions without additional charge.</p>
                                <li>Do you offer design services?</li>
                                <p>No, Sealab focuses on manufacturing data generation. You should use your preferred
                                    design process.</p>
                                <li>Can I see samples before placing a large order?</li>
                                <p>You'd need to check with Sealab directly about their sample policy.</p>
                                <li>What's your minimum order quantity?</li>
                                <p>There's no mention of a minimum order quantity, but you should confirm with
                                    Sealab.</p>
                                <li>Do you offer assembly services?</li>
                                <p>No, Sealab provides the components for you to assemble.</p>
                                <li>How are the cabinets shipped?</li>
                                <p>Shipping is not automatically included but can be arranged separately upon
                                    request.</p>
                                <li>What's your return policy?</li>
                                <p>Refer to our <a href="/the-sealab/legal/terms">Terms of Service</a>. Returns on
                                    custom parts are not accepted, and any
                                    changes made to approved and paid orders must be made within 48 hrs of placing the
                                    order.</p>
                                <li>How do I handle damages or missing parts?</li>
                                <p>Contact Sealab's customer support for assistance with any issues.</p>
                                <li>Can I use Sealab for commercial projects?</li>
                                <p>Yes, Sealab caters to various professionals, including those working on commercial
                                    projects.</p>
                                <li>Do you offer financing options?</li>
                                <p>No, Sealab does not currently offer any financing options. Once an order is approved,
                                    it must be paid in full prior to manufacturing.</p>
                                <li>How do I integrate Sealab outputs with my existing design software?</li>
                                <p>Sealab provides 2D drawings and 3D models that can be imported back into most design
                                    software. Each workflow is unique, and we can accommodate a wide range of options.
                                    For those working in 2d, we provide a 2d DWG and PDF containing layouts with various
                                    views. The DWG also contains a 3D model of the object, which is generated from the
                                    same data as the manufacturing part files, which ensures the accuracy.</p>
                                <li>What kind of customer support do you offer?</li>
                                <p>Sealab is a small but growing company, and as we grow, we will keep customer support
                                    as a top priority. Please reach out with any questions you might have.</p>
                                <li>Are your products certified for any environmental standards?</li>
                                <p>Insofar as the sheet goods a customer specified are certified, yes. We can work with
                                    any panel type that is available to us regionally, or any panels the customer
                                    specifies as long as they are reasonably flat and stable.</p>
                                <li>Can I save my designs for future use?</li>
                                <p>Yes, you can save partially completed carts in your customer portal for later
                                    access.</p>
                                <li>How do I become a Sealab manufacturing partner?</li>
                                <p>If you are interested in producing parts or handling finishing for Sealab, please
                                    contact us today!.</p>
                                <li>Do you offer white-label services for other cabinet brands?</li>
                                <p>If you are interested in a white-label version of Sealab.com, please contact us today
                                    for licensing information!.</p>
                                <li>What's your pricing structure?</li>
                                <p>All shopping carts that are processed are charged a $25 non-refundable fee, which is
                                    applied toward the order once executed. The order consists of fully parametric
                                    objects, which includes varying quantities of plywood, cut-to-length parts,
                                    hardware, and finishing. All of these are calculated after the geometry is generated
                                    to be as accurate as possible.</p>
                                <li>How do you ensure quality control?</li>
                                <p>Sealab works with vetted manufacturing partners to ensure consistent, high-quality
                                    output. We strive for the highest quality standards, and we work closely with our
                                    partners to ensure these standards are consistently met.</p>
                                <li>Can I request custom hardware installations?</li>
                                <p>Catalog items are based on standard hardware options. Any alternative hardware or
                                    mechanisms can be requested, but are not guaranteed.</p>
                                <li>Do you offer any training or tutorials for using your platform?</li>
                                <p>Documentation may be available at a later time.</p>
                                <li>What file formats do you support for design uploads?</li>
                                <p>Sealab does not configure from design drawings. Customers configure their own
                                    items.</p>
                                <li>How secure is my design data on your platform?</li>
                                <p>Sealab’s hosting is provided on a firewalled server, and all customer and client
                                    information detailed in our privacy policy is protected behind the user login.
                                    Drawings that are available via QR code link are available without login, stripped
                                    of any customer or client data.</p>
                                <li>Do you ship internationally?</li>
                                <p>No, Sealab is only available in North America, and shipping is not provided at this
                                    time.</p>
                                <li>Can I track my order status?</li>
                                <p>No, tracking is not provided at this time, but may be available in the future.</p>
                                <li>What's your typical lead time from order to delivery?</li>
                                <p>Specific lead times aren't provided, but quote turnaround for quotes is typically one
                                    business day. Production lead time can vary from 1-6 weeks depending on current
                                    factory capacity, material availability, and other factors.</p>
                                <li>Do you offer any warranties on your products?</li>
                                <p>We do not offer any warranty at this time.</p>
                                <li>How do I calculate the total cost for a project?</li>
                                <p>You can configure your project in the Sealab platform to get a quote for the total
                                    cost.</p>
                                <li>Can I use Sealab for non-cabinet furniture pieces?</li>
                                <p>Yes, Sealab's platform can be used for various custom furniture pieces, not just
                                    cabinets.</p>
                                <li>Do you offer any discounts for large orders or repeat customers?</li>
                                <p>For large orders, please contact support to discuss discounts.</p>
                            </ol>
                        </section>
                        <section id="case-studies">
                            <h2>Case Studies</h2>
                            <h3>The Custom Cabinet Shop</h3>
                            <p>For decades, Tim's custom cabinet shop has been a staple in his community. His
                                craftsmanship is renowned, but lately, he's been feeling the pressure of increased
                                demand and competition. Tim spends countless hours at his table saw, meticulously
                                cutting sheet after sheet of plywood for cabinet boxes. The process is time-consuming
                                and back breaking, and despite his best efforts, there's always some material waste.
                                Worse, if a client requests a last-minute change, it often means starting from
                                scratch.</p>
                            <p>Tim decides it's time for a change. He starts using Sealab for his basic cabinet boxes.
                                At first, the transition is challenging - old habits die hard. But soon, Tim realizes
                                he's spending far less time on repetitive cuts and more time on the aspects of cabinetry
                                he truly enjoys. When a client requests a change, Tim can quickly adjust the
                                specifications in Sealab and order new components without wasting materials or valuable
                                shop time. The precision of the pre-cut pieces also means less time spent adjusting and
                                fitting during assembly.</p>
                            <p>With the time saved on basic construction, Tim finds he can take on more projects and
                                focus on the custom details that set his work apart. The frustration of material waste
                                and inflexibility in the face of client changes becomes a thing of the past.</p>
                            <h3>The General Contractor</h3>
                            <p>Sarah, a home renovation contractor, has always prided herself on delivering quality work
                                on time. However, kitchen renovations have become a growing source of stress.
                                Coordinating with cabinet makers, dealing with long lead times, and managing last-minute
                                changes often throw her carefully planned schedules into disarray.</p>
                            <p>After one particularly challenging project involving multiple design revisions and
                                delayed custom cabinets, Sarah decides to give Sealab a try. The learning curve is
                                initially steep - she's used to outsourcing all cabinetry work. But as she becomes
                                familiar with the platform, Sarah discovers a new level of control over her
                                projects.</p>
                            <p>Now, when a client wants to adjust the size of their kitchen island midway through a
                                renovation, Sarah can quickly reconfigure the cabinets and order new components without
                                derailing the entire project timeline. The fast turnaround on Sealab orders means she's
                                no longer at the mercy of external cabinet shops' schedules.</p>
                            <p>Sarah's project timelines become more reliable, and her stress levels decrease. She finds
                                herself able to offer more flexibility to clients without compromising her bottom line
                                or sanity.</p>
                            <h3>The Interior Designer</h3>
                            <p>Elena's architectural millwork firm specializes in high-end, custom built-ins for luxury
                                homes. While her designs are innovative, the execution has always been a challenge.
                                Achieving the level of precision required for seamless integration of complex components
                                often leads to multiple rounds of adjustments, eating into profits and causing project
                                delays.</p>
                            <p>Frustrated by a recent project where ill-fitting components led to costly on-site
                                modifications, Elena decides to explore Sealab's offerings. She's initially skeptical -
                                can a digital platform really understand the nuances of high-end millwork?</p>
                            <p>As Elena begins to use Sealab, she's impressed by the level of detail she can specify.
                                The precise, consistent output means that components fit together perfectly the first
                                time, eliminating the need for extensive on-site adjustments. Elena finds she can push
                                her designs further, confident that if she can configure it in Sealab, it can be
                                accurately produced.</p>
                            <p>The reduction in errors and rework not only improves Elena's bottom line but also
                                enhances her firm's reputation for delivering complex projects on time and to exacting
                                standards.</p>
                            <p>These scenarios illustrate how Sealab can address common frustrations in the industry,
                                allowing professionals to embrace automation without losing the essence of their craft.
                                While the transition may present a learning curve, the benefits in efficiency,
                                precision, and flexibility can transform how these businesses operate.</p>
                        </section>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AboutUsComponent;
