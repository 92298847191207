import React from 'react';

const GlobalSettingsGuide = () => {
    return (
        <section id="global-settings" className="mt-5">
            <h2 className="sealab-tutorial__section-title">Working with Global Settings</h2>

            <div className="guide-content">
                <p>
                    Global settings allow you to efficiently manage shared specifications
                    across multiple cabinets. These settings include material selections,
                    construction methods, and hardware preferences. Consider your entire
                    project when setting these parameters, as they can significantly
                    impact both aesthetics and functionality.
                </p>

                <div className="mt-4">
                    <p>Key areas to consider include:</p>
                    <ul className="list-disc pl-6 space-y-2">
                        <li>Carcass material selection and thickness</li>
                        <li>Front material choices</li>
                        <li>Back panel specifications</li>
                        <li>Joint methods</li>
                        <li>Edge banding options</li>
                        <li>Hardware mounting preferences</li>
                        <li>Drawer box specifications</li>
                    </ul>
                </div>

                <p className="mt-4">
                    Remember that while global settings streamline the process, individual
                    cabinets can still be customized as needed after applying global parameters.
                </p>
            </div>
        </section>
    );
};

export default GlobalSettingsGuide;