import React from 'react';
import {Button, Form, Modal} from 'react-bootstrap';

const NewMaterialModal = ({
                              show,
                              onHide,
                              newMaterial,
                              setNewMaterial,
                              handleNewMaterialSubmit,
                              materialValidation
                          }) => {
    return (
        <Modal className="om-modal-new-material" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Create New Material</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Imos Name</Form.Label>
                        <Form.Control
                            type="text"
                            value={newMaterial.name}
                            onChange={(e) => setNewMaterial({...newMaterial, name: e.target.value})}
                            isInvalid={!!materialValidation.name}
                        />
                        <Form.Control.Feedback type="invalid">{materialValidation.name}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="text"
                            value={newMaterial.description}
                            onChange={(e) => setNewMaterial({...newMaterial, description: e.target.value})}
                            isInvalid={!!materialValidation.description}
                        />
                        <Form.Control.Feedback type="invalid">{materialValidation.description}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Thickness (mm)</Form.Label>
                        <Form.Control
                            type="number"
                            step="0.01"
                            value={newMaterial.thickness}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value === '' || /^\d*\.?\d*$/.test(value)) {
                                    setNewMaterial({...newMaterial, thickness: value});
                                }
                            }}
                            isInvalid={!!materialValidation.thickness}
                        />
                        <Form.Control.Feedback type="invalid">{materialValidation.thickness}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Raw Cost</Form.Label>
                        <Form.Control
                            type="number"
                            step="0.01"
                            value={newMaterial.rawCost}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value === '' || /^\d*\.?\d*$/.test(value)) {
                                    setNewMaterial({...newMaterial, rawCost: value});
                                }
                            }}
                            isInvalid={!!materialValidation.rawCost}
                        />
                        <Form.Control.Feedback type="invalid">{materialValidation.rawCost}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Visible</Form.Label>
                        <Form.Select value={newMaterial.visible}
                                     onChange={(e) => setNewMaterial({...newMaterial, visible: e.target.value})}>
                            <option value="1">1</option>
                            <option value="0">0</option>
                        </Form.Select>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="dark" onClick={handleNewMaterialSubmit}>
                    Create Material
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NewMaterialModal;
