import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TSStyles.css';
import {UserLoggedIn} from "../../hooks/isUserLoggedIn";

const TSComponent = () => {
    const userLoggedIn = UserLoggedIn();

    useEffect(() => {
        window.scrollTo({top: 0, behavior: "instant"})
    }, []);

    return (
        <>
            <div className="container mt-5 min-vh-100">
                <div className="terms-of-service">
                    <h1 className="text-center">Terms of Service</h1>
                    <p className="text-center">Effective Date: 6-21-2024</p>
                    <h2>1. Introduction</h2>
                    <p>
                        Welcome to The Sealab ("we," "us," "our"). These Terms of Service ("Terms") govern your access
                        to and use of our website www.TheSealab.com ("Website") and our services.
                    </p>
                    <h2>2. Acceptance of Terms</h2>
                    <p>
                        By accessing or using our Website and services, you agree to comply with and be bound by these
                        Terms. If you do not agree, please do not use our services.
                    </p>
                    <h2>3. Orders and Payments</h2>
                    <p>
                        <strong>Order Acceptance:</strong> All orders are subject to acceptance and availability. We
                        reserve the right to refuse or cancel any order. Orders for custom furniture cannot be easily
                        modified or canceled once placed. Please review quotes thoroughly before ordering to prevent
                        accidental orders. Any changes or cancellations must be requested within 48 hours of placing the
                        order and may incur charges.
                        <br/>
                        <strong>Pricing:</strong> All prices are subject to change without notice.
                        <br/>
                        <strong>Payment:</strong> Payments are processed via Stripe. We do not store any billing address
                        or payment information. We store whether the payment was processed or not.
                    </p>
                    <h2>4. Quotation Process</h2>
                    <p>
                        <strong>Quotation Fee:</strong> We charge a non-refundable upfront cost of $25 to process the
                        quotation.
                        <br/>
                        <strong>Discount:</strong> This fee will be discounted off the production cost once the quote is
                        approved.
                        <br/>
                        <strong>Revisions:</strong> The $25 fee covers up to 2 revisions on the order.
                    </p>
                    <h2>5. Returns and Refunds</h2>
                    <p>
                        <strong>Return Policy:</strong> Custom furniture orders are non-refundable due to the
                        personalized nature of the products. For non-custom items, return requests must be made within
                        30 days of receipt.
                        <br/>
                        <strong>Refunds:</strong> Refunds for eligible returns will be processed within 30 days after we
                        receive the returned item in its original condition.
                        <br/>
                        <strong>Return Shipping:</strong> Customers are responsible for return shipping costs unless the
                        item was damaged or incorrect.
                    </p>
                    <h2>6. Shipping and Delivery</h2>
                    <p>
                        <strong>Shipping:</strong> At this time, only Local Pickup is available. Third Party shipping
                        may be available upon request.
                        <br/>
                        <strong>Delivery:</strong> Delivery dates are estimates and are not guaranteed.
                    </p>
                    <h2>7. Use of the Website</h2>
                    <p>
                        <strong>License:</strong> We grant you a limited, non-exclusive, non-transferable license to
                        access and use the Website for personal and non-commercial use.
                        <br/>
                        <strong>Prohibited Activities:</strong> You agree not to misuse the Website, including but not
                        limited to, engaging in any fraudulent activity, violating any laws, or infringing on the rights
                        of others.
                    </p>
                    <h2>8. Privacy Policy</h2>
                    <p>
                        Your use of our Website is also governed by our Privacy Policy, which outlines how we collect,
                        use, and protect your information.
                    </p>
                    <h2>9. Limitation of Liability</h2>
                    <p>
                        To the fullest extent permitted by law, we disclaim all warranties, express or implied, and
                        shall not be liable for any damages arising from your use of our Website or services. Our
                        products are provided "as is," and we disclaim all warranties, including those of
                        merchantability and fitness for a particular purpose.
                    </p>
                    <h2>10. Changes to the Terms</h2>
                    <p>
                        We may update these Terms from time to time. Any changes will be posted on this page with an
                        updated effective date. Your continued use of our services after any changes indicates your
                        acceptance of the new Terms.
                    </p>
                    <h2>11. Governing Law</h2>
                    <p>
                        These Terms are governed by and construed in accordance with the laws of the State of New York,
                        without regard to its conflict of law principles. By using our services, you agree that any
                        legal action or proceeding arising out of or relating to these Terms or your use of the Website
                        shall be brought exclusively in the federal and state courts located in New York County, New
                        York. You consent to the jurisdiction of such courts and waive any objection to the convenience
                        of such forums.
                    </p>
                    <h2>12. Intellectual Property</h2>
                    <p>
                        <strong>User Content:</strong> Users retain ownership of their project specifications. By
                        submitting content, you grant us a non-exclusive, perpetual, royalty-free license to use the
                        content for manufacturing purposes.
                        <br/>
                        <strong>Proprietary Rights:</strong> We retain all ownership and intellectual property rights to
                        the Website and its content. You may not reverse engineer, disassemble, or decompile any part of
                        our Website or its underlying technology.
                    </p>
                    <h2>13. Pricing and Fees</h2>
                    <p>
                        <strong>Pricing Structure:</strong> Prices for our custom furniture products are based on
                        several factors, including but not limited to square footage, linear footage, and quantity of
                        parts and hardware. The cost structure includes both material and labor costs.
                        <br/>
                        <strong>Third-Party Fulfillment:</strong> We partner with third-party providers for the
                        fulfillment of certain components. Pricing calculations are based on estimated values provided
                        by these partners at the time of the quote. Final prices may vary depending on actual
                        fulfillment costs incurred.
                        <br/>
                        <strong>Quotations:</strong> Upon request, we provide a detailed quotation outlining the
                        estimated costs for your custom furniture order. This quotation is based on the information
                        available at the time and is subject to change.
                        <br/>
                        <strong>Estimates vs. Final Costs:</strong> All quotations provided are estimates. The final
                        cost of your order may differ from the initial quotation due to changes in third-party
                        fulfillment costs or other variables. We will notify you of any significant changes as soon as
                        possible.
                        <br/>
                        <strong>Adjustments and Additional Charges:</strong> Any adjustments to the scope of work or
                        changes requested after the initial quotation may result in additional charges. These will be
                        communicated and agreed upon before proceeding with the changes.
                        <br/>
                        <strong>Payment Terms:</strong> Payments are processed via Stripe. The initial $25 quotation fee
                        is non-refundable but will be deducted from the total production cost if the quote is approved.
                        Full payment details and terms will be provided in the final invoice.
                        <br/>
                        <strong>Taxes and Other Charges:</strong> All prices are exclusive of applicable taxes, duties,
                        and shipping costs, which will be added to the invoice as required.
                        <br/>
                        <strong>Disclaimers:</strong> We strive to provide accurate pricing information but reserve the
                        right to correct any errors or discrepancies. Prices and availability are subject to change
                        without notice.
                    </p>
                    <h2>14. Drawing Packages and Hosted Data</h2>
                    <p>
                        <strong>Drawing Packages:</strong> We offer comprehensive drawing packages for your custom
                        furniture orders. These drawings are generated from the same data that drives our production
                        machinery, ensuring accuracy.
                        <br/>
                        <strong>Access to Hosted Documents:</strong> Customers have access to hosted documents related
                        to production data, drawings, lists, and other relevant information. These documents can be
                        accessed, downloaded, and used at any time for your convenience.
                        <br/>
                        <strong>Accuracy and Responsibility:</strong> While we strive to ensure the accuracy of the
                        drawings, they are to be used under advisement. The ultimate responsibility for ensuring the
                        proper fit and finish of the product lies with the customer.
                        <br/>
                        <strong>Public Access:</strong> The drawings will be made available to anyone with the public
                        link to the data. Public access versions of the drawings will not contain any customer or client
                        information—only the order number and product information.
                    </p>
                    <h2>15. Customer Product Submissions</h2>
                    <p>
                        <strong>Ownership and Rights:</strong> By submitting design ideas, configurations, or product
                        suggestions ("Submissions") to Sealab, you grant us a non-exclusive, royalty-free, worldwide
                        license to use, modify, and incorporate your Submissions into our products or services.
                        <br/>
                        <strong>No Guarantee of Use:</strong> While we appreciate all Submissions, we cannot guarantee
                        that your design will be used or incorporated into our catalog.
                        <br/>
                        <strong>Compensation:</strong> Submission of designs does not entitle you to any form of
                        compensation, unless explicitly agreed upon in writing by Sealab.
                        <br/>
                        <strong>Original Work:</strong> By submitting a design, you confirm that it is your original
                        work and does not infringe on any third-party intellectual property rights.
                        <br/>
                        <strong>Confidentiality:</strong> We will make reasonable efforts to keep your Submission
                        confidential, but we cannot guarantee absolute confidentiality. Please do not submit any
                        information you consider to be a trade secret.
                        <br/>
                        <strong>Liability:</strong> Sealab is not liable for any loss or damage arising from your
                        Submission or our use of your design.
                        <br/>
                        <strong>Modifications:</strong> We reserve the right to modify, adapt, or combine your design
                        with other elements without notifying you.
                        <br/>
                        <strong>No Obligation:</strong> Sealab is under no obligation to use, produce, or provide
                        feedback on any submitted designs.
                        <br/>
                        <strong>Age Requirement:</strong> You must be at least 18 years old to submit a design.
                        <br/>
                        <strong>Feedback:</strong> Any feedback or suggestions you provide regarding our existing
                        products or services may be used by us without any obligation to compensate you.
                        <br/>
                        <strong>Acknowledgment:</strong> By submitting a design, you acknowledge that you have read,
                        understood, and agree to these terms.
                    </p>
                    <h2>16. Contact Information</h2>
                    <p>
                        If you have any questions about these Terms, please contact us at:
                        <br/>
                        Sealab Productions
                        <br/>
                        63 Flushing Ave,
                        <br/>
                        Building 3, Suite 1108
                        <br/>
                        Brooklyn, NY 11205
                        <br/>
                        info@thesealab.com
                    </p>
                </div>
            </div>
        </>
    );
};

export default TSComponent;
