// import React, {useEffect, useRef, useState} from 'react';
// import {useLocation, useNavigate} from 'react-router-dom';
// import {useDispatch, useSelector} from 'react-redux';
// import NavbarLoggedInComponent from '../NavBarComponents/NavbarLoggedInComponent';
// import NavbarComponent from '../NavBarComponents/NavbarComponent';
// import './OrderStyles.css';
// import {
//     addToCart,
//     decrementQuantity,
//     incrementQuantity,
//     selectCart,
//     updateItemsInCart
// } from '../../features/cart/cartSlice'; // Import selectCart
// import {selectGlobalSettings} from '../../features/globalSettings/globalSettingsSlice';
// import DataService from '../../api/DataService';
// import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
// import ImageCarousel from '../ImageCarouselComponent/ImageCarousel';
// import axios from "axios";
// import {FaMinusCircle, FaPlusCircle} from "react-icons/fa";
//
// function OrderPage() {
//     const [materials, setMaterials] = useState([]);
//     const [edgebandingOptions, setEdgebandingOptions] = useState([]);
//     const location = useLocation();
//     const selectedItems = location.state?.items || [];
//     const fromConfigureButton = location.state?.fromConfigureButton || false;
//     const userLoggedIn = UserLoggedIn();
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const positionNameRef = useRef(null); // Reference for the position name input
//
//     const uniqueId = location.state?.uniqueId;
//     const foundItem = location.state?.items;
//     const [itemProperties, setItemProperties] = useState({});
//     const [includeAssembly, setIncludeAssembly] = useState(false);
//     const [includeLegLevelers, setIncludeLegLevelers] = useState(false);
//     const [excludeFronts, setExcludeFronts] = useState(false);
//     const [configOptions, setConfigOptions] = useState([]);
//     const [jointOptions, setJointOptions] = useState([]);
//     const [drawerOptions, setDrawerOptions] = useState([]);
//     const [hingeOptions, setHingeOptions] = useState([]);
//     const [backPanelOptions, setBackPanelOptions] = useState([]);
//     const baseURL = process.env.REACT_APP_BASEURL;
//     const [applyGlobalSettings, setApplyGlobalSettings] = useState(false);
//     const globalSettings = useSelector(selectGlobalSettings);
//     const cartItems = useSelector(selectCart); // Select cart items from Redux store
//
//     const [positionNameError, setPositionNameError] = useState(''); // State to store the position name error
//
//     const initialProperties = {
//         caseMaterial: selectedItems[0].caseMaterial,
//         frontMaterial: selectedItems[0].frontMaterial,
//         backPanelMaterial: selectedItems[0].backPanelMaterial,
//         caseEdge: selectedItems[0].caseEdge,
//         frontEdge: selectedItems[0].frontEdge,
//     };
//
//     useEffect(() => {
//     }, [])
//
//     useEffect(() => {
//         axios.get(`${baseURL}/api/cabinet-features/getAllConfigOptions`)
//             .then((res) => {
//                 setConfigOptions(res.data);
//                 setJointOptions(res.data
//                     .filter(j => j.setting === 'Joint Method' && j.isVisible === '1')
//                     .map(j => j.description));
//                 setDrawerOptions(res.data
//                     .filter(d => d.setting === 'Drawer Type' && d.isVisible === '1')
//                     .map(d => d.description));
//                 setHingeOptions(res.data
//                     .filter(h => h.setting === 'Hinge Plate' && h.isVisible === '1')
//                     .map(h => h.description));
//                 setBackPanelOptions(res.data
//                     .filter(b => b.setting === 'Back Panel Method' && b.isVisible === '1')
//                     .map(b => b.description));
//             });
//     }, []);
//
//     const [edgeBanding, setEdgeBanding] = useState({
//         top: false,
//         right: false,
//         bottom: false,
//         left: false,
//     });
//
//     const [errors, setErrors] = useState({
//         height: false,
//         width: false,
//         depth: false,
//         gapLeft: false,
//         gapRight: false,
//         gapTop: false,
//         gapBottom: false,
//         gapCenter: false,
//         topDrwrHeightValue: false,
//         leftCornerWidth: false,
//         rightCornerDepth: false,
//     });
//
//     useEffect(() => {
//         if (fromConfigureButton) {
//             const item = {
//                 itemId: foundItem[0]?.id,
//                 backPanel: foundItem[0]?.backPanel || 'Inset',
//                 serialNumber: foundItem[0]?.serialNumber || 'test',
//                 drawerType: foundItem[0]?.drawers === '0' ? false : foundItem[0]?.drawerType || 'Wooden 5/8"',
//                 gapTop: itemProperties?.gapTop || (1 / 8).toFixed(3),
//                 gapBottom: itemProperties?.gapBottom || (1 / 8).toFixed(3),
//                 gapLeft: itemProperties?.gapLeft || (1 / 8).toFixed(3),
//                 gapRight: itemProperties?.gapRight || (1 / 8).toFixed(3),
//                 jointControl: foundItem[0]?.jointControl,
//                 jointMethod: foundItem[0]?.jointControl === '0' ? false : foundItem[0]?.jointMethod || 'Biscuit',
//                 heightRange: foundItem[0]?.heightRange,
//                 widthRange: foundItem[0]?.widthRange,
//                 depthRange: foundItem[0]?.depthRange,
//                 frontMaterial: foundItem[0]?.frontMaterial || '3/4" Medex',
//                 caseMaterial: foundItem[0]?.caseMaterial || '3/4" Pre-finished Maple',
//                 backPanelMaterial: foundItem[0]?.backPanelMaterial || foundItem[0]?.caseMaterial || `3/4" Pre-finished Maple`,
//                 quantity: foundItem[0]?.quantity || 1,
//                 hingeSide: foundItem[0]?.doors === '0' ? false : foundItem[0]?.hingeSide || 'Left',
//                 height: foundItem[0]?.heightRange.split('-')[0],
//                 width: foundItem[0]?.widthRange.split('-')[0],
//                 depth: foundItem[0]?.depthRange.split('-')[0],
//                 positionName: foundItem[0]?.positionName,
//                 caseEdge: foundItem[0]?.caseEdge,
//                 frontEdge: foundItem[0]?.frontEdge,
//                 edge1: foundItem[0]?.edgeBandingBottom === 'true' ? foundItem[0]?.edgeBandingType : 'No Edgebanding',
//                 edge2: foundItem[0]?.edgeBandingRight === 'true' ? foundItem[0]?.edgeBandingType : 'No Edgebanding',
//                 edge3: foundItem[0]?.edgeBandingTop === 'true' ? foundItem[0]?.edgeBandingType : 'No Edgebanding',
//                 edge4: foundItem[0]?.edgeBandingLeft === 'true' ? foundItem[0]?.edgeBandingType : 'No Edgebanding',
//                 edgeBandingType: foundItem[0]?.edgeBandingType || 'No Edgebanding',
//                 description: foundItem[0]?.description,
//                 drawers: foundItem[0]?.drawers,
//                 doors: foundItem[0]?.doors,
//                 topDrwrHeight: foundItem[0]?.topDrwrHeight || '1',
//                 topDrwrHeightValue: foundItem[0]?.topDrwrHeightValue || '6',
//                 adjShelves: foundItem[0]?.adjShelves,
//                 numOfShelves: foundItem[0]?.numOfShelves || 'Parametric Shelves',
//                 includeLegLevelers: includeLegLevelers ? 'true' : 'false',
//                 bottomPanelConnector: foundItem[0]?.includeLegLevelers === 'true' ? 'Leg Levelers' : null,
//                 hingePlate: foundItem[0]?.doors === '1' ? foundItem[0]?.hingePlate : 'Cross',
//                 excludeFronts: foundItem[0]?.excludeFronts || false,
//                 matBack: foundItem[0]?.matBack,
//                 legLevelers: foundItem[0]?.legLevelers,
//                 gapControl: foundItem[0]?.gapControl,
//                 filterTags: foundItem[0]?.filterTags,
//             };
//             setItemProperties(item);
//             setIncludeLegLevelers(item.includeLegLevelers === 'true');
//         }
//     }, [fromConfigureButton]);
//
//     useEffect(() => {
//         window.scrollTo({top: 0, behavior: "instant"});
//         const fetchMaterials = async () => {
//             try {
//                 const response = await DataService.getAllMaterials();
//                 const sortedMaterials = response.sort((a, b) => {
//                     if (a.description.startsWith('3/4') && !b.description.startsWith('3/4')) {
//                         return -1;
//                     } else if (!a.description.startsWith('3/4') && b.description.startsWith('3/4')) {
//                         return 1;
//                     }
//                     return a.description.localeCompare(b.description);
//                 });
//                 setMaterials(sortedMaterials);
//             } catch (error) {
//                 console.error('Error fetching materials:', error);
//             }
//         };
//
//         const fetchEdgebandingOptions = async () => {
//             try {
//                 const response = await DataService.getAllEdgebanding();
//                 setEdgebandingOptions(response);
//             } catch (error) {
//                 console.error('Error fetching edgebanding options:', error);
//             }
//         };
//
//         fetchMaterials();
//         fetchEdgebandingOptions();
//     }, []);
//
//     useEffect(() => {
//         if (fromConfigureButton) {
//         } else {
//             const commonProps = {};
//             if (selectedItems.length > 0) {
//                 Object.keys(selectedItems[0]).forEach((key) => {
//                     if (selectedItems.every((item) => item[key] === selectedItems[0][key])) {
//                         commonProps[key] = selectedItems[0][key];
//                     }
//                 });
//             }
//             setItemProperties(commonProps);
//             setIncludeLegLevelers(commonProps.includeLegLevelers === 'true');
//         }
//     }, [fromConfigureButton, selectedItems, uniqueId, dispatch]);
//
//     useEffect(() => {
//         if (fromConfigureButton && Object.keys(foundItem).length > 0) {
//             setIncludeLegLevelers(foundItem[0].includeLegLevelers);
//         }
//     }, [fromConfigureButton, foundItem]);
//
//     useEffect(() => {
//         setEdgeBanding({
//             top: itemProperties.edgeBandingTop === 'true',
//             right: itemProperties.edgeBandingRight === 'true',
//             bottom: itemProperties.edgeBandingBottom === 'true',
//             left: itemProperties.edgeBandingLeft === 'true',
//         });
//     }, [itemProperties.edgeBandingTop, itemProperties.edgeBandingRight, itemProperties.edgeBandingBottom, itemProperties.edgeBandingLeft]);
//
//     useEffect(() => {
//         if (applyGlobalSettings) {
//             setItemProperties(prevProps => ({
//                 ...prevProps,
//                 caseMaterial: globalSettings?.caseMaterial,
//                 frontMaterial: globalSettings?.frontMaterial,
//                 backPanelMaterial: globalSettings?.backPanelMaterial,
//                 caseEdge: itemProperties?.caseEdge !== '0' ? globalSettings?.caseEdge : '0',
//                 frontEdge: itemProperties?.frontEdge !== '0' ? globalSettings?.frontEdge : '0',
//                 backPanel: globalSettings?.backPanel,
//                 drawerType: globalSettings?.drawerType,
//                 hingePlate: globalSettings?.hingePlate,
//                 jointMethod: globalSettings?.jointMethod,
//                 globalSettingsApplied: true
//             }));
//         }
//     }, [applyGlobalSettings, globalSettings]);
//
//     const parseRange = (range) => {
//         if (!range) return [0, 100];
//
//         const parts = range.split('-');
//         const min = parts[0] ? Number(parts[0]) : 0;
//         const max = parts[1] ? Number(parts[1]) : Number(parts[0]);
//
//         if (isNaN(min) || isNaN(max)) {
//             return [0, 100];
//         }
//
//         return [min, max];
//     };
//
//     const handleInputChange = (e, field) => {
//         const {value} = e.target;
//         let transformedValue = value;
//         let isError = false;
//
//         const limitDecimalPlaces = (val) => {
//             const parts = val.split('.');
//             if (parts.length > 1) {
//                 return `${parts[0]}.${parts[1].slice(0, 3)}`;
//             }
//             return val;
//         };
//
//         // Handle Gap Fields (dropdown selections)
//         if (['gapTop', 'gapBottom', 'gapLeft', 'gapRight', 'gapCenter'].includes(field)) {
//             // These fields have predefined dropdown values
//             transformedValue = parseFloat(value); // Convert the dropdown string values to numbers
//         }
//
//         // Handle Numeric Fields with Validation
//         else if (['height', 'width', 'depth', 'leftCornerWidth', 'rightCornerDepth', 'topDrwrHeightValue'].includes(field)) {
//             let min, max;
//             if (field === 'topDrwrHeightValue') {
//                 [min, max] = [4, 28];
//             } else {
//                 // Assuming there's a method to get ranges for other fields
//                 [min, max] = parseRange(itemProperties[`${field}Range`]);
//             }
//
//             const numValue = parseFloat(value);
//             if (isNaN(numValue) || numValue < min || numValue > max) {
//                 isError = true;
//             }
//             transformedValue = limitDecimalPlaces(value.replace(/[^0-9.]/g, ''));
//         }
//
//             // else if (field === 'positionName') {
//             //     transformedValue = value.replace(/-/g, '_');
//             //     // Check if the position name already exists
//             //     const positionExists = cartItems.cart.some(item => item.positionName === transformedValue);
//             //     if (positionExists) {
//             //         setPositionNameError(`The item name "${transformedValue}" already exists in the cart.`);
//             //     } else {
//             //         setPositionNameError('');
//             //     }
//         // }
//
//         else if (field === 'positionName') {
//             transformedValue = value.replace(/-/g, '_');
//             const pattern = /(.*?)(_(\d+)|(\d+))?$/;
//             const match = pattern.exec(transformedValue);
//
//             let positionNamePrefix = transformedValue;
//             let positionNameNumber = 1;
//             let hasLeadingZeros = false;
//
//             if (match) {
//                 positionNamePrefix = match[1];
//                 if (match[3]) {
//                     positionNameNumber = parseInt(match[3], 10);
//                     hasLeadingZeros = match[3].startsWith('0');
//                 } else if (match[4]) {
//                     positionNameNumber = parseInt(match[4], 10);
//                 }
//             }
//
//             // Collect all items with the same prefix
//             const relatedItems = cartItems.cart.filter(item =>
//                 item.positionName?.startsWith(positionNamePrefix)
//             );
//
//             // Create a set of all reserved names across related items
//             const reservedNames = new Set();
//             let highestPositionNumber = 1;
//
//             relatedItems.forEach(item => {
//                 let itemQuantity = item.quantity || 1;
//                 let baseNumber = 1;
//
//                 const itemMatch = pattern.exec(item.positionName);
//                 if (itemMatch && itemMatch[3]) {
//                     baseNumber = parseInt(itemMatch[3], 10);
//                 } else if (itemMatch && itemMatch[4]) {
//                     baseNumber = parseInt(itemMatch[4], 10);
//                 }
//
//                 for (let i = 0; i < itemQuantity; i++) {
//                     const reservedName = `${positionNamePrefix}_${String(baseNumber + i).padStart(3, '0')}`;
//                     reservedNames.add(reservedName);
//                     highestPositionNumber = Math.max(highestPositionNumber, baseNumber + i);
//                 }
//
//             });
//
//             // Find the first available position name after the reserved range
//             let nextAvailableName;
//             let nextAvailableNumber = highestPositionNumber + 1;
//             do {
//                 nextAvailableName = `${positionNamePrefix}_${String(nextAvailableNumber).padStart(3, '0')}`;
//                 nextAvailableNumber++;
//             } while (reservedNames.has(nextAvailableName));
//
//             // Check if the entered position name conflicts with reserved names
//             if (reservedNames.has(transformedValue)) {
//                 setPositionNameError(`The item name "${transformedValue}" is reserved or already in use. The next available name is "${nextAvailableName}".`);
//             } else {
//                 setPositionNameError('');
//             }
//         }
//
//
//         // Update the state with the transformed value and any error status
//         setItemProperties((prevProps) => ({
//             ...prevProps,
//             [field]: transformedValue,
//         }));
//
//         setErrors((prevErrors) => ({
//             ...prevErrors,
//             [field]: isError,
//         }));
//     };
//
//     useEffect(() => {
//         if (positionNameError && positionNameRef.current) {
//             // Scroll to the position name input field when there's an error
//             setTimeout(() => {
//                 positionNameRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
//                 positionNameRef.current.focus(); // Optionally, set focus to the input as well
//             }, 100); // Small timeout to ensure smooth scrolling
//         }
//     }, [itemProperties.positionName]);
//
//     useEffect(() => {
//         setItemProperties((prevProps) => ({
//             ...prevProps,
//             includeLegLevelers: includeLegLevelers,
//             legLevelers: includeLegLevelers
//         }));
//     }, [includeLegLevelers]);
//
//     const handleUpdateItems = async (e) => {
//         e.preventDefault();
//         if (positionNameError && positionNameRef.current) {
//             // Scroll to the position name input field when there's an error
//             setTimeout(() => {
//                 positionNameRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
//                 positionNameRef.current.focus(); // Optionally, set focus to the input as well
//             }, 100); // Small timeout to ensure smooth scrolling
//         }
//
//         if (!positionNameError) {
//             if (fromConfigureButton) {
//                 const updatedItem = {
//                     ...itemProperties,
//                     id: itemProperties.itemId,
//                     positionName: itemProperties.positionName,
//                     legLevelers: includeLegLevelers,
//                     includeAssembly: includeAssembly ? 'true' : 'false',
//                     includeLegLevelers: includeLegLevelers ? 'true' : 'false',
//                     bottomPanelConnector: includeLegLevelers ? 'Leg Levelers' : null,
//                     edgeBandingType: itemProperties.edgeBandingType || 'No Edgebanding',
//                     edge1: edgeBanding.bottom ? itemProperties.edgeBandingType : 'No Edgebanding',
//                     edge2: edgeBanding.right ? itemProperties.edgeBandingType : 'No Edgebanding',
//                     edge3: edgeBanding.top ? itemProperties.edgeBandingType : 'No Edgebanding',
//                     edge4: edgeBanding.left ? itemProperties.edgeBandingType : 'No Edgebanding',
//                     gapTop: itemProperties?.gapTop,
//                     gapBottom: itemProperties?.gapBottom,
//                     gapLeft: itemProperties?.gapLeft,
//                     gapRight: itemProperties?.gapRight,
//                     quantity: foundItem[0].quantity,
//                     edgeBandingTop: edgeBanding.top ? 'true' : 'false',
//                     edgeBandingRight: edgeBanding.right ? 'true' : 'false',
//                     edgeBandingBottom: edgeBanding.bottom ? 'true' : 'false',
//                     edgeBandingLeft: edgeBanding.left ? 'true' : 'false',
//                     excludeFronts: excludeFronts,
//                     frontMaterial: excludeFronts ? 'Fronts By Others' : itemProperties.frontMaterial,
//                 };
//                 await dispatch(addToCart(updatedItem));
//                 navigate(-1);
//             } else if (Object.values(errors).every((error) => !error)) {
//                 const updatedItems = selectedItems.map((item) => ({
//                     ...item,
//                     ...itemProperties,
//                     itemId: item.itemId,
//                     positionName: itemProperties.positionName,
//                     includeLegLevelers: includeLegLevelers ? 'true' : 'false',
//                     bottomPanelConnector: includeLegLevelers ? 'Leg Levelers' : null,
//                     excludeFronts: itemProperties?.excludeFronts,
//                     frontMaterial: excludeFronts ? 'Fronts By Others' : itemProperties.frontMaterial,
//                     edgeBandingType: itemProperties.edgeBandingType || 'No Edgebanding',
//                     edgeBandingTop: edgeBanding.top ? 'true' : 'false',
//                     edgeBandingRight: edgeBanding.right ? 'true' : 'false',
//                     edgeBandingBottom: edgeBanding.bottom ? 'true' : 'false',
//                     edgeBandingLeft: edgeBanding.left ? 'true' : 'false',
//                     edge1: edgeBanding.bottom ? itemProperties.edgeBandingType : 'No Edgebanding',
//                     edge2: edgeBanding.right ? itemProperties.edgeBandingType : 'No Edgebanding',
//                     edge3: edgeBanding.top ? itemProperties.edgeBandingType : 'No Edgebanding',
//                     edge4: edgeBanding.left ? itemProperties.edgeBandingType : 'No Edgebanding',
//                 }));
//                 if (!applyGlobalSettings && (initialProperties?.caseMaterial !== itemProperties?.caseMaterial || initialProperties?.frontMaterial !== itemProperties?.frontMaterial ||
//                     initialProperties?.backPanelMaterial !== itemProperties?.backPanelMaterial || initialProperties?.caseEdge !== itemProperties?.caseEdge ||
//                     initialProperties?.frontEdge !== itemProperties?.frontEdge)) {
//
//                     const itemsWithGlobalSettings = updatedItems.map((item) => ({
//                         ...item,
//                         globalSettingsApplied: false
//                     }))
//
//                     await dispatch(updateItemsInCart(itemsWithGlobalSettings))
//                 } else
//                     await dispatch(updateItemsInCart(updatedItems));
//
//                 navigate(-1);
//             }
//         }
//
//     };
//
//     const renderDropdown = (label, fieldName, options, defaultValue, disabled = false) => (
//         <div className="order-form__group">
//             <label htmlFor={fieldName} className="order-form__label">
//                 {label}
//                 <span className="order-form__tooltip-trigger" onClick={() => handleToolTipClick(fieldName)}>
//                 <span className="order-form__tooltip-content">
//                     {getTooltipContent(fieldName)}
//                 </span>
//             </span>
//             </label>
//             <select
//                 id={fieldName}
//                 className="order-form__select"
//                 value={itemProperties[fieldName] || defaultValue}
//                 onChange={(e) => handleInputChange(e, fieldName)}
//                 disabled={disabled}
//             >
//                 {options.map((option) => (
//                     <option key={option} value={option}>
//                         {option}
//                     </option>
//                 ))}
//             </select>
//         </div>
//     );
//
//     const renderInputField = (label, field) => {
//         const [min, max] = parseRange(itemProperties[`${field}Range`]);
//         return (
//             <div className="order-form__group">
//                 <label htmlFor={field} className="order-form__label">
//                     {label} (in)
//                     <span className="order-form__tooltip-trigger" onClick={() => handleToolTipClick(field)}>
//                 <span className="order-form__tooltip-content">
//                     {getTooltipContent(field)}
//                 </span>
//             </span>
//                 </label>
//                 <input
//                     type="number"
//                     id={field}
//                     className={`order-form__input ${errors[field] ? 'order-form__input--invalid' : ''}`}
//                     value={itemProperties[field] || ''}
//                     onChange={(e) => handleInputChange(e, field)}
//                     step="0.001"
//                     min={min}
//                     max={max}
//                     onKeyDown={(e) => {
//                         if (e.key === 'Enter')
//                             e.preventDefault()
//                     }}
//                 />
//
//                 {errors[field] && (
//                     <div className="order-form__error-message">Please enter a value between {min} and {max}.</div>
//                 )}
//             </div>
//         );
//     };
//
//     const renderGapDropdown = (field, label) => (
//         <div className={`order-form__gap-input-wrapper order-form__gap-${field.toLowerCase()}`}>
//     <span className="order-form__tooltip-trigger" onClick={() => handleToolTipClick(field)}>
//         <span className="order-form__tooltip-content">
//             {getTooltipContent(field)}
//         </span>
//     </span>
//             <label htmlFor={field} className="order-form__label">
//                 {label}
//             </label>
//             <select
//                 id={field}
//                 className={`order-form__input order-form__gap-dropdown ${errors[field] ? 'order-form__input--invalid' : ''}`}
//                 value={itemProperties[field] !== undefined ? itemProperties[field] : (1 / 8).toFixed(3)}  // Preselect Full Reveal (0.125) if no value is set
//                 onChange={(e) => handleInputChange(e, field)}
//             >
//                 <option value="0">Flush (0")</option>
//                 <option value={(1 / 16).toFixed(3)}>Half Reveal (1/16")</option>
//                 <option value={(1 / 8).toFixed(3)}>Full Reveal (1/8")</option>
//             </select>
//             {errors[field] && (
//                 <div className="order-form__error-message">Please select a valid option.</div>
//             )}
//         </div>
//     );
//
//     const renderGapInputFields = () => {
//         return (
//             <div className="order-form__gap-input-container">
//                 {renderGapDropdown('gapTop', 'Gap Top')}
//                 <div className="order-form__gap-input-row">
//                     {renderGapDropdown('gapLeft', 'Gap Left')}
//                     {/*{renderGapDropdown('gapCenter', 'Gap Center')}*/}
//                     {renderGapDropdown('gapRight', 'Gap Right')}
//                 </div>
//                 {renderGapDropdown('gapBottom', 'Gap Bottom')}
//             </div>
//         );
//     };
//
//     const handleEdgeBandingChange = (position) => {
//         setEdgeBanding((prevEdgeBanding) => ({
//             ...prevEdgeBanding,
//             [position]: !prevEdgeBanding[position],
//         }));
//     };
//
//     const renderEdgebandingOptions = () => {
//         if (!itemProperties?.serialNumber?.startsWith('LP'))
//             return null;
//
//         return (
//             <div className="order-form__edgebanding-options">
//                 <label htmlFor="edgeBandingType" className="order-form__label order-form__label--bold">
//                     Edgebanding Type
//                     <span className="order-form__tooltip-trigger" onClick={() => handleToolTipClick('edgeBandingType')}>
//                     <span className="order-form__tooltip-content">
//                         {getTooltipContent('edgeBandingType')}
//                     </span>
//                 </span>
//                 </label>
//                 <select
//                     id="edgeBandingType"
//                     className="order-form__select"
//                     value={itemProperties?.edgeBandingType}
//                     onChange={(e) => setItemProperties((prevProps) => ({
//                         ...prevProps,
//                         edgeBandingType: e.target.value
//                     }))}
//                 >
//                     {edgebandingOptions.map((option) => (
//                         <option key={option.id} value={option.englishDescription}>
//                             {option.englishDescription}
//                         </option>
//                     ))}
//                 </select>
//
//                 <div className="order-form__edgebanding-square-container">
//                     <div className="order-form__edgebanding-square">
//                         <div
//                             className={`order-form__edgebanding-edge order-form__edgebanding-edge--top ${edgeBanding.top ? 'order-form__edgebanding-edge--selected' : ''}`}
//                             onClick={() => handleEdgeBandingChange('top')}
//                         >
//                             <span className="order-form__edgebanding-label">Width</span>
//                         </div>
//                         <div
//                             className={`order-form__edgebanding-edge order-form__edgebanding-edge--right ${edgeBanding.right ? 'order-form__edgebanding-edge--selected' : ''}`}
//                             onClick={() => handleEdgeBandingChange('right')}
//                         >
//                         <span
//                             className="order-form__edgebanding-label order-form__edgebanding-label--vertical">Length</span>
//                         </div>
//                         <div
//                             className={`order-form__edgebanding-edge order-form__edgebanding-edge--bottom ${edgeBanding.bottom ? 'order-form__edgebanding-edge--selected' : ''}`}
//                             onClick={() => handleEdgeBandingChange('bottom')}
//                         >
//                             <span className="order-form__edgebanding-label">Width</span>
//                         </div>
//                         <div
//                             className={`order-form__edgebanding-edge order-form__edgebanding-edge--left ${edgeBanding.left ? 'order-form__edgebanding-edge--selected' : ''}`}
//                             onClick={() => handleEdgeBandingChange('left')}
//                         >
//                         <span
//                             className="order-form__edgebanding-label order-form__edgebanding-label--vertical">Length</span>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     };
//
//     const renderCornerInputField = (label, field, maxValue) => {
//         const handleInputChangeWithValidation = (e, field) => {
//             const rawValue = e.target.value;
//             const parsedValue = parseFloat(rawValue);
//
//             // Helper function to limit decimal places
//             const limitDecimalPlaces = (val) => {
//                 const parts = val.split('.');
//                 if (parts.length > 1) {
//                     return `${parts[0]}.${parts[1].slice(0, 3)}`; // Limiting to 3 decimal places
//                 }
//                 return val;
//             };
//
//             // Processed value limited to 3 decimal places
//             let value = limitDecimalPlaces(rawValue);
//
//             // Validation: Check if the value is within the range
//             if (isNaN(parsedValue) || parsedValue < 1.5 || parsedValue > maxValue) {
//                 // Set the error for this field
//                 setErrors((prevErrors) => ({
//                     ...prevErrors,
//                     [field]: `Please enter a value between 1.5 and ${maxValue}.`
//                 }));
//             } else {
//                 // Clear the error if the value is valid
//                 setErrors((prevErrors) => ({
//                     ...prevErrors,
//                     [field]: null
//                 }));
//             }
//
//             // Update state with the processed value
//             setItemProperties((prevProps) => ({
//                 ...prevProps,
//                 [field]: value
//             }));
//         };
//
//
//         return (
//             <div className="order-form__group">
//                 <label htmlFor={field} className="order-form__label">
//                     {label} (in)
//                     <span className="order-form__tooltip-trigger" onClick={() => handleToolTipClick(field)}>
//                 <span className="order-form__tooltip-content">
//                     {getTooltipContent(field)}
//                 </span>
//             </span>
//                 </label>
//                 <input
//                     type="text" // Use "text" to control decimal precision
//                     id={field}
//                     className={`order-form__input ${errors[field] ? 'order-form__input--invalid' : ''}`}
//                     value={itemProperties[field] || 1.5}
//                     min="1.5"
//                     max={maxValue}
//                     onChange={(e) => handleInputChangeWithValidation(e, field)}
//                     onKeyDown={(e) => {
//                         // Prevent anything other than numbers and decimals
//                         if (!/[0-9.]/.test(e.key)) {
//                             e.preventDefault();
//                         }
//                     }}
//                 />
//                 {errors[field] && (
//                     <div className="order-form__error-message">
//                         {errors[field]}
//                     </div>
//                 )}
//             </div>
//         );
//     };
//
//     const getTooltipContent = (field) => {
//         const tooltips = {
//             height: "Enter the desired height of your cabinet in inches.",
//             width: "Enter the desired width of your cabinet in inches.",
//             depth: "Enter the desired depth of your cabinet in inches (carcass only).",
//             positionName: "Enter a unique name or identifier for this cabinet.",
//             caseMaterial: "Select the material for the cabinet case.",
//             frontMaterial: "Select the material for the cabinet front.",
//             backPanelMaterial: "Select the material for the back panel of the cabinet.",
//             caseEdge: "Choose the edge type for the cabinet case.",
//             frontEdge: "Choose the edge type for the cabinet front.",
//             backPanel: "Select the method for attaching the back panel.",
//             hingePlate: "Choose the type of hinge plate for the cabinet doors.",
//             drawerType: "Select the type of drawer for this cabinet.",
//             jointMethod: "Choose the method for joining cabinet parts.",
//             numOfShelves: "Select the number of adjustable shelves or choose parametric shelving.",
//             topDrwrHeightValue: "Enter the height of the top drawer in inches (between 4 and 28).",
//             includeLegLevelers: "Check this box to include leg levelers with your cabinet.",
//             gapTop: "Choose the desired gap at the top of the cabinet (max 0.125 inches).",
//             gapBottom: "Choose the desired gap at the bottom of the cabinet (max 0.125 inches).",
//             gapLeft: "Choose the desired gap on the left side of the cabinet (max 0.125 inches).",
//             gapRight: "Choose the desired gap on the right side of the cabinet (max 0.125 inches).",
//             gapCenter: "Enter the desired gap in the center of the cabinet (max 0.125 inches).",
//             edgeBandingType: "Select the type of edgebanding for your cabinet.",
//             leftCornerWidth: "Enter the width of the left corner (min 1.5 inches, max cabinet width minus 1.5 inches).",
//             rightCornerDepth: "Enter the depth of the right corner (min 1.5 inches, max cabinet depth minus 1.5 inches).",
//             excludeFronts: "Check this box if you want to exclude front parts from your cabinet order."
//         };
//         return tooltips[field] || "Additional information about this option.";
//     };
//
//     const itemsToRender = fromConfigureButton ? [foundItem[0]] : selectedItems;
//     const uniqueSerialNumbers = [...new Set(itemsToRender.map(item => item.serialNumber))];
//
//     const handleToolTipClick = (field) => {
//         navigate("/the-sealab/tool-tip/explain", {state: {field: field}})
//     }
//
//     const handleIncrement = (itemId) => {
//         if (itemId && itemProperties.serialNumber) {
//             dispatch(incrementQuantity({id: itemId}));
//             setItemProperties({...itemProperties, quantity: itemProperties.quantity += 1})
//         }
//     }
//
//     const handleDecrement = (itemId) => {
//         if (itemId && itemProperties.serialNumber && itemProperties.quantity > 1) {
//             dispatch(decrementQuantity({id: itemId}));
//             setItemProperties({...itemProperties, quantity: itemProperties.quantity -= 1});
//
//         }
//     }
//
//     return (
//         <div className="order-page">
//             {userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}
//             <div className="order-page__container">
//                 <div className="order-page__serial-number">
//                     <strong>Serial
//                         Number{uniqueSerialNumbers.length > 1 ? 's' : ''}:</strong> {uniqueSerialNumbers.join(', ')}
//                 </div>
//                 <div className="order-page__layout">
//                     <div className="order-page__image-section">
//                         {uniqueSerialNumbers.map((serialNumber) => (
//                             <div key={serialNumber} className="order-page__image-wrapper">
//                                 <ImageCarousel
//                                     cabinet={itemsToRender.find(item => item.serialNumber === serialNumber)}/>
//                             </div>
//                         ))}
//                         <div className="order-page__description">{itemProperties.description}</div>
//                         {itemProperties.serialNumber && (
//                             <div className="quantity-control">
//                             <span className="quantity-text">
//                                 <strong>Quantity:</strong> {itemProperties.quantity}
//                             </span>
//                                 <FaMinusCircle className="quantity-icon"
//                                                onClick={() => handleDecrement(itemProperties.itemId)}/>
//                                 <FaPlusCircle className="quantity-icon"
//                                               onClick={() => handleIncrement(itemProperties.itemId)}/>
//                             </div>
//                         )}
//                     </div>
//                     <div className="order-page__form-section">
//                         <form onSubmit={handleUpdateItems} className="order-form">
//                             <div className="order-form__global-settings">
//                                 <label htmlFor="applyGlobalSettings" className="order-form__checkbox-label">
//                                     <input
//                                         type="checkbox"
//                                         id="applyGlobalSettings"
//                                         checked={applyGlobalSettings}
//                                         onChange={() => setApplyGlobalSettings(!applyGlobalSettings)}
//                                         className="order-form__checkbox"
//                                     />
//                                     Apply Global Settings?
//                                 </label>
//                             </div>
//                             {/* Dimensions Section */}
//                             {itemProperties.heightRange && (
//                                 <div className="order-form__section">
//                                     <h3 className="order-form__section-title">Dimensions</h3>
//                                     {renderInputField('Height', 'height')}
//                                     {renderInputField('Width', 'width')}
//                                     {renderInputField('Depth', 'depth')}
//                                     {itemProperties.serialNumber?.includes('CR') && (
//                                         <div className="">
//                                             <h3 className="order-form__section-title">Corner Dimensions</h3>
//                                             {renderCornerInputField('Left Corner Width', 'leftCornerWidth', parseFloat(itemProperties.width) - 1.5)}
//                                             {renderCornerInputField('Right Corner Depth', 'rightCornerDepth', parseFloat(itemProperties.depth) - 1.5)}
//                                         </div>
//                                     )}
//                                 </div>
//                             )}
//                             {/* Name Your Cabinet Section */}
//                             <>
//                                 {selectedItems.length <= 1 && (
//                                     <div className="order-form__section">
//                                         <h3 className="order-form__section-title">Name Your Cabinet</h3>
//                                         <p className="item-name-caution-text">**Please use unique Item Names. Note that
//                                             'Item Names' for cabinets with a quantity greater than one will be
//                                             auto-incremented.**</p>
//                                         <div className="order-form__group">
//                                             <label htmlFor="positionName" className="order-form__label">
//                                                 Item Name
//                                                 <span className="order-form__tooltip-trigger"
//                                                       onClick={() => handleToolTipClick('positionName')}>
//                                         <span className="order-form__tooltip-content">
//                                             {getTooltipContent('positionName')}
//                                         </span>
//                                     </span>
//                                             </label>
//                                             <input
//                                                 type="text"
//                                                 className={`order-form__input ${positionNameError ? 'order-form__input--invalid' : ''}`}
//                                                 placeholder="Please enter a max of six characters"
//                                                 id="positionName"
//                                                 ref={positionNameRef}
//                                                 value={itemProperties.positionName || ''}
//                                                 onChange={(e) => handleInputChange(e, 'positionName')}
//                                                 onKeyDown={(e) => {
//                                                     if (e.key === 'Enter') {
//                                                         e.preventDefault();
//                                                     }
//                                                 }}
//                                             />
//                                             {positionNameError && (
//                                                 <div className="order-form__error-message">{positionNameError}</div>
//                                             )}
//                                         </div>
//                                     </div>
//                                 )}
//                             </>
//                             {/* Materials Section */}
//                             {((itemProperties.caseEdge !== '0') || (itemProperties.frontEdge !== '0') || (itemProperties.matBack !== '0')) && (
//                                 <div className="order-form__section">
//                                     <h3 className="order-form__section-title">Materials</h3>
//                                     {itemProperties.caseEdge !== '0' && renderDropdown('Case Material', 'caseMaterial', materials.filter(m => m.visible === 1).map(m => m.description))}
//                                     {itemProperties.frontEdge !== '0' && (
//                                         <>
//                                             {renderDropdown('Front Material', 'frontMaterial', materials.filter(m => m.visible === 1).map(m => m.description), undefined, itemProperties.excludeFronts)}
//                                             <div className="order-form__checkbox-group">
//                                                 <input
//                                                     type="checkbox"
//                                                     className="order-form__checkbox"
//                                                     id="excludeFronts"
//                                                     checked={itemProperties.excludeFronts}
//                                                     onChange={() => {
//                                                         setExcludeFronts(!excludeFronts);
//                                                         setItemProperties(prev => ({
//                                                             ...prev,
//                                                             excludeFronts: !excludeFronts
//                                                         }));
//                                                     }}
//                                                 />
//                                                 <label className="order-form__checkbox-label" htmlFor="excludeFronts">
//                                                     Exclude Front Parts?
//                                                 </label>
//                                                 <span className="order-form__tooltip-trigger"
//                                                       onClick={() => handleToolTipClick('excludeFronts')}>
//                                                     <span className="order-form__tooltip-content">
//                                                         {getTooltipContent('excludeFronts')}
//                                                     </span>
//                                             </span>
//
//                                             </div>
//                                         </>
//                                     )}
//                                     {itemProperties.matBack !== '0' && renderDropdown('Back Panel Material', 'backPanelMaterial', materials.filter(m => m.visible === 1).map(m => m.description))}
//                                 </div>
//                             )}
//                             {/* Joinery Method Section */}
//                             {((itemProperties.matBack === '1') || (itemProperties.jointMethod === '1')) && (
//                                 <div className="order-form__section">
//                                     <h3 className="order-form__section-title">Joinery Method</h3>
//                                     {itemProperties.matBack === '1' && renderDropdown('Back Panel Method', 'backPanel', backPanelOptions)}
//                                     {itemProperties.jointControl === '1' && renderDropdown('Joint Method', 'jointMethod', jointOptions)}
//                                 </div>
//                             )}
//                             {/* Edgebanding Section */}
//                             {((itemProperties.caseEdge !== '0') || (itemProperties.frontEdge !== '0') || (itemProperties.serialNumber?.startsWith('LP_SP') || itemProperties.serialNumber?.startsWith('LP_GP'))) && (
//                                 <div className="order-form__section">
//                                     <h3 className="order-form__section-title">Edgebanding</h3>
//                                     {itemProperties.caseEdge !== '0' && !itemProperties?.serialNumber?.startsWith('LP') &&
//                                         renderDropdown('Case Edge', 'caseEdge', edgebandingOptions.map(o => o.englishDescription))}
//                                     {itemProperties.frontEdge !== '0' && !itemProperties?.serialNumber?.startsWith('LP') &&
//                                         renderDropdown('Front Edge', 'frontEdge', edgebandingOptions.map(o => o.englishDescription))}
//                                     {renderEdgebandingOptions()}
//                                 </div>
//                             )}
//                             {/* Door Settings Section */}
//                             {itemProperties.doors === '1' && (
//                                 <div className="order-form__section">
//                                     <h3 className="order-form__section-title">Door Settings</h3>
//                                     {renderDropdown('Hinge Plate', 'hingePlate', hingeOptions)}
//                                 </div>
//                             )}
//                             {/* Drawer Settings Section */}
//                             {itemProperties.drawers === '1' && (
//                                 <div className="order-form__section">
//                                     <h3 className="order-form__section-title">Drawer Settings</h3>
//                                     {itemProperties.drawers === '1' && renderDropdown('Drawer Type', 'drawerType', drawerOptions)}
//                                     {itemProperties.topDrwrHeight === "1" && (
//                                         <div className="order-form__group">
//                                             <label htmlFor="topDrwHtValue" className="order-form__label">
//                                                 Top Drawer Height (in)
//                                                 <span className="order-form__tooltip-trigger"
//                                                       onClick={() => handleToolTipClick('topDrwrHeight')}>
//                                                 <span className="order-form__tooltip-content">
//                                                     {getTooltipContent('topDrwrHeightValue')}
//                                                 </span>
//                                             </span>
//                                             </label>
//                                             <input
//                                                 type="text"
//                                                 id="topDrwrHeightValue"
//                                                 className={`order-form__input ${errors.topDrwrHeightValue ? 'order-form__input--invalid' : ''}`}
//                                                 value={itemProperties.topDrwrHeightValue}
//                                                 onChange={(e) => handleInputChange(e, 'topDrwrHeightValue')}
//                                                 onKeyDown={(e) => {
//                                                     if (e.key === 'Enter') {
//                                                         e.preventDefault();
//                                                     }
//                                                 }}
//                                             />
//                                             {errors.topDrwrHeightValue && (
//                                                 <div className="order-form__error-message">Please enter a value between
//                                                     4 and 28.</div>
//                                             )}
//                                         </div>
//                                     )}
//                                 </div>
//                             )}
//                             {/* Adjustable Shelves Section */}
//                             {itemProperties.adjShelves === '1' && (
//                                 <div className="order-form__section">
//                                     <h3 className="order-form__section-title">Adjustable Shelves</h3>
//                                     <div className="order-form__group">
//                                         <label htmlFor="numOfShelves" className="order-form__label">
//                                             Number of Adjustable Shelves
//                                             <span className="order-form__tooltip-trigger"
//                                                   onClick={() => handleToolTipClick('numOfShelves')}>
//                                             <span className="order-form__tooltip-content">
//                                                 {getTooltipContent('numOfShelves')}
//                                             </span>
//                                         </span>
//                                         </label>
//                                         <select
//                                             id="numOfShelves"
//                                             className="order-form__select"
//                                             value={itemProperties.numOfShelves || 'Parametric Shelves'}
//                                             onChange={(e) => handleInputChange(e, 'numOfShelves')}
//                                         >
//                                             <option value="Parametric Shelves">Parametric Shelves</option>
//                                             {[...Array(13).keys()].map(i => (
//                                                 <option key={i} value={i}>{i}</option>
//                                             ))}
//                                         </select>
//                                     </div>
//                                 </div>
//                             )}
//                             {/* Include Leg Levelers Checkbox */}
//                             {itemProperties?.filterTags?.includes('Leg_Levelers') && (itemProperties.depthRange.split('-')[0] >= 10.75 || itemProperties.depth >= 10.75) &&
//                                 (itemProperties?.widthRange.split('-')[0] >= 10.75 || itemProperties.width >= 10.75) && (
//                                     <div className="order-form__section">
//                                         <h3 className="order-form__section-title">Bottom Panel Connector</h3>
//                                         <div className="order-form__group">
//                                             <label htmlFor="includeLegLevelers" className="order-form__label">
//                                                 Include Leg Levelers?
//                                                 <span className="order-form__tooltip-trigger"
//                                                       onClick={() => handleToolTipClick('includeLegLevelers')}>
//                                             <span className="order-form__tooltip-content">
//                                                 {getTooltipContent('includeLegLevelers')}
//                                             </span>
//                                         </span>
//                                             </label>
//                                             <input
//                                                 type="checkbox"
//                                                 id="includeLegLevelers"
//                                                 className="order-form__checkbox"
//                                                 checked={includeLegLevelers}
//                                                 onChange={() => setIncludeLegLevelers(!includeLegLevelers)}
//                                             />
//                                         </div>
//                                     </div>
//                                 )}
//                             {/* Gap Settings Section */}
//                             {itemProperties.gapControl === '1' && (
//                                 <div className="order-form__section">
//                                     <h3 className="order-form__section-title">Set Front Panel Gaps</h3>
//                                     {renderGapInputFields()}
//                                 </div>
//                             )}
//                             <div className="order-form__submit-container">
//                                 <button type="submit" className="order-form__submit-button">
//                                     {fromConfigureButton ? 'Add to Cart' : 'Update'}
//                                 </button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }
//
// export default OrderPage;
//
//
// src/components/OrderPageComponents/OrderPage.js
import React from 'react';
import OrderPageContainer from './OrderPageContainer';

function OrderPage() {
    return <OrderPageContainer/>;
}

export default OrderPage;
