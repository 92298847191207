import React, {useEffect, useState} from 'react';
import {Nav, Navbar} from 'react-bootstrap';
import {FaShoppingCart} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import LoginModal from "../../modals/LoginModal";
import RegisterModal from "../../modals/RegisterModal";
import './NavbarStyles.css';
import {UserLoggedIn} from "../../hooks/isUserLoggedIn";
import NavbarLoggedInComponent from "./NavbarLoggedInComponent";

const NavbarComponent = () => {
    const cartQuantity = useSelector(state => state.cart.totalQuantity);
    const cart = useSelector(state => state.cart)
    // const cartQuantity = useSelector(state => state.cart ? state.cart.totalQuantity : 0);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const userLoggedIn = UserLoggedIn();
    const dispatch = useDispatch();

    useEffect(() => {
        if (cart.cart.length < 1 && cart.totalQuantity > 0) {
            cart.totalQuantity = 0
        }
    }, [cart.cart.length])
    const openRegister = () => {
        setShowLogin(false);
        setShowRegister(true);
    };
    return (
        <div>
            {userLoggedIn ? (
                <NavbarLoggedInComponent/>
            ) : (
                <>
                    <Navbar collapseOnSelect expand="lg" className="custom-navbar" variant="dark">
                        <Navbar.Brand href="/">THE SEALAB</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                {/* Left-aligned items (if any) */}
                            </Nav>
                            <Nav className="mx-auto">
                                <Nav.Link href="/showroom">Catalog</Nav.Link>

                            </Nav>
                            <Nav className="ms-auto">
                                <Nav.Link onClick={() => setShowLogin(true)}>Login</Nav.Link>
                                <Nav.Link href="/learning/tutorials">Tutorials</Nav.Link>
                                <Nav.Link href="/customer-support/contact">Contact</Nav.Link>
                                <Nav.Link href="/the-sealab/about-us">About Us</Nav.Link>
                                <Nav.Link href="/cart" className="nav-cart-link">
                                    <div className="nav-cart-icon">
                                        <FaShoppingCart size={24} color="white"/>
                                        {cartQuantity > 0 && (
                                            <span className="cart-quantity-badge">{cartQuantity}</span>
                                        )}
                                    </div>
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    <LoginModal
                        show={showLogin}
                        handleClose={() => setShowLogin(false)}
                        handleRegisterOpen={openRegister}
                        fromCart={false}
                    />
                    <RegisterModal
                        show={showRegister}
                        handleClose={() => setShowRegister(false)}
                    />
                </>
            )}
        </div>
    );
};

export default NavbarComponent;
