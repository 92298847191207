// components/ShowRoom/utils/helpers.js

/**
 * Shuffles the elements of an array in place using the Fisher-Yates algorithm.
 * This function randomly reorders the elements of the array.
 *
 * @param {Array} array - The array to shuffle.
 * @returns {Array} - The shuffled array.
 */
export const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        // Pick a random index from 0 to i
        const j = Math.floor(Math.random() * (i + 1));

        // Swap the elements at index i and index j
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

/**
 * A mapping of textual numbers to their corresponding numeric representations.
 * Used for converting words like 'one' to '1' during text standardization.
 */
export const numberMapToNumbers = {
    'one': '1',
    'two': '2',
    'three': '3',
    'four': '4',
    'five': '5',
    'six': '6'
};

/**
 * Standardizes text by replacing textual numbers with numeric representations.
 * For example, 'one' becomes '1', 'two' becomes '2', etc.
 *
 * @param {string} text - The input text to standardize.
 * @returns {string} - The standardized text with numeric representations.
 */
export const standardizeText = (text) => {
    return text.replace(
        /\b(one|two|three|four|five|six)\b/gi, // Matches whole words for the numbers 1-6
        match => {
            const lowercaseMatch = match.toLowerCase(); // Convert match to lowercase
            return numberMapToNumbers[lowercaseMatch] || match; // Replace with numeric value or keep original
        }
    );
};
