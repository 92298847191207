import React from 'react';
import {Form, Table} from 'react-bootstrap';

const MaterialsTable = ({
                            articles,
                            materials,
                            selectedArticles,
                            handleSelectAll,
                            handleSelectArticle,
                            isAllSelected,
                            handleMaterialChange,
                            handleBulkMaterialChange,
                            imageExistsMap,
                            orderId,
                            thumbNail
                        }) => {
    return (
        <>
            <Table responsive className="om-table">
                <thead>
                <tr>
                    <th>
                        <Form.Check type="checkbox" className="om-checkbox" checked={isAllSelected}
                                    onChange={handleSelectAll}/>
                    </th>
                    <th>Preview</th>
                    <th>Position Name</th>
                    <th>Case Material</th>
                    <th>Front Material</th>
                    <th>Back Panel Material</th>
                </tr>
                </thead>
                <tbody>
                {articles.map((article, index) => (
                    <tr key={index} className="om-article-row">
                        <td>
                            <Form.Check
                                type="checkbox"
                                className="om-checkbox"
                                checked={selectedArticles.has(article.positionName)}
                                onChange={() => handleSelectArticle(article.positionName)}
                            />
                        </td>
                        <td className="om-preview-cell">
                            {imageExistsMap[article.positionName] ? (
                                <img
                                    src={`${thumbNail}${orderId}/${article.positionName}.png`}
                                    alt={article.positionName}
                                    className="om-preview-image"
                                />
                            ) : (
                                <div className="om-no-image">No Preview</div>
                            )}
                        </td>
                        <td>{article.positionName}</td>
                        <td>
                            <Form.Group className="om-material-select">
                                <Form.Select
                                    className="om-case-material-select"
                                    value={article.caseMaterial}
                                    onChange={(e) => {
                                        if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
                                            handleBulkMaterialChange('caseMaterial', e.target.value);
                                        } else {
                                            handleMaterialChange('caseMaterial', e.target.value, article.positionName);
                                        }
                                    }}
                                >
                                    {materials.map((mat, idx) => (
                                        <option key={idx} value={mat.description}>
                                            {mat.description}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group className="om-material-select">
                                <Form.Select
                                    className="om-front-material-select"
                                    value={article.frontMaterial}
                                    onChange={(e) => {
                                        if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
                                            handleBulkMaterialChange('frontMaterial', e.target.value);
                                        } else {
                                            handleMaterialChange('frontMaterial', e.target.value, article.positionName);
                                        }
                                    }}
                                >
                                    {materials.map((mat, idx) => (
                                        <option key={idx} value={mat.description}>
                                            {mat.description}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group className="om-material-select">
                                <Form.Select
                                    className="om-backpanel-material-select"
                                    value={article.backPanelMaterial}
                                    onChange={(e) => {
                                        if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
                                            handleBulkMaterialChange('backPanelMaterial', e.target.value);
                                        } else {
                                            handleMaterialChange('backPanelMaterial', e.target.value, article.positionName);
                                        }
                                    }}
                                >
                                    {materials.map((mat, idx) => (
                                        <option key={idx} value={mat.description}>
                                            {mat.description}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );
};

export default MaterialsTable;
