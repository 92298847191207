import React from 'react';
import {Form, Table} from 'react-bootstrap';

const EdgebandingTable = ({
                              articles,
                              edgebandingOptions,
                              selectedArticles,
                              handleSelectAll,
                              handleSelectArticle,
                              isAllSelected,
                              handleEdgebandingChange,
                              handleBulkEdgebandingChange,
                              imageExistsMap,
                              orderId,
                              thumbNail
                          }) => {
    return (
        <>
            <Table responsive className="om-table">
                <thead>
                <tr>
                    <th>
                        <Form.Check type="checkbox" className="om-checkbox" checked={isAllSelected}
                                    onChange={handleSelectAll}/>
                    </th>
                    <th>Preview</th>
                    <th>Position Name</th>
                    <th>Case Edge</th>
                    <th>Front Edge</th>
                </tr>
                </thead>
                <tbody>
                {articles.map((article, index) => (
                    <tr key={index} className="om-article-row">
                        <td>
                            <Form.Check type="checkbox" className="om-checkbox"
                                        checked={selectedArticles.has(article.positionName)}
                                        onChange={() => handleSelectArticle(article.positionName)}/>
                        </td>
                        <td className="om-preview-cell">
                            {imageExistsMap[article.positionName] ? (
                                <img
                                    src={`${thumbNail}${orderId}/${article.positionName}.png`}
                                    alt={article.positionName}
                                    className="om-preview-image"
                                />
                            ) : (
                                <div className="om-no-image">No Preview</div>
                            )}
                        </td>
                        <td>{article.positionName}</td>
                        <td>
                            <Form.Group className="om-material-select">
                                <Form.Select
                                    className="om-case-edge-select"
                                    value={article.caseEdge || ''}
                                    onChange={(e) => {
                                        if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
                                            handleBulkEdgebandingChange('caseEdge', e.target.value);
                                        } else {
                                            handleEdgebandingChange('caseEdge', e.target.value, article.positionName);
                                        }
                                    }}
                                >
                                    {edgebandingOptions.map((edge, idx) => (
                                        <option key={idx} value={edge.englishDescription}>
                                            {edge.englishDescription}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </td>
                        <td>
                            <Form.Group className="om-material-select">
                                <Form.Select
                                    className="om-front-edge-select"
                                    value={article.frontEdge || ''}
                                    onChange={(e) => {
                                        if (selectedArticles.has(article.positionName) && selectedArticles.size > 1) {
                                            handleBulkEdgebandingChange('frontEdge', e.target.value);
                                        } else {
                                            handleEdgebandingChange('frontEdge', e.target.value, article.positionName);
                                        }
                                    }}
                                >
                                    {edgebandingOptions.map((edge, idx) => (
                                        <option key={idx} value={edge.englishDescription}>
                                            {edge.englishDescription}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );
};

export default EdgebandingTable;
