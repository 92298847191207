// import React, {useEffect, useState} from 'react';
// import NavbarLoggedInComponent from '../NavBarComponents/NavbarLoggedInComponent';
// import NavbarComponent from '../NavBarComponents/NavbarComponent';
// import {UserLoggedIn} from "../../hooks/isUserLoggedIn";
// import {useDispatch, useSelector} from "react-redux";
// import {reAddToCart} from "../../features/cart/cartSlice";
// import {deleteSavedOrder, getAllSavedOrders} from "../../api/OrderService";
// import './SavedOrdersStyles.css';
// import PdfWriterComponent from '../PdfWriterComponent/PdfWriterComponent';
// import {nanoid} from "@reduxjs/toolkit";
//
// const SavedOrdersComponent = () => {
//     const [savedOrders, setSavedOrders] = useState(null); // Stores the fetched saved orders
//     const [shouldFetchOrders, setShouldFetchOrders] = useState(true); // Flag to determine if saved orders need to be fetched
//     const userLoggedIn = UserLoggedIn(); // Checks if the user is logged in
//     const email = useSelector((state) => state.user.user.email); // Gets the logged-in user's email from Redux store
//     const token = useSelector(state => state.user.accessToken); // Gets the user's access token from Redux store
//     const dispatch = useDispatch(); // Redux dispatch function for state updates
//     const imageURL = process.env.REACT_APP_IMAGEURL; // URL for fetching order images
//
//     // Fetch saved orders from the API when the component mounts or `shouldFetchOrders` changes
//     useEffect(() => {
//         if (shouldFetchOrders) {
//             const fetchSavedOrders = async () => {
//                 try {
//                     const response = await getAllSavedOrders(email, token);
//                     setSavedOrders(response.data);
//                     setShouldFetchOrders(false); // Reset the fetch flag
//                 } catch (e) {
//                     console.error('Error fetching saved orders: ', e);
//                     setSavedOrders([]); // Set saved orders to an empty array on error
//                 }
//             };
//             fetchSavedOrders();
//         }
//     }, [shouldFetchOrders]);
//
//     // Scroll to the top of the page if there are no saved orders
//     useEffect(() => {
//         if (savedOrders?.length <= 0) {
//             window.scrollTo({top: 0, behavior: 'instant'});
//         }
//     }, [savedOrders]);
//
//     // Handles re-adding all items in a saved order to the cart
//     const handleOrderAgain = (tempOrderId) => {
//         const ordersToAdd = savedOrders.filter(order => {
//             return order.tempOrderId.toString() === tempOrderId.toString();
//         });
//
//         ordersToAdd.forEach(order => {
//             dispatch(reAddToCart({...order, itemId: nanoid(), orderId: tempOrderId})); // Dispatch Redux action for each item
//         });
//         window.scrollTo({top: 0, behavior: "instant"}); // Scroll to the top of the page
//     };
//
//     // Handles deleting a saved order by its temporary order ID
//     const handleDeleteSavedOrder = async (tempOrderId) => {
//         try {
//             const response = await deleteSavedOrder(tempOrderId, token);
//             if (response.status === 200) {
//                 // Remove the deleted order from the local state
//                 setSavedOrders(prevOrders =>
//                     prevOrders.filter(order => order.tempOrderId !== tempOrderId)
//                 );
//                 setShouldFetchOrders(true); // Trigger a re-fetch of saved orders
//             } else {
//                 console.error('Failed to delete saved order:', response.data);
//             }
//         } catch (error) {
//             console.error('Error deleting saved order:', error);
//         }
//     };
//
//     // Group saved orders by their temporary order ID
//     const groupedOrders = savedOrders?.reduce((acc, order) => {
//         const groupId = order.tempOrderId;
//         if (!acc[groupId]) {
//             acc[groupId] = [];
//         }
//         acc[groupId].push(order);
//         return acc;
//     }, {});
//
//     return (
//         <div>
//             {/* Render appropriate navbar based on login status */}
//             {userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}
//
//             <div className="container my-4 min-vh-100">
//                 {savedOrders === null ? (
//                     // Show loading state while orders are being fetched
//                     <div className="text-center">Loading...</div>
//                 ) : savedOrders?.length === 0 ? (
//                     // Show a message if no saved orders are found
//                     <div className="text-center">
//                         <h5><strong>You do not have any saved orders at this time.</strong></h5>
//                     </div>
//                 ) : (
//                     // Render grouped saved orders
//                     Object.entries(groupedOrders).map(([tempOrderId, orders]) => (
//                         <div key={tempOrderId} className="row mb-4">
//                             <div className="col">
//                                 <div className="card" id={`order-${tempOrderId}`}>
//                                     {/* Card header with order ID and PDF generation */}
//                                     <div className="card-header d-flex justify-content-between align-items-center">
//                                         <h5 className="card-title">Temporary Order ID: {tempOrderId}</h5>
//                                         <PdfWriterComponent orderDetails={orders} imageURL={imageURL}/>
//                                     </div>
//                                     {/* List all items in the order */}
//                                     {orders.map((order, index) => (
//                                         <div key={index} className="card-body d-flex"
//                                              id={`order-item-${order.serialNumber}`}>
//                                             <div className="order-details flex-grow-1">
//                                                 <p className="card-text">
//                                                     <strong>Item Name:</strong> {order?.positionName}<br/>
//                                                     <strong>Serial Number:</strong> {order.serialNumber}<br/>
//                                                     <strong>Height:</strong> {order.height} in<br/>
//                                                     <strong>Width:</strong> {order.width} in<br/>
//                                                     <strong>Depth:</strong> {order.depth} in<br/>
//                                                     <strong>Front Material:</strong> {order.frontMaterial}<br/>
//                                                     <strong>Case Material:</strong> {order.caseMaterial}<br/>
//                                                     <strong>Quantity:</strong> {order.quantity}<br/>
//                                                 </p>
//                                             </div>
//                                             <div className="order-image">
//                                                 <img
//                                                     src={`${imageURL}${order.serialNumber}.webp`}
//                                                     className="card-img-top sr-cstm-img"
//                                                     alt={`${order.serialNumber}`}
//                                                 />
//                                             </div>
//                                         </div>
//                                     ))}
//                                     {/* Footer with action buttons */}
//                                     <div className="card-footer text-center so-btn-container">
//                                         <button
//                                             className="btn btn-dark me-2"
//                                             onClick={() => handleOrderAgain(tempOrderId)}
//                                         >
//                                             Order Again
//                                         </button>
//                                         <button
//                                             className="btn btn-dark"
//                                             onClick={() => handleDeleteSavedOrder(tempOrderId)}
//                                         >
//                                             Delete
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     ))
//                 )}
//             </div>
//         </div>
//     );
// };
//
// export default SavedOrdersComponent;


import React, {useEffect, useState} from 'react';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import {useDispatch, useSelector} from 'react-redux';
import {reAddToCart} from '../../features/cart/cartSlice';
import {deleteSavedOrder, getAllSavedOrders} from '../../api/OrderService';
import PdfWriterComponent from '../PdfWriterComponent/PdfWriterComponent';
import {nanoid} from '@reduxjs/toolkit';
import './SavedOrdersStyles.css';

const SavedOrdersComponent = () => {
    const [savedOrders, setSavedOrders] = useState(null);
    const [shouldFetchOrders, setShouldFetchOrders] = useState(true);
    const userLoggedIn = UserLoggedIn();
    const email = useSelector((state) => state.user.user.email);
    const token = useSelector((state) => state.user.accessToken);
    const dispatch = useDispatch();

    const imageURL = process.env.REACT_APP_IMAGEURL;

    useEffect(() => {
        console.log()
        if (shouldFetchOrders) {
            const fetchSavedOrders = async () => {
                try {
                    const response = await getAllSavedOrders(email, token);
                    setSavedOrders(response.data);
                    setShouldFetchOrders(false);
                } catch (e) {
                    console.error('Error fetching saved orders: ', e);
                    setSavedOrders([]);
                }

            };
            fetchSavedOrders();
        }
    }, [shouldFetchOrders]);

    const handleOrderAgain = (tempOrderId) => {
        const ordersToAdd = savedOrders.filter(
            (order) => order.tempOrderId.toString() === tempOrderId.toString()
        );
        ordersToAdd.forEach((order) => {
            dispatch(reAddToCart({...order, itemId: nanoid(), orderId: tempOrderId}));
        });
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    const handleDeleteSavedOrder = async (tempOrderId) => {
        try {
            const response = await deleteSavedOrder(tempOrderId, token);
            if (response.status === 200) {
                setSavedOrders((prevOrders) =>
                    prevOrders.filter((order) => order.tempOrderId !== tempOrderId)
                );
                setShouldFetchOrders(true);
            } else {
                console.error('Failed to delete saved order:', response.data);
            }
        } catch (error) {
            console.error('Error deleting saved order:', error);
        }
    };

    const groupedOrders = savedOrders?.reduce((acc, order) => {
        const groupId = order.tempOrderId;
        if (!acc[groupId]) {
            acc[groupId] = [];
        }
        acc[groupId].push(order);
        return acc;
    }, {});

    return (
        <div className="saved-orders-page">
            <div className="container-wide my-4 min-vh-100">
                {savedOrders === null ? (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                        <p>Loading your orders...</p>
                    </div>
                ) : savedOrders?.length === 0 ? (
                    <div className="empty-state">
                        <h5>You do not have any saved orders at this time.</h5>
                        <p>Your saved orders will appear here once you create them.</p>
                    </div>
                ) : (
                    Object.entries(groupedOrders).map(([tempOrderId, orders]) => (
                        <div key={tempOrderId} className="saved-order-container">
                            <div className="saved-order-header">
                                <div className="saved-order-header-content">
                                    <h5 className="saved-order-id">Order ID: {tempOrderId}</h5>
                                    <div className="pdf-button-wrapper">
                                        <PdfWriterComponent orderDetails={orders} imageURL={imageURL}/>
                                    </div>
                                </div>
                            </div>
                            <div className="saved-order-items">
                                {orders.map((order, index) => (
                                    <div key={index} className="saved-order-item">
                                        <div className="saved-order-card">
                                            <div className="saved-order-image-wrapper">
                                                <div className="saved-order-image">
                                                    <img
                                                        src={`${imageURL}${order.serialNumber}.webp`}
                                                        alt={order.positionName}
                                                        className="saved-order-item-img"
                                                    />
                                                </div>
                                            </div>
                                            <div className="saved-order-details">
                                                <div className="detail-row">
                                                    <span className="detail-label">Item:</span>
                                                    <span className="detail-value">{order.positionName}</span>
                                                </div>
                                                <div className="detail-row">
                                                    <span className="detail-label">Serial:</span>
                                                    <span className="detail-value">{order.serialNumber}</span>
                                                </div>
                                                <div className="detail-row">
                                                    <span className="detail-label">Dimensions:</span>
                                                    <span className="detail-value">
                                                        {order.height} x {order.width} x {order.depth} in
                                                    </span>
                                                </div>
                                                <div className="detail-row">
                                                    <span className="detail-label">Front Material:</span>
                                                    <span className="detail-value">{order.frontMaterial}</span>
                                                </div>
                                                <div className="detail-row">
                                                    <span className="detail-label">Case Material:</span>
                                                    <span className="detail-value">{order.caseMaterial}</span>
                                                </div>
                                                <div className="detail-row">
                                                    <span className="detail-label">Quantity:</span>
                                                    <span className="detail-value">{order.quantity}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="saved-order-footer">
                                <button
                                    className="btn-order-again"
                                    onClick={() => handleOrderAgain(tempOrderId)}
                                >
                                    Order Again
                                </button>
                                <button
                                    className="btn-delete"
                                    onClick={() => handleDeleteSavedOrder(tempOrderId)}
                                >
                                    Delete Order
                                </button>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default SavedOrdersComponent;