import React from 'react';
import {Form, Table} from 'react-bootstrap';

const DrawersTable = ({drawers, order, handleDrawerFieldChange}) => {
    const drawerOptionsList = [
        'A - Cutlery Insert',
        'B - Utensil Insert',
        'C - Cutlery/Utensil',
        'D - Knife Insert',
        'E - Spice Inser',
        'F - Custom Dividers',
        'G - Double Drawer',
        'H1 - File Drawer - Front to Back',
        'H2 - File Drawer - Side to Side',
        'J - Shaped Sides',
        'L - U-Shaped Drawer',
        'M - Corner Drawer',
        'N - Trash Pull-Out, Drawer',
        'O - Trash Pull-Out Drawer',
        'P - Bread Drawer'
    ];
    return (
        <>
            <Table responsive className="om-table">
                <thead>
                <tr>
                    <th>Qty</th>
                    <th>Width</th>
                    <th>Height</th>
                    <th>Depth</th>
                    <th>Bottom</th>
                    <th>Notch &amp; Bore</th>
                    <th>Finish</th>
                    <th>Drawer Options</th>
                    <th>Finish Front</th>
                    <th>Scoop</th>
                    <th>Engrave Logo</th>
                    <th>Mount Type</th>
                    <th>Include Glides</th>
                    <th>Notes</th>
                </tr>
                </thead>
                <tbody>
                {drawers.map((drawer, index) => (
                    <tr key={index}>
                        <td>{drawer.quantity}</td>
                        <td>{drawer.width}</td>
                        <td>{drawer.height}</td>
                        <td>{drawer.depth}</td>
                        <td>{drawer.bottom || '1/4"'}</td>
                        <td>{drawer.notchAndBore || 'Yes'}</td>
                        <td>{drawer.finish || 'Yes'}</td>
                        <td>
                            <Form.Group>
                                <Form.Select
                                    value={drawer.drawerOptions || ''}
                                    onChange={(e) => {
                                        const selectedText = e.target.value;
                                        // Store only the letter (the part before the hyphen)
                                        handleDrawerFieldChange(index, 'drawerOptions', selectedText);
                                    }}
                                >
                                    <option value="">Select</option>
                                    {drawerOptionsList.map((option, idx) => {
                                        const value = option.split('-')[0].trim();
                                        return (
                                            <option key={idx} value={value}>
                                                {option}
                                            </option>
                                        );
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </td>
                        <td>{drawer.finishFront || 'No'}</td>
                        <td>{drawer.scoop || 'No'}</td>
                        <td>{drawer.engraveLogo || 'No'}</td>
                        <td>{drawer.mountType || 'Under'}</td>
                        <td>{order && order.includeHardware ? 'Yes' : 'No'}</td>
                        <td>{drawer.notes || ''}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </>
    );
};

export default DrawersTable;
