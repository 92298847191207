import React from 'react';

const ConfigurationGuide = () => {
    return (
        <section id="configuration" className="mt-5">
            <h2 className="sealab-tutorial__section-title">Cabinet Configuration Details</h2>

            <div className="guide-content">
                <p>
                    After establishing global settings, focus on configuring individual
                    cabinet specifications. Pay particular attention to reveal gaps
                    between cabinets, as these affect both aesthetics and functionality.
                    Consider how cabinets interact with adjacent units and walls when
                    setting gap measurements.
                </p>

                <div className="mt-4">
                    <p>Configuration options include:</p>
                    <ul className="list-disc pl-6 space-y-2">
                        <li>Individual material overrides from global settings</li>
                        <li>Specific edge treatments</li>
                        <li>Hardware placement</li>
                        <li>Shelf quantity and positioning</li>
                        <li>Gap settings for all sides</li>
                        <li>Special features like leg levelers (please note, a cabinet's width and depth must both be
                            greater than 10.75" to apply leg levelers) or custom drawer heights
                        </li>
                    </ul>
                </div>

                <p className="mt-4">
                    Take time to review each cabinet's configuration thoroughly before
                    proceeding to ensure all specifications align with your project
                    requirements.
                </p>
            </div>
        </section>
    );
};

export default ConfigurationGuide;