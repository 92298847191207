import React, {useEffect, useState} from 'react';
import {FaArrowLeft, FaArrowRight} from 'react-icons/fa';
import './ToolTipImageCarouselStyles.css';
import ToolTipService from '../../api/ToolTipService';

const ToolTipImageCarousel = ({field}) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [images, setImages] = useState([]);

    // Fetch images dynamically based on field
    useEffect(() => {
        if (field) {
            ToolTipService.getToolTipImages(field)
                .then(response => {
                    setImages(response.data); // Set the image URLs retrieved from the server
                })
                .catch(error => {
                    console.error('Error fetching images:', error);
                    setImages([]); // Handle the case when no images are available
                });
        }
    }, [field]);

    useEffect(() => {
    }, [images])

    const handlePrevClick = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNextClick = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="tooltip-carousel-container">
            <div className="tooltip-image-wrapper">
                {images.length > 0 ? (
                    <img
                        src={images[currentImageIndex]} // Load the image dynamically from S3 or base URL
                        className="tooltip-carousel-img"
                        alt={`Tooltip Image ${currentImageIndex + 1}`}
                        // loading="lazy"
                    />
                ) : (
                    <p>No images available.</p> // If no images are available
                )}
            </div>
            {images.length > 1 && ( // Only show arrows if there are multiple images
                <div className="tooltip-carousel-controls">
                    <FaArrowLeft onClick={handlePrevClick} className="tooltip-carousel-arrow tooltip-left-arrow"/>
                    <FaArrowRight onClick={handleNextClick} className="tooltip-carousel-arrow tooltip-right-arrow"/>
                </div>
            )}
        </div>
    );
};

export default ToolTipImageCarousel;
