import React from 'react';
import GlobalSettingsSection from './GlobalSettingsSection';
import DimensionsSection from './DimensionsSection';
import NameCabinetSection from './NameCabinetSection';
import MaterialsSection from './MaterialsSection';
import JoineryMethodSection from './JoineryMethodSection';
import EdgebandingSection from './EdgebandingSection';
import DoorSettingsSection from './DoorSettingsSection';
import DrawerSettingsSection from './DrawerSettingsSection';
import AdjustableShelvesSection from './AdjustableShelvesSection';
import LegLevelersSection from './LegLevelersSection';
import GapsSection from './GapsSection';

/**
 * OrderForm component renders the form for configuring an order.
 * It dynamically renders sections based on item properties.
 *
 * @param {Object} props - Contains all the required data and handlers for the form.
 */
function OrderForm({
                       fromConfigureButton, // Boolean to determine if the form is for configuration or update
                       handleUpdateItems, // Function to handle form submission
                       applyGlobalSettings, // Whether to apply global settings to the order
                       setApplyGlobalSettings, // Function to toggle global settings application
                       itemProperties, // Object containing all properties of the item being configured
                       setItemProperties,
                       errors, // Object holding error states for various fields
                       handleInputChange, // Function to handle input changes in the form
                       handleToolTipClick, // Function to handle tooltip interactions
                       parseRange, // Function to parse range values (e.g., "0-100")
                       selectedItems, // List of items selected for the order
                       positionNameRef, // Reference for the position name input field
                       positionNameError, // Error message for position name validation
                       materials, // List of material options
                       excludeFronts, // Whether fronts are excluded from the configuration
                       setExcludeFronts, // Function to toggle exclusion of fronts
                       backPanelOptions, // List of back panel options
                       jointOptions, // List of joinery method options
                       drawerOptions, // List of drawer options
                       hingeOptions, // List of hinge options
                       edgebandingOptions, // List of edgebanding options
                       edgeBanding, // Object holding edgebanding state
                       setEdgeBanding, // Function to update edgebanding state
                       includeLegLevelers, // Boolean to determine if leg levelers are included
                       setIncludeLegLevelers, // Function to toggle inclusion of leg levelers
                   }) {
    return (
        <form onSubmit={handleUpdateItems} className="order-form">
            {/* Global Settings Section */}
            <GlobalSettingsSection
                applyGlobalSettings={applyGlobalSettings}
                setApplyGlobalSettings={setApplyGlobalSettings}
            />

            {/* Dimensions Section - Rendered only if height range exists */}
            {itemProperties.heightRange && (
                <DimensionsSection
                    itemProperties={itemProperties}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleToolTipClick={handleToolTipClick}
                    parseRange={parseRange}
                />
            )}

            {/* Cabinet Name Section */}
            <NameCabinetSection
                selectedItems={selectedItems}
                itemProperties={itemProperties}
                positionNameRef={positionNameRef}
                handleInputChange={handleInputChange}
                handleToolTipClick={handleToolTipClick}
                positionNameError={positionNameError}
            />

            {/* Materials Section - Rendered if certain conditions are met */}
            {((itemProperties.caseEdge !== '0') || (itemProperties.frontEdge !== '0') || (itemProperties.matBack !== '0')) && (
                <MaterialsSection
                    itemProperties={itemProperties}
                    excludeFronts={excludeFronts}
                    setExcludeFronts={setExcludeFronts}
                    materials={materials}
                    handleInputChange={handleInputChange}
                    handleToolTipClick={handleToolTipClick}
                    edgebandingOptions={edgebandingOptions}
                />
            )}

            {/* Joinery Method Section */}
            {((itemProperties.matBack === '1') || (itemProperties.jointMethod === '1')) && (
                <JoineryMethodSection
                    itemProperties={itemProperties}
                    backPanelOptions={backPanelOptions}
                    jointOptions={jointOptions}
                    handleInputChange={handleInputChange}
                    handleToolTipClick={handleToolTipClick}
                />
            )}

            {/* Edgebanding Section */}
            {((itemProperties.caseEdge !== '0') || (itemProperties.frontEdge !== '0') ||
                (itemProperties.serialNumber?.startsWith('LP_SP') || itemProperties.serialNumber?.startsWith('LP_GP'))) && (
                <EdgebandingSection
                    itemProperties={itemProperties}
                    edgeBanding={edgeBanding}
                    setEdgeBanding={setEdgeBanding}
                    edgebandingOptions={edgebandingOptions}
                    handleInputChange={handleInputChange}
                    handleToolTipClick={handleToolTipClick}
                />
            )}

            {/* Door Settings Section */}
            {itemProperties.doors === '1' && (
                <DoorSettingsSection
                    itemProperties={itemProperties}
                    hingeOptions={hingeOptions}
                    handleInputChange={handleInputChange}
                    handleToolTipClick={handleToolTipClick}
                />
            )}

            {/* Drawer Settings Section */}
            {itemProperties.drawers === '1' && (
                <DrawerSettingsSection
                    itemProperties={itemProperties}
                    drawerOptions={drawerOptions}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleToolTipClick={handleToolTipClick}
                />
            )}

            {/* Adjustable Shelves Section */}
            {itemProperties.adjShelves === '1' && (
                <AdjustableShelvesSection
                    itemProperties={itemProperties}
                    handleInputChange={handleInputChange}
                    handleToolTipClick={handleToolTipClick}
                />
            )}

            {/* Leg Levelers Section - Rendered based on multiple conditions */}
            {(itemProperties?.filterTags?.includes('Leg_Levelers') &&
                (itemProperties.depthRange.split('-')[0] >= 10.75 || itemProperties.depth >= 10.75) &&
                (itemProperties?.widthRange.split('-')[0] >= 10.75 || itemProperties.width >= 10.75)) && (
                <LegLevelersSection
                    itemProperties={itemProperties}
                    includeLegLevelers={includeLegLevelers}
                    setIncludeLegLevelers={setIncludeLegLevelers}
                    handleToolTipClick={handleToolTipClick}
                />
            )}

            {/* Gaps Section */}
            {itemProperties.gapControl === '1' && (
                <GapsSection
                    itemProperties={itemProperties}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    handleToolTipClick={handleToolTipClick}
                />
            )}

            {/* Submit Button */}
            <div className="order-form__submit-container">
                <button type="submit" className="order-form__submit-button">
                    {fromConfigureButton ? 'Add to Cart' : 'Update'}
                </button>
            </div>
        </form>
    );
}

export default OrderForm;
