import React, {useEffect, useRef, useState} from 'react';
import {Camera, Ruler} from 'lucide-react';

const RoomMeasurement = () => {
    const [image, setImage] = useState(null);
    const [dimensions, setDimensions] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [serverStatus, setServerStatus] = useState(null);
    const canvasRef = useRef(null);

    useEffect(() => {
        checkServerStatus();
    }, []);

    const checkServerStatus = async () => {
        try {
            const response = await fetch('http://localhost:8000/health');
            if (response.ok) {
                setServerStatus('connected');
            } else {
                setServerStatus('error');
                setError('Server is not responding correctly');
            }
        } catch (err) {
            setServerStatus('error');
            setError('Cannot connect to server. Please ensure it is running on port 8000');
        }
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setLoading(true);
        setError(null);
        setDimensions(null);

        try {
            // Create FormData for image upload
            const formData = new FormData();
            formData.append('file', file);

            // Upload the image through FastAPI
            const uploadResponse = await fetch('http://localhost:8000/upload-room-photo', {
                method: 'POST',
                body: formData,
            });

            if (!uploadResponse.ok) {
                throw new Error('Failed to upload image');
            }

            const uploadResult = await uploadResponse.json();

            // Send message through the FastAPI chat proxy
            const chatResponse = await fetch('http://localhost:8000/chat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sender: 'user',
                    message: 'upload_room_photo',
                    metadata: {
                        room_photo_path: uploadResult.file_path
                    }
                }),
            });

            if (!chatResponse.ok) {
                throw new Error('Failed to process image');
            }

            const chatResult = await chatResponse.json();
            console.log('Chat result:', chatResult);

            // Display the image in canvas
            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = canvasRef.current;
                    if (canvas) {
                        canvas.width = img.width;
                        canvas.height = img.height;
                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(img, 0, 0);
                    }
                };
                img.src = e.target.result;
                setImage(e.target.result);
            };
            reader.readAsDataURL(file);

            // Parse the dimensions from response
            if (chatResult.responses && chatResult.responses[0] && chatResult.responses[0].text) {
                const dimensionText = chatResult.responses[0].text;
                const parsedDims = parseDimensionsFromResponse(dimensionText);
                if (parsedDims) {
                    setDimensions(parsedDims);
                } else {
                    throw new Error('Failed to parse room dimensions');
                }
            } else {
                throw new Error('Invalid response format from server');
            }

        } catch (err) {
            console.error('Error processing image:', err);
            setError(err.message || 'An unexpected error occurred');
        } finally {
            setLoading(false);
        }
    };

    const parseDimensionsFromResponse = (text) => {
        try {
            const dims = {};
            const lines = text.split('\n');

            lines.forEach(line => {
                if (line.includes(':')) {
                    const [dimension, value] = line.split(':');
                    if (dimension && value) {
                        const numericValue = value.match(/[\d.]+/);
                        if (numericValue) {
                            dims[dimension.trim().toLowerCase()] = `${numericValue[0]} feet`;
                        }
                    }
                }
            });

            return Object.keys(dims).length > 0 ? dims : null;
        } catch (err) {
            console.error('Error parsing dimensions:', err);
            return null;
        }
    };

    return (
        <div className="card w-100 mx-auto" style={{maxWidth: '64rem'}}>
            <div className="card-header">
                <h5 className="card-title mb-0 d-flex align-items-center gap-2">
                    <Ruler className="me-2" size={24}/>
                    Room Measurement Tool
                </h5>
            </div>
            <div className="card-body">
                <div className="d-flex flex-column gap-4">
                    {serverStatus === 'error' && (
                        <div className="alert alert-warning">
                            <strong>Server Status:</strong> Not Connected
                            <br/>
                            <small>Please ensure the server is running on port 8000</small>
                        </div>
                    )}

                    <div className="text-center">
                        <label
                            className="d-flex flex-column align-items-center p-4 border border-2 border-dashed rounded cursor-pointer hover-bg-light"
                        >
                            <Camera className="text-secondary mb-2" size={32}/>
                            <span className="text-secondary small">Upload room photo</span>
                            <input
                                type="file"
                                className="d-none"
                                accept="image/*"
                                onChange={handleImageUpload}
                                disabled={serverStatus === 'error'}
                            />
                        </label>
                    </div>

                    {loading && (
                        <div className="alert alert-info">
                            <div className="d-flex align-items-center">
                                <div className="spinner-border spinner-border-sm me-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                Analyzing room dimensions...
                            </div>
                        </div>
                    )}

                    {error && (
                        <div className="alert alert-danger">
                            <strong>Error:</strong> {error}
                        </div>
                    )}

                    {image && (
                        <div className="position-relative">
                            <canvas
                                ref={canvasRef}
                                className="w-100 h-auto rounded shadow"
                            />
                            {dimensions && (
                                <div className="position-absolute top-0 end-0 m-3 bg-white p-3 rounded shadow">
                                    <h6 className="fw-bold mb-2">Estimated Dimensions:</h6>
                                    <ul className="list-unstyled mb-0">
                                        <li className="mb-1">Length: {dimensions.length}</li>
                                        <li className="mb-1">Width: {dimensions.width}</li>
                                        <li>Height: {dimensions.height}</li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RoomMeasurement;
