// Import necessary dependencies
import React from 'react';
import {getTooltipContent} from './utils/renderUtils';

/**
 * LegLevelersSection Component
 *
 * This component renders a section in the order form for selecting whether
 * to include leg levelers as a bottom panel connector.
 *
 * @param {Object} props - Props passed to the component
 * @param {Object} itemProperties - The properties of the current item being configured
 * @param {boolean} includeLegLevelers - State indicating whether leg levelers are included
 * @param {Function} setIncludeLegLevelers - Function to toggle the inclusion of leg levelers
 * @param {Function} handleToolTipClick - Function to handle tooltip interactions
 */
function LegLevelersSection({
                                itemProperties,
                                includeLegLevelers,
                                setIncludeLegLevelers,
                                handleToolTipClick
                            }) {
    
    return (
        <div className="order-form__section">
            {/* Section Title */}
            <h3 className="order-form__section-title">Bottom Panel Connector</h3>

            {/* Checkbox Group for Including Leg Levelers */}
            <div className="order-form__group">
                {/* Label for the Checkbox */}
                <label htmlFor="includeLegLevelers" className="order-form__label">
                    Include Leg Levelers?

                    {/* Tooltip for Additional Information */}
                    <span
                        className="order-form__tooltip-trigger"
                        onClick={() => handleToolTipClick('includeLegLevelers')}
                    >
                        <span className="order-form__tooltip-content">
                            {getTooltipContent('includeLegLevelers')}
                        </span>
                    </span>
                </label>

                {/* Checkbox for Including Leg Levelers */}
                <input
                    type="checkbox"
                    id="includeLegLevelers"
                    className="order-form__checkbox"
                    checked={includeLegLevelers}
                    onChange={(e) => setIncludeLegLevelers(e.target.checked)}
                />

            </div>
        </div>
    );
}

export default LegLevelersSection;
