// import React, {useEffect} from 'react';
// import {BrowserRouter, Route, Routes} from "react-router-dom";
// import OrderPage from "./components/OrderComponent/OrderPage";
// import LandingPage from "./components/LandingComponent/LandingPage";
// import CartComponent from "./components/CartComponent/CartComponent";
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './App.css';
//
// import {Provider} from "react-redux";
// import {PersistGate} from 'redux-persist/integration/react';
// import {persistor, store} from "./redux/store";
// import ProfileComponent from "./components/ProfileComponent/ProfileComponent";
// import ShowRoomComponent from "./components/ShowRoomComponent/ShowRoomComponent";
// import EditProfileComponent from "./components/ProfileComponent/EditProfileComponent";
// import CheckoutComponent from "./components/CheckoutComponent/CheckoutComponent";
// import ContactComponent from "./components/ContactComponent/ContactComponent";
// import SavedOrdersComponent from "./components/SavedOrdersComponent/SavedOrdersComponent";
// import ResetPasswordPage from "./modals/ResetPasswordPage";
// import OrderDetailsComponent from "./components/CustomerPortalComponent/OrderDetailsComponent";
// import CustomerPortal from "./components/CustomerPortalComponent/CustomerPortal";
// import CabinetQCComponent from "./components/CustomerPortalComponent/CabinetQCComponent";
// import OrderSummaryComponent from "./components/CustomerPortalComponent/OrderSummaryComponent";
// import {ToastContainer} from "react-toastify";
// import AuthCheck from "../src/hooks/useAuthCheck";
// import OrderMatHardwareComponent from "./components/OrderMatHardwareComponent/OrderMatHardwareComponent";
// import PrivacyComponent from "./components/LegalComponents/PrivacyComponent";
// import TSComponent from "./components/LegalComponents/TSComponent";
// import Footer from "./components/LegalComponents/Footer";
// import AboutUsComponent from "./components/AboutUsComponent/AboutUsComponent";
// import {HelmetProvider} from "react-helmet-async";
// import ToolTipExplainComponent from "./components/ToolTipComponent/ToolTipExplainComponent";
// import GlobalSettingsComponent from "./components/GlobalSettingsComponent/GlobalSettingsComponent";
// import DrawingTool from "./components/DrawingComponent/DrawingTool";
// import AdminRoute from "./components/UploadArticleComponent/AdminRoute";
// import TutorialComponent from "./components/TutorialComponent/TutorialComponent";
// import OrderList from "./components/OrderManagementComponent/OrderList";
// import OrderDetailPage from "./components/OrderManagementComponent/OrderDetailPage";
// import InternalTools from "./components/InternalToolsComponent/InternalTools";
// import PDFViewer from "./components/CustomerPortalComponent/PDFViewer";
// import ChatBot from "./components/ChatComponent/ChatBot";
// import CatalogInfo from "./components/CatalogInfoComponent/CatalogInfo";
// import RoomMeasurement from "./components/RoomMeasurement";
//
//
// // const stripePromise = loadStripe('pk_live_51PNcpVHPrftNOJaIOhl0tlU2xcpGczBG2EBxTqp89VIxzcAPYGthqG3CyxUVxdZukljFDMbnrt9FtmrXyiFydcdR00h6K6BoG7');
//
// // const stripePromise = loadStripe('pk_test_51PNcpVHPrftNOJaIGr4jPmF6IzYDzG64q4FHbOGaybQWg9Mn6Y6i20nXkmojJbUALh1mCFCGXKqyJpSPahogWwj500j0VNAFPt');
//
// function App() {
//     useEffect(() => {
//         const script = document.createElement("script");
//         script.src = "https://www.googletagmanager.com/gtag/js?id=AW-11552954224";
//         script.async = true;
//         document.head.appendChild(script);
//
//         script.onload = () => {
//             window.dataLayer = window.dataLayer || [];
//
//             function gtag() {
//                 window.dataLayer.push(arguments);
//             }
//
//             gtag("js", new Date());
//             gtag("config", "AW-11552954224");
//         };
//     }, []);
//     return (
//         <HelmetProvider>
//             <Provider store={store}>
//                 <PersistGate loading={null} persistor={persistor}>
//                     <BrowserRouter>
//                         <AuthCheck>
//                             {/*<Elements stripe={stripePromise}>*/}
//                             <Routes>
//                                 <Route path="/" element={<LandingPage/>}/>
//                                 <Route path="/order" element={<OrderPage/>}/>
//                                 <Route path="/cart" element={<CartComponent/>}/>
//                                 <Route path="/user/profile" element={<ProfileComponent/>}/>
//                                 <Route path="/showroom" element={<ShowRoomComponent/>}/>
//                                 <Route path="/user/edit-profile" element={<EditProfileComponent/>}/>
//                                 <Route path="/checkout" element={<CheckoutComponent/>}/>
//                                 <Route path="/customer-support/contact" element={<ContactComponent/>}/>
//                                 <Route path="/user/customer-portal" element={<CustomerPortal/>}/>
//                                 <Route path="/user/customer-portal/:orderId" element={<OrderDetailsComponent/>}/>
//                                 <Route path="/api/cabinet/quality-control/:orderId/:positionName"
//                                        element={<CabinetQCComponent/>}/>
//                                 <Route path="/user/customer-portal/:orderId/summary"
//                                        element={<OrderSummaryComponent/>}/>
//                                 <Route path="/user/customer-portal/:orderId/materials&hardware"
//                                        element={<OrderMatHardwareComponent/>}/>
//                                 <Route path="/user/profile/saved-orders" element={<SavedOrdersComponent/>}/>
//                                 <Route path="/reset-password" element={<ResetPasswordPage/>}/>
//                                 <Route path="/the-sealab/legal/privacy" element={<PrivacyComponent/>}/>
//                                 <Route path="/the-sealab/legal/terms" element={<TSComponent/>}/>
//                                 <Route path="/the-sealab/about-us" element={<AboutUsComponent/>}/>
//                                 <Route path="/learning/tool-tip/explain" element={<ToolTipExplainComponent/>}/>
//                                 <Route path="/cart/global-settings" element={<GlobalSettingsComponent/>}/>
//                                 {/*<Route path="/drawing" element={<DrawingTool/>}/>*/}
//                                 <Route path="/drawing" element={<DrawingTool/>}/>
//                                 <Route path="/learning/tutorials" element={<TutorialComponent/>}/>
//
//                                 <Route path="/internal/order-management"
//                                        element={<AdminRoute element={<OrderList/>}/>}/>
//                                 <Route path="/internal/order-management/:orderId"
//                                        element={<AdminRoute element={<OrderDetailPage/>}/>}/>
//                                 <Route path="/admin/internal-tools" element={<AdminRoute element={<InternalTools/>}/>}/>
//                                 <Route path="/pdf-viewer" element={<PDFViewer/>}/>
//                                 <Route path="/testing1" element={<ChatBot/>}/>
//                                 <Route path="/catalog/:serialNumber" element={<CatalogInfo/>}/>
//                                 <Route path="/testing2" element={<RoomMeasurement/>}/>
//
//                             </Routes>
//                             {/*</Elements>*/}
//                             <ToastContainer position="top-center" autoClose={2000}
//                                             hideProgressBar={true}></ToastContainer>
//                             {/*<TokenChecker/>*/}
//                         </AuthCheck>
//                         <Footer/>
//                     </BrowserRouter>
//                 </PersistGate>
//             </Provider>
//         </HelmetProvider>
//     );
// }
//
// export default App;


import React, {useEffect} from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Provider, useSelector} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react';
import {ToastContainer} from "react-toastify";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import {persistor, store} from "./redux/store";
import {HelmetProvider} from "react-helmet-async";

// Components
import NavbarComponent from "./components/NavBarComponents/NavbarComponent";
import NavbarLoggedInComponent from "./components/NavBarComponents/NavbarLoggedInComponent";
import Footer from "./components/LegalComponents/Footer";
import AuthCheck from "../src/hooks/useAuthCheck";

// Pages
import LandingPage from "./components/LandingComponent/LandingPage";
import OrderPage from "./components/OrderComponent/OrderPage";
import CartComponent from "./components/CartComponent/CartComponent";
import ProfileComponent from "./components/ProfileComponent/ProfileComponent";
import ShowRoomComponent from "./components/ShowRoomComponent/ShowRoomComponent";
import EditProfileComponent from "./components/ProfileComponent/EditProfileComponent";
import CheckoutComponent from "./components/CheckoutComponent/CheckoutComponent";
import ContactComponent from "./components/ContactComponent/ContactComponent";
import SavedOrdersComponent from "./components/SavedOrdersComponent/SavedOrdersComponent";
import ResetPasswordPage from "./modals/ResetPasswordPage";
import OrderDetailsComponent from "./components/CustomerPortalComponent/OrderDetailsComponent";
import CustomerPortal from "./components/CustomerPortalComponent/CustomerPortal";
import CabinetQCComponent from "./components/CustomerPortalComponent/CabinetQCComponent";
import OrderSummaryComponent from "./components/CustomerPortalComponent/OrderSummaryComponent";
import OrderMatHardwareComponent from "./components/OrderMatHardwareComponent/OrderMatHardwareComponent";
import PrivacyComponent from "./components/LegalComponents/PrivacyComponent";
import TSComponent from "./components/LegalComponents/TSComponent";
import AboutUsComponent from "./components/AboutUsComponent/AboutUsComponent";
import ToolTipExplainComponent from "./components/ToolTipComponent/ToolTipExplainComponent";
import GlobalSettingsComponent from "./components/GlobalSettingsComponent/GlobalSettingsComponent";
import DrawingTool from "./components/DrawingComponent/DrawingTool";
import AdminRoute from "./components/UploadArticleComponent/AdminRoute";
import TutorialComponent from "./components/TutorialComponent/TutorialComponent";
import OrderList from "./components/OrderManagementComponent/OrderList";
import OrderDetailPage from "./components/OrderManagementComponent/OrderDetailPage";
import InternalTools from "./components/InternalToolsComponent/InternalTools";
import PDFViewer from "./components/CustomerPortalComponent/PDFViewer";
import ChatBot from "./components/ChatComponent/ChatBot";
import CatalogInfo from "./components/CatalogInfoComponent/CatalogInfo";
import RoomMeasurement from "./components/RoomMeasurement";

const UserLoggedIn = () => {
    const user = useSelector((state) => state.user.user) || {};
    return user.email !== null && Boolean(Object.keys(user).length > 0);
};

function App() {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.googletagmanager.com/gtag/js?id=AW-11552954224";
        script.async = true;
        document.head.appendChild(script);

        script.onload = () => {
            window.dataLayer = window.dataLayer || [];

            function gtag() {
                window.dataLayer.push(arguments);
            }

            gtag("js", new Date());
            gtag("config", "AW-11552954224");
        };
    }, []);

    return (
        <HelmetProvider>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <AuthCheck>
                            <Navbar/>
                            <Routes>
                                <Route path="/" element={<LandingPage/>}/>
                                <Route path="/order" element={<OrderPage/>}/>
                                <Route path="/cart" element={<CartComponent/>}/>
                                <Route path="/user/profile" element={<ProfileComponent/>}/>
                                <Route path="/showroom" element={<ShowRoomComponent/>}/>
                                <Route path="/user/edit-profile" element={<EditProfileComponent/>}/>
                                <Route path="/checkout" element={<CheckoutComponent/>}/>
                                <Route path="/customer-support/contact" element={<ContactComponent/>}/>
                                <Route path="/user/customer-portal" element={<CustomerPortal/>}/>
                                <Route path="/user/customer-portal/:orderId" element={<OrderDetailsComponent/>}/>
                                <Route path="/api/cabinet/quality-control/:orderId/:positionName"
                                       element={<CabinetQCComponent/>}/>
                                <Route path="/user/customer-portal/:orderId/summary"
                                       element={<OrderSummaryComponent/>}/>
                                <Route path="/user/customer-portal/:orderId/materials&hardware"
                                       element={<OrderMatHardwareComponent/>}/>
                                <Route path="/user/profile/saved-orders" element={<SavedOrdersComponent/>}/>
                                <Route path="/reset-password" element={<ResetPasswordPage/>}/>
                                <Route path="/the-sealab/legal/privacy" element={<PrivacyComponent/>}/>
                                <Route path="/the-sealab/legal/terms" element={<TSComponent/>}/>
                                <Route path="/the-sealab/about-us" element={<AboutUsComponent/>}/>
                                <Route path="/learning/tool-tip/explain" element={<ToolTipExplainComponent/>}/>
                                <Route path="/cart/global-settings" element={<GlobalSettingsComponent/>}/>
                                <Route path="/drawing" element={<DrawingTool/>}/>
                                <Route path="/learning/tutorials" element={<TutorialComponent/>}/>
                                <Route path="/internal/order-management"
                                       element={<AdminRoute element={<OrderList/>}/>}/>
                                <Route path="/internal/order-management/:orderId"
                                       element={<AdminRoute element={<OrderDetailPage/>}/>}/>
                                <Route path="/admin/internal-tools" element={<AdminRoute element={<InternalTools/>}/>}/>
                                <Route path="/pdf-viewer" element={<PDFViewer/>}/>
                                <Route path="/testing1" element={<ChatBot/>}/>
                                <Route path="/catalog/:serialNumber" element={<CatalogInfo/>}/>
                                <Route path="/testing2" element={<RoomMeasurement/>}/>
                            </Routes>
                            <ToastContainer position="top-center" autoClose={2000} hideProgressBar={true}/>
                        </AuthCheck>
                        <Footer/>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        </HelmetProvider>
    );
}

const Navbar = () => {
    const isUserLoggedIn = UserLoggedIn();
    return isUserLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>;
};

export default App;
