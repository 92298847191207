// sections/VideoTutorials.js
import React from 'react';

const VideoTutorials = () => {
    const videos = [
        {
            url: 'https://www.youtube.com/embed/nEmzdlhQ640',
            title: 'SEALAB TUTORIAL - INTRODUCTION',
            params: {
                vq: 'hd1080',
                modestbranding: 1,
                rel: 0,
                controls: 1,
                quality: 'hd1080'
            }
        },
        {
            url: 'https://www.youtube.com/embed/UCgU-ALkBMU',
            title: 'SEALAB TUTORIALS - STARTING YOUR CART',
            params: {
                vq: 'hd1080',
                modestbranding: 1,
                rel: 0,
                controls: 1,
                quality: 'hd1080'
            }
        },
        {
            url: 'https://www.youtube.com/embed/rzOki2HM62s',
            title: 'SEALAB TUTORIALS - GLOBAL SETTINGS',
            params: {
                vq: 'hd1080',
                modestbranding: 1,
                rel: 0,
                controls: 1,
                quality: 'hd1080'
            }
        },
        {
            url: 'https://www.youtube.com/embed/CkXNoTTiZgU',
            title: 'SEALAB TUTORIALS - CABINET CONFIGURATIONS',
            params: {
                vq: 'hd1080',
                modestbranding: 1,
                rel: 0,
                controls: 1,
                quality: 'hd1080'
            }
        },

        {
            url: 'https://www.youtube.com/embed/N-iyKuhQH0U',
            title: 'SEALAB TUTORIALS - SUBMITTING THE CART',
            params: {
                vq: 'hd1080',
                modestbranding: 1,
                rel: 0,
                controls: 1,
                quality: 'hd1080'
            }
        },
        {
            url: 'https://www.youtube.com/embed/680G5xBkONU',
            title: 'SEALAB TUTORIALS - MANAGING YOUR ORDER',
            params: {
                vq: 'hd1080',
                modestbranding: 1,
                rel: 0,
                controls: 1,
                quality: 'hd1080'
            }
        },
    ];

    const getVideoUrl = (video) => {
        const params = new URLSearchParams(video.params).toString();
        return `${video.url}?${params}`;
    };

    return (
        <section id="video-tutorials" className="sealab-tutorial__section">
            <h2 className="sealab-tutorial__section-title">Video Tutorials</h2>
            <div className="sealab-tutorial__video-grid">
                {videos.map((video, index) => (
                    <div key={index} className="sealab-tutorial__video-item">
                        <div className="sealab-tutorial__video-wrapper">
                            <iframe
                                className="sealab-tutorial__video-frame"
                                src={getVideoUrl(video)}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title={video.title}
                                loading="lazy"
                            ></iframe>
                        </div>
                        <h3 className="sealab-tutorial__video-title">{video.title}</h3>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default VideoTutorials;