// components/ShowRoom/Pagination/Pagination.js

import React from 'react';

/**
 * Pagination component for navigating through multiple pages of data.
 * Displays the list of page numbers along with "First" and "Last" navigation buttons.
 *
 * @param {number} currentPage - The currently active page number.
 * @param {number} totalPages - The total number of pages.
 * @param {function} onPageChange - Callback function triggered when a page number is clicked.
 */
const Pagination = ({currentPage, totalPages, onPageChange}) => {
    // Generate the list of page numbers dynamically
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
        pages.push(
            <li
                key={i}
                className={`page-item sr-pi ${i === currentPage ? 'active' : ''}`} // Add 'active' class for the current page
            >
                <button
                    className="page-link sr-pl"
                    onClick={() => onPageChange(i)} // Trigger `onPageChange` with the selected page number
                >
                    {i}
                </button>
            </li>
        );
    }

    return (
        <nav>
            <ul className="pagination sr-pagination justify-content-center">
                {/* "First" button - Navigates to the first page */}
                <li
                    className={`page-item sr-pi ${currentPage === 1 ? 'disabled' : ''}`} // Disable button if on the first page
                >
                    <button
                        className="page-link sr-pl"
                        onClick={() => onPageChange(1)} // Go to the first page
                        disabled={currentPage === 1} // Prevent click when already on the first page
                    >
                        First
                    </button>
                </li>

                {/* Render the dynamically generated list of page numbers */}
                {pages}

                {/* "Last" button - Navigates to the last page */}
                <li
                    className={`page-item sr-pi ${currentPage === totalPages ? 'disabled' : ''}`} // Disable button if on the last page
                >
                    <button
                        className="page-link sr-pl"
                        onClick={() => onPageChange(totalPages)} // Go to the last page
                        disabled={currentPage === totalPages} // Prevent click when already on the last page
                    >
                        Last
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
