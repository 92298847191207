// src/components/OrderPageComponents/DimensionsSection.js

import React from 'react';
import {renderCornerInputField, renderInputField} from './utils/renderUtils';

/**
 * Component for configuring dimensions in the order form.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.itemProperties - Properties of the item being configured.
 * @param {Object} props.errors - Object indicating validation errors for specific fields.
 * @param {Function} props.handleInputChange - Function to handle changes in input fields.
 * @param {Function} props.handleToolTipClick - Function to handle tooltip clicks.
 * @param {Function} props.parseRange - Function to parse a range string into minimum and maximum values.
 */
function DimensionsSection({itemProperties, errors, handleInputChange, handleToolTipClick, parseRange}) {
    return (
        <div className="order-form__section">
            {/* Section title for dimensions */}
            <h3 className="order-form__section-title">Dimensions</h3>

            {/* Input fields for basic dimensions: Height, Width, Depth */}
            {renderInputField(
                'Height', // Label for the field
                'height', // Field name in item properties
                itemProperties, // Current item properties
                errors, // Validation errors
                handleInputChange, // Function to handle input changes
                handleToolTipClick, // Function to handle tooltip interactions
                parseRange // Function to parse range values
            )}
            {renderInputField(
                'Width',
                'width',
                itemProperties,
                errors,
                handleInputChange,
                handleToolTipClick,
                parseRange
            )}
            {renderInputField(
                'Depth',
                'depth',
                itemProperties,
                errors,
                handleInputChange,
                handleToolTipClick,
                parseRange
            )}

            {/* Additional input fields for corner dimensions, rendered only for corner cabinets */}
            {itemProperties.serialNumber?.includes('CR') && (
                <div className="">
                    {/* Section title for corner dimensions */}
                    <h3 className="order-form__section-title">Corner Dimensions</h3>

                    {/* Input fields for corner-specific dimensions */}
                    {renderCornerInputField(
                        'Left Corner Width', // Label for the field
                        'leftCornerWidth', // Field name in item properties
                        parseFloat(itemProperties.width) - 1.5, // Maximum allowed value
                        itemProperties, // Current item properties
                        errors, // Validation errors
                        handleInputChange, // Function to handle input changes
                        handleToolTipClick // Function to handle tooltip interactions
                    )}
                    {renderCornerInputField(
                        'Right Corner Depth',
                        'rightCornerDepth',
                        parseFloat(itemProperties.depth) - 1.5,
                        itemProperties,
                        errors,
                        handleInputChange,
                        handleToolTipClick
                    )}
                </div>
            )}
        </div>
    );
}

export default DimensionsSection;
