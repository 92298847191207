import {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import axios from 'axios';
import './OrderSummaryStyles.css';
import {useSelector} from "react-redux";
import jsPDF from 'jspdf';
import QRCode from 'qrcode';
import {FaInfoCircle} from "react-icons/fa";


const OrderSummaryComponent = () => {
    const {orderId} = useParams();
    const [order, setOrder] = useState(null);
    const [articles, setArticles] = useState([]);
    const [imageExistsMap, setImageExistsMap] = useState({});
    const userLoggedIn = UserLoggedIn();
    const token = useSelector(state => state.user.accessToken);
    const navigate = useNavigate();
    const orderSummaryContainerRef = useRef(null);
    const downloadButtonRef = useRef(null);
    const baseURL = process.env.REACT_APP_BASEURL;
    const imageURL = process.env.REACT_APP_IMAGEURL;
    const thumbNail = process.env.REACT_APP_THMBNAIL;
    const orderDWG = process.env.REACT_APP_THMBNAIL + `${orderId}/` + 'Article_Report/' + `${orderId}.DWG`
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
        const fetchOrderSummary = async () => {
            try {
                const response = await axios.get(`${baseURL}/api/orders/${orderId}/summary`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setOrder(response.data.order);
                setArticles(response.data.articles);
                checkImagesExistence(response.data.articles);
            } catch (error) {
                console.error('Error fetching order summary:', error);
            }
        };

        fetchOrderSummary();
    }, [orderId, token, baseURL]);

    const checkImagesExistence = async (articles) => {
        const map = {};
        const promises = articles.map(async article => {
            const url = `${thumbNail}${orderId}/${article.positionName}.png`;
            try {
                const response = await fetch(url, {method: 'HEAD'});
                map[article.positionName] = response.ok;
            } catch (error) {
                console.error('Error checking image existence:', error);
                map[article.positionName] = false;
            }
        });

        await Promise.all(promises);
        setImageExistsMap(map);
    };

    const handleArticleClick = (positionName) => {
        const article = articles.find(article => article.positionName === positionName);
        navigate(`/api/cabinet/quality-control/${orderId}/${positionName}`, {state: {article, order}});
    };

    const getArticleColumnClass = () => {
        const windowWidth = window.innerWidth;
        if (windowWidth <= 768) {
            return 'col-12';
        } else if (windowWidth <= 992) {
            return 'col-md-6';
        } else {
            return 'col-md-4';
        }
    };

    const downloadPDF = async () => {
        setDownloading(true);
        if (!order) return;

        const pdf = new jsPDF('p', 'mm', 'a4'); // A4 portrait
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const margin = 5;
        const cardWidth = (pageWidth - 4 * margin) / 3;
        const cardHeight = (pageHeight - 6 * margin) / 5;

        for (let i = 0; i < articles.length; i++) {
            if (i > 0 && i % 15 === 0) {
                pdf.addPage();
            }

            const article = articles[i];
            const col = i % 3;
            const row = Math.floor((i % 15) / 3);

            const x = margin + col * (cardWidth + margin);
            const y = margin + row * (cardHeight + margin);

            // Generate QR code
            const qrCodeDataUrl = await QRCode.toDataURL(`https://www.thesealab.com/api/cabinet/quality-control/${orderId}/${article.positionName}`);

            // Draw article details
            pdf.setFontSize(6);
            pdf.text(`Serial Number: ${article.serialNumber}`, x + 2, y + 5);
            pdf.text(`Position #: ${article.positionName}`, x + 2, y + 9);
            pdf.text(`Dimensions: ${article.width}in x ${article.height}in x ${article.depth}in`, x + 2, y + 13);
            pdf.text(`Materials: ${article.caseMaterial}, ${article.frontMaterial}`, x + 2, y + 17);

            // Add QR code
            pdf.addImage(qrCodeDataUrl, 'PNG', x + 2, y + 20, 20, 20);

            // Add article image
            const imgUrl = imageExistsMap[article.positionName]
                ? `${thumbNail}${orderId}/${article.positionName}.png`
                : `${imageURL}no_picture.jpg`;
            const img = await loadImage(imgUrl);
            pdf.addImage(img, 'PNG', x + 24, y + 20, cardWidth - 26, cardHeight - 22);

            // Draw border
            pdf.rect(x, y, cardWidth, cardHeight);
        }

        pdf.save(`order-${order.orderId}.pdf`);
        setDownloading(false);
    };

    const loadImage = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = url;
        });
    };

    const downloadOrderDWG = async () => {
        try {
            // Fetch the file from the S3 bucket
            const response = await fetch(orderDWG);
            if (!response.ok) {
                throw new Error(`Failed to fetch file: ${response.statusText}`);
            }

            // Convert the response to a Blob
            const blob = await response.blob();

            // Create a link element to download the file
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.download = `${orderId}.DWG`; // Suggest the file name

            // Append, trigger click, and remove the link
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Release the object URL
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <div className="qsc-order-summary-page qsc-min-vh-100">


            <div className="qsc-order-summary-container" id="qsc-order-summary-container"
                 ref={orderSummaryContainerRef}>
                <div className="qsc-main-container">
                    <div className="qsc-header-container">
                        <h2 className="qsc-text-center qsc-mb-4">Item Details</h2>
                        <div className="qsc-test-container">
                            <button
                                ref={downloadButtonRef}
                                onClick={downloadPDF}
                                className="qsc-download-pdf-button"
                                disabled={downloading}
                            >
                                {downloading ? 'Downloading...' : 'Download PDF'}
                            </button>
                            <button
                                className="qsc-download-dwg-button"
                                onClick={downloadOrderDWG}
                            >
                                Download Order DWG
                            </button>
                        </div>
                    </div>

                    {order && (
                        <>
                            <div className="qsc-order-details-card">
                                <div className="qsc-order-details">
                                    <h4>Order Details</h4>
                                    <p><strong>Order ID:</strong> {order.orderId}</p>
                                    <p><strong>Status:</strong> {order.status}</p>
                                    <p><strong>Date:</strong> {order.date}</p>
                                </div>
                            </div>
                            <div className="qsc-articles">
                                <h4>Articles</h4>
                                <div className="qsc-row">
                                    {articles.map((article, index) => (
                                        <div key={article.articleId}
                                             className={`${getArticleColumnClass()} qsc-article-card ${(index + 1) % 3 === 0 ? 'qsc-end-row' : ''}`}
                                             onClick={() => handleArticleClick(article.positionName)}>
                                            <div className="qsc-article-details">
                                                <p className="qsc-order-summary-text"><strong>Serial
                                                    Number:</strong> {article.serialNumber}</p>
                                                <p className="qsc-order-summary-text"><strong>Position
                                                    #:</strong> {article.positionName}</p>
                                                <p className="qsc-cart-item-text">
                                                    <strong>Dimensions:</strong> {article.width}in
                                                    {article.serialNumber.startsWith("LP") && !article.serialNumber.startsWith("LP_SP")
                                                        ? ''
                                                        : ` x ${article.height}in`}
                                                    {!(article.serialNumber.startsWith("LP_SP")) && ` x ${article.depth}in`}
                                                </p>

                                                <p className="qsc-order-summary-text">
                                                    <strong>Materials:</strong> {article.caseMaterial}
                                                    {!article.serialNumber.startsWith('LP_GP') && `, ${article.frontMaterial}`}
                                                </p>
                                            </div>
                                            <div className="qsc-article-media">
                                                <div className="qsc-article-image">
                                                    {imageExistsMap[article.positionName] ? (
                                                        <img src={`${thumbNail}${orderId}/${article.positionName}.png`}
                                                             alt={`Article ${article.positionName}`}
                                                             className="qsc-article-thumbnail"/>
                                                    ) : (
                                                        <div className="qsc-custom-tooltip">
                                                            <FaInfoCircle className="qsc-tooltip-icon"/>
                                                            <span
                                                                className="qsc-custom-tooltiptext">Image Processing</span>
                                                            <img src={`${imageURL}no_picture.jpg`}
                                                                 alt={`Article ${article.articleId}`}
                                                                 className="qsc-no-img-thumbnail"/>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );

};

export default OrderSummaryComponent;