// // src/utils/renderUtils.js
// import React from 'react';
//
// export const getTooltipContent = (field) => {
//     const tooltips = {
//         height: "Enter the desired height of your cabinet in inches.",
//         width: "Enter the desired width of your cabinet in inches.",
//         depth: "Enter the desired depth of your cabinet in inches (carcass only).",
//         positionName: "Enter a unique name or identifier for this cabinet.",
//         caseMaterial: "Select the material for the cabinet case.",
//         frontMaterial: "Select the material for the cabinet front.",
//         backPanelMaterial: "Select the material for the back panel of the cabinet.",
//         caseEdge: "Choose the edge type for the cabinet case.",
//         frontEdge: "Choose the edge type for the cabinet front.",
//         backPanel: "Select the method for attaching the back panel.",
//         hingePlate: "Choose the type of hinge plate for the cabinet doors.",
//         drawerType: "Select the type of drawer for this cabinet.",
//         jointMethod: "Choose the method for joining cabinet parts.",
//         numOfShelves: "Select the number of adjustable shelves or choose parametric shelving.",
//         topDrwrHeightValue: "Enter the height of the top drawer in inches (between 4 and 28).",
//         includeLegLevelers: "Check this box to include leg levelers with your cabinet.",
//         gapTop: "Choose the desired gap at the top of the cabinet (max 0.125 inches).",
//         gapBottom: "Choose the desired gap at the bottom of the cabinet (max 0.125 inches).",
//         gapLeft: "Choose the desired gap on the left side of the cabinet (max 0.125 inches).",
//         gapRight: "Choose the desired gap on the right side of the cabinet (max 0.125 inches).",
//         gapCenter: "Enter the desired gap in the center of the cabinet (max 0.125 inches).",
//         edgeBandingType: "Select the type of edgebanding for your cabinet.",
//         leftCornerWidth: "Enter the width of the left corner (min 1.5 inches, max cabinet width minus 1.5 inches).",
//         rightCornerDepth: "Enter the depth of the right corner (min 1.5 inches, max cabinet depth minus 1.5 inches).",
//         excludeFronts: "Check this box if you want to exclude front parts from your cabinet order."
//     };
//     return tooltips[field] || "Additional information about this option.";
// };
//
// export const renderDropdown = (label, fieldName, options, defaultValue, disabled, itemProperties, handleInputChange, handleToolTipClick) => (
//
//     <div className="order-form__group">
//         <label htmlFor={fieldName} className="order-form__label">
//             {label}
//             <span className="order-form__tooltip-trigger" onClick={() => handleToolTipClick(fieldName)}>
//                 <span className="order-form__tooltip-content">
//                     {getTooltipContent(fieldName)}
//                 </span>
//             </span>
//         </label>
//         <select
//             id={fieldName}
//             className="order-form__select"
//             // value={itemProperties[fieldName] || defaultValue}
//             value={itemProperties[fieldName] === '1' ? 'No Edgebanding' : itemProperties[fieldName]}
//             onChange={(e) => handleInputChange(e, fieldName)}
//             disabled={disabled}
//         >
//             {options.map((option) => (
//                 <option key={option} value={option}>
//                     {option}
//                 </option>
//             ))}
//         </select>
//     </div>
// );
//
// export const renderInputField = (label, field, itemProperties, errors, handleInputChange, handleToolTipClick, parseRange) => {
//     const [min, max] = parseRange(itemProperties[`${field}Range`]);
//     return (
//         <div className="order-form__group">
//             <label htmlFor={field} className="order-form__label">
//                 {label} (in)
//                 <span className="order-form__tooltip-trigger" onClick={() => handleToolTipClick(field)}>
//                     <span className="order-form__tooltip-content">
//                         {getTooltipContent(field)}
//                     </span>
//                 </span>
//             </label>
//             <input
//                 type="number"
//                 id={field}
//                 className={`order-form__input ${errors[field] ? 'order-form__input--invalid' : ''}`}
//                 value={itemProperties[field] || ''}
//                 onChange={(e) => handleInputChange(e, field)}
//                 step="0.001"
//                 min={min}
//                 max={max}
//                 onKeyDown={(e) => {
//                     if (e.key === 'Enter')
//                         e.preventDefault()
//                 }}
//             />
//             {errors[field] && (
//                 <div className="order-form__error-message">Please enter a value between {min} and {max}.</div>
//             )}
//         </div>
//     );
// };
//
// export const renderGapDropdown = (field, label, itemProperties, errors, handleInputChange, handleToolTipClick) => (
//     <div className={`order-form__gap-input-wrapper order-form__gap-${field.toLowerCase()}`}>
//     <span className="order-form__tooltip-trigger" onClick={() => handleToolTipClick(field)}>
//         <span className="order-form__tooltip-content">
//             {getTooltipContent(field)}
//         </span>
//     </span>
//         <label htmlFor={field} className="order-form__label">
//             {label}
//         </label>
//         <select
//             id={field}
//             className={`order-form__input order-form__gap-dropdown ${errors[field] ? 'order-form__input--invalid' : ''}`}
//             value={itemProperties[field] !== undefined ? itemProperties[field] : (1 / 8).toFixed(3)}
//             onChange={(e) => handleInputChange(e, field)}
//         >
//             <option value="0">Flush (0")</option>
//             <option value={(1 / 16).toFixed(3)}>Half Reveal (1/16")</option>
//             <option value={(1 / 8).toFixed(3)}>Full Reveal (1/8")</option>
//         </select>
//         {errors[field] && (
//             <div className="order-form__error-message">Please select a valid option.</div>
//         )}
//     </div>
// );
//
// export const renderGapInputFields = (itemProperties, errors, handleInputChange, handleToolTipClick) => {
//     return (
//         <div className="order-form__gap-input-container">
//             {renderGapDropdown('gapTop', 'Gap Top', itemProperties, errors, handleInputChange, handleToolTipClick)}
//             <div className="order-form__gap-input-row">
//                 {renderGapDropdown('gapLeft', 'Gap Left', itemProperties, errors, handleInputChange, handleToolTipClick)}
//                 {renderGapDropdown('gapRight', 'Gap Right', itemProperties, errors, handleInputChange, handleToolTipClick)}
//             </div>
//             {renderGapDropdown('gapBottom', 'Gap Bottom', itemProperties, errors, handleInputChange, handleToolTipClick)}
//         </div>
//     );
// };
//
// export const renderCornerInputField = (label, field, maxValue, itemProperties, errors, handleInputChange, handleToolTipClick) => {
//     return (
//         <div className="order-form__group">
//             <label htmlFor={field} className="order-form__label">
//                 {label} (in)
//                 <span className="order-form__tooltip-trigger" onClick={() => handleToolTipClick(field)}>
//                 <span className="order-form__tooltip-content">
//                     {getTooltipContent(field)}
//                 </span>
//             </span>
//             </label>
//             <input
//                 type="text"
//                 id={field}
//                 className={`order-form__input ${errors[field] ? 'order-form__input--invalid' : ''}`}
//                 value={itemProperties[field] || 1.5}
//                 min="1.5"
//                 max={maxValue}
//                 onChange={(e) => handleInputChange(e, field)}
//                 onKeyDown={(e) => {
//                     if (!/[0-9.]/.test(e.key)) {
//                         e.preventDefault();
//                     }
//                 }}
//             />
//             {errors[field] && (
//                 <div className="order-form__error-message">
//                     {errors[field]}
//                 </div>
//             )}
//         </div>
//     );
// };


// src/utils/renderUtils.js

import React from 'react';

/**
 * Provides tooltip content for various fields.
 * @param {string} field - The field name to retrieve tooltip content for.
 * @returns {string} Tooltip content for the field.
 */
export const getTooltipContent = (field) => {
    const tooltips = {
        height: "Enter the desired height of your cabinet in inches.",
        width: "Enter the desired width of your cabinet in inches.",
        depth: "Enter the desired depth of your cabinet in inches (carcass only).",
        positionName: "Enter a unique name or identifier for this cabinet.",
        caseMaterial: "Select the material for the cabinet case.",
        frontMaterial: "Select the material for the cabinet front.",
        backPanelMaterial: "Select the material for the back panel of the cabinet.",
        caseEdge: "Choose the edge type for the cabinet case.",
        frontEdge: "Choose the edge type for the cabinet front.",
        backPanel: "Select the method for attaching the back panel.",
        hingePlate: "Choose the type of hinge plate for the cabinet doors.",
        drawerType: "Select the type of drawer for this cabinet.",
        jointMethod: "Choose the method for joining cabinet parts.",
        numOfShelves: "Select the number of adjustable shelves or choose parametric shelving.",
        topDrwrHeightValue: "Enter the height of the top drawer in inches (between 4 and 28).",
        includeLegLevelers: "Check this box to include leg levelers with your cabinet.",
        gapTop: "Choose the desired gap at the top of the cabinet (max 0.125 inches).",
        gapBottom: "Choose the desired gap at the bottom of the cabinet (max 0.125 inches).",
        gapLeft: "Choose the desired gap on the left side of the cabinet (max 0.125 inches).",
        gapRight: "Choose the desired gap on the right side of the cabinet (max 0.125 inches).",
        gapCenter: "Enter the desired gap in the center of the cabinet (max 0.125 inches).",
        edgeBandingType: "Select the type of edgebanding for your cabinet.",
        leftCornerWidth: "Enter the width of the left corner (min 1.5 inches, max cabinet width minus 1.5 inches).",
        rightCornerDepth: "Enter the depth of the right corner (min 1.5 inches, max cabinet depth minus 1.5 inches).",
        excludeFronts: "Check this box if you want to exclude front parts from your cabinet order.",
    };
    return tooltips[field] || "Additional information about this option.";
};

/**
 * Renders a dropdown field with a label and tooltip.
 * @param {string} label - The label for the dropdown.
 * @param {string} fieldName - The name of the field being represented by the dropdown.
 * @param {Array<string>} options - An array of options for the dropdown.
 * @param {string} defaultValue - The default value for the dropdown.
 * @param {boolean} disabled - Whether the dropdown is disabled.
 * @param {Object} itemProperties - The current properties of the item.
 * @param {Function} handleInputChange - Function to handle changes in the input field.
 * @param {Function} handleToolTipClick - Function to handle tooltip interactions.
 * @returns {JSX.Element} The dropdown component.
 */
export const renderDropdown = (label, fieldName, options, defaultValue, disabled, itemProperties, handleInputChange, handleToolTipClick) => (
    <div className="order-form__group">
        <label htmlFor={fieldName} className="order-form__label">
            {label}
            <span className="order-form__tooltip-trigger" onClick={() => handleToolTipClick(fieldName)}>
                <span className="order-form__tooltip-content">
                    {getTooltipContent(fieldName)}
                </span>
            </span>
        </label>
        <select
            id={fieldName}
            className="order-form__select"
            value={itemProperties[fieldName] === '1' ? 'No Edgebanding' : itemProperties[fieldName]}
            onChange={(e) => handleInputChange(e, fieldName)}
            disabled={disabled}
        >
            {options.map((option) => (
                <option key={option} value={option}>
                    {option}
                </option>
            ))}
        </select>
    </div>
);

/**
 * Renders a numeric input field for user input.
 * @param {string} label - The label for the input field.
 * @param {string} field - The field name being represented by the input.
 * @param {Object} itemProperties - The current properties of the item.
 * @param {Object} errors - Any validation errors for the input.
 * @param {Function} handleInputChange - Function to handle changes in the input field.
 * @param {Function} handleToolTipClick - Function to handle tooltip interactions.
 * @param {Function} parseRange - Function to parse min and max range for the field.
 * @returns {JSX.Element} The numeric input component.
 */
export const renderInputField = (label, field, itemProperties, errors, handleInputChange, handleToolTipClick, parseRange) => {
    const [min, max] = parseRange(itemProperties[`${field}Range`]);
    return (
        <div className="order-form__group">
            <label htmlFor={field} className="order-form__label">
                {label} (in)
                <span className="order-form__tooltip-trigger" onClick={() => handleToolTipClick(field)}>
                    <span className="order-form__tooltip-content">
                        {getTooltipContent(field)}
                    </span>
                </span>
            </label>
            <input
                type="number"
                id={field}
                className={`order-form__input ${errors[field] ? 'order-form__input--invalid' : ''}`}
                value={itemProperties[field] || ''}
                onChange={(e) => handleInputChange(e, field)}
                step="0.001"
                min={min}
                max={max}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') e.preventDefault();
                }}
            />
            {errors[field] && (
                <div className="order-form__error-message">Please enter a value between {min} and {max}.</div>
            )}
        </div>
    );
};

/**
 * Renders dropdown fields for adjustable gaps in the cabinet.
 * @param {string} field - The name of the field being represented by the dropdown.
 * @param {string} label - The label for the dropdown field.
 * @param {Object} itemProperties - The current properties of the item.
 * @param {Object} errors - Any validation errors for the input.
 * @param {Function} handleInputChange - Function to handle changes in the input field.
 * @param {Function} handleToolTipClick - Function to handle tooltip interactions.
 * @returns {JSX.Element} The gap dropdown component.
 */
export const renderGapDropdown = (field, label, itemProperties, errors, handleInputChange, handleToolTipClick) => (
    <div className={`order-form__gap-input-wrapper order-form__gap-${field.toLowerCase()}`}>
        <span className="order-form__tooltip-trigger" onClick={() => handleToolTipClick(field)}>
            <span className="order-form__tooltip-content">
                {getTooltipContent(field)}
            </span>
        </span>
        <label htmlFor={field} className="order-form__label">
            {label}
        </label>
        <select
            id={field}
            className={`order-form__input order-form__gap-dropdown ${errors[field] ? 'order-form__input--invalid' : ''}`}
            value={itemProperties[field] !== undefined ? itemProperties[field] : (1 / 8).toFixed(3)}
            onChange={(e) => handleInputChange(e, field)}
        >
            <option value="0">Flush (0")</option>
            <option value={(1 / 16).toFixed(3)}>Half Reveal (1/16")</option>
            <option value={(1 / 8).toFixed(3)}>Full Reveal (1/8")</option>
        </select>
        {errors[field] && (
            <div className="order-form__error-message">Please select a valid option.</div>
        )}
    </div>
);

/**
 * Renders input fields for gaps around the cabinet.
 * @param {Object} itemProperties - The current properties of the item.
 * @param {Object} errors - Any validation errors for the input.
 * @param {Function} handleInputChange - Function to handle changes in the input fields.
 * @param {Function} handleToolTipClick - Function to handle tooltip interactions.
 * @returns {JSX.Element} A container with all the gap input fields.
 */
export const renderGapInputFields = (itemProperties, errors, handleInputChange, handleToolTipClick) => (
    <div className="order-form__gap-input-container">
        {renderGapDropdown('gapTop', 'Gap Top', itemProperties, errors, handleInputChange, handleToolTipClick)}
        <div className="order-form__gap-input-row">
            {renderGapDropdown('gapLeft', 'Gap Left', itemProperties, errors, handleInputChange, handleToolTipClick)}
            {renderGapDropdown('gapRight', 'Gap Right', itemProperties, errors, handleInputChange, handleToolTipClick)}
        </div>
        {renderGapDropdown('gapBottom', 'Gap Bottom', itemProperties, errors, handleInputChange, handleToolTipClick)}
    </div>
);

/**
 * Renders input fields for corner dimensions.
 * @param {string} label - The label for the input field.
 * @param {string} field - The name of the field being represented.
 * @param {number} maxValue - The maximum allowable value for the field.
 * @param {Object} itemProperties - The current properties of the item.
 * @param {Object} errors - Any validation errors for the input.
 * @param {Function} handleInputChange - Function to handle changes in the input field.
 * @param {Function} handleToolTipClick - Function to handle tooltip interactions.
 * @returns {JSX.Element} The corner input field component.
 */
export const renderCornerInputField = (label, field, maxValue, itemProperties, errors, handleInputChange, handleToolTipClick) => (
    <div className="order-form__group">
        <label htmlFor={field} className="order-form__label">
            {label} (in)
            <span className="order-form__tooltip-trigger" onClick={() => handleToolTipClick(field)}>
                <span className="order-form__tooltip-content">
                    {getTooltipContent(field)}
                </span>
            </span>
        </label>
        <input
            type="text"
            id={field}
            className={`order-form__input ${errors[field] ? 'order-form__input--invalid' : ''}`}
            value={itemProperties[field] || 1.5}
            min="1.5"
            max={maxValue}
            onChange={(e) => handleInputChange(e, field)}
            onKeyDown={(e) => {
                if (!/[0-9.]/.test(e.key)) {
                    e.preventDefault();
                }
            }}
        />
        {errors[field] && (
            <div className="order-form__error-message">
                {errors[field]}
            </div>
        )}
    </div>
);
