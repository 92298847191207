import React from 'react';

const CatalogGuide = () => {
    return (
        <section id="catalog-guide" className="mt-5">
            <h2 className="sealab-tutorial__section-title">Getting Started with Cabinet Selection</h2>

            <div className="guide-content">
                <p>
                    The first step in placing your order is understanding how to effectively use
                    the catalog system. Begin by preparing your basic layout requirements, which
                    can be as simple as a hand sketch or as detailed as architectural drawings.
                    The key is defining your space divisions and cabinet requirements clearly.
                </p>

                <p>
                    When selecting cabinets, remember that dimensions are based on the cabinet
                    box itself, not including the front thickness or bumper gaps. Search the
                    catalog using general terms for the type of cabinet you need, such as
                    "base cabinet" or "sink base." As you find appropriate items, add them
                    to your cart for later configuration.
                </p>

                <p>
                    Consider creating a simple naming convention for your cabinets to track
                    them throughout the project. This organizational step will prove valuable
                    during configuration and installation phases.
                </p>
            </div>
        </section>
    );
};

export default CatalogGuide;