// src/components/OrderPageComponents/hooks/usePositionNameErrorHandling.js
import {useEffect} from 'react';

export function usePositionNameErrorHandling(positionNameError, positionNameRef, itemProperties) {
    useEffect(() => {
        if (positionNameError && positionNameRef.current) {
            setTimeout(() => {
                positionNameRef.current.scrollIntoView({behavior: 'smooth', block: 'center'});
                positionNameRef.current.focus();
            }, 100);
        }
    }, [itemProperties.positionName, positionNameError, positionNameRef]);
}
