// // components/Profile/ProfileComponent.js
//
// import React, {useEffect, useState} from 'react';
// import {NavLink, useNavigate} from 'react-router-dom';
// import {Helmet} from 'react-helmet-async'; // For SEO meta tags
// import NavbarComponent from '../NavBarComponents/NavbarComponent';
// import ChangedPasswordModal from '../../modals/ChangePasswordModal'; // Modal for changing password
// import './ProfileStyles.css';
// import {useDispatch, useSelector} from "react-redux";
// import {logOut} from "../../features/user/userSlice"; // Redux action to handle user logout
// import {UserLoggedIn} from "../../hooks/isUserLoggedIn"; // Hook to check if the user is logged in
//
// /**
//  * ProfileComponent - Renders the user profile page with options to manage profile,
//  * view orders, change the password, or logout.
//  *
//  * @returns {JSX.Element} - The profile page component.
//  */
// const ProfileComponent = () => {
//     const userLoggedIn = UserLoggedIn(); // Check if the user is logged in
//     const [showChangePasswordModal, setShowChangePasswordModal] = useState(false); // State for showing the change password modal
//     const name = useSelector(state => state.user.user.firstName); // Get the user's first name from the Redux store
//     const dispatch = useDispatch(); // Redux dispatch function
//     const navigate = useNavigate(); // Navigation hook for routing
//
//     // Redirect to the homepage if the user is not logged in
//     useEffect(() => {
//         if (!userLoggedIn) {
//             navigate('/');
//         }
//     }, [userLoggedIn, navigate]);
//
//     /**
//      * Utility function to capitalize the first letter of a string.
//      *
//      * @param {string} string - The string to capitalize.
//      * @returns {string} - The capitalized string.
//      */
//     const capitalizeFirstLetter = string => string?.charAt(0)?.toUpperCase() + string?.slice(1);
//
//     // Handlers for modal visibility
//     const handleOpenModal = () => setShowChangePasswordModal(true);
//     const handleCloseModal = () => setShowChangePasswordModal(false);
//
//     // Logout handler
//     const handleLogout = () => {
//         dispatch(logOut()); // Dispatch the logout action
//         navigate('/'); // Redirect to the homepage
//     };
//
//     // Render a loader or spinner if the user is not logged in
//     if (!userLoggedIn) {
//         return null; // This can be replaced with a loading spinner if needed
//     }
//
//     return (
//         <div className="profile-page-wrapper">
//             {/* SEO meta tags for the profile page */}
//             <Helmet>
//                 <title>{`${name} | The Sealab`}</title>
//                 <meta name="description"
//                       content="Manage your profile, view past orders, change your password, or logout. All your cabinet management in one place."/>
//                 <meta name="keywords"
//                       content="user profile, edit profile, view orders, change password, kitchen cabinets, closet cabinets, e-commerce, cabinet store"/>
//             </Helmet>
//
//             {/* Navigation bar */}
//             <NavbarComponent/>
//
//             <div className="profile-page">
//                 <div className="profile-container">
//                     <h1 className="profile-title">My Profile</h1>
//                     <div className="profile-card">
//                         {/* Display user's name */}
//                         <h2 className="profile-name">{capitalizeFirstLetter(name)}</h2>
//
//                         {/* Navigation links for profile management */}
//                         <div className="profile-links">
//                             {/* Link to edit profile */}
//                             <NavLink to="/user/edit-profile" className="profile-link">
//                                 <span className="link-icon">✏️</span>
//                                 Edit Profile
//                             </NavLink>
//
//                             {/* Link to view saved orders */}
//                             <NavLink to="/user/profile/saved-orders" className="profile-link">
//                                 <span className="link-icon">📦</span>
//                                 View Saved Orders
//                             </NavLink>
//
//                             {/* Link to open the change password modal */}
//                             <NavLink to="#" onClick={handleOpenModal} className="profile-link">
//                                 <span className="link-icon">🔒</span>
//                                 Change Password
//                             </NavLink>
//
//                             {/* Link to log out */}
//                             <NavLink to="/" onClick={handleLogout} className="profile-link">
//                                 <span className="link-icon">🚪</span>
//                                 Logout
//                             </NavLink>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//
//             {/* Change password modal */}
//             <ChangedPasswordModal show={showChangePasswordModal} handleClose={handleCloseModal}/>
//         </div>
//     );
// };
//
// export default ProfileComponent;


// components/Profile/ProfileComponent.js

import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import ChangedPasswordModal from '../../modals/ChangePasswordModal';
import {useDispatch, useSelector} from "react-redux";
import {logOut} from "../../features/user/userSlice";
import {UserLoggedIn} from "../../hooks/isUserLoggedIn";
import './ProfileStyles.css';

const ProfileComponent = () => {
    const userLoggedIn = UserLoggedIn();
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const name = useSelector(state => state.user.user.firstName);
    const lastName = useSelector(state => state.user.user.lastName);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userLoggedIn) {
            navigate('/');
        }
    }, [userLoggedIn, navigate]);

    const capitalizeFirstLetter = string => string?.charAt(0)?.toUpperCase() + string?.slice(1);

    const handleOpenModal = () => setShowChangePasswordModal(true);
    const handleCloseModal = () => setShowChangePasswordModal(false);

    const handleLogout = () => {
        dispatch(logOut());
        navigate('/');
    };

    if (!userLoggedIn) {
        return null;
    }

    return (
        <div className="sealab-profile-wrapper">
            <Helmet>
                <title>{`${name} | The Sealab`}</title>
                <meta name="description"
                      content="Manage your profile, view past orders, change your password, or logout. All your cabinet management in one place."/>
                <meta name="keywords"
                      content="user profile, edit profile, view orders, change password, kitchen cabinets, closet cabinets, e-commerce, cabinet store"/>
            </Helmet>

            {/*<NavbarComponent/>*/}

            <div className="sealab-profile-content">
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-6">
                            <div className="sealab-profile-card">
                                <div className="sealab-profile-header">
                                    <h1 className="sealab-profile-title">My Profile</h1>
                                    {/*<div className="sealab-profile-avatar">*/}
                                    {/*    {capitalizeFirstLetter(name)?.charAt(0)}*/}
                                    {/*</div>*/}
                                    {/*<h2 className="sealab-profile-name">{capitalizeFirstLetter(name)}</h2>*/}
                                    <h2 className="sealab-profile-name">{`${capitalizeFirstLetter(name)}` + ' ' + `${capitalizeFirstLetter(lastName)}`}</h2>
                                </div>

                                <div className="sealab-profile-actions">
                                    <NavLink to="/user/edit-profile" className="sealab-profile-button">
                                        <div className="sealab-button-icon">
                                            <span className="emoji-icon">✏️</span>
                                            <i className="bi bi-pencil-square"></i>
                                        </div>
                                        <div className="sealab-button-content">
                                            <div className="sealab-button-title">Edit Profile</div>
                                            <div className="sealab-button-subtitle">Update your personal information
                                            </div>
                                        </div>
                                    </NavLink>

                                    <NavLink to="/user/profile/saved-orders" className="sealab-profile-button">
                                        <div className="sealab-button-icon">
                                            <span className="emoji-icon">📦</span>
                                            <i className="bi bi-box-seam"></i>
                                        </div>
                                        <div className="sealab-button-content">
                                            <div className="sealab-button-title">Saved Orders</div>
                                            <div className="sealab-button-subtitle">View your order history</div>
                                        </div>
                                    </NavLink>

                                    <NavLink to="#" onClick={handleOpenModal} className="sealab-profile-button">
                                        <div className="sealab-button-icon">
                                            <span className="emoji-icon">🔒</span>
                                            <i className="bi bi-shield-lock"></i>
                                        </div>
                                        <div className="sealab-button-content">
                                            <div className="sealab-button-title">Change Password</div>
                                            <div className="sealab-button-subtitle">Update your security credentials
                                            </div>
                                        </div>
                                    </NavLink>

                                    <NavLink to="/" onClick={handleLogout} className="sealab-profile-button">
                                        <div className="sealab-button-icon">
                                            <span className="emoji-icon">🚪</span>
                                            <i className="bi bi-box-arrow-right"></i>
                                        </div>
                                        <div className="sealab-button-content">
                                            <div className="sealab-button-title">Logout</div>
                                            <div className="sealab-button-subtitle">Sign out of your account</div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ChangedPasswordModal show={showChangePasswordModal} handleClose={handleCloseModal}/>
        </div>
    );
};

export default ProfileComponent;