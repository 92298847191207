import React, {useRef, useState} from 'react';
import {UserLoggedIn} from "../../hooks/isUserLoggedIn";
import './ContactStyles.css';
import ErrorModal from '../../modals/ErrorModal';
import SuccessModal from '../../modals/SuccessModal';
import {Helmet} from "react-helmet-async";
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome

const ContactComponent = () => {
    const userLoggedIn = UserLoggedIn();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorFields, setErrorFields] = useState([]);
    const fileInputRef = useRef(null);
    const baseURL = process.env.REACT_APP_BASEURL;

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validateFields = () => {
        const requiredFields = ['name', 'email', 'message'];
        const emptyFields = requiredFields.filter(field => {
            if (field === 'email') {
                return !validateEmail(email);
            }
            return eval(field).trim() === '';
        });

        setErrorFields(emptyFields);
        return emptyFields.length === 0;
    };

    const handleFileChange = (e) => {
        setAttachment(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateFields()) {
            setErrorMessage('Please fill in all required fields correctly.');
            setShowErrorModal(true);
            return;
        }

        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('message', message);
        if (attachment) {
            formData.append('attachment', attachment);
        }

        try {
            const response = await fetch(`${baseURL}/api/sendContactEmail`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                setShowSuccessModal(true);
                setName('');
                setEmail('');
                setMessage('');
                setAttachment(null);
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            } else {
                setErrorMessage('Failed to send the message. Please try again.');
                setShowErrorModal(true);
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage('An error occurred while sending the message. Please try again.');
            setShowErrorModal(true);
        }
    };

    return (
        <div>
            <Helmet>
                <title>Contact Us | The Sealab - Kitchen & Closet Cabinets</title>
                <meta name="description"
                      content="Get in touch with The Sealab for inquiries about our high-quality kitchen and closet cabinets. Contact us for support, questions, or to request more information."/>
                <meta name="keywords"
                      content="contact us, customer support, inquiries, kitchen cabinets, closet cabinets, The Sealab, cabinet information, support questions"/>
                <meta property="og:title" content="Contact Us | The Sealab - Kitchen & Closet Cabinets"/>
                <meta property="og:description"
                      content="Get in touch with The Sealab for inquiries about our high-quality kitchen and closet cabinets. Contact us for support, questions, or to request more information."/>
                <meta property="og:url" content="https://www.thesealab.com/contact"/>
                <meta property="og:type" content="website"/>
            </Helmet>


            <div className="contact-container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="contact-content">
                                <h3 className="contact-heading">Contact The Sealab</h3>
                                <p className="contact-description">
                                    If you have any questions or inquiries, please don't hesitate to reach out to us.
                                </p>
                                <div className="contact-info">
                                    <p>
                                        <i className="fas fa-envelope"></i>
                                        <span><strong>Email:</strong> info@thesealab.com</span>
                                    </p>
                                </div>
                                <form className="contact-form" onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            placeholder="Your Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder="Your Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="message" className="form-label">Message</label>
                                        <textarea
                                            className="form-control"
                                            id="message"
                                            rows="5"
                                            placeholder="Your Message"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="attachment" className="form-label">
                                            <i className="fas fa-paperclip"></i> Attachment
                                        </label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            id="attachment"
                                            onChange={handleFileChange}
                                            ref={fileInputRef}
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary">Send Message</button>
                                </form>
                                <div className="disclaimer mt-3">
                                    By contacting us, you agree to our <a href="/the-sealab/legal/terms" target="_blank"
                                                                          rel="noopener noreferrer">Terms of Service</a>.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ErrorModal
                show={showErrorModal}
                handleClose={() => setShowErrorModal(false)}
                title="Missing Information"
                message={errorMessage}
                fields={errorFields}
            />
            <SuccessModal
                show={showSuccessModal}
                handleClose={() => setShowSuccessModal(false)}
                title="Message Sent"
                message="Your message has been sent successfully!"
            />
        </div>
    );
};

export default ContactComponent;
