import {jsPDF} from 'jspdf';
import autoTable from 'jspdf-autotable';
import sealabIcon from '../../images/sealab_icon.png';

/**
 * Generates a PDF file containing order material and hardware summaries.
 * @param {Object} data - The data to include in the PDF.
 * @param {Object} data.materialSqft - Material square footage data.
 * @param {Object} data.hardwareQuantity - Hardware quantities.
 * @param {Array} data.drawerData - Drawer box information.
 * @param {Array} data.drawerFronts - Drawer front information.
 * @param {Array} data.doorDimensions - Door dimensions.
 * @param {Array} data.blindFronts - Blind front dimensions.
 * @param {Array} data.sppData - Stretchable purchased parts data.
 */
const generatePDF = ({
                         materialSqft,
                         hardwareQuantity,
                         drawerData,
                         drawerFronts,
                         doorDimensions,
                         blindFronts,
                         sppData,
                     }) => {
    const doc = new jsPDF();

    // Add company logo
    doc.addImage(sealabIcon, 'PNG', 14, 10, 30, 30);

    // Add company info
    const pageWidth = doc.internal.pageSize.getWidth();
    const companyInfo = [
        'The Sealab',
        '63 Flushing Ave',
        'Building 3, Suite 1108',
        'Brooklyn, NY 11205',
        'info@thesealab.com',
    ];

    doc.setFontSize(10);
    let startY = 15;
    companyInfo.forEach((line) => {
        doc.text(line, pageWidth - 14, startY, {align: 'right'});
        startY += 5;
    });

    // Add title
    doc.setFontSize(18);
    doc.text(
        'Material & Hardware Summary',
        doc.internal.pageSize.getWidth() / 2,
        45,
        {align: 'center'}
    );

    startY = 65; // Reset starting Y position after header

    // Material Table
    if (materialSqft && Object.keys(materialSqft).length > 0) {
        const materialData = Object.entries(materialSqft).map(([matId, sqft]) => {
            const totalSheets = Math.round((sqft / 32) * 1.2) || 1;
            return [matId, sqft.toFixed(2), totalSheets];
        });

        doc.text('Materials', pageWidth / 2, startY - 2, {align: 'center'});
        autoTable(doc, {
            head: [['Material', 'Total SQFT', 'Estimated Sheets']],
            body: materialData,
            startY,
            styles: {fontSize: 12, cellPadding: 2},
            headStyles: {fillColor: [0, 0, 0], textColor: [255, 255, 255]},
        });
        startY = doc.lastAutoTable.finalY + 15;
    }

    // Hardware Table
    if (hardwareQuantity && Object.keys(hardwareQuantity).length > 0) {
        const hardwareData = Object.entries(hardwareQuantity).map(([hardware, quantity]) => [
            hardware,
            quantity,
        ]);

        doc.text('Hardware', pageWidth / 2, startY - 2, {align: 'center'});
        autoTable(doc, {
            head: [['Hardware', 'QTY']],
            body: hardwareData,
            startY,
            styles: {fontSize: 12, cellPadding: 2},
            headStyles: {fillColor: [0, 0, 0], textColor: [255, 255, 255]},
        });
        startY = doc.lastAutoTable.finalY + 15;
    }

    // Drawer Data Table
    if (drawerData && drawerData.length > 0) {
        const drawerDataFormatted = drawerData.map((drawer) => [
            drawer.pos,
            drawer.cpId,
            `${drawer.width}"`,
            `${drawer.height}"`,
            `${drawer.depth}"`,
        ]);

        doc.text('Drawer Boxes', pageWidth / 2, startY - 2, {align: 'center'});
        autoTable(doc, {
            head: [['Item Name', 'Drawer Type', 'Width', 'Height', 'Depth']],
            body: drawerDataFormatted,
            startY,
            styles: {fontSize: 12, cellPadding: 2},
            headStyles: {fillColor: [0, 0, 0], textColor: [255, 255, 255]},
        });
        startY = doc.lastAutoTable.finalY + 15;
    }

    // Drawer Fronts Table
    if (drawerFronts && drawerFronts.length > 0) {
        const drawerFrontsFormatted = drawerFronts.map((front) => [
            front.pos,
            front.matId,
            `${front.width}"`,
            `${front.height}"`,
        ]);

        doc.text('Drawer Fronts', pageWidth / 2, startY - 2, {align: 'center'});
        autoTable(doc, {
            head: [['Item Name', 'Material', 'Width', 'Height']],
            body: drawerFrontsFormatted,
            startY,
            styles: {fontSize: 12, cellPadding: 2},
            headStyles: {fillColor: [0, 0, 0], textColor: [255, 255, 255]},
        });
        startY = doc.lastAutoTable.finalY + 15;
    }

    // Door Dimensions Table
    if (doorDimensions && doorDimensions.length > 0) {
        const doorDataFormatted = doorDimensions.map((door) => [
            door.pos,
            door.name1,
            door.matId,
            `${door.width}"`,
            `${door.height}"`,
        ]);

        doc.text('Door Dimensions', pageWidth / 2, startY - 2, {align: 'center'});
        autoTable(doc, {
            head: [['Item Name', 'Door Side', 'Material', 'Width', 'Height']],
            body: doorDataFormatted,
            startY,
            styles: {fontSize: 12, cellPadding: 2},
            headStyles: {fillColor: [0, 0, 0], textColor: [255, 255, 255]},
        });
        startY = doc.lastAutoTable.finalY + 15;
    }

    // Blind Fronts Table
    if (blindFronts && blindFronts.length > 0) {
        const blindDataFormatted = blindFronts.map((blind) => [
            blind.pos,
            blind.name1,
            blind.matId,
            `${blind.width}"`,
            `${blind.height}"`,
        ]);

        doc.text('Blind Front Dimensions', pageWidth / 2, startY - 2, {align: 'center'});
        autoTable(doc, {
            head: [['Item Name', 'Part', 'Material', 'Width', 'Height']],
            body: blindDataFormatted,
            startY,
            styles: {fontSize: 12, cellPadding: 2},
            headStyles: {fillColor: [0, 0, 0], textColor: [255, 255, 255]},
        });
        startY = doc.lastAutoTable.finalY + 15;
    }

    // Stretchable Purchased Parts Table
    if (sppData && sppData.length > 0) {
        const sppFormatted = sppData.map((spp) => [spp.sppName, spp.sppLength]);
        const totalLength = sppData.reduce((total, spp) => total + parseFloat(spp.sppLength), 0);

        doc.text('Stretchable Purchased Parts', pageWidth / 2, startY - 2, {align: 'center'});
        autoTable(doc, {
            head: [['Name', 'Length (ft)']],
            body: [...sppFormatted, ['Total Length (ft)', totalLength.toFixed(2)]],
            startY,
            styles: {fontSize: 12, cellPadding: 2},
            headStyles: {fillColor: [0, 0, 0], textColor: [255, 255, 255]},
        });
    }

    doc.save('material_hardware_summary.pdf');
};

export default generatePDF;
