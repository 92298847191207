// store.js
import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from "redux";
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {persistReducer, persistStore} from 'redux-persist';
import cartReducer from '../features/cart/cartSlice';
import userReducer from '../features/user/userSlice';
import globalSettingsReducer from '../features/globalSettings/globalSettingsSlice'
import suggestedArticlesReducer from '../features/suggestedArticles/suggestedArticlesSlice'
import chatBotReducer from '../features/chatBot/chatBotSlice'

const persistConfig = {
    key: 'root',
    storage,
};

const appReducer = combineReducers({
    user: userReducer,
    cart: cartReducer,
    globalSettings: globalSettingsReducer,
    suggestedArticles: suggestedArticlesReducer,
    chatBot: chatBotReducer,
    // include other reducers here
});

const rootReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
        storage.removeItem('persist:root'); // remove persisted data from storage
        state = undefined; // reset the state to initial state
    }
    return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
});

export const persistor = persistStore(store);