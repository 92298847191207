// components/OrderMatHardware/DrawerTable.js

import React from 'react';

/**
 * DrawerTable Component
 *
 * This component renders a table displaying details of drawer boxes.
 *
 * @param {Array} drawerData - An array of objects representing drawer information.
 * @param {Object} drawerData[] - Individual drawer data.
 * @param {string} drawerData[].pos - Item name or position name of the drawer.
 * @param {string} drawerData[].cpId - Type of the drawer box.
 * @param {number} drawerData[].width - Width of the drawer (in inches).
 * @param {number} drawerData[].height - Height of the drawer (in inches).
 * @param {number} drawerData[].depth - Depth of the drawer (in inches).
 *
 * @returns {JSX.Element | null} - The rendered table showing drawer box data or null if no data exists.
 */
const DrawerTable = ({drawerData}) => {
    // If drawerData is null or empty, return null to avoid rendering the table
    if (!drawerData || drawerData.length === 0) return null;

    return (
        <div className="col-md-6 mb-4">
            <h4 className="mat-hware-titles">Drawer Boxes</h4> {/* Section title */}
            <table className="table table-striped mat-hware-tables">
                <thead>
                <tr>
                    <th>Item Name</th>
                    {/* Column header for the drawer position name */}
                    <th>Drawer Type</th>
                    {/* Column header for drawer type */}
                    <th>Width</th>
                    {/* Column header for drawer width */}
                    <th>Height</th>
                    {/* Column header for drawer height */}
                    <th>Depth</th>
                    {/* Column header for drawer depth */}
                </tr>
                </thead>
                <tbody>
                {/* Iterate over drawerData to render rows for each drawer box */}
                {drawerData.map((drawer, index) => (
                    <tr key={index}>
                        <td>{drawer.pos}</td>
                        {/* Render the drawer's position name */}
                        <td>{drawer.cpId}</td>
                        {/* Render the drawer's type */}
                        <td>{drawer.width}"</td>
                        {/* Render the drawer's width in inches */}
                        <td>{drawer.height}"</td>
                        {/* Render the drawer's height in inches */}
                        <td>{drawer.depth}"</td>
                        {/* Render the drawer's depth in inches */}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default DrawerTable;
