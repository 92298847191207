// Import necessary dependencies
import React from 'react';
import {renderDropdown} from './utils/renderUtils';

/**
 * JoineryMethodSection Component
 *
 * This component renders a section in the order form for selecting the joinery method
 * and the back panel options based on the item properties.
 *
 * @param {Object} props - Props passed to the component
 * @param {Object} itemProperties - The properties of the current item being configured
 * @param {Array} backPanelOptions - List of available back panel options
 * @param {Array} jointOptions - List of available joint method options
 * @param {Function} handleInputChange - Function to handle changes in dropdown selection
 * @param {Function} handleToolTipClick - Function to handle tooltip interactions
 */
function JoineryMethodSection({
                                  itemProperties,
                                  backPanelOptions,
                                  jointOptions,
                                  handleInputChange,
                                  handleToolTipClick
                              }) {
    return (
        <div className="order-form__section">
            {/* Section Title */}
            <h3 className="order-form__section-title">Joinery Method</h3>

            {/* Render Dropdown for Back Panel if matBack property is enabled */}
            {itemProperties.matBack === '1' &&
                renderDropdown(
                    'Back Panel', // Label for the dropdown
                    'backPanel', // Property to update
                    backPanelOptions, // Options to display
                    undefined, // Default value
                    false, // Disable flag (false means enabled)
                    itemProperties, // Current item properties
                    handleInputChange, // Change handler
                    handleToolTipClick // Tooltip handler
                )
            }

            {/* Render Dropdown for Joint Method if jointControl property is enabled */}
            {itemProperties.jointControl === '1' &&
                renderDropdown(
                    'Joint Method', // Label for the dropdown
                    'jointMethod', // Property to update
                    jointOptions, // Options to display
                    undefined, // Default value
                    false, // Disable flag (false means enabled)
                    itemProperties, // Current item properties
                    handleInputChange, // Change handler
                    handleToolTipClick // Tooltip handler
                )
            }
        </div>
    );
}

export default JoineryMethodSection;
