// DataService.js
import axios from 'axios';

const baseURL = process.env.REACT_APP_BASEURL


const DataService = {
    getAllMaterials: async () => {
        try {
            // const response = await axios.get('http://localhost:8080/getAllMaterials');
            const response = await axios.get(`${baseURL}/getAllMaterials`);
            return response.data;
        } catch (error) {
            console.error('Error fetching materials:', error);
            return [];
        }
    },

    getAllEdgebanding: async () => {
        try {
            // const response = await axios.get('http://localhost:8080/getAllMaterials');
            const response = await axios.get(`${baseURL}/api/cabinet-features/getAllEdgebanding`);
            return response.data.filter((eb) => eb.visible === 1);
        } catch (error) {
            console.error('Error fetching materials:', error);
            return [];
        }
    },

    createNewMaterial: async (newMaterial, token) => {
        try {
            const response = await axios.post(`${baseURL}/api/internal/create-material`, {
                name: newMaterial.name,
                description: newMaterial.description,
                thickness: parseFloat(newMaterial.thickness),
                rawCost: parseFloat(newMaterial.rawCost),
                visible: parseInt(newMaterial.visible)
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

            return response;
        } catch (error) {
            throw new Error(error.response?.data?.message || 'Failed to create material');
        }
    },

    createNewEdgebanding: async (newEdgebanding, token) => {
        try {
            const response = await axios.post(`${baseURL}/api/internal/create-edgebanding`, {
                imosName: newEdgebanding.imosName,
                englishDescription: newEdgebanding.englishDescription,
                visible: parseInt(newEdgebanding.visible)
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return response;
        } catch (error) {
            throw new Error(error.response?.data?.message || 'Failed to create edgebanding');
        }
    }

};

export default DataService;
