// components/OrderMatHardware/SppTable.js

import React from 'react';

/**
 * SppTable Component
 *
 * This component displays a table for "Stretchable Purchased Parts" (SPP).
 * It lists each part's name and length in feet, and calculates the total length.
 *
 * @param {Object[]} sppData - Array of objects containing SPP data.
 * @param {string} sppData[].sppName - Name of the stretchable purchased part.
 * @param {string} sppData[].sppLength - Length of the part in feet.
 *
 * @returns {JSX.Element | null} - The rendered SPP table or null if no data is provided.
 */
const SppTable = ({sppData}) => {
    // If sppData is null or empty, do not render the table
    if (!sppData || sppData.length === 0) return null;

    // Calculate the total length of all SPP parts
    const totalLength = sppData.reduce((total, spp) => total + parseFloat(spp.sppLength), 0);

    return (
        <div className="col-md-6 mb-4">
            <h4 className="mat-hware-titles">Stretchable Purchased Parts</h4>
            <table className="table table-striped mat-hware-tables">
                <thead>
                <tr>
                    <th>Name</th>
                    {/* Column for part names */}
                    <th>Length (ft)</th>
                    {/* Column for part lengths */}
                </tr>
                </thead>
                <tbody>
                {/* Map through sppData to render each part */}
                {sppData.map((spp, index) => (
                    <tr key={index}>
                        <td>{spp.sppName}</td>
                        {/* Render part name */}
                        <td>{spp.sppLength}</td>
                        {/* Render part length */}
                    </tr>
                ))}
                {/* Add a row to display the total length */}
                <tr>
                    <td><strong>Total Length (ft)</strong></td>
                    {/* Total length label */}
                    <td><strong>{totalLength.toFixed(2)}</strong></td>
                    {/* Render total length */}
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default SppTable;
