import React from 'react';
import {useLocation} from 'react-router-dom';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';

const PDFViewer = () => {
    const userLoggedIn = UserLoggedIn();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const pdfUrl = params.get('pdfUrl'); // Extract the PDF URL from query params

    return (
        <div>
            {/* Render the appropriate Navbar */}

            {/* Embed the PDF using an iframe */}
            <div style={{height: '100vh', width: '100%'}}>
                {pdfUrl ? (
                    <iframe
                        src={pdfUrl}
                        width="100%"
                        height="100%"
                        style={{border: 'none'}}
                        title="PDF Viewer"
                    />
                ) : (
                    <p>No PDF URL provided. Please try again.</p>
                )}
            </div>
        </div>
    );
};

export default PDFViewer;
