// import React from 'react';
// import jsPDF from 'jspdf';
// import sealabIcon from '../../images/sealab_icon.png'; // Adjust the path if necessary
//
// const PdfWriterComponent = ({orderDetails, imageURL}) => {
//     const generatePDF = async () => {
//         const pdf = new jsPDF({
//             orientation: 'portrait',
//             unit: 'pt',
//             format: 'a4',
//         });
//
//         const loadImage = (url) => {
//             return new Promise((resolve, reject) => {
//                 const img = new Image();
//                 img.crossOrigin = 'Anonymous';
//                 img.onload = () => resolve(img);
//                 img.onerror = (err) => reject(err);
//                 img.src = url;
//             });
//         };
//
//         // Load the company logo
//         const logo = await loadImage(sealabIcon);
//
//         // Add SEALAB header with logo
//         pdf.addImage(logo, 'PNG', 40, 30, 50, 50); // Adjust size and position as needed
//         pdf.setFontSize(22);
//         pdf.text("SEALAB", 500, 50, {align: 'right'}); // Company name to the right
//         pdf.setFontSize(10);
//         pdf.text("63 Flushing Ave,\nBuilding 3, Suite 1108\nBrooklyn, NY 11205", 500, 70, {align: 'right'}); // Company info to the right
//         pdf.setLineWidth(0.5);
//         pdf.line(40, 100, 570, 100); // Horizontal line below the header
//
//         let currentY = 120; // Starting Y position for order details
//         let itemsOnPage = 0; // Counter for items per page
//
//         for (const order of orderDetails) {
//             if (itemsOnPage >= 2 || currentY > 700) {
//                 // Add a new page if there are 2 items already or if the current Y position is too low
//                 pdf.addPage();
//                 currentY = 40; // Reset Y position for new page
//                 itemsOnPage = 0; // Reset items counter
//             }
//
//             pdf.setFontSize(12);
//
//             const details = [
//                 `Item Name: ${order.positionName}`,
//                 `Serial Number: ${order.serialNumber}`,
//                 `Height: ${order.height} in`,
//                 `Width: ${order.width} in`,
//                 `Depth: ${order.depth} in`,
//                 `Case Material: ${order.caseMaterial}`,
//                 `Front Material: ${order.frontMaterial}`,
//                 order.drawerType !== 'false' ? `Drawer Type: ${order.drawerType}` : '',
//                 order.gapTop !== 'false' ? `Gap Top: ${order.gapTop}` : '',
//                 order.gapBottom !== 'false' ? `Gap Bottom: ${order.gapBottom}` : '',
//                 order.gapLeft !== 'false' ? `Gap Left: ${order.gapLeft}` : '',
//                 order.gapRight !== 'false' ? `Gap Right: ${order.gapRight}` : '',
//                 order.gapCenter !== 'false' ? `Gap Center: ${order.gapCenter}` : '',
//                 order.hingeSide !== 'false' && order.hingeSide != null ? `Hinge Side: ${order.hingeSide}` : '',
//                 order.jointMethod !== 'false' ? `Joint Method: ${order.jointMethod}` : '',
//             ];
//
//             details.forEach((detail) => {
//                 if (detail) {
//                     pdf.text(detail, 40, currentY); // Print order details
//                     currentY += 15; // Adjusted spacing
//                 }
//             });
//
//             try {
//                 const img = await loadImage(`${imageURL}${order.serialNumber}.png`);
//                 const imgNF = await loadImage(`${imageURL}${order.serialNumber}_NF.png`);
//                 pdf.addImage(img, 'PNG', 320, currentY - (15 * details.length) + 20, 125, 125); // Add first product image
//                 pdf.addImage(imgNF, 'PNG', 450, currentY - (15 * details.length) + 20, 125, 125); // Add second product image
//
//                 pdf.setFontSize(8);
//                 pdf.text("Please note, these images are not reflective of configured cabinet dimensions.", 320, currentY - (15 * details.length) + 150, {maxWidth: 260});
//             } catch (error) {
//                 console.error('Error loading image:', error);
//             }
//
//             currentY += 50; // Adjust space after each item
//             itemsOnPage++;
//             if (itemsOnPage < 2 && order !== orderDetails[orderDetails.length - 1]) {
//                 currentY += 10; // Extra spacing before the horizontal line
//                 pdf.line(40, currentY - 10, 570, currentY - 10); // Line separator
//                 currentY += 10; // Additional spacing after the horizontal line
//             }
//         }
//
//         pdf.save('order-details.pdf');
//     };
//
//     return (
//         <button onClick={generatePDF} className="btn btn-dark">
//             Print Full Summary to PDF
//         </button>
//     );
// };
//
// export default PdfWriterComponent;


import React from 'react';
import jsPDF from 'jspdf';
import sealabIcon from '../../images/sealab_icon.png';


const PdfWriterComponent = ({orderDetails, imageURL}) => {
    const generatePDF = async () => {
        // Create custom page size for wider format
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'pt',
            format: [842, 1200] // Wider A4 format
        });

        const loadImage = (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.crossOrigin = 'Anonymous';
                img.onload = () => resolve(img);
                img.onerror = (err) => reject(err);
                img.src = url;
            });
        };

        const pageWidth = pdf.internal.pageSize.width;
        const pageHeight = pdf.internal.pageSize.height;
        const margin = 40;

        // Increased header height
        const headerHeight = 100;

        // Add logo to the left
        const logo = await loadImage(sealabIcon);
        const logoSize = 80;
        pdf.addImage(logo, 'PNG', margin, 20, logoSize, logoSize);

        // Company info on the right
        pdf.setTextColor(0, 0, 0);
        pdf.setFont('helvetica', 'normal');
        pdf.setFontSize(12);

        const companyInfo = [
            "The Sealab",
            "63 Flushing Ave",
            "Building 3, Suite 1108",
            "Brooklyn, NY 11205",
            "info@thesealab.com"
        ];

        pdf.text(companyInfo, pageWidth - margin, 30, {
            align: 'right',
            lineHeightFactor: 1.4
        });

        // Start content lower to account for taller header
        let currentY = headerHeight + 20;
        const contentWidth = pageWidth - (margin * 2);

        // Group orders by tempOrderId
        const groupedOrders = orderDetails.reduce((acc, order) => {
            const groupId = order.tempOrderId;
            if (!acc[groupId]) acc[groupId] = [];
            acc[groupId].push(order);
            return acc;
        }, {});

        for (const [tempOrderId, orders] of Object.entries(groupedOrders)) {
            // Main container border
            pdf.setDrawColor(220, 220, 220);
            pdf.setLineWidth(1);
            const containerHeight = Math.ceil(orders.length / 4) * 340 + 80; // Increased container height
            pdf.roundedRect(margin, currentY, contentWidth, containerHeight, 3, 3, 'S');

            // Order header bar
            pdf.setFillColor(0, 0, 0);
            pdf.rect(margin, currentY, contentWidth, 40, 'F');

            pdf.setTextColor(255, 255, 255);
            pdf.setFont('helvetica', 'bold');
            pdf.setFontSize(14);
            pdf.text(`Order ID: ${tempOrderId}`, margin + 20, currentY + 26);

            currentY += 60;

            // Calculate grid layout
            const itemsPerRow = 4;
            const itemWidth = (contentWidth - (40 * (itemsPerRow - 1))) / itemsPerRow;
            const itemHeight = 300; // Increased item height
            const itemsPerPage = 8; // 2 rows of 4

            for (let i = 0; i < orders.length; i++) {
                const order = orders[i];

                // Check if we need a new page
                if (i > 0 && i % itemsPerPage === 0) {
                    pdf.addPage();
                    currentY = 40;

                    // Recreate header on new page
                    const logo = await loadImage(sealabIcon);
                    const logoSize = 80;
                    pdf.addImage(logo, 'PNG', margin, 20, logoSize, logoSize);

                    pdf.setTextColor(0, 0, 0);
                    pdf.setFont('helvetica', 'normal');
                    pdf.setFontSize(12);

                    const companyInfo = [
                        "The Sealab",
                        "63 Flushing Ave",
                        "Building 3, Suite 1108",
                        "Brooklyn, NY 11205",
                        "info@thesealab.com"
                    ];

                    pdf.text(companyInfo, pageWidth - margin, 30, {
                        align: 'right',
                        lineHeightFactor: 1.4
                    });
                }

                // Calculate item position
                const row = Math.floor((i % itemsPerPage) / itemsPerRow);
                const col = i % itemsPerRow;
                const x = margin + (col * (itemWidth + 40));
                const y = currentY + (row * (itemHeight + 20));

                // Draw item card
                pdf.setDrawColor(238, 238, 238);
                pdf.setLineWidth(0.5);
                pdf.roundedRect(x, y, itemWidth, itemHeight, 3, 3, 'S');

                // Add image
                try {
                    const img = await loadImage(`${imageURL}${order.serialNumber}.webp`);
                    const imgSize = 140;
                    const imgX = x + (itemWidth - imgSize) / 2;
                    pdf.addImage(img, 'WEBP', imgX, y + 20, imgSize, imgSize);
                } catch (error) {
                    console.error('Error loading image:', error);
                }

                // Add details with increased spacing
                const details = [
                    {label: 'Item', value: order.positionName || 'N/A'},
                    {label: 'Serial', value: order.serialNumber},
                    {label: 'Dimensions', value: `${order.height} x ${order.width} x ${order.depth} in`},
                    {label: 'Front Material', value: order.frontMaterial},
                    {label: 'Case Material', value: order.caseMaterial},
                    {label: 'Quantity', value: order.quantity}
                ];

                let detailY = y + 180;
                details.forEach((detail, index) => {
                    const labelX = x + 15;
                    const valueX = x + itemWidth - 15;

                    // Label
                    pdf.setFont('helvetica', 'normal');
                    pdf.setFontSize(10);
                    pdf.setTextColor(102, 102, 102);
                    pdf.text(detail.label, labelX, detailY);

                    // Value
                    pdf.setTextColor(0, 0, 0);
                    const value = String(detail.value);

                    // Check text width and adjust if needed
                    if (pdf.getTextWidth(value) > itemWidth - 30) {
                        pdf.setFontSize(9);
                    }

                    pdf.text(value, valueX, detailY, {align: 'right'});

                    // Separator line with more space at bottom
                    if (index < details.length - 1) {
                        pdf.setDrawColor(240, 240, 240);
                        pdf.line(labelX, detailY + 7, valueX, detailY + 7);
                    }

                    detailY += 22; // Increased spacing between details
                });
            }

            // Move to next section
            currentY += containerHeight + 40;

            // Add new page if needed
            if (currentY > pageHeight - margin && Object.keys(groupedOrders).indexOf(tempOrderId) < Object.keys(groupedOrders).length - 1) {
                pdf.addPage();
                currentY = headerHeight + 20;
            }
        }

        pdf.save('order-details.pdf');
    };

    return (
        <button onClick={generatePDF} className="btn btn-light">
            Print Full Summary to PDF
        </button>
    );
};

export default PdfWriterComponent;