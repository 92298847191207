// src/components/OrderPageComponents/DrawerSettingsSection.js

import React from 'react';
import {getTooltipContent, renderDropdown} from './utils/renderUtils';

/**
 * Component for configuring drawer settings in the order form.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.itemProperties - Properties of the item being configured.
 * @param {Array} props.drawerOptions - Available drawer options to select from.
 * @param {Object} props.errors - Error states for form validation.
 * @param {Function} props.handleInputChange - Function to handle changes in input fields.
 * @param {Function} props.handleToolTipClick - Function to handle tooltip clicks.
 */
function DrawerSettingsSection({
                                   itemProperties,
                                   drawerOptions,
                                   errors,
                                   handleInputChange,
                                   handleToolTipClick
                               }) {
    return (
        <div className="order-form__section">
            {/* Section title */}
            <h3 className="order-form__section-title">Drawer Settings</h3>

            {/* Dropdown to select the drawer type */}
            {renderDropdown(
                'Drawer Type', // Label for the dropdown
                'drawerType', // Field name to update in item properties
                drawerOptions, // List of available options
                undefined, // Default value
                false, // Whether this dropdown is disabled
                itemProperties, // Current item properties
                handleInputChange, // Function to handle changes
                handleToolTipClick // Function to handle tooltip interactions
            )}

            {/* Conditional rendering for top drawer height input */}
            {itemProperties.topDrwrHeight === "1" && (
                <div className="order-form__group">
                    {/* Label and tooltip for the input */}
                    <label htmlFor="topDrwrHeightValue" className="order-form__label">
                        Top Drawer Height (in)
                        <span
                            className="order-form__tooltip-trigger"
                            onClick={() => handleToolTipClick('topDrwrHeight')}
                        >
                            <span className="order-form__tooltip-content">
                                {getTooltipContent('topDrwrHeightValue')}
                            </span>
                        </span>
                    </label>

                    {/* Input field for top drawer height */}
                    <input
                        type="text"
                        id="topDrwrHeightValue"
                        className={`order-form__input ${
                            errors.topDrwrHeightValue ? 'order-form__input--invalid' : ''
                        }`}
                        value={itemProperties.topDrwrHeightValue} // Current value of top drawer height
                        onChange={(e) => handleInputChange(e, 'topDrwrHeightValue')} // Handle changes
                        onKeyDown={(e) => {
                            // Prevent form submission on Enter
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }}
                    />

                    {/* Error message for invalid input */}
                    {errors.topDrwrHeightValue && (
                        <div className="order-form__error-message">
                            Please enter a value between 4 and 28.
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default DrawerSettingsSection;
