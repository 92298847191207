import {createSlice} from '@reduxjs/toolkit';
import {sendMessage} from './chatBotActions';

const initialState = {
    messages: [],
    isLoading: false,
    error: null,
};

const chatBotSlice = createSlice({
    name: 'chatBot',
    initialState,
    reducers: {
        clearMessages: (state) => {
            state.messages = [];
            state.error = null;
            state.isLoading = false;
        },
    },
    extraReducers: (builder) => {
        // Send Message
        builder.addCase(sendMessage.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(sendMessage.fulfilled, (state, action) => {
            state.isLoading = false;

            // // Add userMessage only if it's not a system-triggered message
            // if (!action.meta.arg.isSystem) {
            //     state.messages = [...state.messages, action.payload.userMessage];
            // }

            // Add botMessage if present
            if (action.payload.botMessage) {
                state.messages = [...state.messages, action.payload.botMessage];
            }
        });
        builder.addCase(sendMessage.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
            state.messages = [
                ...state.messages,
                {
                    sender: 'bot',
                    text: 'Error connecting to the chatbot.',
                },
            ];
        });
    },


});

// Export actions
export const {clearMessages} = chatBotSlice.actions;

// Selectors
export const selectMessages = (state) => state.chatBot?.messages || [];
export const selectIsLoading = (state) => state.chatBot?.isLoading || false;
export const selectError = (state) => state.chatBot?.error || null;

export default chatBotSlice.reducer;




