// components/ShowRoom/FilterPanel/Category.js

import React from 'react';

/**
 * The Category component represents a category in the filter panel.
 * Each category can contain subcategories or individual filter items.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.category - The name of the category.
 * @param {Array} props.subcategories - The subcategories or tags under the category.
 * @param {Function} props.hasCabinetsWithFilterTag - Function to determine if any cabinets match a given filter tag.
 * @param {Function} props.handleCategoryClick - Callback function to handle category expansion/collapse.
 * @param {Function} props.isCategoryExpanded - Function to check if the category is currently expanded.
 * @param {Function} props.renderFilterItem - Function to render individual filter items.
 * @param {Function} props.renderSubcategory - Function to render subcategories recursively.
 *
 * @returns {JSX.Element|null} - A filter category with expandable subcategories or individual filter items.
 */
const Category = ({
                      category,
                      subcategories,
                      hasCabinetsWithFilterTag,
                      handleCategoryClick,
                      isCategoryExpanded,
                      renderFilterItem,
                      renderSubcategory
                  }) => {
    // Check if the category has any child subcategories or filter tags with matching cabinets
    const hasChildrenWithFilterTags = subcategories.some(subcategory =>
        subcategory.tags
            ? subcategory.tags.some(tag => hasCabinetsWithFilterTag(tag))
            : subcategory.subcategories && subcategory.subcategories.some(sub =>
            sub.tags.some(tag => hasCabinetsWithFilterTag(tag))
        )
    );

    // If no child subcategories or tags have matching cabinets, do not render this category
    if (!hasChildrenWithFilterTags) {
        return null;
    }

    return (
        <div className="filter-category">
            {/* Category button to expand or collapse the subcategories */}
            <button
                className="btn btn-dark sr-dropdown-toggle w-100 text-start"
                onClick={() => handleCategoryClick(category)}
            >
                {category}
            </button>

            {/* Render subcategories or filter items if the category is expanded */}
            {isCategoryExpanded(category) && (
                <div className="sr-dropdown-content">
                    {subcategories.map(subcategory =>
                        subcategory.tags
                            ? renderFilterItem(subcategory.tags[0], subcategory.displayName) // Render individual filter item
                            : renderSubcategory(subcategory.category, subcategory.subcategories) // Render nested subcategories
                    )}
                </div>
            )}
        </div>
    );
};

export default Category;
