// components/ShowRoom/CabinetCard/QuantityInput.js
import React from 'react';

const QuantityInput = ({onChange}) => (
    <div className="quantity-input">
        <label>Qty: </label>
        <input
            type="number"
            min="1"
            defaultValue={1}
            className="form-control"
            onChange={(e) => onChange(e.target.value)}
        />
    </div>
);

export default QuantityInput;