import React, {Suspense, useState} from 'react';
import {Button} from 'react-bootstrap';
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';


// const PaymentElement = React.lazy(() => import('@stripe/react-stripe-js').then(module => ({default: module.PaymentElement})));


const PaymentForm = ({price, onPaymentSuccess, onPaymentError, clientSecret}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [processing, setProcessing] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcessing(true);

        if (!stripe || !elements || !clientSecret) {
            setProcessing(false);
            return;
        }

        const {error, paymentIntent} = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/payment-complete`,
            },
            redirect: 'if_required',
        });

        if (error) {
            onPaymentError(error.message);
        } else if (paymentIntent) {
            if (paymentIntent.status === 'succeeded' || paymentIntent.status === 'processing') {
                onPaymentSuccess(paymentIntent);
            } else if (paymentIntent.status === 'requires_action' && paymentIntent.next_action.type === 'verify_with_microdeposits') {
                onPaymentError('Payment requires additional verification. Please check your email for further instructions.');
            } else {
                onPaymentError('Payment failed. Please try again.');
            }
        } else {
            onPaymentError('Payment failed. Please try again.');
        }
        setProcessing(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Suspense fallback={<div>Loading Payment Options...</div>}>
                <PaymentElement
                    options={{
                        paymentMethodOrder: ['card', 'us_bank_account'],
                        fields: {
                            billingDetails: {
                                name: 'auto',
                                email: 'auto',
                            }
                        },
                        wallets: {
                            applePay: 'never',
                            googlePay: 'never',
                            cashApp: 'never',
                            amazonPay: 'never'
                        },
                        us_bank_account: {
                            verification_method: 'automatic', // Ensures only automatic (instant) verification is allowed
                        }
                    }}
                />
            </Suspense>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <span className="processing-fee">Due Now: ${Number(price)}</span>
                <Button type="submit" variant="dark" disabled={!stripe || processing}>
                    {processing ? 'Processing...' : 'Pay Now'}
                </Button>
            </div>
            <div className="disclaimer mt-3">
                <small>
                    By clicking "Pay Now", you agree to The Sealab's <a href="/the-sealab/legal/terms" target="_blank"
                                                                        className="hyperlink">Terms of Service</a>.
                </small>
            </div>
        </form>
    );
};

export default PaymentForm;