// src/components/OrderPageComponents/SerialNumberDisplay.js
import React from 'react';

function SerialNumberDisplay({uniqueSerialNumbers}) {
    return (
        <div className="order-page__serial-number">
            <strong>Serial Number{uniqueSerialNumbers.length > 1 ? 's' : ''}:</strong> {uniqueSerialNumbers.join(', ')}
        </div>
    );
}

export default SerialNumberDisplay;
