// components/ShowRoom/data/memoizedCategories.js
const memoizedCategories = [
    {
        category: 'Cabinet Type and Function', subcategories: [
            {category: 'Base', displayName: 'Base', tags: ['Base']},
            {category: 'Upper', displayName: 'Upper', tags: ['Upper']},
            {category: 'FullHeight', displayName: 'Full Height', tags: ['FullHeight']},
            {category: 'Vanity', displayName: 'Vanity', tags: ['Vanity']},
            {category: 'Medicine', displayName: 'Medicine', tags: ['Medicine']},
            {category: 'Closet', displayName: 'Closet', tags: ['Closet']},
            {category: 'Refrigerator', displayName: 'Refrigerator', tags: ['Refrigerator']},
            {category: 'SinkBase', displayName: 'Sink Base', tags: ['SinkBase']},
            {category: 'Dishwasher_Panel', displayName: 'Dishwasher Panel', tags: ['Dishwasher_Panel']},
            {category: 'ApplianceGarage', displayName: 'Appliance Garage', tags: ['ApplianceGarage']},
            {category: 'Oven', displayName: 'Oven', tags: ['Oven']},
            {category: 'Microwave', displayName: 'Microwave', tags: ['Microwave']},
            {category: 'Coffee_System', displayName: 'Coffee System', tags: ['Coffee_System']},
            {category: 'TV', displayName: 'TV', tags: ['TV']},
            {category: 'Washing_Machine', displayName: 'Washing Machine', tags: ['Washing_Machine']},
            {category: 'Dryer', displayName: 'Dryer', tags: ['Dryer']},
            {category: 'Lazy_Susan', displayName: 'Lazy Susan', tags: ['Lazy_Susan']},
            {category: 'TambourCorner', displayName: 'Tambour Corner', tags: ['TambourCorner']},
            {category: 'PantryCorner', displayName: 'Pantry Corner', tags: ['PantryCorner']},
            {category: 'Blind_Corner_Pullout', displayName: 'Blind Corner Pullout', tags: ['Blind_Corner_Pullout']},
            {category: 'Finished_Panel', displayName: 'Finished Panel', tags: ['Finished_Panel']},
            {category: 'Surround_Panels', displayName: 'Surround Panels', tags: ['Surround_Panels']},
            {category: 'Filler', displayName: 'Filler', tags: ['Filler']},
            {category: 'Toe_Kick', displayName: 'Toe Kick', tags: ['Toe_Kick']},
            {category: 'Loose_Parts', displayName: 'Loose Parts', tags: ['Loose_Parts']},
            {category: 'Countertop', displayName: 'Countertop', tags: ['Countertop']},
            {category: 'Plinth_unfinished', displayName: 'Plinth (unfinished)', tags: ['Plinth_unfinished']},
            {category: 'Open_Shelving', displayName: 'Open Shelving', tags: ['Open_Shelving']},
            {category: 'Bookcase', displayName: 'Bookcase', tags: ['Bookcase']},
            {category: 'Desk', displayName: 'Desk', tags: ['Desk']},
            {category: 'Vitrine', displayName: 'Vitrine', tags: ['Vitrine']},
            {category: 'Banquette', displayName: 'Banquette', tags: ['Banquette']},
            {category: 'Bench', displayName: 'Bench', tags: ['Bench']},
            {category: 'Credenza', displayName: 'Credenza', tags: ['Credenza']},
            {category: 'WineRack', displayName: 'Wine Rack', tags: ['WineRack']},
            {category: 'Bed', displayName: 'Bed', tags: ['Bed']}
        ]
    },
    {
        category: 'Cabinet Features', subcategories: [
            {
                category: 'Door Style', subcategories: [
                    {category: 'Shaker', displayName: 'Shaker', tags: ['Shaker']},
                    {category: 'Flat_Panel', displayName: 'Flat Panel', tags: ['Flat_Panel']},
                    {category: 'Raised_Panel', displayName: 'Raised Panel', tags: ['Raised_Panel']},
                    {category: 'Glass_Front', displayName: 'Glass Front', tags: ['Glass_Front']},
                    {category: 'Louvered', displayName: 'Louvered', tags: ['Louvered']},
                    {category: 'Sliding_Door', displayName: 'Sliding Door', tags: ['Sliding_Door']},
                    {category: 'TambourVertical', displayName: 'Tambour Vertical', tags: ['TambourVertical']},
                    {category: 'TambourHorizontal', displayName: 'Tambour Horizontal', tags: ['TambourHorizontal']},
                    {category: 'Flap_Door', displayName: 'Flap Door', tags: ['Flap_Door']},
                    {category: 'JibDoor', displayName: 'Jib Door', tags: ['JibDoor']},
                    {category: 'Bi-Fold_Door', displayName: 'Bi-Fold Door', tags: ['Bi-Fold_Door']}
                ]
            },
            {
                category: 'Number of Drawers', subcategories: [
                    {category: 'One_DRWR', displayName: '1', tags: ['One_DRWR']},
                    {category: 'Two_DRWR', displayName: '2', tags: ['Two_DRWR']},
                    {category: 'Three_DRWR', displayName: '3', tags: ['Three_DRWR']},
                    {category: 'FourPlus_DRWR', displayName: '4+', tags: ['FourPlus_DRWR']},
                    {category: 'Internal', displayName: 'Internal', tags: ['Internal']}
                ]
            },
            {
                category: 'Number of Doors', subcategories: [
                    {category: 'One_DR', displayName: '1', tags: ['One_DR']},
                    {category: 'Two_DR', displayName: '2', tags: ['Two_DR']},
                    {category: 'Three_DR', displayName: '3', tags: ['Three_DR']},
                    {category: 'FourPlus_DR', displayName: '4+', tags: ['FourPlus_DR']}
                ]
            },
            {
                category: 'Shelves', subcategories: [
                    {category: 'Fixed', displayName: 'Fixed', tags: ['Fixed']},
                    {category: 'Adjustable', displayName: 'Adjustable', tags: ['Adjustable']},
                    {category: 'Internal_Counter', displayName: 'Internal Counter', tags: ['Internal_Counter']},
                    {category: 'Glass', displayName: 'Glass', tags: ['Glass']},
                    {category: 'Cantilever', displayName: 'Cantilever', tags: ['Cantilever']}
                ]
            },
            {
                category: 'Base', subcategories: [
                    {category: 'Leg_Levelers', displayName: 'Leg Levelers', tags: ['Leg_Levelers']},
                    {category: 'Plinth', displayName: 'Plinth', tags: ['Plinth']},
                    {category: 'Integrated', displayName: 'Integrated', tags: ['Integrated']}
                ]
            },
            {
                category: 'Hinge Type', subcategories: [
                    {category: 'Concealed', displayName: 'Concealed', tags: ['Concealed']},
                    {category: 'Exposed', displayName: 'Exposed', tags: ['Exposed']},
                    {category: 'Overlay', displayName: 'Overlay', tags: ['Overlay']},
                    {category: 'Inset', displayName: 'Inset', tags: ['Inset']},
                    {category: 'Specialty', displayName: 'Specialty', tags: ['Specialty']},
                    {category: 'Pivot', displayName: 'Pivot', tags: ['Pivot']}
                ]
            },
            {
                category: 'Opening Type', subcategories: [
                    {category: 'OpenShelving', displayName: 'Open Shelving', tags: ['OpenShelving']},
                    {category: 'Drawers', displayName: 'Drawers', tags: ['Drawers']},
                    {category: 'Doors', displayName: 'Doors', tags: ['Doors']},
                    {category: 'EmptyCabinet', displayName: 'Empty Cabinet', tags: ['EmptyCabinet']},
                    {category: 'Sliding_Door', displayName: 'Sliding Door', tags: ['Sliding_Door']}
                ]
            },
            {
                category: 'Mounting option', subcategories: [
                    {category: 'WallMount', displayName: 'Wall Mount', tags: ['WallMount']},
                    {category: 'FloorMount', displayName: 'Floor Mount', tags: ['FloorMount']}
                ]
            }
        ]
    },
    {
        category: 'Materials and Finishes', subcategories: [
            {
                category: 'Material Type', subcategories: [
                    {category: 'Solid_Wood', displayName: 'Solid Wood', tags: ['Solid_Wood']},
                    {category: 'MDF', displayName: 'MDF', tags: ['MDF']},
                    {category: 'Plywood', displayName: 'Plywood', tags: ['Plywood']},
                    {category: 'Particle_Board', displayName: 'Particle Board', tags: ['Particle_Board']},
                    {category: 'Metal', displayName: 'Metal', tags: ['Metal']},
                    {category: 'Glass', displayName: 'Glass', tags: ['Glass']}
                ]
            },
            {
                category: 'Finish Type', subcategories: [
                    {category: 'Paint', displayName: 'Paint', tags: ['Paint']},
                    {category: 'Stain', displayName: 'Stain', tags: ['Stain']},
                    {category: 'Veneer', displayName: 'Veneer', tags: ['Veneer']},
                    {category: 'Laminate', displayName: 'Laminate', tags: ['Laminate']},
                    {category: 'Melamine', displayName: 'Melamine', tags: ['Melamine']}
                ]
            },
            {
                category: 'Color Options', subcategories: [
                    {category: 'Yes_NO', displayName: 'Yes/NO', tags: ['Yes_NO']}
                ]
            }
        ]
    },
    {
        category: 'Lighting and Accessories', subcategories: [
            {
                category: 'Lighting Integrated', subcategories: [
                    {
                        category: 'LED_Strip_Under_Counter',
                        displayName: 'LED Strip Under Counter',
                        tags: ['LED_Strip_Under_Counter']
                    },
                    {category: 'LED_Puck', displayName: 'LED Puck', tags: ['LED_Puck']},
                    {category: 'LED_Vertical', displayName: 'LED Vertical', tags: ['LED_Vertical']},
                    {
                        category: 'LED_Internal_Shelf',
                        displayName: 'LED Internal Shelf',
                        tags: ['LED_Internal_Shelf']
                    }
                ]
            },
            {
                category: 'Kitchen Accessory', subcategories: [
                    {category: 'Cutlery_Tray', displayName: 'Cutlery Tray', tags: ['Cutlery_Tray']},
                    {category: 'Spice_Rack', displayName: 'Spice Rack', tags: ['Spice_Rack']},
                    {category: 'Plate_Holder', displayName: 'Plate Holder', tags: ['Plate_Holder']},
                    {category: 'Lid_Organizer', displayName: 'Lid Organizer', tags: ['Lid_Organizer']},
                    {category: 'Utensil_Tray', displayName: 'Utensil Tray', tags: ['Utensil_Tray']},
                    {
                        category: 'Pull_Out_Trash_Bin',
                        displayName: 'Pull Out Trash Bin',
                        tags: ['Pull_Out_Trash_Bin']
                    }
                ]
            },
            {
                category: 'Closet Accessory', subcategories: [
                    {category: 'Clothing_Rods', displayName: 'Clothing Rods', tags: ['Clothing_Rods']},
                    {category: 'Tie_Racks', displayName: 'Tie Racks', tags: ['Tie_Racks']},
                    {category: 'Belt_Racks', displayName: 'Belt Racks', tags: ['Belt_Racks']},
                    {category: 'Valet_Rods', displayName: 'Valet Rods', tags: ['Valet_Rods']},
                    {category: 'Jewelry_Trays', displayName: 'Jewelry Trays', tags: ['Jewelry_Trays']},
                    {category: 'Shoe_Racks', displayName: 'Shoe Racks', tags: ['Shoe_Racks']},
                    {category: 'Shoe_Shelves', displayName: 'Shoe Shelves', tags: ['Shoe_Shelves']},
                    {category: 'Shoe_Drawers', displayName: 'Shoe Drawers', tags: ['Shoe_Drawers']},
                    {category: 'Laundry_Hampers', displayName: 'Laundry Hampers', tags: ['Laundry_Hampers']},
                    {category: 'Ironing_Boards', displayName: 'Ironing Boards', tags: ['Ironing_Boards']},
                    {category: 'Drawer_Organizers', displayName: 'Drawer Organizers', tags: ['Drawer_Organizers']},
                    {category: 'Shelf_Dividers', displayName: 'Shelf Dividers', tags: ['Shelf_Dividers']},
                    {category: 'FileDrawer', displayName: 'File Drawer', tags: ['FileDrawer']},
                    {category: 'Hooks', displayName: 'Hooks', tags: ['Hooks']},
                    {
                        category: 'DockingDrawer_outlet',
                        displayName: 'Docking Drawer (outlet)',
                        tags: ['DockingDrawer_outlet']
                    },
                    {category: 'Stepladder', displayName: 'Stepladder', tags: ['Stepladder']}
                ]
            }
        ]
    },
    {
        category: 'Additional Features', subcategories: [
            {
                category: 'Drawer Type', subcategories: [
                    {category: 'SideMount', displayName: 'Side Mount', tags: ['SideMount']},
                    {category: 'Soft_Close', displayName: 'Soft Close', tags: ['Soft_Close']},
                    {category: 'Push_To_Open', displayName: 'Push To Open', tags: ['Push_To_Open']}
                ]
            },
            {
                category: 'Drawer Box Material', subcategories: [
                    {category: 'Solid_Wood', displayName: 'Solid Wood', tags: ['Solid_Wood']},
                    {category: 'Plywood', displayName: 'Plywood', tags: ['Plywood']},
                    {category: 'Metal', displayName: 'Metal', tags: ['Metal']}
                ]
            },
            {
                category: 'Size Range', subcategories: [
                    {category: 'HeightMinMax', displayName: 'Height min/max', tags: ['HeightMinMax']},
                    {category: 'WidthMinMax', displayName: 'Width min/max', tags: ['WidthMinMax']},
                    {category: 'DepthMinMax', displayName: 'Depth min/max', tags: ['DepthMinMax']}
                ]
            }
        ]
    }
];

export default memoizedCategories