// src/components/OrderPageComponents/DoorSettingsSection.js

import React from 'react';
import {renderDropdown} from './utils/renderUtils';

/**
 * Component for configuring door settings in the order form.
 *
 * @param {Object} props - The props for the component.
 * @param {Object} props.itemProperties - Properties of the item being configured.
 * @param {Array} props.hingeOptions - Available hinge options for selection.
 * @param {Function} props.handleInputChange - Function to handle changes in input fields.
 * @param {Function} props.handleToolTipClick - Function to handle tooltip clicks.
 */
function DoorSettingsSection({itemProperties, hingeOptions, handleInputChange, handleToolTipClick}) {
    return (
        <div className="order-form__section">
            {/* Section title */}
            <h3 className="order-form__section-title">Door Settings</h3>

            {/* Dropdown for selecting hinge plate */}
            {renderDropdown(
                'Hinge Plate', // Label for the dropdown
                'hingePlate', // Field name to update in item properties
                hingeOptions, // List of available hinge options
                undefined, // Default value (none selected initially)
                false, // Whether the dropdown is disabled
                itemProperties, // Current item properties
                handleInputChange, // Function to handle changes in dropdown selection
                handleToolTipClick // Function to handle tooltip interactions
            )}
        </div>
    );
}

export default DoorSettingsSection;
