import React, {useEffect, useRef, useState} from 'react';
import {Group, Layer, Line, Rect, Stage, Text, Transformer} from 'react-konva';
import './DrawingStyles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {UserLoggedIn} from '../../hooks/isUserLoggedIn';
import {useDispatch, useSelector} from "react-redux";
import {updateCabinetsFromDrawingTool, updateItemsInCart} from "../../features/cart/cartSlice";
import sealabIcon from '../../images/sealab_icon.png'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft";

const SNAP_DISTANCE = 1.2;
const FILLER_SNAP_DISTANCE = 1.5; // Stronger snap for filler (wall)
const WALL_THICKNESS = 1;
const VIEWPORT_WIDTH = 260;
const VIEWPORT_HEIGHT = 170;

function getBoundingBox(x, y, width, depth, rotation) {
    const rad = rotation * Math.PI / 180;
    const sin = Math.sin(rad);
    const cos = Math.cos(rad);

    const corners = [
        {x: 0, y: 0},
        {x: width, y: 0},
        {x: 0, y: depth},
        {x: width, y: depth}
    ];

    const rotated = corners.map(c => ({
        x: x + c.x * cos - c.y * sin,
        y: y + c.x * sin + c.y * cos
    }));

    const minX = Math.min(...rotated.map(p => p.x));
    const maxX = Math.max(...rotated.map(p => p.x));
    const minY = Math.min(...rotated.map(p => p.y));
    const maxY = Math.max(...rotated.map(p => p.y));


    return {minX, maxX, minY, maxY};
}

const DrawingTool = () => {
    const initialCartItems = useSelector(state => state.cart.cart);
    const userLoggedIn = UserLoggedIn();
    const dispatch = useDispatch();

    const [roomShape, setRoomShape] = useState('rectangular');
    const [dimensions, setDimensions] = useState({width: 120, height: 96});
    const [ceilingHeight, setCeilingHeight] = useState(96);
    const [cabinets, setCabinets] = useState([]);
    const [roomName, setRoomName] = useState("");
    const [cartItems, setCartItems] = useState([]);

    const [fillerInventory] = useState([{
        itemId: 'wall_001',
        positionName: 'Wall',
        width: 40,
        depth: 2,
        height: 96,
        groupId: null,
        widthRange: '10-300',
        heightRange: '10-300',
        depthRange: '1-20'
    }]);
    const [fillerItems, setFillerItems] = useState([]);

    const [selectedItemType, setSelectedItemType] = useState(null); // 'cabinet' or 'filler'
    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const [dimensionErrors, setDimensionErrors] = useState({});
    const [inputValues, setInputValues] = useState({});
    const [activeInput, setActiveInput] = useState(null);

    const [menuVisible, setMenuVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    const stageRef = useRef();
    const transformerRef = useRef();
    const transformerRefFiller = useRef();
    const dragItemRef = useRef();
    const containerRef = useRef();
    const groupRef = useRef();
    const hasInitialized = useRef(false);

    const [fillerCount, setFillerCount] = useState(0);

    useEffect(() => {
        if (hasInitialized.current) return;

        const savedCabinets = JSON.parse(localStorage.getItem('savedCabinets')) || [];
        const savedFillerItems = JSON.parse(localStorage.getItem('savedFillerItems')) || [];

        const savedRoomName = localStorage.getItem('roomName');
        if (savedRoomName) {
            setRoomName(savedRoomName);
        }

        const savedDimensions = JSON.parse(localStorage.getItem('dimensions')) || null;
        if (savedDimensions && savedDimensions.width && savedDimensions.height) {
            setDimensions(savedDimensions);
        }

        // Process cabinets
        if (savedCabinets.length > 0) {
            savedCabinets.forEach(savedCabinet => {
                const matchingCartItem = initialCartItems.find(item => item.itemId === savedCabinet.itemId);
                if (matchingCartItem) {
                    const {x, y, z, rotation, id, height, width, depth, ...rest} = matchingCartItem;
                    Object.assign(savedCabinet, rest);
                }
            });

            const groupedCabinets = {};
            savedCabinets.forEach(savedCabinet => {
                if (savedCabinet.groupId) {
                    if (!groupedCabinets[savedCabinet.groupId]) {
                        groupedCabinets[savedCabinet.groupId] = [];
                    }
                    groupedCabinets[savedCabinet.groupId].push(savedCabinet);
                }
            });

            Object.values(groupedCabinets).forEach(group => {
                const baseName = group[0].positionName
                    ? group[0].positionName.replace(/_\d{3}$/, '')
                    : 'Position';
                group.sort((a, b) => (a.positionName || '').localeCompare(b.positionName || ''));
                group.forEach((cabinet, index) => {
                    cabinet.positionName = `${baseName}_${String(index + 1).padStart(3, '0')}`;
                });
            });

            setCabinets(savedCabinets);

            const usedCounts = savedCabinets.reduce((counts, cabinet) => {
                counts[cabinet.itemId] = (counts[cabinet.itemId] || 0) + 1;
                return counts;
            }, {});

            const adjustedCartItems = initialCartItems
                .map(cartItem => ({
                    ...cartItem,
                    quantity: Math.max(cartItem.quantity - (usedCounts[cartItem.itemId] || 0), 0)
                }))
                .filter(item => item.quantity > 0);

            setCartItems(adjustedCartItems);
        } else {
            setCartItems(initialCartItems);
        }

        if (savedFillerItems.length > 0) {
            setFillerItems(savedFillerItems);
        }

        hasInitialized.current = true;
    }, [initialCartItems]);

    useEffect(() => {
        if (cabinets.length > 0) {
            localStorage.setItem('savedCabinets', JSON.stringify(cabinets));
        } else {
            localStorage.removeItem('savedCabinets');
        }
    }, [cabinets]);

    useEffect(() => {
        if (fillerItems.length > 0) {
            localStorage.setItem('savedFillerItems', JSON.stringify(fillerItems));
        } else {
            localStorage.removeItem('savedFillerItems');
        }
    }, [fillerItems]);

    useEffect(() => {
        localStorage.setItem('dimensions', JSON.stringify(dimensions));
    }, [dimensions]);

    useEffect(() => {
        if (selectedId) {
            const selectedNode = stageRef.current.findOne('#' + selectedId);
            if (selectedNode) {
                const isFillerSelected = fillerItems.some((f, i) => f.id?.toString() === selectedId.toString());
                if (isFillerSelected) {
                    transformerRefFiller.current.nodes([selectedNode]);
                    transformerRefFiller.current.getLayer().batchDraw();
                    transformerRef.current.nodes([]);
                    transformerRef.current.getLayer().batchDraw();
                } else {
                    transformerRef.current.nodes([selectedNode]);
                    transformerRef.current.getLayer().batchDraw();
                    transformerRefFiller.current.nodes([]);
                    transformerRefFiller.current.getLayer().batchDraw();
                }
            }
        } else {
            if (transformerRef.current) {
                transformerRef.current.nodes([]);
                transformerRef.current.getLayer().batchDraw();
            }
            if (transformerRefFiller.current) {
                transformerRefFiller.current.nodes([]);
                transformerRefFiller.current.getLayer().batchDraw();
            }
        }
    }, [selectedId, fillerItems]);

    const handleRoomNameChange = (e) => {
        const name = e.target.value;
        setRoomName(name);
        localStorage.setItem('roomName', name);
    }

    const getNextPositionName = (positionName, baseName = 'Position') => {
        const originalPositionName = positionName || baseName;
        let positionNamePrefix = originalPositionName;
        let positionNameNumber = 1;

        const pattern = /(.*?)(_(\d+)|(\d+))?$/;
        const match = pattern.exec(originalPositionName);

        if (match) {
            positionNamePrefix = match[1] || baseName;
            if (match[3]) {
                positionNameNumber = parseInt(match[3], 10);
            } else if (match[4]) {
                positionNameNumber = parseInt(match[4], 10);
            }
        }

        const allItems = [...cabinets, ...fillerItems];
        const relatedItems = allItems.filter(item =>
            item.positionName && item.positionName.startsWith(positionNamePrefix)
        );

        const positionNameMap = new Set(relatedItems.map(item => item.positionName));

        let newPositionName = `${positionNamePrefix}_${String(positionNameNumber).padStart(3, '0')}`;

        while (positionNameMap.has(newPositionName)) {
            positionNameNumber++;
            newPositionName = `${positionNamePrefix}_${String(positionNameNumber).padStart(3, '0')}`;
        }
        return newPositionName;
    };

    const validateAllDimensions = (index, type) => {
        const {array} = (type === 'cabinet') ? {array: cabinets} : {array: fillerItems};
        const item = array[index];
        const currentValues = inputValues[index] || item;

        let isValid = true;
        let newInputValues = {...currentValues};

        const [minWidth, maxWidth] = item.widthRange.split('-').map(Number);
        if (currentValues.width < minWidth || currentValues.width > maxWidth) {
            setDimensionErrors(prev => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    width: `Must be between ${minWidth}" and ${maxWidth}"`
                }
            }));
            newInputValues.width = item.width;
            isValid = false;
        } else {
            setDimensionErrors(prev => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    width: null
                }
            }));
        }

        const [minHeight, maxHeight] = item.heightRange.split('-').map(Number);
        if (currentValues.height < minHeight || currentValues.height > maxHeight) {
            setDimensionErrors(prev => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    height: `Must be between ${minHeight}" and ${maxHeight}"`
                }
            }));
            newInputValues.height = item.height;
            isValid = false;
        } else {
            setDimensionErrors(prev => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    height: null,
                }
            }));
        }

        const [minDepth, maxDepth] = item.depthRange.split('-').map(Number);
        if (currentValues.depth < minDepth || currentValues.depth > maxDepth) {
            setDimensionErrors(prev => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    depth: `Must be between ${minDepth}" and ${maxDepth}"`
                }
            }));
            newInputValues.depth = item.depth;
            isValid = false;
        } else {
            setDimensionErrors(prev => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    depth: null
                }
            }));
        }

        if (!isValid) {
            setInputValues(prev => ({
                ...prev,
                [index]: newInputValues
            }));
        } else {
            handleEditItem(index, type, newInputValues);
        }

        return isValid;
    };

    const handleEditItem = (index, type, newProps) => {
        if (type === 'cabinet') {
            const groupId = cabinets[index]?.groupId;
            setCabinets(prev =>
                prev.map((cab, i) => {
                    if (groupId && cab.groupId === groupId && (newProps.width || newProps.depth || newProps.height)) {
                        return {
                            ...cab,
                            width: newProps.width ?? cab.width,
                            depth: newProps.depth ?? cab.depth,
                            height: newProps.height ?? cab.height,
                            z: newProps.z ?? cab.z
                        };
                    }
                    return i === index
                        ? {
                            ...cab,
                            width: newProps.width ?? cab.width,
                            depth: newProps.depth ?? cab.depth,
                            height: newProps.height ?? cab.height,
                            z: newProps.z ?? cab.z,
                            x: newProps.x ?? cab.x,
                            y: newProps.y ?? cab.y,
                            rotation: newProps.rotation ?? cab.rotation,
                            positionName: newProps.positionName ?? cab.positionName,
                            stackedOverAnother: newProps.stackedOverAnother ?? cab.stackedOverAnother
                        }
                        : cab;
                })
            );
        } else {
            setFillerItems(prev =>
                prev.map((filler, i) =>
                    i === index
                        ? {
                            ...filler,
                            width: newProps.width ?? filler.width,
                            depth: newProps.depth ?? filler.depth,
                            height: newProps.height ?? filler.height,
                            z: newProps.z ?? filler.z,
                            x: newProps.x ?? filler.x,
                            y: newProps.y ?? filler.y,
                            rotation: newProps.rotation ?? filler.rotation,
                            positionName: newProps.positionName ?? filler.positionName,
                            stackedOverAnother: newProps.stackedOverAnother ?? filler.stackedOverAnother
                        }
                        : filler
                )
            );
        }

        setSelectedItem({...selectedItem, ...newProps});
    };

    const validateDimension = (index, type, name, value) => {
        const {array} = (type === 'cabinet') ? {array: cabinets} : {array: fillerItems};
        const item = array[index];

        let range = (name === 'width') ? item.widthRange :
            (name === 'height') ? item.heightRange :
                item.depthRange;
        const [min, max] = range.split('-').map(Number);

        if (value < min || value > max) {
            setDimensionErrors(prev => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    [name]: `Must be between ${min}" and ${max}"`
                }
            }));
            return false;
        }
        setDimensionErrors(prev => ({
            ...prev,
            [index]: {
                ...prev[index],
                [name]: null
            }
        }));
        return true;
    };

    const handleInputChange = (e) => {
        if (selectedItemIndex == null || selectedItemType == null) return;
        const {name, value} = e.target;
        const numericValue = parseFloat(value);

        setInputValues(prev => ({
            ...prev,
            [selectedItemIndex]: {
                ...prev[selectedItemIndex],
                [name]: numericValue
            }
        }));
        setSelectedItem({
            ...selectedItem,
            [name]: numericValue
        });

        validateDimension(selectedItemIndex, selectedItemType, name, numericValue);
    };

    const handleInputBlur = (e) => {
        if (selectedItemIndex == null || selectedItemType == null) return;
        const {name, value} = e.target;
        const numericValue = parseFloat(value);

        if (validateDimension(selectedItemIndex, selectedItemType, name, numericValue)) {
            handleEditItem(selectedItemIndex, selectedItemType, {[name]: numericValue});
        } else {
            const {array} = (selectedItemType === 'cabinet') ? {array: cabinets} : {array: fillerItems};
            setInputValues(prev => ({
                ...prev,
                [selectedItemIndex]: {
                    ...prev[selectedItemIndex],
                    [name]: array[selectedItemIndex][name]
                }
            }));
        }
        setActiveInput(null);
    };

    const handleZChange = (e) => {
        if (selectedItemIndex == null || selectedItemType == null) return;
        const {name, value} = e.target;
        const numericValue = parseFloat(value);

        setInputValues(prev => ({
            ...prev,
            [selectedItemIndex]: {
                ...prev[selectedItemIndex],
                [name]: numericValue
            }
        }));
        setSelectedItem({
            ...selectedItem,
            [name]: numericValue
        });
        validateZHeight(selectedItemIndex, selectedItemType, numericValue);
    };

    const validateZHeight = (index, type, value) => {
        const {array} = (type === 'cabinet') ? {array: cabinets} : {array: fillerItems};
        const item = array[index];
        const maxZ = ceilingHeight - item?.height;

        if (value < 0 || value > maxZ) {
            setDimensionErrors(prev => ({
                ...prev,
                [index]: {
                    ...prev[index],
                    z: `Must be between 0" and ${maxZ}"`
                }
            }))
            return false
        }
        setDimensionErrors(prev => ({
            ...prev,
            [index]: {
                ...prev[index],
                ['z']: null
            }
        }));
        return true
    }

    const handleZBlur = (e) => {
        if (selectedItemIndex == null || selectedItemType == null) return;
        const {name, value} = e.target;
        const numericValue = parseFloat(value);

        if (validateZHeight(selectedItemIndex, selectedItemType, numericValue)) {
            handleEditItem(selectedItemIndex, selectedItemType, {[name]: numericValue});
        } else {
            const {array} = (selectedItemType === 'cabinet') ? {array: cabinets} : {array: fillerItems};
            const maxZ = ceilingHeight - (array[selectedItemIndex]?.height || 0);
            const correctedZ = Math.min(Math.max(numericValue, 0), maxZ);
            handleEditItem(selectedItemIndex, selectedItemType, {[name]: correctedZ});
        }
        setActiveInput(null);
    };

    const handleDimensionChange = (e) => {
        const {name, value} = e.target;
        const numericValue = parseFloat(value);
        setDimensions((prev) => {
            const updated = {...prev, [name]: numericValue};
            localStorage.setItem('dimensions', JSON.stringify(updated));
            return updated;
        });
    };

    const checkOverlap = (objA, objB) => {
        const aBox = getBoundingBox(objA.x, objA.y, parseFloat(objA.width), parseFloat(objA.depth), objA.rotation || 0);
        const bBox = getBoundingBox(objB.x, objB.y, parseFloat(objB.width), parseFloat(objB.depth), objB.rotation || 0);

        return (aBox.minX < bBox.maxX && aBox.maxX > bBox.minX && aBox.minY < bBox.maxY && aBox.maxY > bBox.minY);
    };

    const snapToClosestEdge = (obj, others, fillerItems) => {
        const objBox = getBoundingBox(obj.x, obj.y, parseFloat(obj.width), parseFloat(obj.depth), obj.rotation || 0);

        let snappedX = obj.x;
        let snappedY = obj.y;

        let horizontalSnapped = false;
        let verticalSnapped = false;

        for (let item of others) {
            const itemBox = getBoundingBox(item.x, item.y, parseFloat(item.width), parseFloat(item.depth), item.rotation || 0);
            const objEdges = {
                left: objBox.minX,
                right: objBox.maxX,
                top: objBox.minY,
                bottom: objBox.maxY
            };

            const itemEdges = {
                left: itemBox.minX,
                right: itemBox.maxX,
                top: itemBox.minY,
                bottom: itemBox.maxY
            };

            const isFillerItem = fillerItems.some(f => f.id === item.id);
            const currentSnapDist = isFillerItem ? FILLER_SNAP_DISTANCE : SNAP_DISTANCE;

            if (!horizontalSnapped) {
                if (Math.abs(objEdges.left - itemEdges.right) <= currentSnapDist) {
                    const dx = itemEdges.right - objBox.minX;
                    snappedX = obj.x + dx;
                    horizontalSnapped = true;
                } else if (Math.abs(objEdges.right - itemEdges.left) <= currentSnapDist) {
                    const dx = itemEdges.left - objBox.maxX;
                    snappedX = obj.x + dx;
                    horizontalSnapped = true;
                }
            }

            if (!verticalSnapped) {
                if (Math.abs(objEdges.top - itemEdges.bottom) <= currentSnapDist) {
                    const dy = itemEdges.bottom - objBox.minY;
                    snappedY = obj.y + dy;
                    verticalSnapped = true;
                } else if (Math.abs(objEdges.bottom - itemEdges.top) <= currentSnapDist) {
                    const dy = itemEdges.top - objBox.maxY;
                    snappedY = obj.y + dy;
                    verticalSnapped = true;
                }
            }

            if (horizontalSnapped || verticalSnapped) {
                const adjustedBox = getBoundingBox(snappedX, snappedY, parseFloat(obj.width), parseFloat(obj.depth), obj.rotation || 0);
                objBox.minX = adjustedBox.minX;
                objBox.maxX = adjustedBox.maxX;
                objBox.minY = adjustedBox.minY;
                objBox.maxY = adjustedBox.maxY;
            }

            if (horizontalSnapped && verticalSnapped) break;
        }

        return {x: snappedX, y: snappedY};
    };

    const constrainToLShape = (x, y, width, depth, currentRotation) => {
        const lShapeWidth = dimensions.width / 2;
        const lShapeHeight = dimensions.height / 2;
        let newX = x;
        let newY = y;
        let newRotation = currentRotation;

        let effectiveWidth = currentRotation === 90 ? depth : width;
        let effectiveDepth = currentRotation === 90 ? width : depth;

        if (newX + effectiveWidth > lShapeWidth + 10 && newY < lShapeHeight - 10) {
            newX = lShapeWidth - 0.5;
            newRotation = 90;
        } else if (newX > dimensions.width) {
            if (newY > lShapeHeight) {
                newRotation = 90;
                newX = dimensions.width + 0.5;
            }
            if (newY < lShapeHeight) {
                newRotation = 0;
                newX = dimensions.width - effectiveWidth;
                newY = lShapeHeight + 0.5;
            }
            if (newY + effectiveDepth > dimensions.height) {
                newY = dimensions.height - effectiveDepth;
            }
        } else if ((newX > lShapeWidth) && (Math.abs(newY - effectiveDepth) - Math.abs(lShapeHeight - effectiveDepth)) <= SNAP_DISTANCE) {
            newY = lShapeHeight + 0.5;
            newRotation = 0;
        } else if (newX <= 0 && newY >= 0) {
            newX = 0;
            newRotation = -90;
        } else if (newY + effectiveDepth > dimensions.height) {
            newY = dimensions.height;
            newRotation = 180;
            if (newX - effectiveWidth <= 0) {
                newX = effectiveWidth;
            }
        } else if (newY < 0) {
            newRotation = 0;
            newY = -0.5
        }

        if ((newY + effectiveDepth) > dimensions.height && (newX) > dimensions.width) {
            newX = dimensions.width - effectiveWidth;
            newY = dimensions.height + effectiveDepth + WALL_THICKNESS;
        }

        newX = Math.max(0.5, Math.min(newX, dimensions.width - 0.5));
        newY = Math.max(0.5, Math.min(newY, dimensions.height - 0.5));

        return {x: newX, y: newY, rotation: newRotation};
    };

    const constrainToRectangle = (x, y, width, depth, currentRotation) => {
        let newX = x;
        let newY = y;
        let newRotation = currentRotation;
        let effectiveWidth = currentRotation === 90 ? depth : width;
        let effectiveDepth = currentRotation === 90 ? width : depth;

        if (newX < 0) {
            newRotation = -90;
            newX = .5;
            if (newY - effectiveWidth - .5 <= 0) {
                newX = 0.5;
                newY = effectiveWidth;
            }
            if (newY >= dimensions.height) {
                newY = dimensions.height - 0.5;
            }
        } else if (newX === 1) {
            if (newY >= dimensions.height) {
                newRotation = -180;
                newY = dimensions.height - WALL_THICKNESS;
                newX = newX + effectiveWidth;
            }
        } else if (newX > 1 && (newY) > dimensions.height) {
            newRotation = -180;
            newY = dimensions.height - .5;
            if (newX - effectiveWidth < 0) {
                newX = effectiveWidth
            }
            if (newX > dimensions.width) {
                newX = dimensions.width - .5;
            }
        } else if (newX > dimensions.width && newY > 0) {
            newRotation = 90;
            newX = dimensions.width - 0.5;
            if (newY + effectiveWidth > dimensions.height) {
                newY = dimensions.height - effectiveWidth;
            }
            if (newY < 0) {
                newY = .5;
            }
        } else if (newY <= 0) {
            if (newRotation === 0) {
                if (newY <= 0) {
                    newY = .5
                }
            } else {
                newRotation = 0;
                newY = .5;
            }
            if (newX <= 0) {
                newX = 1.5
            }
            if (newX + effectiveWidth > dimensions.width) {
                newX = dimensions.width - effectiveWidth - WALL_THICKNESS + 1;
                newY = .5;
            }
        } else if (newRotation === 0 && newY < 0) {
            newRotation = 0;
            newY = .5
            if (newX > dimensions.width) {
                newX = dimensions.width - 0.5
            }
        }

        return {x: newX, y: newY, rotation: newRotation};
    };

    const handleDragMoveItem = (type, index, e) => {
        const {array} = type === 'cabinet' ? {array: cabinets} : {array: fillerItems};
        const item = array[index];

        let newX = e.target.x();
        let newY = e.target.y();
        const rotation = item.rotation || 0;
        const itemWidth = parseFloat(item.width);
        const itemDepth = parseFloat(item.depth);

        let newRotation = rotation;

        if (roomShape === 'l-shaped') {
            const constrainedPosition = constrainToLShape(newX, newY, itemWidth, itemDepth, newRotation);
            newX = constrainedPosition.x;
            newY = constrainedPosition.y;
            newRotation = constrainedPosition.rotation;
        } else if (roomShape === 'rectangular') {
            const constrainedPosition = constrainToRectangle(newX, newY, itemWidth, itemDepth, newRotation);
            newX = constrainedPosition.x;
            newY = constrainedPosition.y;
            newRotation = constrainedPosition.rotation;
        } else {
            newX = Math.max(WALL_THICKNESS, Math.min(newX, dimensions.width - itemWidth - WALL_THICKNESS));
            newY = Math.max(WALL_THICKNESS, Math.min(newY, dimensions.height - itemDepth - WALL_THICKNESS));
        }

        const otherCabinets = cabinets.filter((_, i) => !(type === 'cabinet' && i === index));
        const otherFillers = fillerItems.filter((_, i) => !(type === 'filler' && i === index));
        const otherItems = [...otherCabinets, ...otherFillers];

        const snapped = snapToClosestEdge({
            x: newX,
            y: newY,
            width: itemWidth,
            depth: itemDepth,
            rotation: newRotation
        }, otherItems, fillerItems);
        newX = snapped.x;
        newY = snapped.y;

        let isOverlapping = false;
        let stackedOverAnother = false;
        for (let i = 0; i < cabinets.length; i++) {
            if (!(type === 'cabinet' && i === index)) {
                if (checkOverlap({
                    x: newX,
                    y: newY,
                    width: itemWidth,
                    depth: itemDepth,
                    rotation: newRotation
                }, cabinets[i])) {
                    isOverlapping = true;
                    // If this item is higher in z than the overlapped one, stackedOverAnother = true
                    if ((item.z || 0) > (cabinets[i].z || 0)) {
                        stackedOverAnother = true;
                    }
                }
            }
        }
        for (let f = 0; f < fillerItems.length; f++) {
            if (!(type === 'filler' && f === index)) {
                if (checkOverlap({
                    x: newX,
                    y: newY,
                    width: itemWidth,
                    depth: itemDepth,
                    rotation: newRotation
                }, fillerItems[f])) {
                    isOverlapping = true;
                    // If this item is higher in z than overlapped filler
                    if ((item.z || 0) > (fillerItems[f].z || 0)) {
                        stackedOverAnother = true;
                    }
                }
            }
        }

        e.target.x(newX);
        e.target.y(newY);
        e.target.rotation(newRotation);

        handleEditItem(index, type, {x: newX, y: newY, rotation: newRotation, isOverlapping, stackedOverAnother});
    };

    const handleDragEndItem = (type, index, e) => {
        const newX = e.target.x();
        const newY = e.target.y();
        const newRotation = e.target.rotation();
        handleEditItem(index, type, {x: newX, y: newY, rotation: newRotation});
    };

    const handleCartItemDragStart = (e, item) => {
        if (item?.quantity > 0) {
            dragItemRef.current = {...item, type: 'cabinet'};
        }
    };

    const handleFillerDragStart = (e, item) => {
        dragItemRef.current = {...item, type: 'filler'};
    };

    const handleStageDragOver = (e) => {
        e.preventDefault();
    };

    const handleStageDrop = (e) => {
        e.preventDefault();
        const stage = stageRef.current;
        if (stage && dragItemRef.current) {
            stage.setPointersPositions(e);
            const pointerPosition = stage.getPointerPosition();

            if (dragItemRef.current.type === 'cabinet') {
                let isCopy = false;
                const groupId = dragItemRef.current?.groupId;

                if (groupId !== null && cabinets.length > 0) {
                    const foundCabinet = cabinets.find((cabinet) => cabinet?.groupId === groupId);
                    if (foundCabinet) {
                        dragItemRef.current.width = foundCabinet.width;
                        dragItemRef.current.depth = foundCabinet.depth;
                        dragItemRef.current.height = foundCabinet.height;
                        dragItemRef.current.z = foundCabinet.z !== undefined ? foundCabinet.z : 0;
                        isCopy = true;
                    } else {
                        isCopy = false;
                        dragItemRef.current.z = 0;
                    }
                }

                dragItemRef.current.width = parseFloat(dragItemRef.current?.width);
                dragItemRef.current.depth = parseFloat(dragItemRef.current?.depth);
                dragItemRef.current.height = parseFloat(dragItemRef.current?.height || 30);
                dragItemRef.current.z = parseFloat(dragItemRef.current?.z || 0);

                const newPositionName = dragItemRef.current.positionName
                    ? getNextPositionName(dragItemRef.current.positionName)
                    : getNextPositionName('', 'Position');

                const newCabinet = {
                    ...dragItemRef.current,
                    x: pointerPosition.x / (800 / dimensions.width),
                    y: pointerPosition.y / (800 / dimensions.width),
                    rotation: 0,
                    id: Date.now(),
                    positionName: newPositionName,
                    isCopy: isCopy,
                    isOverlapping: false,
                    stackedOverAnother: false
                };

                const updatedCabinets = [...cabinets, newCabinet];
                setCabinets(updatedCabinets);

                const updatedCartItems = cartItems.map(item =>
                    item.itemId === dragItemRef.current.itemId
                        ? {...item, quantity: item.quantity - 1}
                        : item
                ).filter(item => item.quantity > 0);

                setSelectedItem(newCabinet)
                setSelectedItemIndex(updatedCabinets.length - 1);
                setSelectedItemType('cabinet');

                setCartItems(updatedCartItems);
            } else if (dragItemRef.current.type === 'filler') {
                dragItemRef.current.width = parseFloat(dragItemRef.current?.width);
                dragItemRef.current.depth = parseFloat(dragItemRef.current?.depth);
                dragItemRef.current.height = parseFloat(dragItemRef.current?.height || 96);
                dragItemRef.current.z = parseFloat(dragItemRef.current?.z || 0);
                const newId = `wall_${fillerCount + 1}`;
                setFillerCount(fillerCount + 1);

                const newPositionName = getNextPositionName('', 'Wall');
                const newFiller = {
                    ...dragItemRef.current,
                    x: pointerPosition.x / (800 / dimensions.width),
                    y: pointerPosition.y / (800 / dimensions.width),
                    rotation: 0,
                    id: newId,
                    positionName: newPositionName,
                    isOverlapping: false,
                    stackedOverAnother: false
                };

                const updatedFillers = [...fillerItems, newFiller];
                setFillerItems(updatedFillers);

                setSelectedItem(newFiller);
                setSelectedItemIndex(updatedFillers.length - 1);
                setSelectedItemType('filler');
            }

            dragItemRef.current = null;
        }
    };

    const handleReturnToPanelClick = () => {
        if (selectedItemType === 'cabinet' && selectedItemIndex != null) {
            const cabinetToReturn = cabinets[selectedItemIndex];
            const existingCartItem = cartItems.find(item => item.itemId === cabinetToReturn.itemId);

            if (existingCartItem) {
                setCartItems(cartItems.map(item =>
                    item.itemId === cabinetToReturn.itemId
                        ? {...item, quantity: item.quantity + 1}
                        : item
                ));
            } else {
                setCartItems([...cartItems, {...cabinetToReturn, quantity: 1}]);
            }

            setCabinets(cabinets.filter((_, i) => i !== selectedItemIndex));
        } else if (selectedItemType === 'filler' && selectedItemIndex != null) {
            setFillerItems(fillerItems.filter((_, i) => i !== selectedItemIndex));
        }

        setSelectedItemIndex(null);
        setSelectedItemType(null);
        setSelectedItem(null);
        setDimensionErrors({});
        setInputValues({});
        setMenuVisible(false);
    };

    const handleClearLayout = () => {
        setCartItems(initialCartItems);
        setCabinets([]);
        setFillerItems([]);
        localStorage.removeItem('savedCabinets');
        localStorage.removeItem('savedFillerItems');
        setDimensionErrors({});
        setInputValues({});
        setMenuVisible(false);
        setSelectedItem(null);
        setSelectedItemIndex(null);
        setSelectedItemType(null);
    };

    const handleSaveChanges = () => {
        dispatch(updateItemsInCart(cabinets.filter((cabinet) => cabinet?.isCopy !== true)));
        dispatch(updateCabinetsFromDrawingTool(cabinets));
    };

    const checkDeselect = (e) => {
        const clickedOnEmpty = e.target === e.target.getStage();
        const layer = e.target.getLayer();
        const clickedOnBackground = layer && e.target.getParent().getClassName() !== 'Group';

        if (clickedOnEmpty || clickedOnBackground) {
            setSelectedId(null);
            setSelectedItem(null);
            setSelectedItemIndex(null);
            setSelectedItemType(null);
        }
    };

    const handleTransformEnd = (obj, isFiller = false) => {
        if (selectedId && (transformerRef.current || transformerRefFiller.current)) {
            const selectedNode = stageRef.current.findOne('#' + selectedId);
            if (selectedNode) {
                const currentTransformer = isFiller ? transformerRefFiller.current : transformerRef.current;
                const selectedObj = currentTransformer.nodes()[0];
                obj.rotation = parseFloat(selectedObj.rotation().toFixed(2));

                const scaleX = selectedObj.scaleX();
                const scaleY = selectedObj.scaleY();
                const newWidth = obj.width * scaleX;
                const newDepth = obj.depth * scaleY;
                selectedObj.scaleX(1);
                selectedObj.scaleY(1);

                handleEditItem(
                    isFiller ? selectedItemIndex : selectedItemIndex,
                    isFiller ? 'filler' : 'cabinet',
                    {
                        // width: newWidth,
                        // depth: newDepth,
                        width: isFiller ? newWidth : obj.width,
                        depth: isFiller ? newDepth : obj.depth,
                        x: selectedObj.x(),
                        y: selectedObj.y(),
                        rotation: selectedObj.rotation()
                    }
                );
            }
        }
    };

    const handleGroupClick = (id, index, isFiller = false) => {
        setSelectedId(id.id);
        setSelectedItem(id);
        if (isFiller) {
            const fIndex = fillerItems.findIndex(f => f.id === id.id);
            setSelectedItemIndex(fIndex);
            setSelectedItemType('filler');
        } else {
            const cIndex = cabinets.findIndex(c => c.id === id.id);
            setSelectedItemIndex(cIndex);
            setSelectedItemType('cabinet');

        }
    };

    const handleExportToPDF = async () => {
        const jsPDFModule = await import('jspdf');
        const jsPDF = jsPDFModule.default;
        const fileName = (roomName !== '' && roomName !== null) ? `${roomName}_layout` : 'layout'

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: 'letter'
        });

        const pageWidth = pdf.internal.pageSize.getWidth();

        try {
            pdf.addImage(sealabIcon, 'PNG', 14, 10, 60, 60);
        } catch (error) {
            console.error("Error adding company logo:", error);
        }

        const companyInfo = [
            'The Sealab',
            '63 Flushing Ave',
            'Building 3, Suite 1108',
            'Brooklyn, NY 11205',
            'info@thesealab.com',
        ];
        pdf.setFontSize(10);
        let infoStartY = 15;

        companyInfo.forEach((line) => {
            pdf.text(line, pageWidth - 14, infoStartY, {align: 'right'});
            infoStartY += 12;
        });

        pdf.setFontSize(18);
        pdf.text(`${roomName} Room Layout`, pageWidth / 2, 80, {align: 'center'});

        const dataURL = stageRef.current?.toDataURL({pixelRatio: 2});

        if (!dataURL || !dataURL.startsWith('data:image/png;base64,')) {
            console.error('Invalid or undefined dataURL from Konva stage');
            return;
        }

        const pageHeight = pdf.internal.pageSize.getHeight();
        const margin = 40;
        const maxWidth = pageWidth - (margin * 2);
        const maxHeight = pageHeight - 140;

        const stage = stageRef.current;
        if (!stage) {
            console.error('Stage reference is undefined');
            return;
        }

        const imageWidth = stage.width();
        const imageHeight = stage.height();

        const scale = Math.min(
            maxWidth / imageWidth,
            maxHeight / imageHeight
        );

        const scaledWidth = Math.floor(imageWidth * scale);
        const scaledHeight = Math.floor(imageHeight * scale);

        const x = Math.floor((pageWidth - scaledWidth) / 2);
        const y = 120;

        try {
            const img = new Image();
            img.src = dataURL;

            await new Promise((resolve, reject) => {
                img.onload = resolve;
                img.onerror = reject;
            });

            pdf.addImage(img, 'PNG', x, y, scaledWidth, scaledHeight);
            pdf.save(`${fileName}.pdf`)
        } catch (error) {
            console.error('Error adding image to PDF:', error);
        }
    };

    const handlePositionNameChange = (e) => {
        const newName = e.target.value;
        setSelectedItem({
            ...selectedItem,
            positionName: newName
        });

        if (selectedItemIndex != null && selectedItemType === 'cabinet') {
            cabinets[selectedItemIndex].positionName = newName;
            const cabinet = cabinets[selectedItemIndex];
            if (cabinet.groupId) {
                const groupedCabinets = cabinets.filter(obj => obj.groupId === cabinet.groupId);
                const groupedCartItems = cartItems.filter(obj => obj.groupId === cabinet.groupId);

                const baseName = newName.trim().replace(/_\d{3}$/, '');
                if (baseName) {
                    groupedCabinets.forEach((groupedCabinet, index) => {
                        groupedCabinet.positionName = `${baseName}_${String(index + 1).padStart(3, '0')}`;
                    });
                    groupedCartItems.forEach((cartItem, index) => {
                        cartItem.positionName = `${baseName}_${String(index + 1).padStart(3, '0')}`;
                    });
                } else {
                    groupedCabinets.forEach(groupedCabinet => {
                        groupedCabinet.positionName = '';
                    });
                    groupedCartItems.forEach(cartItem => {
                        cartItem.positionName = '';
                    });
                }
                setCabinets([...cabinets]);
            } else {
                setCabinets([...cabinets]);
            }
        }
    };

    const handleCabinetDimensionsChange = (e) => {
        if (e.key === 'Enter' && selectedItemIndex != null && selectedItemType != null) {
            validateAllDimensions(selectedItemIndex, selectedItemType);
        }
    };

    const handleZKeyDown = (e) => {
        if (e.key === 'Enter' && selectedItemIndex != null && selectedItemType != null) {
            if (validateZHeight(selectedItemIndex, selectedItemType, parseFloat(e.target.value))) {
                handleEditItem(selectedItemIndex, selectedItemType, {['z']: parseFloat(e.target.value)});
            }
        }
    };

    function calculateFontSize() {
        if (dimensions.width < 220)
            return 2;
        else if (dimensions.width > 220 && dimensions.width < 600)
            return 5;
        else
            return 10;
    }

    // Sort items by z so higher z are rendered last (on top)
    const sortedFillerItems = fillerItems.slice().sort((a, b) => (a.z || 0) - (b.z || 0));
    const sortedCabinets = cabinets.slice().sort((a, b) => (a.z || 0) - (b.z || 0));

    // Identify stacks of cabinets
    function identifyStacks(cabinetsList) {
        const stacks = [];
        const visited = new Set();
        for (let i = 0; i < cabinetsList.length; i++) {
            if (visited.has(cabinetsList[i].id)) continue;
            const stackGroup = [cabinetsList[i]];
            visited.add(cabinetsList[i].id);
            let added = true;
            while (added) {
                added = false;
                for (let j = 0; j < cabinetsList.length; j++) {
                    if (!visited.has(cabinetsList[j].id)) {
                        for (let k = 0; k < stackGroup.length; k++) {
                            if (checkOverlap(stackGroup[k], cabinetsList[j])) {
                                stackGroup.push(cabinetsList[j]);
                                visited.add(cabinetsList[j].id);
                                added = true;
                                break;
                            }
                        }
                    }
                }
            }
            stackGroup.sort((a, b) => (a.z || 0) - (b.z || 0));
            stacks.push(stackGroup);
        }
        return stacks;
    }

    const cabinetStacks = identifyStacks(sortedCabinets);

    const cabinetStackMap = new Map();
    cabinetStacks.forEach(stack => {
        const stackSize = stack.length;
        stack.forEach((cab, idx) => {
            cabinetStackMap.set(cab.id, {stackSize, indexInStack: idx});
        });
    });

    return (
        <>
            {/*{userLoggedIn ? <NavbarLoggedInComponent/> : <NavbarComponent/>}*/}
            <div className="drawing-tool-container container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <div className="drawing-tool-controls cabinet-properties-card card p-4 mt-4">
                            <h3 className="cart-inventory-items-title">
                                {selectedItemType === 'filler' ? 'Wall Properties' : 'Item Properties'}
                            </h3>
                            <div className="cabinet-properties-button-div">
                                <button
                                    className="btn btn-outline-secondary btn-sm cabinet-return-button"
                                    onClick={() => handleReturnToPanelClick()}
                                >
                                    <FontAwesomeIcon icon={faArrowAltCircleLeft}/>
                                </button>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="position-name" className="form-label">Item Name:</label>
                                <input
                                    type="text"
                                    id="position-name"
                                    name="position-name"
                                    value={selectedItem?.positionName || ''}
                                    onChange={handlePositionNameChange}
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="width" className="form-label">Width:</label>
                                <input
                                    type="number"
                                    id="width"
                                    name="width"
                                    value={selectedItem?.width || ''}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    onKeyDown={handleCabinetDimensionsChange}
                                    onBlur={handleInputBlur}
                                />
                                {dimensionErrors[selectedItemIndex]?.width && (
                                    <div className="cabinet-dimension-error">
                                        {dimensionErrors[selectedItemIndex]?.width}
                                    </div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="depth" className="form-label">Depth:</label>
                                <input
                                    type="number"
                                    id="depth"
                                    name="depth"
                                    value={selectedItem?.depth || ''}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    onKeyDown={handleCabinetDimensionsChange}
                                    onBlur={handleInputBlur}
                                />
                                {dimensionErrors[selectedItemIndex]?.depth && (
                                    <div className="cabinet-dimension-error">
                                        {dimensionErrors[selectedItemIndex]?.depth}
                                    </div>
                                )}
                            </div>

                            <div className="mb-3">
                                <label htmlFor="height" className="form-label">Height:</label>
                                <input
                                    type="number"
                                    id="height"
                                    name="height"
                                    value={selectedItem?.height || ''}
                                    onChange={handleInputChange}
                                    className="form-control"
                                    onKeyDown={handleCabinetDimensionsChange}
                                    onBlur={handleInputBlur}
                                />
                                {dimensionErrors[selectedItemIndex]?.height && (
                                    <div className="cabinet-dimension-error">
                                        {dimensionErrors[selectedItemIndex]?.height}
                                    </div>
                                )}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="z" className="form-label">Height from floor:</label>
                                <input
                                    type="number"
                                    id="z"
                                    name="z"
                                    min="0"
                                    max={ceilingHeight - (selectedItem?.height || 0)}
                                    value={selectedItem?.z || 0}
                                    onChange={handleZChange}
                                    onBlur={handleZBlur}
                                    onKeyDown={handleZKeyDown}
                                    className="form-control"
                                />
                                {dimensionErrors[selectedItemIndex]?.z && (
                                    <div className="cabinet-dimension-error">
                                        {dimensionErrors[selectedItemIndex]?.z}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="cart-inventory-items-card card p-4 mt-4">
                            <h3 className="cart-inventory-items-title mb-4">Cart Items</h3>
                            <div className="cart-inventory-items-grid">
                                {cartItems.map((item) => (
                                    <div
                                        key={item?.itemId}
                                        className="cart-inventory-item"
                                        draggable
                                        onDragStart={(e) => handleCartItemDragStart(e, item)}
                                    >
                                        <div
                                            className="cart-inventory-item-rectangle"
                                            style={{
                                                width: '100%',
                                                height: `100%`,
                                                backgroundColor: '#e0e0e0',
                                                border: '1px solid #555',
                                            }}
                                        >
                                            <span className="cart-inventory-item-text">
                                                {item?.positionName ? item.positionName.toUpperCase() : `${item?.width}" x ${item?.depth}"`}
                                            </span>
                                            <span className="cart-inventory-item-quantity">
                                                ({item?.quantity})
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="cart-inventory-items-card card p-4 mt-4">
                            <h3 className="cart-inventory-items-title mb-4">Filler Objects</h3>
                            <div className="cart-inventory-items-grid">
                                {fillerInventory.map((item) => (
                                    <div
                                        key={item?.itemId}
                                        className="cart-inventory-item"
                                        draggable
                                        onDragStart={(e) => handleFillerDragStart(e, item)}
                                    >
                                        <div
                                            className="cart-inventory-item-rectangle"
                                            style={{
                                                width: '100%',
                                                height: `100%`,
                                                backgroundColor: '#0077be',
                                                border: '0.1px solid #0077be',
                                            }}
                                        >
                                            <span className="cart-inventory-item-text" style={{color: 'white'}}>
                                                {item?.positionName ? item.positionName.toUpperCase() : `${item?.width}" x ${item?.depth}"`}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="drawing-tool-controls card p-4 mb-4">
                            <h2 className="drawing-tool-title mb-4">Layout Designer</h2>
                            <div className="mb-3">
                                <label htmlFor="room-name" className="form-label">Project Name:</label>
                                <input
                                    type="text"
                                    id="room-name"
                                    name="name"
                                    value={roomName}
                                    onChange={handleRoomNameChange}
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="room-shape" className="form-label">Room Shape:</label>
                                <select
                                    id="room-shape"
                                    value={roomShape}
                                    onChange={(e) => setRoomShape(e.target.value)}
                                    className="form-select"
                                >
                                    <option value="rectangular">Rectangular</option>
                                    <option value="l-shaped">L Shaped</option>
                                </select>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="room-width" className="form-label">Room Width (inches):</label>
                                <input
                                    type="number"
                                    id="room-width"
                                    name="width"
                                    value={dimensions.width}
                                    onChange={handleDimensionChange}
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="room-height" className="form-label">Room Height (inches):</label>
                                <input
                                    type="number"
                                    id="room-height"
                                    name="height"
                                    value={dimensions.height}
                                    onChange={handleDimensionChange}
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="ceiling-height" className="form-label">Ceiling Height (inches):</label>
                                <input
                                    type="number"
                                    id="ceiling-height"
                                    name="ceilingHeight"
                                    value={ceilingHeight}
                                    onChange={(e) => setCeilingHeight(parseFloat(e.target.value))}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <button
                            className="btn btn-dark save-chngs-btn"
                            onClick={() => handleClearLayout()}
                        >Clear Layout
                        </button>
                        <button
                            className="btn btn-dark save-chngs-btn"
                            onClick={() => handleSaveChanges()}>
                            Record Layout
                        </button>
                        <button
                            className="btn btn-dark save-chngs-btn"
                            onClick={handleExportToPDF}>
                            Export to PDF
                        </button>
                        <div
                            className="drawing-tool-stage-wrapper"
                            onDragOver={handleStageDragOver}
                            onDrop={handleStageDrop}
                            ref={containerRef}
                            style={{
                                width: dimensions.width * (800 / dimensions.width) + 'px',
                                height: dimensions.height * (800 / dimensions.width) + 'px',
                                overflow: 'auto',
                            }}
                        >
                            <Stage
                                width={dimensions.width * (800 / dimensions.width)}
                                height={dimensions.height * (800 / dimensions.width)}
                                ref={stageRef}
                                className="drawing-tool-stage"
                                onClick={checkDeselect}
                                scale={{x: 800 / dimensions.width, y: 800 / dimensions.width}}
                            >
                                <Layer>
                                    {roomShape === 'rectangular' && (
                                        <Rect
                                            x={0}
                                            y={0}
                                            width={dimensions.width}
                                            height={dimensions.height}
                                            fill="white"
                                            stroke="black"
                                            strokeWidth={WALL_THICKNESS}
                                        />
                                    )}
                                    {roomShape === 'l-shaped' && (
                                        <Line
                                            points={[
                                                0, 0,
                                                dimensions.width / 2, 0,
                                                dimensions.width / 2, dimensions.height / 2,
                                                dimensions.width, dimensions.height / 2,
                                                dimensions.width, dimensions.height,
                                                0, dimensions.height,
                                                0, 0,
                                            ]}
                                            fill="white"
                                            stroke="black"
                                            strokeWidth={WALL_THICKNESS}
                                            closed
                                        />
                                    )}
                                    {sortedFillerItems.map((filler, i) => {
                                        const fillColor = 'rgba(0,119,190,1)';
                                        return (
                                            <Group
                                                key={filler.id || filler.itemId}
                                                id={filler?.id?.toString()}
                                                x={filler.x}
                                                y={filler.y}
                                                rotation={filler.rotation || 0}
                                                draggable
                                                onClick={() => handleGroupClick(filler, i, true)}
                                                onTransformEnd={() => handleTransformEnd(filler, true)}
                                                onDragMove={(e) => handleDragMoveItem('filler', i, e)}
                                                onDragEnd={(e) => handleDragEndItem('filler', i, e)}
                                            >
                                                <Rect
                                                    width={parseFloat(filler?.width)}
                                                    height={parseFloat(filler?.depth)}
                                                    fill={fillColor}
                                                    stroke={fillColor}
                                                    strokeWidth={0.1}
                                                />
                                            </Group>
                                        );
                                    })}

                                    {sortedCabinets.map((cabinet, i) => {
                                        const z = cabinet.z || 0;
                                        const stackInfo = cabinetStackMap.get(cabinet.id);
                                        let baseColor = 'rgb(200,200,200)';
                                        let textY;
                                        if (stackInfo && stackInfo.stackSize > 1) {
                                            // Multiple cabinets stacked
                                            const {stackSize, indexInStack} = stackInfo;
                                            // Interpolate opacity from 0.2 (top) to 1.0 (bottom)
                                            const opacity = 0.2 + ((indexInStack) * (0.8 / (stackSize - 1)));
                                            baseColor = `rgba(100,100,255,${opacity})`;
                                            // Offset text position based on indexInStack
                                            textY = parseFloat(cabinet?.depth) + (indexInStack * (calculateFontSize() + 2));
                                        } else {
                                            // Single cabinet scenario
                                            if (cabinet.stackedOverAnother) {
                                                baseColor = 'rgba(100,100,255,0.5)';
                                            } else {
                                                baseColor = 'rgb(200,200,200)';
                                            }
                                            textY = cabinet.stackedOverAnother
                                                ? (parseFloat(cabinet?.depth) / 2) - (calculateFontSize() / 2)
                                                : parseFloat(cabinet?.depth) + 0.75;
                                        }

                                        return (
                                            <Group
                                                key={cabinet.id || cabinet.itemId}
                                                id={cabinet?.id?.toString()}
                                                x={cabinet.x}
                                                y={cabinet.y}
                                                rotation={cabinet.rotation || 0}
                                                draggable
                                                onClick={() => handleGroupClick(cabinet, cabinets.indexOf(cabinet), false)}
                                                onTransformEnd={() => handleTransformEnd(cabinet, false)}
                                                onDragMove={(e) => handleDragMoveItem('cabinet', cabinets.indexOf(cabinet), e)}
                                                onDragEnd={(e) => handleDragEndItem('cabinet', cabinets.indexOf(cabinet), e)}
                                            >
                                                <Rect
                                                    width={parseFloat(cabinet?.width) - 0.1}
                                                    height={parseFloat(cabinet?.depth) - 0.1}
                                                    fill={baseColor}
                                                    stroke="#555"
                                                    strokeWidth={0.1}
                                                />
                                                <Text
                                                    text={cabinet?.positionName}
                                                    fill="black"
                                                    fontStyle="bold"
                                                    align="center"
                                                    fontSize={calculateFontSize()}
                                                    x={0}
                                                    y={textY}
                                                    width={parseFloat(cabinet?.width)}
                                                />
                                            </Group>
                                        );
                                    })}
                                    {selectedId && (
                                        <>
                                            <Transformer
                                                ref={transformerRef}
                                                rotationSnaps={[0, 90, 180, 270]}
                                                boundBoxFunc={(oldBox, newBox) => newBox}
                                                flipEnabled={false}
                                                rotationEnabled={false}
                                                resizeEnabled={false}
                                                rotationSnapTolerance={45}
                                            />
                                            <Transformer
                                                ref={transformerRefFiller}
                                                rotationSnaps={[0, 90, 180, 270]}
                                                boundBoxFunc={(oldBox, newBox) => newBox}
                                                flipEnabled={false}
                                                rotationEnabled={true}
                                                resizeEnabled={true}
                                                rotationSnapTolerance={45}
                                            />
                                        </>
                                    )}
                                </Layer>
                            </Stage>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DrawingTool;




