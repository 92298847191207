// components/ShowRoom/Search/SearchBar.js

import React from 'react';

/**
 * A reusable SearchBar component that allows users to input search terms.
 * This component is typically used for filtering or searching data within a list or catalog.
 *
 * @param {string} value - The current value of the search input.
 * @param {function} onChange - Callback function triggered when the input value changes.
 */
const SearchBar = ({value, onChange}) => (
    <div className="showroom-search-container">
        {/* Input field for search functionality */}
        <input
            type="text" // Defines the input type as text
            className="form-control showroom-search-input" // Adds CSS classes for styling the input
            placeholder="Search cabinets..." // Placeholder text displayed when the input is empty
            value={value} // Binds the input's value to the `value` prop
            onChange={onChange} // Calls the `onChange` function when the user types in the input
        />
    </div>
);

export default SearchBar;
