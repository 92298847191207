// src/components/OrderPageComponents/hooks/useFetchConfigOptions.js
import {useEffect} from 'react';
import axios from "axios";

export function useFetchConfigOptions(baseURL, setConfigOptions, setJointOptions, setDrawerOptions, setHingeOptions, setBackPanelOptions) {
    useEffect(() => {
        axios.get(`${baseURL}/api/cabinet-features/getAllConfigOptions`)
            .then((res) => {
                setConfigOptions(res.data);
                setJointOptions(res.data
                    .filter(j => j.setting === 'Joint Method' && j.isVisible === '1')
                    .map(j => j.description));
                setDrawerOptions(res.data
                    .filter(d => d.setting === 'Drawer Type' && d.isVisible === '1')
                    .map(d => d.description));
                setHingeOptions(res.data
                    .filter(h => h.setting === 'Hinge Plate' && h.isVisible === '1')
                    .map(h => h.description));
                setBackPanelOptions(res.data
                    .filter(b => b.setting === 'Back Panel Method' && b.isVisible === '1')
                    .map(b => b.description));
            });
    }, [baseURL, setConfigOptions, setJointOptions, setDrawerOptions, setHingeOptions, setBackPanelOptions]);
}
