// src/components/OrderPageComponents/hooks/useEdgeBandingSync.js
import {useEffect} from 'react';

export function useEdgeBandingSync(itemProperties, setEdgeBanding) {
    useEffect(() => {
        setEdgeBanding({
            top: itemProperties.edgeBandingTop === 'true',
            right: itemProperties.edgeBandingRight === 'true',
            bottom: itemProperties.edgeBandingBottom === 'true',
            left: itemProperties.edgeBandingLeft === 'true',
        });
    }, [itemProperties.edgeBandingTop, itemProperties.edgeBandingRight, itemProperties.edgeBandingBottom, itemProperties.edgeBandingLeft, setEdgeBanding]);
}
