// components/OrderMatHardware/DoorDimensionsTable.js

import React from 'react';

/**
 * DoorDimensionsTable Component
 *
 * This component renders a table displaying details of door dimensions.
 *
 * @param {Array} doorDimensions - An array of objects representing door dimension data.
 * @param {Object} doorDimensions[] - Individual door dimension information.
 * @param {string} doorDimensions[].pos - The position name or item name of the door.
 * @param {string} doorDimensions[].name1 - The door side (e.g., Left, Right, etc.).
 * @param {string} doorDimensions[].matId - The material ID used for the door.
 * @param {number} doorDimensions[].width - The width of the door (in inches).
 * @param {number} doorDimensions[].height - The height of the door (in inches).
 *
 * @returns {JSX.Element | null} - The rendered table displaying door dimensions or null if no data is available.
 */
const DoorDimensionsTable = ({doorDimensions}) => {
    // If doorDimensions is null or empty, return null to avoid rendering an empty table
    if (!doorDimensions || doorDimensions.length === 0) return null;

    return (
        <div className="col-md-6 mb-4">
            <h4 className="mat-hware-titles">Door Dimensions</h4> {/* Section title */}
            <table className="table table-striped mat-hware-tables">
                <thead>
                <tr>
                    <th>Item Name</th>
                    {/* Column header for the position or item name */}
                    <th>Door Side</th>
                    {/* Column header for the door side (e.g., Left, Right) */}
                    <th>Material</th>
                    {/* Column header for the material ID */}
                    <th>Width</th>
                    {/* Column header for the width of the door */}
                    <th>Height</th>
                    {/* Column header for the height of the door */}
                </tr>
                </thead>
                <tbody>
                {/* Iterate over doorDimensions to render rows for each door dimension */}
                {doorDimensions.map((doorDimension, index) => (
                    <tr key={index}>
                        <td>{doorDimension.pos}</td>
                        {/* Render the position or item name */}
                        <td>{doorDimension.name1}</td>
                        {/* Render the door side (Left/Right) */}
                        <td>{doorDimension.matId}</td>
                        {/* Render the material ID */}
                        <td>{doorDimension.width}"</td>
                        {/* Render the width in inches */}
                        <td>{doorDimension.height}"</td>
                        {/* Render the height in inches */}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default DoorDimensionsTable;
