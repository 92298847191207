// src/components/OrderPageComponents/EdgebandingSection.js

import React from 'react';
import {getTooltipContent, renderDropdown} from './utils/renderUtils';

/**
 * Component for managing edgebanding options in the Order Form.
 *
 * @param {Object} itemProperties - Properties of the item being configured.
 * @param {Object} edgeBanding - State object for selected edge banding sides.
 * @param {Function} setEdgeBanding - Function to update the edge banding state.
 * @param {Array} edgebandingOptions - List of available edgebanding options.
 * @param {Function} handleInputChange - Function to handle changes in the input fields.
 * @param {Function} handleToolTipClick - Function to handle tooltip clicks.
 */
function EdgebandingSection({
                                itemProperties,
                                edgeBanding,
                                setEdgeBanding,
                                edgebandingOptions,
                                handleInputChange,
                                handleToolTipClick
                            }) {
    /**
     * Toggles the selected state of a specific edge banding side.
     *
     * @param {string} position - The edge position to toggle (e.g., 'top', 'right').
     */
    const handleEdgeBandingChange = (position) => {
        setEdgeBanding((prevEdgeBanding) => ({
            ...prevEdgeBanding,
            [position]: !prevEdgeBanding[position],
        }));
    };

    /**
     * Renders the edge banding options for items with specific serial numbers.
     *
     * @returns {JSX.Element|null} The edge banding options or null if not applicable.
     */
    const renderEdgebandingOptions = () => {
        // Only render for specific item serial numbers (e.g., starting with 'LP').
        if (!itemProperties?.serialNumber?.startsWith('LP')) return null;

        return (
            <div className="order-form__edgebanding-options">
                <label htmlFor="edgeBandingType" className="order-form__label order-form__label--bold">
                    Edgebanding Type
                    <span className="order-form__tooltip-trigger" onClick={() => handleToolTipClick('edgeBandingType')}>
                        <span className="order-form__tooltip-content">
                            {getTooltipContent('edgeBandingType')}
                        </span>
                    </span>
                </label>
                <select
                    id="edgeBandingType"
                    className="order-form__select"
                    value={itemProperties?.edgeBandingType}
                    onChange={(e) => handleInputChange({target: {value: e.target.value}}, 'edgeBandingType')}
                >
                    {edgebandingOptions.map((option) => (
                        <option key={option.id} value={option.englishDescription}>
                            {option.englishDescription}
                        </option>
                    ))}
                </select>

                <div className="order-form__edgebanding-square-container">
                    {/* Visual layout for edge banding selection */}
                    <div className="order-form__edgebanding-square">
                        {/* Top edge */}
                        <div
                            className={`order-form__edgebanding-edge order-form__edgebanding-edge--top ${
                                edgeBanding.top ? 'order-form__edgebanding-edge--selected' : ''
                            }`}
                            onClick={() => handleEdgeBandingChange('top')}
                        >
                            <span className="order-form__edgebanding-label">Width</span>
                        </div>
                        {/* Right edge */}
                        <div
                            className={`order-form__edgebanding-edge order-form__edgebanding-edge--right ${
                                edgeBanding.right ? 'order-form__edgebanding-edge--selected' : ''
                            }`}
                            onClick={() => handleEdgeBandingChange('right')}
                        >
                            <span className="order-form__edgebanding-label order-form__edgebanding-label--vertical">
                                Length
                            </span>
                        </div>
                        {/* Bottom edge */}
                        <div
                            className={`order-form__edgebanding-edge order-form__edgebanding-edge--bottom ${
                                edgeBanding.bottom ? 'order-form__edgebanding-edge--selected' : ''
                            }`}
                            onClick={() => handleEdgeBandingChange('bottom')}
                        >
                            <span className="order-form__edgebanding-label">Width</span>
                        </div>
                        {/* Left edge */}
                        <div
                            className={`order-form__edgebanding-edge order-form__edgebanding-edge--left ${
                                edgeBanding.left ? 'order-form__edgebanding-edge--selected' : ''
                            }`}
                            onClick={() => handleEdgeBandingChange('left')}
                        >
                            <span className="order-form__edgebanding-label order-form__edgebanding-label--vertical">
                                Length
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="order-form__section">
            <h3 className="order-form__section-title">Edgebanding</h3>
            {/* Render dropdown for Case Edge if applicable */}
            {itemProperties.caseEdge !== '0' &&
                !itemProperties?.serialNumber?.startsWith('LP') &&
                renderDropdown(
                    'Case Edge',
                    'caseEdge',
                    edgebandingOptions.map((o) => o.englishDescription),
                    undefined,
                    false,
                    itemProperties,
                    handleInputChange,
                    handleToolTipClick
                )}
            {/* Render dropdown for Front Edge if applicable */}
            {itemProperties.frontEdge !== '0' &&
                !itemProperties?.serialNumber?.startsWith('LP') &&
                renderDropdown(
                    'Front Edge',
                    'frontEdge',
                    edgebandingOptions.map((o) => o.englishDescription),
                    undefined,
                    false,
                    itemProperties,
                    handleInputChange,
                    handleToolTipClick
                )}
            {/* Render specific edge banding options for LP serial numbers */}
            {renderEdgebandingOptions()}
        </div>
    );
}

export default EdgebandingSection;
