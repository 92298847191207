import React from 'react';

const OrderManagementGuide = () => {
    return (
        <section id="order-management" className="mt-5 min-vh-100">
            <h2 className="sealab-tutorial__section-title">Order Management and Flexible Production Planning</h2>

            <div className="guide-content space-y-6">
                {/* Documentation Access */}
                <div>
                    <p>
                        Once your order is submitted, you'll gain access to comprehensive
                        documentation through your customer portal, typically within one
                        business day. This documentation serves as both a detailed
                        specification package and a versatile production planning tool
                        that you can adapt to your specific workflow needs.
                    </p>
                </div>

                {/* Documentation Package */}
                <div>
                    <h3 className="text-xl font-semibold mb-3">Documentation Package</h3>
                    <p>
                        The documentation package includes dimensional drawings, complete
                        specifications, and detailed component lists that can serve multiple
                        purposes. You may choose to use these specifications in various ways
                        - as cutting lists for your own workshop, as ordering specifications
                        for third-party vendors, or as production documents for our
                        manufacturing team.
                    </p>
                    <p className="mt-3">
                        The component lists are particularly valuable, providing precise
                        dimensions for all parts including drawer boxes, doors, and panels.
                        These lists are deliberately designed to be vendor-agnostic, allowing
                        you to source components from any supplier you choose. For instance,
                        you might decide to order drawer boxes from a specialized manufacturer
                        while fabricating doors in your own shop.
                    </p>
                </div>

                {/* Quote Status and Modifications */}
                <div>
                    <h3 className="text-xl font-semibold mb-3">Quote Status and Modifications</h3>
                    <p>
                        Your order remains in a flexible quote status until payment is processed.
                        During this period, you can freely modify the configuration by returning
                        it to cart status. While each modification requires reprocessing of the
                        documentation, this flexibility allows you to:
                    </p>
                    <ul className="list-disc pl-6 space-y-2 mt-3">
                        <li>Fine-tune specifications based on client feedback</li>
                        <li>Adjust configurations as project requirements evolve</li>
                        <li>Compare different material or construction options</li>
                        <li>Coordinate timing with other aspects of your project</li>
                    </ul>
                </div>

                {/* Production Options */}
                <div>
                    <h3 className="text-xl font-semibold mb-3">Production Options</h3>
                    <p>
                        When you're ready to proceed with production, you can select which
                        elements you want us to provide. Our a la carte options include:
                    </p>
                    <ul className="list-disc pl-6 space-y-2 mt-3">
                        <li>CNC-cut and edge-banded parts</li>
                        <li>Drawer box fabrication</li>
                        <li>Hardware supply (Coming Soon)</li>
                        <li>Assembly services (Coming Soon)</li>
                        <li>Finishing services (Coming Soon)</li>
                        <li>Delivery coordination (Coming Soon)</li>
                    </ul>
                </div>

                {/* Flexibility and Project Management */}
                <div>
                    <p>
                        This flexible approach allows you to maintain control over your project's
                        timeline and budget while leveraging our production capabilities where
                        they best serve your needs. Each element can be added to your order
                        independently, ensuring you only pay for the services that add value to
                        your specific workflow.
                    </p>
                    <p className="mt-3">
                        The customer portal serves as your project management hub throughout
                        this process, providing continuous access to all documentation and
                        order status updates. This system enables you to make informed
                        decisions about production timing and maintain clear communication
                        channels with our team as your project progresses.
                    </p>
                </div>
            </div>
        </section>
    );
};

export default OrderManagementGuide;