// src/components/OrderPageComponents/hooks/useFetchMaterialsAndEdgebanding.js
import {useEffect} from 'react';
import DataService from '../../../api/DataService';

export function useFetchMaterialsAndEdgebanding(setMaterials, setEdgebandingOptions) {
    useEffect(() => {
        window.scrollTo({top: 0, behavior: "instant"});
        const fetchMaterials = async () => {
            try {
                const response = await DataService.getAllMaterials();
                const sortedMaterials = response.sort((a, b) => {
                    if (a.description.startsWith('3/4') && !b.description.startsWith('3/4')) {
                        return -1;
                    } else if (!a.description.startsWith('3/4') && b.description.startsWith('3/4')) {
                        return 1;
                    }
                    return a.description.localeCompare(b.description);
                });
                setMaterials(sortedMaterials);
            } catch (error) {
                console.error('Error fetching materials:', error);
            }
        };

        const fetchEdgebandingOptions = async () => {
            try {
                const response = await DataService.getAllEdgebanding();
                setEdgebandingOptions(response);
            } catch (error) {
                console.error('Error fetching edgebanding options:', error);
            }
        };

        fetchMaterials();
        fetchEdgebandingOptions();
    }, [setMaterials, setEdgebandingOptions]);
}
