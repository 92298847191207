// import {createSlice} from '@reduxjs/toolkit';
//
// const initialState = {
//     articles: {},
//     dimensions: {
//         width: 0,
//         height: 0,
//         depth: 0
//     }
// };
//
// const suggestedArticlesSlice = createSlice({
//     name: 'suggestedArticles',
//     initialState,
//     reducers: {
//         setSuggestedArticles: (state, action) => {
//             const {articles, dimensions} = action.payload;
//             state.articles = articles.reduce((acc, article) => {
//                 acc[article.serial_number] = article;
//                 return acc;
//             }, {});
//             state.dimensions = dimensions || state.dimensions;
//         },
//         clearSuggestedArticles: (state) => {
//             state.articles = {};
//             state.dimensions = initialState.dimensions;
//         }
//     }
// });
//
// export const {setSuggestedArticles, clearSuggestedArticles} = suggestedArticlesSlice.actions;
//
// // Selectors
// export const selectArticleBySerial = (state, serialNumber) =>
//     state.suggestedArticles.articles[serialNumber];
// export const selectDimensions = (state) => state.suggestedArticles.dimensions;
//
// export default suggestedArticlesSlice.reducer;


import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    articles: {},
    dimensions: {
        width: 0,
        height: 0,
        depth: 0,
    },
    dimensionRanges: {
        width_range: [0, 0],
        height_range: [0, 0],
        depth_range: [0, 0],
    },
};

const suggestedArticlesSlice = createSlice({
    name: 'suggestedArticles',
    initialState,
    reducers: {
        setSuggestedArticles: (state, action) => {
            const {articles, dimensions, dimensionRanges} = action.payload;
            state.articles = articles.reduce((acc, article) => {
                acc[article.serial_number] = article;
                return acc;
            }, {});
            state.dimensions = dimensions || initialState.dimensions;
            state.dimensionRanges = dimensionRanges || initialState.dimensionRanges;
        },
        clearSuggestedArticles: (state) => {
            state.articles = {};
            state.dimensions = initialState.dimensions;
            state.dimensionRanges = initialState.dimensionRanges;
        },
    },
});

export const {setSuggestedArticles, clearSuggestedArticles} = suggestedArticlesSlice.actions;

// Selectors
export const selectArticleBySerial = (state, serialNumber) =>
    state.suggestedArticles.articles[serialNumber];
export const selectDimensions = (state) => state.suggestedArticles.dimensions;
export const selectDimensionRanges = (state) => state.suggestedArticles.dimensionRanges;

export default suggestedArticlesSlice.reducer;
