// src/components/OrderPageComponents/GlobalSettingsSection.js

import React from 'react';

/**
 * Component for managing the Global Settings toggle in the Order Form.
 *
 * @param {boolean} applyGlobalSettings - Current state of the global settings toggle.
 * @param {function} setApplyGlobalSettings - Function to update the state of the global settings toggle.
 */
function GlobalSettingsSection({applyGlobalSettings, setApplyGlobalSettings}) {
    return (
        <div className="order-form__global-settings">
            {/* Checkbox for toggling the application of global settings */}
            <label htmlFor="applyGlobalSettings" className="order-form__checkbox-label">
                <input
                    type="checkbox"
                    id="applyGlobalSettings"
                    checked={applyGlobalSettings} // Bind the checkbox to the state
                    onChange={() => setApplyGlobalSettings(!applyGlobalSettings)} // Toggle state on change
                    className="order-form__checkbox"
                />
                Apply Global Settings? {/* Label text to explain the checkbox */}
            </label>
        </div>
    );
}

export default GlobalSettingsSection;
