// src/components/OrderPageComponents/hooks/useGlobalSettingsEffect.js
import {useEffect} from 'react';

export function useGlobalSettingsEffect(applyGlobalSettings, globalSettings, itemProperties, setItemProperties) {
    useEffect(() => {
        if (applyGlobalSettings) {
            setItemProperties(prevProps => ({
                ...prevProps,
                caseMaterial: globalSettings?.caseMaterial,
                frontMaterial: globalSettings?.frontMaterial,
                backPanelMaterial: globalSettings?.backPanelMaterial,
                caseEdge: itemProperties?.caseEdge !== '0' ? globalSettings?.caseEdge : '0',
                frontEdge: itemProperties?.frontEdge !== '0' ? globalSettings?.frontEdge : '0',
                backPanel: globalSettings?.backPanel,
                drawerType: globalSettings?.drawerType,
                hingePlate: globalSettings?.hingePlate,
                jointMethod: globalSettings?.jointMethod,
                globalSettingsApplied: true
            }));
        }
    }, [applyGlobalSettings, globalSettings, itemProperties?.caseEdge, itemProperties?.frontEdge, setItemProperties]);
}
